import React from "react";
import { Link } from "react-router-dom";
import { MenuItem, Select } from "@material-ui/core";

// Components and helpers
import { FilterInput, FilterDateRange } from "../../components/System";
import { checkDateRange, editComponent, sortByDate } from "../../shared/tables/helperTables";

// Sorting helper for main table
export const sortingPhraseHelper = [
  { key: "accepted", path: "orders.accepted" },
  { key: "number", path: "orders.number" },
  { key: "contact", path: "contacts.name" },
  { key: "company", path: "contacts.company" },
  { key: "designer_code", path: "designers.code" },
  { key: "season_id", path: "seasons.code" },
  { key: "barcode_status", path: "combinations.error" },
  { key: "sent_to_supplier", path: "orders.sent_to_supplier" },
  { key: "ready_to_sync", path: "orders.ready_to_sync" },
  { key: "shipment_date", path: "orders.shipment_date" },
];

export const successQuery =
  "((SELECT COUNT(*) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE (products.order_id = orders.id AND combinations.quantity > 0 AND (combinations.error IS NOT NULL OR combinations.error <> '')))) = 0 AND\n" +
  "((SELECT COUNT(*) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE (products.order_id = orders.id AND combinations.quantity > 0 AND (combinations.barcode IS NOT NULL AND combinations.barcode <> '')))) = (SELECT COUNT(*) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE (products.order_id = orders.id AND combinations.quantity > 0)) AND  (SELECT COUNT(*) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE (products.order_id = orders.id AND combinations.quantity > 0)) > 0";

export const readyQuery =
  "((SELECT COUNT(*) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE products.order_id = orders.id AND combinations.quantity > 0 AND (combinations.barcode IS NULL))) > 0 AND \n" +
  "((SELECT COUNT(*) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE products.order_id = orders.id AND combinations.quantity > 0 AND (combinations.in_progress <> false OR combinations.in_progress IS NOT NULL))) = 0 AND ((SELECT COUNT(*) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE products.order_id = orders.id AND combinations.quantity > 0 AND (combinations.error IS NOT NULL OR combinations.error <> ''))) = 0";

// Columns definition for main table
export const barcodesOrdersColumns = [
  {
    title: "Order number",
    field: "number",
    editable: "never",
    editComponent: (props) => editComponent(props, true),
    type: "text",
    filterComponent: (term) => {
      const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
      return <FilterInput term={term} onChange={onChange} />;
    },
    render: (rowData) => {
      return (
        <div>
          <Link to={"/orders/" + rowData.id}>{rowData.number}</Link>
        </div>
      );
    },
  },
  {
    title: "Contact person",
    field: "contact",
    editable: "never",
    type: "text",
    filterComponent: (term) => {
      const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
      return <FilterInput term={term} onChange={onChange} />;
    },
  },
  {
    title: "Company",
    field: "company",
    editable: "never",
    type: "text",
    filterComponent: (term) => {
      const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
      return <FilterInput term={term} onChange={onChange} />;
    },
  },
  {
    title: "Designer",
    field: "designer_code",
    editable: "never",
    type: "text",
    filterComponent: (term) => {
      const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
      return <FilterInput term={term} onChange={onChange} />;
    },
  },
  {
    title: "Season",
    field: "season_id",
    editable: "never",
    type: "text",
    filterComponent: (term) => {
      const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
      return <FilterInput term={term} onChange={onChange} />;
    },
  },
  {
    title: "Shipment Date",
    field: "shipment_date",
    editable: "never",
    type: "text",
    filterComponent: (term) => {
      const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
      return <FilterInput term={term} onChange={onChange} />;
    },
  },
  {
    title: "Barcode Status",
    field: "barcode_status",
    editable: "never",
    type: "select",
    filterComponent: (term) => {
      return (
        <Select
          value={term.columnDef.tableData.filterValue}
          onChange={(e) => {
            term.columnDef.tableData.filterValue = e.target.value;
            term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
          }}
        >
          <MenuItem value="ready">Ready to accept</MenuItem>
          <MenuItem value="in_progress">In progress</MenuItem>
          <MenuItem value="error">Error</MenuItem>
          <MenuItem value="success">Success</MenuItem>
        </Select>
      );
    },
    render: (rowData) => {
      return (
        <div>
          {rowData.barcode_status === "ready" && <p style={{ color: "#465798" }}>Ready to accept</p>}
          {rowData.barcode_status === "in_progress" && <p style={{ color: "#ffc000" }}>In progress</p>}
          {rowData.barcode_status === "error" && <p style={{ color: "#C00000" }}>Error</p>}
          {rowData.barcode_status === "success" && <p style={{ color: "#013220" }}>Success</p>}
        </div>
      );
    },
  },
  {
    title: "Sent to supplier",
    field: "sent_to_supplier",
    type: "dateType",
    filterComponent: (term) => {
      const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
      return <FilterDateRange onChange={onChange} />;
    },
    customFilterAndSearch: (term, rowData) => {
      return checkDateRange(term, rowData.sent_to_supplier);
    },
    customSort: (a, b, c, d) => sortByDate(a, b, d, "sent_to_supplier"),
  },
  {
    title: "Ready To Sync",
    field: "ready_to_sync",
    type: "boolean",
    editable: (term, rowData) =>
      (rowData && rowData.barcode_status === "ready") ||
      rowData.barcode_status === "success" ||
      rowData.barcode_status === "in_progress",
  },
];

// Columns definition for sub table
export const barcodesCombinationsColumns = [
  { title: "Hz id / Index", field: "hz_id", type: "text", editable: "never" },
  { title: "Barcode", field: "barcode", type: "text", editable: "never" },
  { title: "Article (Hz Title)", field: "hz_title", type: "text", editable: "never" },
  { title: "Collection Ref.", field: "collection_ref", type: "text", editable: "never" },
  { title: "Style Name", field: "style_name", type: "text", editable: "never" },
  { title: "Store Price PLN", field: "store_price", type: "text", editable: "never" },
  { title: "Color For Hz", field: "color_hz", type: "text", editable: "never" },
  { title: "Main Color", field: "color", type: "text", editable: "never" },
  { title: "Size", field: "size", type: "text", editable: "never" },
  { title: "Quantity in pcs", field: "quantity_in_pcs", type: "text", editable: "never" },
  { title: "Error", field: "error", type: "text", editable: "never" },
  {
    title: "Comments",
    field: "barcode_comments",
    type: "text",
    editComponent: (props) => editComponent(props, false),
  },
];
