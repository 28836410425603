import React, { ReactElement, FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText, Typography, Divider } from "@material-ui/core";

interface DrawerItemProps {
  link: string;
  onClick: () => void;
  icon: ReactElement;
  label: string;
  bottomDivider: boolean;
}

const DrawerItem: FC<DrawerItemProps> = ({ link, onClick, icon, label, bottomDivider }) => {
  return (
    <>
      <Link to={link} onClick={onClick} style={{ textDecoration: "none" }}>
        <ListItem button>
          <ListItemIcon style={{ marginRight: -20 }}>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={<StyledTypography variant="body1">{label}</StyledTypography>} />
        </ListItem>
      </Link>
      {bottomDivider && <Divider />}
    </>
  );
};

const StyledTypography = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 15;
  margin-bottom: -2;
  word-break: break-word;
  white-space: normal;
`;

export default DrawerItem;
