import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import { Grid, Paper, FormControl, Button, Input, MenuItem, Select } from "@material-ui/core";
import { ArrowBackIos, AddPhotoAlternate } from "@material-ui/icons";
import MaterialTable, { MTableToolbar } from "material-table";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/src/scss/react-checkbox-tree.scss";
import { Helmet } from "react-helmet";

// Custom components
import axios from "../../../axios-sw";
import { authRedirect, catchResponse } from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import classes from "./SyncProductsFormDetails.module.scss";
import { DateMask, TextMask, FriendlyUrl } from "../../../components/InputMasks/InputMasks";
import { tableIcons } from "../../../shared/tableVariables";
import { role } from "../../../config";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

DateMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

FriendlyUrl.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const sizesColumnsArray = [
  { field: "attribute_size", name: "Regular size" },
  { field: "attribute_size_trousers", name: "Trousers sizes" },
  { field: "attribute_size_kids", name: "Kids sizes" },
  { field: "attribute_size_shoes", name: "Shoes sizes" },
  { field: "attribute_size_hats", name: "Hats sizes" },
  { field: "attribute_size_shorts", name: "Shorts sizes" },
  { field: "attribute_size_socks", name: "Socks sizes" },
];

class SyncProductsFormDetails extends Component {
  state = {
    productData: {},
    combinations: [],
    loading: true,
    editable: {},
    redirection: null,
    categories: {},
    rawCategories: {},
    loadedCategories: false,
    options: [],
    nodes: [],
    checked: [],
    expanded: [],
    syncLoading: false,
    defaultCategory: {},
    combinationsColumns: [
      { title: "ID", field: "index", hidden: true },
      {
        title: "Images",
        field: "images",
        editable: "never",
        render: (rowData) => {
          if (typeof rowData.images !== "undefined") {
            const content = [];
            rowData.images.forEach((image, index) => {
              content.push(
                <div key={index}>
                  <img src={image.url} />
                </div>
              );
            });

            if (this.state.syncLoading) {
              return <Spinner />;
            }

            return (
              <Carousel width={100} showStatus={false} showThumbs={false}>
                {content}
              </Carousel>
            );
          }
        },
      },
      { title: "HZ ID", field: "hz_id", editable: "never" },
      { title: "Default?", field: "default_combination", type: "boolean" },
      {
        title: "System data",
        field: "system_data",
        editable: "never",
        render: (rowData) => {
          return (
            <>
              <p className={classes.TableInfo}>
                System color: <b>{rowData.color_for_website}</b>
              </p>
              <p className={classes.TableInfo}>
                System size: <b>{rowData.system_size}</b>
              </p>
            </>
          );
        },
      },
      { title: "Color", field: "color", type: "string" },
      { title: "Attribute: Size", field: "attribute_size", type: "string", hidden: true },
      { title: "Attribute: Size trousers", field: "attribute_size_trousers", type: "string", hidden: true },
      { title: "Attribute: Size kids", field: "attribute_size_kids", type: "string", hidden: true },
      { title: "Attribute: Size shoes", field: "attribute_size_shoes", type: "string", hidden: true },
      { title: "Attribute: Size hats", field: "attribute_size_hats", type: "string", hidden: true },
      { title: "Attribute: Size shorts", field: "attribute_size_shorts", type: "string", hidden: true },
      { title: "Attribute: Size socks", field: "attribute_size_socks", type: "string", hidden: true },
    ],
  };

  componentDidMount() {
    this.checkActionsAndEditablePermissions();
    this.indexOptions();
    this.indexCategoryTree();
    if (typeof this.props.location.state !== "undefined") {
      axios
        .get(`/sync-products/${this.props.location.state.productId}.json`, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .then((res) => {
          const columns = [...this.state.combinationsColumns];
          sizesColumnsArray.forEach((column) => {
            const index = columns.findIndex(({ field }) => field === column.field);
            columns[index].hidden = column.field !== res.data.product.size_attribute;
          });
          this.setState({
            productData: res.data.product,
            defaultCategory: res.data.product.default_category,
            combinationsColumns: columns,
            combinations: res.data.combinations,
            checked: res.data.product.categories,
            expanded: res.data.product.categories,
            loading: false,
          });
        })
        .catch((err) => {
          catchResponse(err);
        });
    } else {
      this.setState({ loading: false });
    }
  }

  indexCategoryTree = () => {
    const params = {
      output_format: "JSON",
      ws_key: "WR5NPJ885P44DWTF99H6WA173SPX9R8Z",
      display: "[id,id_parent,name]",
    };
    axios.get(`https://carry.pl/api/categories`, { params: params }).then((res) => {
      const categories = res.data.categories;

      for (var i = 0; i < categories.length; i++) {
        // Renaming
        categories[i]["label"] = categories[i]["name"];
        categories[i]["value"] = categories[i]["id"];
        delete categories[i]["name"];

        for (var j = 0; j < categories.length; j++) {
          if (categories[i]["id"] === parseInt(categories[j]["id_parent"])) {
            if (typeof categories[i]["children"] !== "undefined") {
              categories[i]["children"] = [...categories[i]["children"], categories[j]];
              delete categories[i]["id_parent"];
            } else {
              categories[i]["children"] = [categories[j]];
              delete categories[i]["id_parent"];
            }
          }
        }
      }

      this.setState({
        categories: categories[0],
        rawCategories: res.data.categories,
        loadedCategories: true,
      });
    });
  };

  indexOptions = () => {
    let product_options = [];
    let product_option_values = [];
    const params = {
      output_format: "JSON",
      ws_key: "WR5NPJ885P44DWTF99H6WA173SPX9R8Z",
      display: "full",
    };

    const setOptions = (object) => {
      let map = new Map();
      for (var i = 0; i < object.options.length; i++) {
        map.set(object.options[i]["id"], object.options[i]["name"]);
      }
      let obj = Object.fromEntries(map);
      let newState = { ...this.state.combinationsColumns };

      switch (object.name) {
        case "Kolor":
          newState[this.state.combinationsColumns.findIndex((column) => column.field === "color")].lookup = obj;
          break;
        case "Rozmiar":
          newState[this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size")].lookup =
            obj;
          break;
        case "Rozmiar spodni":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_trousers")
          ].lookup = obj;
          break;
        case "Rozmiar dziecięcy":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_kids")
          ].lookup = obj;
          break;
        case "Rozmiar butów":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_shoes")
          ].lookup = obj;
          break;
        case "Rozmiar kapelusza":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_hats")
          ].lookup = obj;
          break;
        case "Rozmiar szortów":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_shorts")
          ].lookup = obj;
          break;
        case "Rozmiar skarpet":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_socks")
          ].lookup = obj;
          break;
        default:
          break;
      }

      this.setState({ combinationsColumns: Object.values(newState) });
    };

    axios.get(`https://carry.pl/api/product_options`, { params: params }).then((res) => {
      product_options = res.data.product_options;
      axios.get(`https://carry.pl/api/product_option_values`, { params: params }).then((res) => {
        product_option_values = res.data.product_option_values;

        for (var i = 0; i < product_options.length; i++) {
          for (var j = 0; j < product_option_values.length; j++) {
            if (product_options[i]["id"] === parseInt(product_option_values[j]["id_attribute_group"])) {
              if (typeof product_options[i]["options"] !== "undefined") {
                product_options[i]["options"] = [...product_options[i]["options"], product_option_values[j]];
              } else {
                product_options[i]["options"] = [product_option_values[j]];
              }
            }
          }

          setOptions(product_options[i]);
        }
      });
    });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
      });
    else {
      this.setState({
        editable: {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateCombination(oldData.id, oldData, newData);
            }),
        },
      });
    }
  };

  updateCombination = (id, oldData, newData) => {
    const data = {
      // Old data to update row
      id: oldData.id,
      hz_id: oldData.hz_id,
      color_for_website: oldData.color_for_website,
      system_size: oldData.system_size,
      images: oldData.images,
      // Potential new data
      default_combination: newData.default_combination,
      color: newData.color,
      attribute_size: newData.attribute_size,
      attribute_size_trousers: newData.attribute_size_trousers,
      attribute_size_kids: newData.attribute_size_kids,
      attribute_size_shoes: newData.attribute_size_shoes,
      attribute_size_hats: newData.attribute_size_hats,
      attribute_size_shorts: newData.attribute_size_shorts,
      attribute_size_socks: newData.attribute_size_socks,
      product_id: this.state.productData.product_id,
    };
    axios
      .put(`/sync-combinations/${id}`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then(() => {
        let newCombinations = [...this.state.combinations];
        newCombinations[this.state.combinations.findIndex((column) => column.id === id)] = data;
        newCombinations.forEach((combination) => {
          if (data.default_combination) {
            if (combination.id === id) {
              combination.default_combination = data.default_combination;
            } else {
              combination.default_combination = false;
            }
          }
        });
        this.setState({ combinations: newCombinations });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  handlerSyncImages = () => {
    const syncRequest = async (hz_id) => {
      const data = {
        hz_id: hz_id,
        user_id: this.props.user_id,
        ip_address: this.props.ip_address,
      };
      await axios
        .post("/sync-images.json", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
        .then((res) => {
          let newCombinations = [...this.state.combinations];
          newCombinations.forEach((combination) => {
            res.data.combinations.forEach((syncCombination) => {
              if (combination.id === syncCombination.id) {
                combination.images = syncCombination.images;
              }
            });
          });
          this.setState({ combinations: newCombinations, syncLoading: false });
        })
        .catch((err) => {
          catchResponse(err);
        });
    };

    this.setState({ syncLoading: true });
    const combinations = this.state.combinations;
    const hzIdArray = [];
    combinations.forEach((combination) => {
      hzIdArray.push(combination.hz_id.substring(0, combination.hz_id.length - 1));
    });
    const uniqHzIdArray = hzIdArray.filter((v, i, a) => a.indexOf(v) === i);
    if (uniqHzIdArray.length !== 0) {
      uniqHzIdArray.map((hzId) => {
        syncRequest(hzId);
      });
    }
  };

  render() {
    let content = <Spinner />;
    if (this.state.loading === false) {
      content = (
        <>
          <Helmet>
            <title>Sync Products Form Details | Carry System</title>
          </Helmet>

          <ToastContainer />
          {authRedirect(this.props.token)}
          {this.state.redirection}

          <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "30vh" }}>
            <Grid item>
              <Paper style={{ padding: 20, width: 1200 }}>
                <Formik
                  initialValues={{
                    product_name:
                      this.state.productData.product_name === null ? "" : this.state.productData.product_name,
                  }}
                  onSubmit={(values, { setSubmitting, setFieldError }) => {
                    const displayCategories = this.state.checked.map((checkedId) => {
                      const rawCategory = this.state.rawCategories.find((x) => x.id === parseInt(checkedId));
                      return rawCategory.label;
                    });

                    const atLeastOneDefault = this.state.combinations.some((com) => com.default_combination);
                    const defaultCategoryIncluded = this.state.checked.some(
                      (checkedId) => parseInt(checkedId) === this.state.defaultCategory.value
                    );

                    if (
                      atLeastOneDefault &&
                      defaultCategoryIncluded &&
                      typeof this.state.defaultCategory.value !== "undefined"
                    ) {
                      const data = {
                        product_name: values.product_name,
                        description: this.state.productData.description,
                        short_description: this.state.productData.short_description,
                        friendly_url: this.state.productData.friendly_url,
                        categories: this.state.checked,
                        display_categories: displayCategories.join(" | "),
                        default_category: this.state.defaultCategory.value,
                        default_category_name: this.state.defaultCategory.label,
                      };
                      axios
                        .put(`/sync-products/${this.state.productData.id}`, data, {
                          headers: { Authorization: `Bearer ${this.props.token}` },
                        })
                        .then(() => {
                          this.setState({
                            redirection: <Redirect to={this.props.location.state.source} />,
                          });
                          toast.success("Sync product updated!");
                          setSubmitting(false);
                        })
                        .catch((err) => {
                          catchResponse(err);
                        });
                    } else {
                      if (!atLeastOneDefault) {
                        setFieldError("combinations_table", "At least one combination must be default!");
                        toast.error("At least one combination must be default!");
                      }
                      if (!defaultCategoryIncluded) {
                        setFieldError(
                          "default_category",
                          "Selected default category is not included in categories array!"
                        );
                        toast.error("Selected default category is not included in categories array!");
                      }
                      if (typeof this.state.defaultCategory.value === "undefined") {
                        setFieldError("default_category", "Product must to have default category!");
                        toast.error("Product must to have default category!");
                      }
                      window.setTimeout(() => {
                        setSubmitting(false);
                      }, 1000);
                    }
                  }}
                >
                  {({ submitForm, touched, errors, isSubmitting, values, handleChange, handleBlur }) => (
                    <Form>
                      <div className={classes.FormNavigation}>
                        <ArrowBackIos
                          onClick={() => {
                            this.props.history.push(this.props.location.state.source);
                          }}
                        />
                        <AddPhotoAlternate onClick={this.handlerSyncImages} />
                      </div>

                      <div className={classes.FormInfo}>
                        <h1>Product and combinations details form</h1>
                        <p>
                          Below you have the product and combinations details form. On this stage we are focused on
                          delivery details data to product and combinations.
                        </p>
                      </div>

                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormControl className={classes.FormControl} style={{ width: "20%" }}>
                          <p className={classes.Label}>Product reference</p>
                          <Input
                            name="reference"
                            id="reference"
                            value={this.state.productData.reference}
                            disabled={true}
                          />
                        </FormControl>

                        <FormControl className={classes.FormControl} style={{ width: "60%", padding: "0 10px" }}>
                          <p className={classes.Label}>Product name</p>
                          <Input
                            name="product_name"
                            id="product_name"
                            value={values.product_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={true}
                          />
                          {errors.product_name && <div className={classes.InputFeedback}>{errors.product_name}</div>}
                        </FormControl>

                        <FormControl className={classes.FormControl} style={{ width: "20%" }}>
                          <p className={classes.Label}>Default category</p>
                          <Input value={this.state.defaultCategory.label} disabled={true} />
                          {errors.default_category && (
                            <div className={classes.InputFeedback}>{errors.default_category}</div>
                          )}
                        </FormControl>
                      </div>

                      <div style={{ marginBottom: 40 }}>
                        {this.state.loadedCategories ? (
                          <CheckboxTree
                            checkModel="all"
                            nameAsArray={true}
                            noCascade={true}
                            nodes={[this.state.categories]}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.setState({ checked })}
                            onExpand={(expanded) => this.setState({ expanded })}
                            onClick={(clicked) => this.setState({ defaultCategory: clicked })}
                          />
                        ) : (
                          <Spinner />
                        )}
                      </div>

                      <MaterialTable
                        title={<>Combinations in product {this.state.productData.reference}</>}
                        icons={tableIcons}
                        style={{ width: "100%", marginBottom: 10 }}
                        columns={this.state.combinationsColumns}
                        data={this.state.combinations}
                        actions={this.state.actions}
                        localization={{ editRow: { cancelTooltip: "Back" } }}
                        options={{
                          pageSize: 5,
                          exportButton: false,
                          filtering: false,
                          search: false,
                          paging: false,
                          actionsColumnIndex: -1,
                        }}
                        editable={this.state.editable}
                        components={{
                          Toolbar: (props) => {
                            return (
                              <div className="mainToolbar" style={{ paddingLeft: 15 }}>
                                <MTableToolbar {...props} />
                                <div style={{ marginLeft: 24, marginBottom: 20 }}>
                                  <p>Choose size category:</p>
                                  <Select
                                    style={{ minWidth: 150 }}
                                    value={this.state.productData.size_attribute}
                                    onChange={(e) => {
                                      if (confirm("Are you sure? By change you remove all size data.")) {
                                        this.setState({ loading: true });
                                        axios
                                          .post(
                                            `/sync-product-attribute/${this.state.productData.product_id}`,
                                            { size_attribute: e.target.value },
                                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                                          )
                                          .then(() => {
                                            const columns = [...this.state.combinationsColumns];
                                            sizesColumnsArray.forEach((column) => {
                                              const index = columns.findIndex(({ field }) => field === column.field);
                                              columns[index].hidden = column.field !== e.target.value;
                                            });
                                            const newProductData = this.state.productData;
                                            newProductData.size_attribute = e.target.value;
                                            axios
                                              .get(`/sync-products/${this.props.location.state.productId}.json`, {
                                                headers: { Authorization: `Bearer ${this.props.token}` },
                                              })
                                              .then((res) => {
                                                this.setState({
                                                  productData: res.data.product,
                                                  combinations: res.data.combinations,
                                                  combinationsColumns: columns,
                                                  loading: false,
                                                });
                                              });
                                          });
                                      }
                                    }}
                                  >
                                    {sizesColumnsArray.map((column, index) => {
                                      return (
                                        <MenuItem key={index} value={column.field}>
                                          {column.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </div>
                              </div>
                            );
                          },
                        }}
                      />

                      {errors.combinations_table && (
                        <div className={classes.InputFeedback}>{errors.combinations_table}</div>
                      )}

                      <FormControl style={{ textAlign: "center" }} className={classes.FormControl}>
                        <Button
                          type="submit"
                          style={{ margin: "10px 0" }}
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          {isSubmitting ? "Submitting" : "Submit"}
                        </Button>
                      </FormControl>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grid>
          </Grid>
        </>
      );
    }

    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    full_name: state.auth.full_name,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(withRouter(SyncProductsFormDetails));
