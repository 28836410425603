import React, { Component } from "react";
import axios from "../../../axios-sw";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";

import { Grid, Paper, FormControl, FormControlLabel, Switch, Button, Input } from "@material-ui/core";
import { authRedirect, disabledOrderRedirection, catchResponse, formatDate } from "../../../shared/utility";

// Formularz
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Spinner from "../../../components/UI/Spinner/Spinner";

import MaskedInput from "react-text-mask";

import classes from "./OrderForm.module.scss";
import ReactSelect from "react-select";

const DateMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholder={formatDate(Date.now())}
      style={{ textTransform: "uppercase" }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/]}
      guide={false}
    />
  );
};

DateMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class OrderForm extends Component {
  state = {
    seasons: [],
    contacts: [],
    ports: [],
    payment_terms: [],
    redirection: null,
    designerCode: "",
    season: null,
    contact: null,
    orderData: [],
    editForm: false,
    loading: true,
  };

  componentDidMount() {
    if (typeof this.props.location.state !== "undefined") {
      axios
        .get("/orders/" + this.props.location.state.order + ".json", {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .then((res) => {
          this.setState({
            orderData: res.data,
            season: {
              value: res.data.season_id,
              label: res.data.season_code,
            },
            contact: {
              value: res.data.contact_id,
              label: res.data.contact_code,
            },
            editForm: true,
            loading: false,
          });
        })
        .catch((err) => {
          catchResponse(err);
        });
    } else {
      this.setState({ loading: false });
    }
    this.indexSeasons();
    this.indexContacts();
    this.indexPorts();
    this.indexPaymentTerms();
    this.getDesignerCode();
  }

  getDesignerCode = () => {
    axios
      .get("/designers/" + this.props.match.params.id + ".json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.setState({ designerCode: res.data.code });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexSeasons = () => {
    axios
      .get("/seasons.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ seasons: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexContacts = () => {
    axios
      .get("/contacts.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ contacts: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ ports: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexPaymentTerms = () => {
    axios
      .get("/payment_terms.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ payment_terms: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        keepCharPositions={false}
        style={{ textTransform: "uppercase" }}
        mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/]}
        guide={false}
      />
    );
  };

  render() {
    const OrderFormSchema = Yup.object().shape({
      order_number: Yup.string().required("Order number is required!"),
      order_date: Yup.date().typeError("Order date must have right format!").required("Order date is required!"),
      port: Yup.object().typeError("Port is required!").required("Port is required!"),
      payment_terms: Yup.object().typeError("Payment terms is required!").required("Payment terms is required!"),
      shipment_date: Yup.date()
        .typeError("Shipment date has wrong format! (xxxx-xx-xx)")
        .required("Shipment date is required!")
        .min(formatDate(Date.now()), "Shipment date can not be earlier than today"),
      country: Yup.object().typeError("Country is required!").required("Country is required!"),
    });

    this.TextMaskCustom.propTypes = {
      inputRef: PropTypes.func.isRequired,
    };

    let content = <Spinner />;
    if (this.state.loading === false) {
      content = (
        <>
          <ToastContainer />
          {authRedirect(this.props.token)}
          {this.state.editForm
            ? disabledOrderRedirection(this.props.user_id, this.props.role, this.state.orderData.accepted)
            : null}
          {this.state.redirection}

          <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: "30vh" }}>
            <Grid item>
              <Paper style={{ padding: 20, width: 600 }}>
                <Formik
                  initialValues={{
                    order_number: this.state.editForm ? this.state.orderData.part_number : "",
                    order_date: formatDate(Date.now()),
                    port: this.state.editForm
                      ? { value: this.state.orderData.port_id, label: this.state.orderData.port }
                      : null,
                    payment_terms: this.state.editForm
                      ? { value: this.state.orderData.payment_term_id, label: this.state.orderData.payment_term }
                      : null,
                    shipment_date: this.state.editForm
                      ? formatDate(this.state.orderData.shipment_date)
                      : /*formatDate(Date.now())*/ "",
                    country: this.state.editForm
                      ? { value: this.state.orderData.country, label: this.state.orderData.country }
                      : "",
                    final_order: this.state.editForm ? this.state.orderData.final_order : false,
                    barcodes_in_process: this.state.editForm ? this.state.orderData.barcodes_in_process : false,
                  }}
                  validationSchema={OrderFormSchema}
                  onSubmit={(values, { setSubmitting, setFieldError }) => {
                    if (this.state.designerCode !== null) {
                      if (this.state.season !== null) {
                        if (this.state.contact !== null) {
                          const number =
                            this.state.season.label +
                            this.state.designerCode +
                            "-" +
                            this.state.contact.label +
                            values.order_number;
                          const data = {
                            order: {
                              number: number == null ? null : number.toUpperCase(),
                              season_id: this.state.season.value,
                              order_date: values.order_date,
                              port_id: values.port.value,
                              payment_term_id: values.payment_terms.value,
                              shipment_date: values.shipment_date,
                              country: values.country.value,
                              final_order: values.final_order,
                              barcodes_in_process: values.barcodes_in_process,
                            },
                            /* Activity logging */
                            user_id: this.props.user_id,
                            ip_address: this.props.ip_address,
                          };
                          if (this.state.editForm) {
                            axios
                              .put("/orders/" + this.props.location.state.order, data, {
                                headers: { Authorization: `Bearer ${this.props.token}` },
                              })
                              .then(() => {
                                this.setState({
                                  redirection: <Redirect to={this.props.location.state.source} />,
                                });
                                toast.success("Order updated!");
                                setSubmitting(false);
                              })
                              .catch((err) => {
                                catchResponse(err);
                              });
                          } else {
                            axios
                              .post("/orders.json", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
                              .then((res) => {
                                this.setState({
                                  redirection: <Redirect to={"/orders/" + res.data.custom_data} />,
                                });
                                toast.success("Order created!");
                                setSubmitting(false);
                              })
                              .catch((err) => {
                                setSubmitting(false);
                                catchResponse(err);
                              });
                          }
                        } else {
                          toast.error("You must set contact!");
                          setFieldError("contact", "Set contact code!");
                          setSubmitting(false);
                        }
                      } else {
                        toast.error("You must set season!");
                        setFieldError("season", "Set season code!");
                        setSubmitting(false);
                      }
                    } else {
                      toast.error("Something is wrong with your designer code!");
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    submitForm,
                    setFieldValue,
                    setFieldTouched,
                    touched,
                    errors,
                    isSubmitting,
                    values,
                    handleChange,
                    handleBlur,
                  }) => (
                    <Form>
                      <div className={classes.FormInfo}>
                        <h1>{this.state.editForm ? "Order edit form" : "Order create form"}</h1>
                        {this.state.editForm ? (
                          <p>Prior number: {this.state.orderData.number}</p>
                        ) : (
                          <p>
                            Below you have the order form to create new order. Remember to fill every inputs data and
                            follow the instruction if it is displayed.
                          </p>
                        )}
                      </div>

                      <FormControl className={classes.FormControlStyleWithSelect}>
                        <p className={classes.Label}>Order number</p>
                        <div className={classes.ReactSelect}>
                          <ReactSelect
                            placeholder=""
                            onChange={(e) => {
                              this.setState({ season: e });
                            }}
                            value={this.state.season}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                            options={this.state.seasons}
                          />
                        </div>
                        <div className={classes.DesignerCode}>
                          <Input disabled={true} value={this.state.designerCode + "-"} />
                        </div>
                        <div className={classes.ReactSelectContact}>
                          <ReactSelect
                            placeholder=""
                            onChange={(e) => {
                              this.setState({ contact: e });
                            }}
                            value={this.state.contact}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                            options={this.state.contacts}
                          />
                        </div>
                        <div className={classes.StyleInput}>
                          <Input
                            name="order_number"
                            id="order_number"
                            placeholder={"23"}
                            value={values.order_number}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputComponent={this.TextMaskCustom}
                          />
                        </div>
                        {errors.order_number && touched.order_number && (
                          <div className={classes.InputFeedback}>{errors.order_number}</div>
                        )}
                        {errors.season && <div className={classes.InputFeedback}>{errors.season}</div>}
                        {errors.contact && <div className={classes.InputFeedback}>{errors.contact}</div>}
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Order's date</p>
                        <Input
                          name="order_date"
                          id="order_date"
                          value={values.order_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputComponent={DateMask}
                          disabled={true}
                        />
                        {errors.order_date && touched.order_date && (
                          <div className={classes.InputFeedback}>{errors.order_date}</div>
                        )}
                      </FormControl>

                      <FormControl className={classes.FormControlSelect}>
                        <p className={classes.Label}>Payment terms</p>
                        <ReactSelect
                          name="payment_terms"
                          id="payment_terms"
                          value={values.payment_terms}
                          onChange={(e) => setFieldValue("payment_terms", e)}
                          onBlur={() => setFieldTouched("payment_terms", true)}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                          options={this.state.payment_terms}
                        />
                        {errors.payment_terms && touched.payment_terms && (
                          <div className={classes.InputFeedback}>{errors.payment_terms}</div>
                        )}
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Shipment date</p>
                        <Input
                          name="shipment_date"
                          id="shipment_date"
                          value={values.shipment_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputComponent={DateMask}
                          autoComplete="off"
                        />
                        {errors.shipment_date && touched.shipment_date && (
                          <div className={classes.InputFeedback}>{errors.shipment_date}</div>
                        )}
                      </FormControl>

                      <FormControl className={classes.FormControlSelect}>
                        <p className={classes.Label}>Country</p>
                        <ReactSelect
                          name="country"
                          id="country"
                          value={values.country}
                          onChange={(e) => setFieldValue("country", e)}
                          onBlur={() => setFieldTouched("country", true)}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                          options={[
                            { value: "CHINA", label: "CHINA" },
                            { value: "BANGLADESH", label: "BANGLADESH" },
                            { value: "INDIA", label: "INDIA" },
                            { value: "MYANMAR", label: "MYANMAR" },
                            { value: "POLSKA", label: "POLSKA" },
                            { value: "LITWA", label: "LITWA" },
                            { value: "PAKISTAN", label: "PAKISTAN" },
                          ]}
                        />
                        {errors.country && touched.country && (
                          <div className={classes.InputFeedback}>{errors.country}</div>
                        )}
                      </FormControl>

                      <FormControl className={classes.FormControlSelect}>
                        <p className={classes.Label}>Port</p>
                        <ReactSelect
                          name="port"
                          id="port"
                          value={values.port}
                          onChange={(e) => setFieldValue("port", e)}
                          onBlur={() => setFieldTouched("port", true)}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                          options={this.state.ports}
                        />
                        {errors.port && touched.port && <div className={classes.InputFeedback}>{errors.port}</div>}
                      </FormControl>

                      {this.state.editForm ? (
                        <FormControl className={classes.FormControl}>
                          <FormControlLabel
                            value="final_order"
                            control={
                              <Switch
                                color="primary"
                                checked={values.final_order}
                                onChange={() => setFieldValue("final_order", !values.final_order)}
                              />
                            }
                            label="Final order?"
                            labelPlacement="end"
                          />
                        </FormControl>
                      ) : null}

                      {this.state.editForm ? (
                        <FormControl className={classes.FormControl}>
                          <FormControlLabel
                            value="barcodes_in_process"
                            control={
                              <Switch
                                color="primary"
                                checked={values.barcodes_in_process}
                                onChange={() => setFieldValue("barcodes_in_process", !values.barcodes_in_process)}
                              />
                            }
                            label="Barcodes in process?"
                            labelPlacement="end"
                          />
                        </FormControl>
                      ) : null}

                      <FormControl style={{ textAlign: "center" }} className={classes.FormControl}>
                        <Button
                          type="submit"
                          style={{ margin: "10px 0" }}
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          {isSubmitting ? "Submitting" : "Submit"}
                        </Button>
                      </FormControl>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Helmet>
          <title>Order form | Carry System</title>
        </Helmet>

        {content}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    full_name: state.auth.full_name,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(withRouter(OrderForm));
