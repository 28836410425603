import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import axios from "../../axios-sw";

import MaterialTable, { MTableBodyRow } from "material-table";
import { commonLocation, tableIcons, titleTemplate } from "../../shared/tableVariables";
import Columns from "../../shared/tables";

import Grid from "@material-ui/core/Grid";

import { authRedirect, catchResponse, nonAdminRedirect } from "../../shared/utility";

class UserLogs extends Component {
  state = {
    columns: Columns.userLogsColumns,
    data: [],
  };

  componentDidMount() {
    this.getUserLogs();
  }

  getUserLogs = () => {
    axios
      .get("/user-logs.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.setState({ data: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Helmet>
          <title>User logs | Carry System</title>
        </Helmet>

        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}

        <Grid item xs={12}>
          <MaterialTable
            title={titleTemplate("Users", "Functionality to filtering and monitoring users activity")}
            localization={commonLocation}
            icons={tableIcons}
            style={{ width: "100%" }}
            columns={this.state.columns}
            data={this.state.data}
            components={{
              Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            }}
            options={{
              pageSize: 50,
              pageSizeOptions: [20, 50, 100],
              search: false,
              filtering: true,
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    token: state.auth.token,
    user_id: state.auth.user_id,
  };
};

export default connect(mapStateToProps)(UserLogs);
