import React from "react";
import {
    createTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const theme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em"
            }
        }
    }
});

const CustomTooltip = ({content}) => {
    return (
        <MuiThemeProvider theme={theme}>
            <Tooltip title={content}>
                <p aria-label="comment">{content}</p>
            </Tooltip>
        </MuiThemeProvider>
    )
}

export default CustomTooltip;
