export enum role {
  ADMIN = "admin",
  DESIGNER = "designer",
  IMPORTER = "importer",
  VM = "vm",
  MARKETING = "marketing",
  WAREHOUSE = "warehouse",
  ECOMMERCE = "ecommerce",
  MANAGEMENT = "management",
}

export const roles = {
  ADMIN: "admin",
  DESIGNER: "designer",
  IMPORTER: "importer",
  VM: "vm",
  MARKETING: "marketing",
  WAREHOUSE: "warehouse",
  ECOMMERCE: "ecommerce",
  MANAGEMENT: "management",
} as const;

export type Roles = (typeof roles)[keyof typeof roles];

export const headIds: number[] = [
  // 1, // Bart
  40, // Asia S
  39, // Bogusław S
];
