import React, { Component } from "react";
import { Button, Paper } from "@material-ui/core";
import { Backspace } from "@material-ui/icons";
import MaterialTable, { MTableEditRow, MTableToolbar } from "material-table";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../axios-sw";

import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { barcodesCombinationsColumns } from "./table";
import { HotKeysSubtable } from "../../components/System/HotKeys/HotKeys";
import { catchResponse, thenResponse } from "../../shared/utility";
import { role } from "../../config";

class BarcodesCombinations extends Component {
  state = {
    actions: [],
    editable: {},
    columns: barcodesCombinationsColumns,
    data: [],
  };

  componentDidMount() {
    this.indexBarcodeCombinations();
    this.checkActionsAndEditablePermissions();
  }

  solveCombinationError(event, rowData) {
    const data = {
      combination: {
        error: null,
      },

      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };

    const url = `/combinations/${rowData.id}`;
    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(`${url}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedOrder = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedOrder.id);
            if (index !== -1) {
              array[index].error = fetchedOrder.error;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  }

  indexBarcodeCombinations = () => {
    const { orderId } = this.props;

    axios
      .get(`/barcodes-combinations/${orderId}.json`, {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        const sorted = res.data.sort(function(a, b) {
          let a1 = a.product_id;
          let a2 = b.product_id;
          let o1 = a.order;
          let o2 = b.order;
          let p1 = a.size_id;
          let p2 = b.size_id;

          if (a1 < a2) return -1;
          if (a1 > a2) return 1;
          if (o1 < o2) return -1;
          if (o1 > o2) return 1;
          if (p1 < p2) return -1;
          if (p1 > p2) return 1;
          return 0;
        });
        this.setState({ data: sorted });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateBarcodeCombination = (id, newData) => {
    const data = {
      combination: {
        barcode_comments: newData.barcode_comments,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };

    const url = `/combinations/${id}.json`;
    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedOrder = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedOrder.id);
            if (index !== -1) {
              array[index].barcode_comments = fetchedOrder.barcode_comments;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateBarcodeCombination(oldData.id, newData);
            }),
        },
        actions: [
          () => ({
            icon: () => (
              <Button color="primary" variant="outlined">
                SOLVE
              </Button>
            ),
            tooltip: "Solve error",
            onClick: (event, rowData) => {
              this.solveCombinationError(event, rowData);
            },
          }),
          (rowData) =>
            rowData.barcode !== null
              ? {
                  icon: () => <Backspace />,
                  tooltip: "Clear barcode data",
                  onClick: (event, rowData) => {
                    this.clearBarcodeData(rowData.id, rowData.barcode);
                  },
                }
              : null,
        ],
      });
    }
  };

  clearBarcodeData = (id, barcode) => {
    axios
      .post(
        `/barcodes/clear-data/${barcode}.json`,
        {
          user_id: this.props.user_id,
          ip_address: this.props.ip_address,
        },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        const newData = [...this.state.data];
        const index = newData.findIndex((x) => x.id === id);
        if (index !== -1) {
          newData[index].barcode = null;
          newData[index].hz_id = newData[index].hz_id.slice(0, -4);
          this.setState({ data: newData });
          toast.success("Cleared data in the selected combination");
        }
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    return (
      <Paper>
        <ToastContainer />
        <HotKeysSubtable />

        <MaterialTable
          title={titleTemplate(<div>Combinations in order {this.props.orderNumber}</div>)}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={this.state.data}
          actions={this.state.actions}
          localization={{
            editRow: {
              cancelTooltip: "Back",
            },
          }}
          options={{
            pageSize: 5,
            exportButton: false,
            filtering: false,
            search: false,
            paging: false,
            actionsColumnIndex: -1,
          }}
          components={{
            EditRow: (props) => <MTableEditRow autoFocus className="subtableEditingRow uppercase " {...props} />,
            Toolbar: (props) => {
              return (
                <div className="subtableToolbar">
                  <MTableToolbar {...props} />
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </Paper>
    );
  }
}

export default BarcodesCombinations;
