import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";
import _ from "lodash";
import { Helmet } from "react-helmet";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableBody, MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";

import Columns from "../../shared/tables";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  formatDate,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  nonAdminRedirect,
  sendEmailAboutLoadingDelay,
  thenResponse,
} from "../../shared/utility";
import classes from "./Bookings.module.scss";
import {
  Button,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Spinner from "../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../shared/defines";
import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff, WrapText } from "@material-ui/icons";
import { json2excel } from "js2excel";
import Modal from "../../components/UI/Modal/Modal";
import CustomSlider from "../../components/UI/Slider/Slider";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import moment from "moment";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";
import WarningInfo from "../../components/UI/WarningInfo/WarningInfo";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const sortingPhraseHelper = [
  { key: "style_name", path: "products.style_name" },
  { key: "accepted", path: "orders.accepted" },
  { key: "index", path: "products.index" },
  { key: "comments", path: "products.comments" },
  { key: "cancelled", path: "products.cancelled" },
  { key: "order_number", path: "orders.number" },
  { key: "designer", path: "designers.code" },
  { key: "shipment_date", path: "orders.shipment_date" },
  { key: "contact_name", path: "contacts.name" },
  { key: "port", path: "ports.code" },
  {
    key: "quantity",
    path: "(SELECT SUM(`combinations`.`quantity`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)",
  },
  {
    key: "price",
    path: "(SELECT AVG(`combinations`.`price`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)",
  },
  {
    key: "total",
    path: "(SELECT SUM(`combinations`.`quantity` * `combinations`.`price`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)",
  },
  { key: "index_check_date", path: "bookings.index_check_date" },
  { key: "sample_confirm_date", path: "bookings.sample_confirm_date" },
  { key: "forwarder", path: "bookings.forwarder" },
  { key: "booking_date", path: "bookings.booking_date" },
  { key: "readiness_date", path: "bookings.readiness_date" },
  { key: "forwarder_confirm_date", path: "bookings.forwarder_confirm_date" },
  { key: "quantity_invoice", path: "bookings.quantity_invoice" },
  { key: "price_invoice", path: "bookings.price_invoice" },
  {
    key: "value",
    path: "(SELECT SUM(quantity_invoice * price_invoice) FROM `bookings` WHERE `bookings`.`product_id` = products.id)",
  },
  { key: "lc_number", path: "orders.lc_number" },
  { key: "container_number", path: "containers.number" },
  { key: "invoice_number", path: "bookings.invoice_number" },
  { key: "etd", path: "containers.etd" },
  { key: "eta", path: "containers.eta" },
  { key: "warehouse", path: "containers.warehouse" },
  { key: "deposit", path: "bookings.deposit" },
  {
    key: "amount_percentage",
    path: "(SELECT ((SELECT SUM(quantity_invoice * 100) FROM `bookings` WHERE `bookings`.`product_id` = products.id) / (SELECT SUM(`combinations`.`quantity`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)))",
  },
  { key: "validated", path: "bookings.validated" },
  { key: "discount", path: "products.discount" },
  { key: "description", path: "descriptions.title" },
  { key: "sex", path: "sexes.code" },
  { key: "fabric", path: "fabrics.title" },
  { key: "accessory", path: "descriptions.accessory", type: "boolean" },
  { key: "barcode_multi", path: "multipacks.barcode_multi" },
];

class Bookings extends Component {
  state = {
    columns: Columns.bookingsColumns,
    columnsHidden: Columns.bookingsColumns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalQuantity: 0,
    totalQuantityInvoice: 0,
    quantityPercentage: 0,
    quantityInvoicePercentage: 0,
    totalValue: 0,
    totalValueInvoice: 0,
    valuePercentage: 0,
    valueInvoicePercentage: 0,
    totalRow: 0,
    sortBy: null,
    order: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    hidden: false,
    loaded: false,
    isModal: false,
    rowData: null,
    exportChosen: false,
    modalValue: 0,
    filterPhrase: "",
    tableRef: React.createRef(),
    editable: {},
    actions: [],
    blockedSaveColumnsButton: true,
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexDescriptions();
    this.indexPorts();
    this.indexBookings(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "all");
        if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "empty");
        if (filter.column.key === "proforma_document" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (filter.column.key === "index" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (
          filter.column.key === "accepted" &&
          filter.value ===
            `<> '' AND ${filter.column.path} NOT LIKE "%CANCEL%" AND ${filter.column.path} NOT LIKE "%HOLD%" AND ${filter.column.path} IS NOT NULL`
        )
          table.onFilterChange(index, "accepted");
        if (
          filter.column.key === "proforma_document" &&
          filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
        )
          table.onFilterChange(index, "added");
        if (filter.column.key === "index" && filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`)
          table.onFilterChange(index, "added");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) table.onFilterChange(index, "hold");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`)
          table.onFilterChange(index, "cancel");
      } else if (filter.type === "multiSelect") {
        if (filter.column.key === "accepted") {
          let value = [];
          if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`))
            value = [...value, "all"];
          if (filter.column.key === "accepted" && filter.value.includes(`="" OR ${filter.column.path} IS NULL`))
            value = [...value, "empty"];
          if (
            filter.column.key === "accepted" &&
            filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
          )
            value = [...value, "accepted"];
          if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value = [...value, "hold"];
          if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
            value = [...value, "cancel"];
          this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
        } else {
          let value = filter.value
            .split("LIKE")
            .join("")
            .split("'%")
            .join("")
            .split("%'")
            .join("")
            .split(`OR ${filter.column.path}`)
            .join(",")
            .trim();
          let valueArray = value.split(",").map((item) => item.trim());
          table.onFilterChange(index, valueArray);
        }
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  indexDescriptions = () => {
    axios
      .get("/descriptions.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["title"], res.data[i]["title"]);
        }
        let obj = Object.fromEntries(map);
        let newState = { ...Columns.bookingsColumns };

        const descriptionIndex = Columns.bookingsColumns.findIndex((column) => column.field === "description");
        descriptionIndex > -1 && (newState[descriptionIndex].lookup = obj);

        this.setState({ columns: Object.values(newState) });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexBookings(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
    if (this.props.role !== prevProps.role) this.checkActionsAndEditablePermissions();
  }

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_bookings_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/bookings?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexBookings(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  indexBookings = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();
    axios
      .get(`/bookings/${page}/${limit}.json`, {
        params: {
          phrase: phrase,
          sortPath: sortPath,
          filterPath: filterPath,
          currentSeasons: currentSeasons,
        },
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then(async (res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");

        const columnSettings = JSON.parse(window.localStorage.getItem(`user_bookings_${this.props.user_id}`));
        let columnsHidden = [];

        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          data: res.data.bookings,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          totalQuantityInvoice: res.data.total_quantity_invoice,
          totalValueInvoice: parseFloat(res.data.total_value_invoice),
          totalFinalOrders: res.data.total_final_orders,
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          quantityInvoicePercentage: res.data.total_quantity_invoice_percentage,
          valueInvoicePercentage: res.data.total_value_invoice_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.bookings,
          columnsHidden: columnsHidden,
          loaded: true,
        });

        this.setDataRefTable();

        if (responseTime > 10) {
          let ids = "";
          res.data.bookings.forEach((booking) => (ids += `${booking.id}, `));
          sendEmailAboutLoadingDelay(
            "Bookings",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let newState = { ...this.state.columns };
        let newStateHidden = { ...this.state.columnsHidden };

        const portIndex = this.state.columns.findIndex((column) => column.field === "port");

        newState[portIndex].lookup = obj;
        newStateHidden[portIndex].lookup = obj;
        this.setState({ columns: Object.values(newState), columnsHidden: Object.values(newStateHidden) });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateBooking = (id, old_invoice_number, newData) => {
    const oldValueInvoice = newData.value;
    const data = {
      booking: {
        index_check_date: newData.index_check_date,
        sample_confirm_date: newData.sample_confirm_date,
        forwarder: newData.forwarder == null ? null : newData.forwarder.toUpperCase(),
        booking_date: newData.booking_date,
        readiness_date: newData.readiness_date,
        forwarder_confirm_date: newData.forwarder_confirm_date,
        quantity_invoice:
          typeof newData.quantity_invoice !== "undefined"
            ? newData.quantity_invoice !== null
              ? newData.quantity_invoice.toString().replace(" ", "")
              : null
            : null,
        price_invoice:
          typeof newData.price_invoice !== "undefined"
            ? newData.price_invoice !== null
              ? newData.price_invoice.toString().replace(" ", "").replace(",", ".")
              : null
            : null,
        invoice_number: newData.invoice_number == null ? null : newData.invoice_number.replace(/\s/g, "").toUpperCase(),
        deposit: newData.deposit,
        amount_percentage: newData.amount_percentage,
        validated: newData.validated,
      },
      // Na potrzeby automatycznego tworzenie container i invoice
      invoice_number: newData.invoice_number == null ? null : newData.invoice_number.replace(/\s/g, "").toUpperCase(),
      old_invoice_number: old_invoice_number,
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/bookings/" + id + ".json";
    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedBooking = res.data;
            const array = [...this.state.filteredData];
            const index = array.findIndex((x) => x.id === fetchedBooking.id);

            const newTotalValueInvoice =
              this.state.totalValueInvoice +
              (parseFloat(fetchedBooking.value.replace(" ", "").replace(",", ".")) -
                parseFloat(oldValueInvoice.replace(" ", "").replace(",", ".")));
            const newTotalQuantityInvoice =
              this.state.totalQuantityInvoice +
              (parseInt(newData.quantity_invoice.replace(" ", "").replace(",", ".")) -
                parseInt(array[index].quantity_invoice.replace(" ", "").replace(",", ".")));

            const newQuantityInvoicePercentage =
              (newTotalQuantityInvoice * this.state.quantityInvoicePercentage) / this.state.totalQuantityInvoice;
            const newValueInvoicePercentage =
              (newTotalValueInvoice * this.state.valueInvoicePercentage) / this.state.totalValueInvoice;

            if (index !== -1) {
              array[index].index_check_date = fetchedBooking.index_check_date;
              array[index].sample_confirm_date = fetchedBooking.sample_confirm_date;
              array[index].forwarder = fetchedBooking.forwarder;
              array[index].booking_date = fetchedBooking.booking_date;
              array[index].readiness_date = fetchedBooking.readiness_date;
              array[index].forwarder_confirm_date = fetchedBooking.forwarder_confirm_date;
              array[index].quantity_invoice = fetchedBooking.quantity_invoice;
              array[index].price_invoice = fetchedBooking.price_invoice;
              array[index].container_number = fetchedBooking.container_number;
              array[index].invoice_number = fetchedBooking.invoice_number;
              array[index].deposit = fetchedBooking.deposit;
              array[index].amount_percentage = fetchedBooking.amount_percentage;
              array[index].etd = fetchedBooking.etd;
              array[index].eta = fetchedBooking.eta;
              array[index].warehouse = fetchedBooking.warehouse;
              array[index].styles = fetchedBooking.styles;
              array[index].value = fetchedBooking.value;
              array[index].validated = fetchedBooking.validated;
              this.setState({
                data: array,
                totalValueInvoice: newTotalValueInvoice,
                totalQuantityInvoice: newTotalQuantityInvoice,
                quantityInvoicePercentage: newQuantityInvoicePercentage.toFixed(2),
                valueInvoicePercentage: newValueInvoicePercentage.toFixed(2),
              });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  hiddenHandler = () => {
    this.setState({
      hidden: !this.state.hidden,
    });
  };

  isModalHandler = () => {
    this.setState({ isModal: !this.state.isModal, rowData: null, modalValue: 0 });
  };

  modalValueHandler = (val) => {
    this.setState({ modalValue: val });
  };

  cloneBooking = () => {
    this.setState({ isModal: false });
    let rowData = this.state.rowData;
    let prompt = this.state.modalValue;
    let quantityInvoice = parseInt(rowData.quantity_invoice.toString().replace(" ", ""));

    if (prompt !== "" && !isNaN(prompt) && parseInt(prompt) <= quantityInvoice) {
      let array = [...this.state.filteredData];
      const index = array.findIndex((x) => x.id === rowData.id);
      if (index !== -1) {
        // Combination clone logic
        const cloned_element = _.cloneDeep(array[index]);
        cloned_element.quantity_invoice = parseInt(prompt);
        cloned_element.id = (Math.random() * 1e6) | 1;
        cloned_element.invoice_number = null;
        cloned_element.eta = null;
        cloned_element.etd = null;
        cloned_element.warehouse = null;
        cloned_element.container_number = null;

        const data = {
          booking: {
            ...cloned_element,
            invoice_number: null,
            price_invoice:
              typeof cloned_element.price_invoice !== "undefined"
                ? cloned_element.price_invoice !== null
                  ? cloned_element.price_invoice.toString().replace(" ", "").replace(",", ".")
                  : null
                : null,
          },
          user_id: this.props.user_id,
          ip_address: this.props.ip_address,
        };

        rowData = {
          ...rowData,
          price_invoice:
            typeof cloned_element.price_invoice !== "undefined"
              ? cloned_element.price_invoice !== null
                ? cloned_element.price_invoice.toString().replace(" ", "").replace(",", ".")
                : null
              : null,
          quantity_invoice: quantityInvoice - parseInt(prompt),
          user_id: this.props.user_id,
          ip_address: this.props.ip_address,
        };

        axios
          .put(`/bookings/${rowData.id}.json`, rowData, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            axios
              .post("/bookings.json", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
              .then((res) => {
                cloned_element.id = res.data.id; //nadanie id wlaściwego dla klona (takie jakie ma w bazie, dla możliwości edycji przed odswieżeniem)
                axios
                  .get(`/bookings/${rowData.id}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
                  .then((res) => {
                    const fetchedBooking = res.data;
                    let index = array.findIndex((x) => x.id === fetchedBooking.id); // Pobieram id zaktualizowanego produtku 1 raz zeby stworzyc nowa tablicę
                    array = [...array.slice(0, index + 1), cloned_element, ...array.slice(index + 1, array.length)]; // Tworzę nową tablicę z klonem pod oryginałem
                    index = array.findIndex((x) => x.id === fetchedBooking.id); // drugi raz pobieram id ponieważ tablica się zmieniła
                    if (index !== -1) {
                      array[index].index_check_date = fetchedBooking.index_check_date;
                      array[index].sample_confirm_date = fetchedBooking.sample_confirm_date;
                      array[index].forwarder = fetchedBooking.forwarder;
                      array[index].booking_date = fetchedBooking.booking_date;
                      array[index].readiness_date = fetchedBooking.readiness_date;
                      array[index].forwarder_confirm_date = fetchedBooking.forwarder_confirm_date;
                      array[index].quantity_invoice = fetchedBooking.quantity_invoice;
                      array[index].price_invoice = fetchedBooking.price_invoice;
                      array[index].container_number = fetchedBooking.container_number;
                      array[index].invoice_number = fetchedBooking.invoice_number;
                      array[index].deposit = fetchedBooking.deposit;
                      array[index].amount_percentage = fetchedBooking.amount_percentage;
                      array[index].styles = fetchedBooking.styles;
                      array[index].value = fetchedBooking.value;
                      array[index].validated = fetchedBooking.validated;
                      this.setState({ filteredData: array, rowData: null, modalValue: 0 });
                    }
                  })
                  .catch((err) => {
                    catchResponse(err);
                  });
                thenResponse(res);
              })
              .catch((err) => {
                catchResponse(err);
              });
            thenResponse(res);
          })
          .catch((err) => {
            catchResponse(err);
          });
      }
    }
  };

  deleteBooking = (id) => {
    const data = {
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/bookings/" + id + ".json", {
        data: data,
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.indexBookings();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  setDataRefTable = () => {
    const stateTable = { ...this.state.tableRef };
    stateTable.current.state.data = this.state.tableRef.current.state.originalData;
    stateTable.current.state.renderData = this.state.tableRef.current.state.originalData;
    stateTable.current.dataManager.pagedData = this.state.tableRef.current.state.originalData;
    stateTable.current.dataManager.searchedData = this.state.tableRef.current.state.originalData;
    stateTable.current.dataManager.sortedData = this.state.tableRef.current.state.originalData;
    this.setState({ tableRef: stateTable });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateBooking(oldData.id, oldData.invoice_number, newData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              if (oldData.id !== oldData.product_id) {
                this.deleteBooking(oldData.id);
              } else {
                toast.error("You can not delete original booking!");
              }
            }),
        },
        actions: [
          () => ({
            icon: WrapText,
            tooltip: "Clone booking",
            onClick: (event, rowData) => {
              this.setState({ isModal: true, rowData: rowData });
            },
          }),
        ],
      });
    }
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        <Helmet>
          <title>Bookings | Carry System</title>
        </Helmet>

        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        {this.state.isModal && (
          <Modal show={this.state.isModal} modalClosed={this.isModalHandler}>
            <div className={classes.WrapperModal}>
              <CustomSlider
                maxValue={parseInt(this.state.rowData.quantity_invoice.toString().replace(" ", ""))}
                onChange={this.modalValueHandler}
                tooltipLabel={`Based quantity: ${
                  parseInt(this.state.rowData.quantity_invoice.toString().replace(" ", "")) - this.state.modalValue
                } / Cloned quantity: ${this.state.modalValue}`}
              />
              <Button variant="contained" color="primary" className={classes.ModalButton} onClick={this.cloneBooking}>
                Clone Booking
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.ModalButton}
                onClick={this.isModalHandler}
              >
                Close
              </Button>
            </div>
          </Modal>
        )}
        <HotKeys />

        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Bookings")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          actions={this.state.actions}
          style={{ width: "100%" }}
          columns={this.state.hidden ? this.state.columns : this.state.columnsHidden}
          data={this.state.filteredData}
          options={{
            pageSize: defaultPageSize,
            exportButton: false,
            exportFileName: "bookings_" + formatDate(Date.now()),
            filtering: true,
            search: false,
          }}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "accepted" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} NOT LIKE "%CANCEL%" AND ${sqlColumn[0].path} NOT LIKE "%HOLD%" AND ${sqlColumn[0].path} IS NOT NULL`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      if (this.state.columnsHidden[columnId].field === "accepted") {
                        let convertValue = "";

                        if (value.length > 0) {
                          value.forEach((item, index) => {
                            if (item === "all")
                              convertValue +=
                                index < value.length - 1
                                  ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                  : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                            else if (item === "empty" || item === "unadded")
                              convertValue +=
                                index < value.length - 1
                                  ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                  : `="" OR ${sqlColumn[0].path} IS NULL`;
                            else if (item === "accepted" || item === "added")
                              convertValue +=
                                index < value.length - 1
                                  ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                                  : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                            else
                              convertValue +=
                                index < value.length - 1
                                  ? `LIKE "%${item}%" OR ${sqlColumn[0].path} `
                                  : `LIKE "%${item}%"`;
                          });

                          if (indexIsExist !== -1) {
                            singleColumnFilterList[indexIsExist].value = convertValue;
                            table.state.columns[columnId].tableData.initialValue = value;
                          } else {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: convertValue,
                                type: "multiSelect",
                              },
                            ];
                            table.state.columns[columnId].tableData.initialValue = value;
                          }
                        } else {
                          singleColumnFilterList = singleColumnFilterList.filter(
                            (filter) => filter.column.key !== "accepted"
                          );
                          table.state.columns[columnId].tableData.initialValue = ["all"];
                        }
                      } else {
                        if (indexIsExist !== -1) {
                          let multiFiltersValues = "";
                          value.forEach((filter, index) => {
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                          });
                          singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                          table.onFilterChange(columnId, value);
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "multiSelect",
                            },
                          ];
                          table.onFilterChange(columnId, value);
                        }
                      }
                    }
                    this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.setState({ limit: event.target.value });
                    this.goPage(
                      this.state.page,
                      event.target.value,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
            Row: (props) => (
              <>
                {props.data.in_progress && <WarningInfo text={"Booking has status: In Progress!"} />}
                <MTableBodyRow className={props.data.styles} {...props} />
              </>
            ),
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;
              let totalQuantity = 0,
                totalValue = 0,
                totalQuantityInvoice = 0,
                totalValueInvoice = 0;
              for (let i = 0; i < props.data.length; i++) {
                if (props.data[i].id === props.data[i].product_id) {
                  totalQuantity += isNaN(props.data[i].quantity)
                    ? parseInt(props.data[i].quantity.replace(/\s/g, ""))
                    : parseInt(props.data[i].quantity);
                  totalValue += isNaN(props.data[i].total)
                    ? parseFloat(props.data[i].total.replace(",", ".").replace(/\s/g, ""))
                    : parseFloat(props.data[i].total);
                }
                totalQuantityInvoice += isNaN(props.data[i].quantity_invoice)
                  ? parseFloat(props.data[i].quantity_invoice.replace(/\s/g, ""))
                  : parseFloat(props.data[i].quantity_invoice);
                totalValueInvoice += isNaN(props.data[i].value)
                  ? parseFloat(props.data[i].value.replace(",", ".").replace(/\s/g, ""))
                  : parseFloat(props.data[i].value);
              }
              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/bookings?page=${this.state.page}&limit=${20}`);

                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.state.columns[index].tableData.filterValue = "all";
                          column.tableData.initialValue = "all";
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />

                  <div className={classes.exportToExcelWrapper}>
                    {/* Eksportowanie danych z tabeli */}
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(`/export-bookings-to-excel.json`, {
                            params: {
                              phrase: search,
                              sortPath: sortPath,
                              filterPath: filterPath,
                              currentSeasons: currentSeasons,
                            },
                            headers: { Authorization: `Bearer ${this.props.token}` },
                          })
                          .then((res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            const bookingsData = res.data.bookings;
                            let filteredBookingsData = bookingsData.map((booking) => {
                              return {
                                accepted:
                                  !booking.accepted || booking.accepted.trim() === ""
                                    ? null
                                    : booking.accepted.includes("CANCEL")
                                    ? booking.accepted
                                    : booking.accepted.includes("HOLD")
                                    ? booking.accepted
                                    : new Date(booking.accepted),
                                style_name: booking.style_name,
                                index: booking.index,
                                order_number: booking.order_number,
                                designer: booking.designer,
                                shipment_per_order: booking.shipment_date ? new Date(booking.shipment_date) : null,
                                shipper_name: booking.contact_name,
                                port: booking.port,
                                quantity_per_order: booking.quantity
                                  ? parseInt(booking.quantity.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                price_per_order: booking.price
                                  ? parseFloat(booking.price.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                total_per_order: booking.total
                                  ? parseFloat(booking.total.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                index_check_date: booking.index_check_date ? new Date(booking.index_check_date) : null,
                                sample_confirm_date: booking.sample_confirm_date
                                  ? new Date(booking.sample_confirm_date)
                                  : null,
                                forwarder: booking.forwarder,
                                booking_date: booking.booking_date,
                                readiness_date: booking.readiness_date ? new Date(booking.readiness_date) : null,
                                forwarder_confirm_date: booking.forwarder_confirm_date
                                  ? new Date(booking.forwarder_confirm_date)
                                  : null,
                                quantity_as_per_commercial_invoices: booking.quantity_invoice
                                  ? parseInt(booking.quantity_invoice.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                price_as_per_commercial_invocies: booking.price_invoice
                                  ? parseFloat(booking.price_invoice.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                value_per_commercial_invoices: booking.value
                                  ? parseFloat(booking.value.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                lc_number: booking.lc_number,
                                container_number: booking.container_number,
                                invoice_number: booking.invoice_number,
                                etd: booking.etd ? new Date(booking.etd) : null,
                                eta: booking.eta ? new Date(booking.eta) : null,
                                warehouse: booking.warehouse ? new Date(booking.warehouse) : null,
                                deposit_date: booking.deposit ? new Date(booking.deposit) : null,
                                amount_percentage: booking.amount_percentage,
                                validated: booking.validated,
                                discount: booking.discount,
                                comments: booking.comments,
                                cancelled: booking.cancelled,
                                sex: booking.sex,
                                fabric: booking.fabric,
                                description: booking.description,
                                accessory: booking.accessory ? "YES" : "NO",
                                barcode_multi: booking.barcode_multi,
                              };
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden.map((column) => {
                                if (column.field === "shipment_date") column.field = "shipment_per_order";
                                if (column.field === "contact_name") column.field = "shipper_name";
                                if (column.field === "price") column.field = "price_per_order";
                                if (column.field === "total") column.field = "total_per_order";
                                if (column.field === "quantity") column.field = "quantity_per_order";
                                if (column.field === "quantity_invoice")
                                  column.field = "quantity_as_per_commercial_invoices";
                                if (column.field === "price_invoice") column.field = "price_as_per_commercial_invocies";
                                if (column.field === "value") column.field = "value_per_commercial_invoices";
                                if (column.field === "deposit") column.field = "deposit_date";
                                return column.field;
                              });

                              filteredBookingsData = filteredBookingsData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            json2excel({
                              data: filteredBookingsData,
                              name: "bookings_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>

                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          this.state.page,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    /*WYJĄTKI*/
                    if (
                      filter.column.key === "accepted" &&
                      filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`
                    )
                      value = "ALL";
                    if (filter.column.key === "index" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
                      value = "ALL";
                    if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                      value = "EMPTY";
                    if (
                      filter.column.key === "accepted" &&
                      filter.value ===
                        `<> '' AND ${filter.column.path} NOT LIKE "%CANCEL%" AND ${filter.column.path} NOT LIKE "%HOLD%" AND ${filter.column.path} IS NOT NULL`
                    )
                      value = "ACCEPTED";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) value = "HOLD";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`) value = "CANCEL";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `="" OR ${filter.column.path} IS NULL`
                    )
                      value = "UNADDED";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`
                    )
                      value = "ADDED";
                    if (filter.column.key === "index" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                      value = "UNADDED";
                    if (filter.column.key === "index" && filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`)
                      value = "ADDED";
                    filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                    if (filter.column.key === "payment_term_id") {
                      let currentValue = "";
                      const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                      const paymnetValue = value.replace(/\s/g, "").split(",");
                      paymnetValue.forEach((item, index) =>
                        index + 1 === paymnetValue.length
                          ? (currentValue += `${column[parseInt(item)]}`)
                          : (currentValue += `${column[parseInt(item)]}, `)
                      );
                      value = currentValue;
                      key = "PAYMENT";
                    }

                    /*MULTISELECT ACCEPTED WYJĄTKI*/
                    if (filter.column.key === "accepted") {
                      value = "";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)
                      )
                        value += "ALL ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`="" OR ${filter.column.path} IS NULL`)
                      )
                        value += "EMPTY ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
                      )
                        value += "ACCEPTED ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value += "HOLD ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
                        value += "CANCEL ";
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            this.state.page,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.state.columns[index].tableData.initialValue = ["all"];
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key;
                    let icon = "";
                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    /*WYJĄTKI*/
                    column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            this.state.page,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${key.toUpperCase().replace("_", " ")}`}
                        icon={icon}
                      />
                    );
                  })}

                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "bookings",
                          };
                        })}
                      options={Columns.bookingsColumns
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "bookings",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_bookings_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_bookings_${this.props.user_id}`);
                          await this.setState({ columnsHidden: this.state.columns, currentStateColumns: null });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>

                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>
                  <div className={classes.Wrapper}>
                    <Table style={{ width: "45%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Summary</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Value</TableCell>
                          <TableCell align="right">Quantity Percentange</TableCell>
                          <TableCell align="right">Value Percentange</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontWeight: "bold",
                              width: "20%",
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell align="right" style={{ width: "20%" }}>
                            {formatterQuantity.format(this.state.totalQuantity)}
                          </TableCell>
                          <TableCell align="right" style={{ width: "20%" }}>
                            {formatterCurrency.format(this.state.totalValue)}
                          </TableCell>
                          <TableCell align="right" style={{ width: "20%" }}>
                            {`${this.state.quantityPercentage}`.replace(".", ",")} %
                          </TableCell>
                          <TableCell align="right" style={{ width: "20%" }}>
                            {`${this.state.valuePercentage}`.replace(".", ",")} %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table className={classes.InvoiceSummary} style={{ width: "35%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Quantity Invoice</TableCell>
                          <TableCell align="center">Value Invoice</TableCell>
                          <TableCell align="right">Quantity Invoice Percentange</TableCell>
                          <TableCell align="right">Value Invoice Percentange</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" style={{ width: "20%" }}>
                            {formatterQuantity.format(this.state.totalQuantityInvoice)}
                          </TableCell>
                          <TableCell align="center" style={{ width: "20%" }}>
                            {formatterCurrency.format(this.state.totalValueInvoice)}
                          </TableCell>
                          <TableCell align="right" style={{ width: "20%" }}>
                            {`${this.state.quantityInvoicePercentage}`.replace(".", ",")} %
                          </TableCell>
                          <TableCell align="right" style={{ width: "20%" }}>
                            {`${this.state.valueInvoicePercentage}`.replace(".", ",")} %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Bookings);
