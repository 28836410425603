import React, { useState } from "react";
import { Input } from "@material-ui/core";
import moment from "moment";
import { Picky } from "react-picky";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export const editComponent = (props, autoFocus, range, inputComponent) => {
  return (
    <Input
      style={{ fontSize: 14 }}
      value={props.value}
      onChange={(e) => {
        if (range) {
          if (e.target.value.length <= range) props.onChange(e.target.value);
        } else props.onChange(e.target.value);
      }}
      autoFocus={autoFocus}
      onPaste={(e) => {
        if (range) {
          if (e.target.value.length <= range) props.onChange(e.target.value);
        } else props.onChange(e.target.value);
      }}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.target.blur();
        }
      }}
      inputComponent={inputComponent}
    />
  );
};

export const editTextarea = (props, autoFocus) => {
  return (
    <TextareaAutosize
      rows={5}
      rowsMax={10}
      value={props.value}
      style={{ fontSize: 14, width: "100%" }}
      onChange={(e) => props.onChange(e.target.value)}
      autoFocus={autoFocus}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.target.blur();
        }
      }}
    />
  );
};

export const editColorsComponent = (props) => {
  const array = Object.keys(props.columnDef.lookup).map((x) => {
    return {
      id: x,
      label: props.columnDef.lookup[x],
    };
  });

  let value = null;
  if (typeof props.value !== "object" && typeof props.value !== "undefined" && props.value !== null) {
    value = array.find((item) => item.id === props.value.toString());
  } else {
    value = props.value;
  }

  return (
    <Picky
      options={array}
      multiple={false}
      valueKey="id"
      labelKey="label"
      includeFilter
      value={value}
      onChange={(e) => {
        props.onChange(e);
      }}
    />
  );
};

export const multiEditSeasonComponent = (props, autoFocus) => {
  let selectedValues = [];
  if (typeof props.value === "string") {
    props.value
      .trim()
      .split(" ")
      .forEach((season) => {
        let index = props.columnDef.tableData.seasons.findIndex((item) => item.label === season);
        if (index !== -1) selectedValues = [...selectedValues, props.columnDef.tableData.seasons[index]];
      });
  } else {
    selectedValues = props.value ? props.value : [];
  }

  return (
    <Picky
      //className={classes.columnsSelect}
      options={props.columnDef.tableData.seasons}
      labelKey="label"
      valueKey="value"
      multiple={true}
      includeFilter
      includeSelectAll
      value={selectedValues}
      onChange={(e) => {
        props.onChange(e);
      }}
    />
  );
};

export const checkDateRange = (range, rowDataDate) => {
  if (range.isEmpty) {
    return !rowDataDate || rowDataDate === "" || rowDataDate.trim() === "";
  } else if (range.isFull) {
    return rowDataDate && rowDataDate !== "" && rowDataDate.trim() !== "";
  } else {
    const start = moment(range.start).format("YYYY-MM-DD");
    const end = moment(range.end).format("YYYY-MM-DD");
    return rowDataDate >= start && rowDataDate <= end;
  }
};

export const referencesSearch = (references, rowData) => {
  if (references.isEmpty) {
    return !rowData || rowData === "" || rowData.trim() === "";
  } else if (rowData.isFull) {
    return rowData && rowData !== "" && rowData.trim() !== "";
  } else {
    const searchedRowData = [];
    references.forEach((reference) => {
      searchedRowData.includes(reference);
    });
    return searchedRowData;
  }
};

export const sortByDate = (a, b, order, key) => {
  if (order === "desc") {
    if (!a[key]) return -1;
    else return a[key] < b[key] ? -1 : 1;
  } else {
    if (!b[key]) return -1;
    else return a[key] < b[key] ? -1 : 1;
  }
};

/*Dla zwykłych kolumn*/
export const cellStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: 90,
  paddingRight: 15,
  paddingLeft: 10,
};
export const headerStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: 90,
  paddingRight: 15,
  paddingLeft: 10,
};

/*Dla kolumn z checkboxami*/

export const checkboxCellStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: 90,
  padding: 0,
};
export const checkboxHeaderStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: 90,
  paddingRight: 0,
  paddingLeft: 0,
};
