import React, {Component} from 'react';
import axios from "../../../../axios-sw";

import Paper from '@material-ui/core/Paper';
import {tableIcons, titleTemplate} from "../../../../shared/tableVariables";
import MaterialTable, {MTableBodyRow, MTableEditRow, MTableToolbar} from "material-table";
import Columns from "../../../../shared/tables";
import {HotKeysSubtable} from "../../../../components/System/HotKeys/HotKeys";

import {catchResponse, postFile, thenResponse} from '../../../../shared/utility';

class ContainerInvoices extends Component {

    state = {
        columns: Columns.vm.containerInvoicesColumns,
        data: []
    };

    componentDidMount() {
        this.indexContainerInvoices();
    }

    indexContainerInvoices = () => {
        axios.get('/container-invoices/' + this.props.containerId + '.json', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                const {filterByColumn, filterByConditional} = this.props;
                if (filterByColumn && filterByConditional) {
                    if (filterByConditional.value === "empty") {
                        const filteredData = res.data.filter(data => {
                            return !data[filterByColumn.value] && !data[filterByColumn.value] || data[filterByColumn.value].toString().trim() === ""
                        })
                        this.setState({data: filteredData})
                    } else if (filterByConditional.value === "full") {
                        const filteredData = res.data.filter(data => {
                            return data[filterByColumn.value] && data[filterByColumn.value].toString().trim() !== ""
                        })
                        this.setState({data: filteredData})
                    } else {
                        this.setState({data: res.data})
                    }
                } else {
                    this.setState({data: res.data});
                }
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    render() {

        const title = (
            <div>
                Container invoices in container: {this.props.containerNumber}
            </div>
        );

        return (
            <Paper>
                <HotKeysSubtable/>
                <MaterialTable
                    title={titleTemplate(title)}
                    icons={tableIcons}
                    style={{width: '100%'}}
                    columns={this.state.columns}
                    data={this.state.data}
                    localization={{
                        editRow: {
                            cancelTooltip: 'Back'
                        }
                    }}
                    options={{
                        pageSize: 5,
                        exportButton: false,
                        filtering: false,
                        search: false,
                        paging: false
                    }}
                    components={{
                        EditRow: props => (
                            <MTableEditRow autoFocus className='subtableEditingRow uppercase ' {...props} />
                        ),
                        Row: props => (
                            <MTableBodyRow className={props.data.styles} {...props} />
                        ),
                        Toolbar: props => {
                            return (
                                <div className='subtableToolbar'>
                                    <MTableToolbar {...props} />
                                </div>
                            )
                        }
                    }}
                />
            </Paper>
        );
    }
}

export default ContainerInvoices;