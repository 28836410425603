import React from "react";

import CarryLogo from "../../../../assets/img/carryLogo.jpeg";
import { Font, Document, Page, View, Text, StyleSheet, Image } from "@react-pdf/renderer";

// Fonts
import NunitoBold from "../../../../assets/fonts/NunitoSans-Bold.ttf";
import NunitoExtraBold from "../../../../assets/fonts/NunitoSans-ExtraBold.ttf";
import NunitoRegular from "../../../../assets/fonts/NunitoSans-Regular.ttf";

const productRowHeight = 200;

const styles = StyleSheet.create({
  /* Header definition */
  body: {
    fontFamily: "NunitoSans-Regular",
    padding: 35,
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  headerLogo: {
    width: "20%",
    height: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  Logo: {
    height: "100%",
  },
  headerCollection: {
    textAlign: "center",
    margin: "30px auto",
    fontSize: 5,
    height: "40%",
  },
  headerContent: {
    textAlign: "center",
    width: "60%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerRightInfo: {
    width: "20%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
  },

  tableColumnTitle: {
    width: "30%",
    fontWeight: "900",
  },

  tableColumnContent: {
    width: "70%",
  },

  tableRow: {
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableCell: {
    margin: "auto",
    padding: "3px 0",
    fontSize: 8,
  },

  productTable: {
    marginLeft: 10,
    marginRight: 10,
  },

  productHeader: {
    textAlign: "center",
    backgroundColor: "lightgrey",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  productTableColumn: {
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
  },
  productHeaderCell: {
    height: 16,
    margin: "0 auto",
    padding: "5px 0",
    fontSize: 5,
  },

  productBody: {
    display: "flex",
    flexDirection: "row",
  },
  productAssortment: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "100px",
  },

  productBodyColumn: {
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
  },
  productBodyCell: {
    margin: "3px auto",
    fontSize: 7,
  },

  productColorCompositionContainer: {
    height: productRowHeight,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  productColorComposition: {
    height: "87%",
    display: "flex",
    flexDirection: "row",
  },
  productColorCompositionSummary: {
    height: "13%",
    display: "flex",
    flexDirection: "row",
  },

  // Compositions
  columnColorComposition: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  productBodyRows: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  productBodyRow: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    borderBottomWidth: 1,
  },

  productSPRow: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  productCollectionRow: {
    width: "100%",
    display: "flex",
    height: productRowHeight,
    padding: 5,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  productBodyRowCell: {
    margin: "auto",
    fontSize: 7,
  },

  // Products Footer
  productFooter: {
    backgroundColor: "lightgrey",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
  },
  productFooterColumn: {
    textAlign: "center",
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
  },
  productFooterCell: {
    height: 16,
    margin: "auto",
    padding: "3px 0",
    fontSize: 7,
  },

  orderSummary: {
    padding: "10px 10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  orderSummaryTable: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    // textAlign: "center",
    // justifyContent: "center",
    // alignItems: "center",
  },
  orderSummaryBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    borderStyle: "solid",
    borderWidth: 1,
  },
  orderSummaryTitle: {
    fontFamily: "NunitoSans-ExtraBold",
    margin: "3px auto",
    fontSize: 8,
  },
  orderSummaryContent: {
    margin: "3px auto",
    fontSize: 8,
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const returnPreviewFiles = (preview_files) => {
  const returnContent = [];
  for (let i = 0; i < preview_files.length; i++) {
    returnContent.push(
      <Image
        key={"prev" + i}
        style={{ padding: 5, width: "100%", height: "auto" }}
        src={{
          uri: /*process.env.REACT_APP_BACKEND_URL + */ preview_files[i].url,
          method: "GET",
          headers: { "Access-Control-Allow-Origin": "*" },
        }}
      />
    );
  }
  return returnContent;
};

const returnSwatchFiles = (swatch_files, swatch_color) => {
  if (swatch_files.length > 0) {
    const returnContent = [];
    for (let i = 0; i < swatch_files.length; i++) {
      returnContent.push(
        <Image
          key={"swatch" + i}
          style={{ padding: 5, height: "100%" }}
          src={{
            uri: /*process.env.REACT_APP_BACKEND_URL + */ swatch_files[i].url,
            method: "GET",
            headers: { "Access-Control-Allow-Origin": "*" },
          }}
        />
      );
    }
    return returnContent;
  } else {
    return (
      <div
        style={{
          width: 100,
          height: 100,
          padding: 5,
          borderWidth: 1,
          boxShadow: "0 0 0 3px rgba(0, 0, 0, 0.7)",
          borderRadius: 2,
          margin: 5,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: swatch_color,
          }}
        />
      </div>
    );
  }
};

const returnSizeQty = (color, combinations, type) => {
  const returnContent = [];
  let countRows = 0;
  for (let i = 0; i < combinations.length; i++) {
    if (color === combinations[i].color_id) {
      countRows++;
    }
  }
  for (let i = 0; i < combinations.length; i++) {
    if (combinations[i].color_id === color) {
      let style;
      let heightLet = productRowHeight / countRows;
      i > 0
        ? (style = {
            ...styles.productSPRow,
            height: heightLet,
            borderTopWidth: 1,
          })
        : (style = { ...styles.productSPRow, height: heightLet });
      returnContent.push(
        <View key={combinations[i].id} style={style}>
          <Text style={styles.productBodyRowCell}>{combinations[i][type]}</Text>
        </View>
      );
    }
  }
  return returnContent;
};

const returnQuantity = (color, combinations) => {
  let totalQuantity = 0;
  for (let i = 0; i < combinations.length; i++) {
    if (color === combinations[i].color_id) {
      totalQuantity += parseInt(combinations[i].quantity.replace(" ", ""));
    }
  }
  return totalQuantity;
};

const returnValue = (color, combinations) => {
  let totalQuantity = 0;
  for (let i = 0; i < combinations.length; i++) {
    if (color === combinations[i].color_id) {
      totalQuantity += parseInt(combinations[i].quantity.replace(" ", ""));
    }
  }
  let price = 0;
  for (let i = 0; i < combinations.length; i++) {
    if (combinations[i].color_id === color) {
      price = parseFloat(combinations[i].price.replace(",", "."));
      break;
    }
  }
  return `${(totalQuantity * price)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} $`;
};

// -------------------------------------------------------------------------------- //

const getColors = (productId, productsData) => {
  // Funkcja filtrująca
  const uniq = (arrArg) => {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) === pos;
    });
  };
  const productsColors = [];
  productsData.forEach((combination) => {
    if (combination.product_id === productId) {
      productsColors.push(combination.color_id);
    }
  });
  return uniq(productsColors);
};

const returnCompositionsGroups = (colors, combinations) => {
  const returnContent = [];
  colors.forEach((color) => {
    for (let i = 0; i < combinations.length; i++) {
      let style;
      i > 0
        ? (style = {
            ...styles.productColorComposition,
            borderTopWidth: 1,
          })
        : (style = { ...styles.productColorComposition });
      if (combinations[i].color_id === color) {
        returnContent.push(
          <View wrap={false} key={combinations[i].id} style={styles.productColorCompositionContainer}>
            <View style={styles.productColorComposition}>
              <View style={{ ...styles.productBodyColumn, width: "33.3%" }}>
                <View style={styles.columnColorComposition}>
                  <View style={{ height: productRowHeight - 20 }}>
                    <Text style={{ ...styles.productBodyCell, fontFamily: "NunitoSans-ExtraBold" }}>
                      MAIN COLOR: {combinations[i].color}
                    </Text>
                    {combinations[i].composition !== null && combinations[i].composition !== "" ? (
                      <Text style={styles.productBodyCell}>{combinations[i].composition}</Text>
                    ) : null}
                    {combinations[i].composition_2 !== null && combinations[i].composition_2 !== "" ? (
                      <Text style={styles.productBodyCell}>{combinations[i].composition_2}</Text>
                    ) : null}
                    {combinations[i].fabric_name !== null && combinations[i].fabric_name !== "" ? (
                      <Text style={styles.productBodyCell}>SWATCH NO: {combinations[i].fabric_name}</Text>
                    ) : null}
                  </View>
                  <View style={{ height: 32, borderTopWidth: 1, justifyContent: "center" }}>
                    {combinations[i].collection_ref !== null && combinations[i].collection_ref !== "" ? (
                      <Text style={{ ...styles.productBodyCell, fontFamily: "NunitoSans-ExtraBold" }}>
                        Collection ref: {combinations[i].collection_ref}
                      </Text>
                    ) : null}
                  </View>
                  <View style={{ height: 32, borderTopWidth: 1, justifyContent: "center" }}>
                    {combinations[i].colour_for_hz !== null && combinations[i].colour_for_hz !== "" ? (
                      <Text style={{ ...styles.productBodyCell }}>For HZ: {combinations[i].colour_for_hz}</Text>
                    ) : null}
                  </View>
                </View>
              </View>

              {/* SIZES AND QUANTITIES */}
              <View style={{ ...styles.productBodyColumn, width: "13.3%" }}>
                <View style={styles.productBodyRows}>{returnSizeQty(color, combinations, "size")}</View>
              </View>
              <View style={{ ...styles.productBodyColumn, width: "13.4%" }}>
                <View style={styles.productBodyRows}>{returnSizeQty(color, combinations, "quantity")}</View>
              </View>

              {/* SWATCH FILE AND OTHER */}
              <View style={{ ...styles.productBodyColumn, width: "40%" }}>
                <View style={styles.columnColorComposition}>
                  <View style={{ minHeight: 50, marginBottom: 15, height: productRowHeight + 30 }}>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        height: productRowHeight - 100,
                      }}
                    >
                      {returnSwatchFiles(combinations[i].swatch_files, combinations[i].swatch_color)}
                    </View>
                  </View>
                  <View style={{ minHeight: 50, borderTopWidth: 1 }}>
                    <Text style={{ ...styles.productBodyCell, fontSize: 5.5 }}>{combinations[i].more_information}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ ...styles.productColorCompositionSummary, backgroundColor: "lightgrey" }}>
              <View style={{ ...styles.productBodyColumn, width: "33.3%" }}>
                <Text style={styles.productBodyCell}>PRICE: {combinations[i].price} $</Text>
              </View>
              <View style={{ ...styles.productBodyColumn, width: "26.7%" }}>
                <Text style={styles.productBodyCell}>QUANTITY: {returnQuantity(color, combinations)}</Text>
              </View>
              <View style={{ ...styles.productBodyColumn, width: "40%" }}>
                <Text style={styles.productBodyCell}>VALUE: {returnValue(color, combinations)}</Text>
              </View>
            </View>
          </View>
        );
        break;
      }
    }
  });
  return returnContent;
};

const GenerateProductsRows = (productsData, combinationsData) => {
  const result = [];
  productsData.forEach((product) => {
    const colors = getColors(product.id, combinationsData);
    const combinations = [];

    combinationsData.forEach((combination) => {
      if (combination.product_id === product.id) {
        combinations.push(combination);
      }
    });

    const additionalInfo = {
      Length: "product_length",
      Sleeves: "sleeves",
      Waist: "waist",
      Shape: "shape",
    };

    const subHeight = 40;
    const height = colors.length * productRowHeight - subHeight * 2;
    result.push(
      <View key={product.id} style={{}}>
        <View style={{ ...styles.productBody }}>
          {/* ASSORTMENT */}
          <View style={{ ...styles.productBodyColumn, width: "20%" }}>
            <View style={styles.productAssortment}>
              <View
                style={{
                  ...styles.productBodyColumn,
                  opacity: product.index !== null ? 1 : 0.2,
                  borderLeftWidth: 0,
                  borderBottomWidth: 1,
                  height: subHeight,
                }}
              >
                <Text style={{ ...styles.productBodyCell }}>{product.index !== null ? product.index : "INDEX"}</Text>
              </View>
              <View
                style={{
                  ...styles.productBodyColumn,
                  opacity: 0.2,
                  borderLeftWidth: 0,
                  borderBottomWidth: 1,
                  height: subHeight,
                }}
              >
                <Text style={{ ...styles.productBodyCell }}>PRICE PLN</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "column", height: height }}>
                <View style={{ minHeight: height, paddingBottom: 15 }}>
                  {returnPreviewFiles(product.preview_files)}
                </View>
              </View>
            </View>
          </View>

          <View style={{ ...styles.productBodyColumn, width: "20%", padding: 10 }}>
            <Text
              fixed={true}
              style={{ ...styles.productBodyCell, marginBottom: 15, fontFamily: "NunitoSans-ExtraBold" }}
            >
              {product.sex_description} {product.fabric} {product.description}
            </Text>
            <Text fixed={true} style={{ ...styles.productBodyCell, marginBottom: 15 }}>
              Style no: <Text style={{ fontFamily: "NunitoSans-ExtraBold", fontSize: 10 }}>{product.style_name}</Text>
            </Text>

            {Object.entries(additionalInfo).map((object) => {
              return (
                product[object[1]] !== "" &&
                product[object[1]] !== null &&
                product[object[1]] !== "--" && (
                  <Text fixed={true} style={{ ...styles.productBodyCell, marginBottom: 5 }}>
                    <Text style={{ fontFamily: "NunitoSans-ExtraBold" }}>{object[0]}:</Text> {product[object[1]]}
                  </Text>
                )
              );
            })}

            {product.product_details !== null && product.product_details !== "" ? (
              <>
                <Text fixed={true} style={{ ...styles.productBodyCell }}>
                  <Text>Product details:</Text>
                </Text>
                <Text fixed={true} style={{ ...styles.productBodyCell, marginBottom: 15 }}>
                  {product.product_details}
                </Text>
              </>
            ) : null}
          </View>

          <View style={{ ...styles.productBodyColumn, borderRightWidth: 1, width: "60%" }}>
            {returnCompositionsGroups(colors, combinations)}
          </View>
        </View>

        {/* Product summary */}
        <View style={styles.productFooter}>
          <View style={{ ...styles.productFooterColumn, width: "60%" }}>
            <Text style={styles.productFooterCell}>SUMMARY OF {product.style_name}</Text>
          </View>
          <View style={{ ...styles.productFooterColumn, width: "20%" }}>
            <Text style={styles.productFooterCell}>TOTAL QUANTITY: {product.quantity}</Text>
          </View>
          <View style={{ ...styles.productFooterColumn, width: "20%" }}>
            <Text style={styles.productFooterCell}>TOTAL VALUE: {product.total}</Text>
          </View>
        </View>
      </View>
    );
  });
  return result;
};

const OrderDocument = (props) => {
  Font.register({
    family: "NunitoSans-ExtraBold",
    src: NunitoExtraBold,
    weight: "700",
  });
  Font.register({
    family: "NunitoSans-Bold",
    src: NunitoBold,
    weight: "700",
  });
  Font.register({
    family: "NunitoSans-Regular",
    src: NunitoRegular,
    weight: "700",
  });

  return (
    <Document onRender={(blob) => console.log("Pdf rendered", blob)}>
      <Page style={styles.body} wrap size="A4">
        {/* Order container */}
        <View style={styles.header}>
          <View style={styles.headerLogo}>
            <Image style={styles.Logo} src={CarryLogo} />
            <Text style={styles.headerCollection}>
              {typeof props.orderData.collection_sex !== "undefined"
                ? props.orderData.collection_sex.toLowerCase()
                : null}
              {typeof props.orderData.collection_sex !== "undefined" && props.orderData.collection_sex === "MEN"
                ? "'s"
                : null}
              {typeof props.orderData.collection_sex !== "undefined" ? " collection" : null}
            </Text>
          </View>

          <View style={styles.headerContent}>
            <View style={styles.tableColumnTitle}>
              <View style={{ ...styles.tableRow, borderTopWidth: 1 }}>
                <Text style={styles.tableCell}>ORDER NUMBER</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>ORDER DATE</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>FROM/DESIGNER</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>TO</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>EMAIL</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>PAYMENT</Text>
              </View>
            </View>

            <View style={styles.tableColumnContent}>
              <View style={{ ...styles.tableRow, borderTopWidth: 1 }}>
                <Text style={styles.tableCell}>{props.orderData.number}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>{props.orderData.order_date} </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>CARRY/{props.orderData.designer}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>
                  {props.orderData.contact_name}{" "}
                  {props.orderData.contact_company !== null ? "(" + props.orderData.contact_company + ")" : null}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>{props.orderData.contact_email} </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>{props.orderData.payment_term}</Text>
              </View>
            </View>
          </View>

          <View style={styles.headerRightInfo}>
            <Text style={styles.tableCell}>SHIPMENT DATE</Text>
            <Text style={styles.tableCell}>{props.orderData.shipment_date}</Text>
            <Text style={styles.tableCell}>{props.orderData.country}</Text>
          </View>
        </View>

        {/* Order summary */}
        <View style={styles.orderSummary}>
          <View style={styles.orderSummaryTable}>
            <View style={styles.orderSummaryBox}>
              <Text style={styles.orderSummaryTitle}>TOTAL QUANTITY</Text>
              <Text style={styles.orderSummaryContent}>{props.orderData.total_quantity}</Text>
            </View>
            <View style={styles.orderSummaryBox}>
              <Text style={styles.orderSummaryTitle}>TOTAL VALUE</Text>
              <Text style={styles.orderSummaryContent}>{props.orderData.total_value} USD</Text>
            </View>
          </View>
        </View>

        {/* Products and combinations container */}
        <View style={styles.productTable}>
          <View style={styles.productHeader}>
            <View style={{ ...styles.productTableColumn, width: "40%" }}>
              <Text style={styles.productHeaderCell}>ASSORTMENT/STYLE NO./PRODUCT DETAILS/SKETCH OR PHOTO</Text>
            </View>
            <View style={{ ...styles.productTableColumn, width: "20%" }}>
              <Text style={styles.productHeaderCell}>COLOUR & COMPOSITION</Text>
            </View>
            <View style={{ ...styles.productTableColumn, width: "8%" }}>
              <Text style={styles.productHeaderCell}>SIZE</Text>
            </View>
            <View style={{ ...styles.productTableColumn, width: "8%" }}>
              <Text style={styles.productHeaderCell}>QTY</Text>
            </View>
            <View style={{ ...styles.productTableColumn, width: "24%", borderRightWidth: 1 }}>
              <Text style={styles.productHeaderCell}>SWATCH FILE & OTHER INFO</Text>
            </View>
          </View>

          <View>{GenerateProductsRows(props.productsData, props.combinationsData)}</View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default OrderDocument;
