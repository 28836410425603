import React, { useState, useEffect } from "react";
import MaterialTable, { MTableBodyRow, MTableEditRow } from "material-table";
import { Grid } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import axios from "../../../axios-sw";
import { commonLocation, tableIcons, titleTemplate } from "../../../shared/tableVariables";
import { catchResponse, thenResponse } from "../../../shared/utility";
import DetailTable from "../DetailTable/DetailTable";

const FeaturesTable = (props) => {
  // Nazwa klasy
  const classTitle = props.classTitle;

  const [token] = useState(props.token);
  const [user_id] = useState(props.user_id);
  const [ip_address] = useState(props.ip_address);
  const [data, setData] = useState([]);
  const [valuesColumns, setValuesColumns] = useState({});

  useEffect(() => {
    index();
    setValuesColumns(props.columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const funcData = (newData) => {
    let result;
    if (typeof newData === "undefined") {
      result = {
        user_id: user_id,
        ip_address: ip_address,
      };
    } else {
      result = {
        ...props.classParams(newData),
        user_id: user_id,
        ip_address: ip_address,
      };
    }
    return result;
  };

  const index = () => {
    axios
      .get("/" + classTitle + ".json", { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  const create = (newData) => {
    const data = funcData(newData);
    axios
      .post("/" + classTitle + ".json", data, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        index();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  const destroy = (id) => {
    const data = funcData();
    axios
      .delete("/" + classTitle + "/" + id, { data: data, headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        index();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  const update = (id, newData) => {
    const data = funcData(newData);
    axios
      .put("/" + classTitle + "/" + id + ".json", data, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        index();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  return (
    <Grid item xs={6}>
      <MaterialTable
        title={titleTemplate(classTitle.charAt(0).toUpperCase() + classTitle.slice(1).replace("_", " "))}
        localization={{
          body: {
            emptyDataSourceMessage: "No data to display",
          },
          editRow: {
            cancelTooltip: "Back",
          },
        }}
        icons={tableIcons}
        style={{ width: "100%", marginBottom: "30px" }}
        columns={props.columns[props.classTitle]}
        data={data}
        options={{
          pageSize: 5,
          exportButton: false,
          filtering: true,
          search: false,
        }}
        components={{
          EditRow: (props) => <MTableEditRow className="uppercase" {...props} />,
          Row: (props) => <MTableBodyRow className="customRow" {...props} />,
        }}
        detailPanel={[
          (rowData) => ({
            disabled: rowData.shop_feature === null,
            render: () => {
              const map = new Map();
              for (var i = 0; i < props.productFeatureValues.length; i++) {
                if (rowData.shop_feature === parseInt(props.productFeatureValues[i]["id_feature"])) {
                  if (typeof props.productFeatureValues[i]["value"] !== "undefined") {
                    map.set(props.productFeatureValues[i]["id"], props.productFeatureValues[i]["value"]);
                  }
                }
              }
              const obj = Object.fromEntries(map);
              const newColumns = { ...valuesColumns };

              console.log(obj);
              console.log(newColumns);

              newColumns["feature_values"][
                props.columns["feature_values"].findIndex((column) => column.field === "shop_feature")
              ].lookup = {
                ...newColumns["feature_values"][
                  props.columns["feature_values"].findIndex((column) => column.field === "shop_feature")
                ].lookup,
                ...obj,
              };

              return (
                <div className="detailPanel">
                  <DetailTable
                    classTitle="feature_values"
                    token={props.token}
                    user_id={props.user_id}
                    ip_address={props.ip_address}
                    columns={newColumns}
                    feature_id={rowData.id}
                    classParams={(newData) => {
                      return {
                        feature_values: {
                          feature_id: rowData.id,
                          title: newData.title == null ? null : newData.title.toUpperCase(),
                          shop_feature:
                            typeof newData.shop_feature.id === "undefined" ? null : parseInt(newData.shop_feature.id),
                        },
                      };
                    }}
                  />
                </div>
              );
            },
          }),
        ]}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              resolve();
              create(newData);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              update(oldData.id, newData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              destroy(oldData.id);
            }),
        }}
      />
    </Grid>
  );
};

export default FeaturesTable;
