import React, {useEffect, useState} from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import './editTags.css';

const EditTags = ({onChange, value, placeholder}) => {
    const convertValueForReactTags = value => {
        return value
            .trim()
            .split(';')
            .map(item => {
                return {id: item, text: item}
            })
            .filter(item => item.text.trim() !== "")
    }

    const convertValueForMaterialTable = value => {
        return value.map(item => {
            return item.text.trim();
        })
    }

    //Hooks
    const [tags, setTags] = useState([]);
    const [suggestions] = useState([]);

    useEffect(() => {
        if(value.trim().length > 0) {
            const convertedValues = convertValueForReactTags(value);
            setTags(convertedValues);
        } else setTags([]);
    },[])


    //Consts
    const KeyCodes = {
        semicolon: 186,
        enter: 13,
    };

    const delimiters = [KeyCodes.semicolon, KeyCodes.enter];

    //Functions
    const handleDelete = i => {
        const tagsArr = [...tags].filter((tag, index) => index !== i);

        setTags(tagsArr)
        onChange(convertValueForMaterialTable(tagsArr))
    }

    const handleAddition = (tag) => {
        const tagsArr = [...tags, tag];

        setTags(tagsArr)
        onChange(convertValueForMaterialTable(tagsArr))
    }

    const handleDrag = (tag, currPos, newPos) => {
        const tagsArr = [...tags];
        const newTags = tagsArr.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setTags(newTags)
        onChange(convertValueForMaterialTable(newTags))
    }

    return (
        <div>
            <ReactTags
                tags={tags}
                suggestions={suggestions}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                delimiters={delimiters}
                placeholder ={placeholder || "Entry the tag"}
            />
        </div>
    )
}

export default EditTags;