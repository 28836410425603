import {Link} from "react-router-dom";
import FilterDateRange from "../../../components/System/FilterDateRange/FilterDateRange";
import {checkDateRange, sortByDate} from "../helperTables";
import React from "react";
import {MenuItem, Select} from "@material-ui/core";

export default {
    designerCancelledProducts: [
        {title: 'Product id', field: 'id', editable: 'never', hidden: true},
        {
            title: 'Accepted',
            field: 'accepted',
            editable: 'never',
            defaultFilter: 'all',
            filterComponent: (term) => {
                return (
                    <Select
                        value={term.columnDef.tableData.filterValue}
                        onChange={(e) => {
                            term.columnDef.tableData.filterValue = e.target.value;
                            term.onFilterChanged(2, e.target.value);
                        }}
                    >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='empty'>Empty</MenuItem>
                        <MenuItem value='cancel'>Cancel</MenuItem>
                        <MenuItem value='hold'>Hold</MenuItem>
                        <MenuItem value='accepted'>Accepted</MenuItem>
                    </Select>
                )
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, 'accepted'),
            customFilterAndSearch: (term, rowData) =>
                term === 'all' ? rowData :
                    term === 'empty' ? rowData.accepted === null :
                        term === 'cancel' ? rowData.accepted === 'CANCEL' :
                            term === 'hold' ? rowData.accepted === 'HOLD' :
                                term === 'accepted' ? (rowData.accepted !== null && rowData.accepted !== '' && rowData.accepted !== 'CANCEL' && rowData.accepted !== 'HOLD') :
                                    rowData.accepted !== null ? rowData.accepted.includes(term) : null,
        },
        {title: 'Contact person', field: 'contact_name', editable: 'never'},
        {title: 'Company', field: 'company', editable: 'never'},
        {title: 'Designer', field: 'designer', editable: 'never'},
        {title: 'Season', field: 'season', editable: 'never'},
        {
            title: 'Order number',
            field: 'order_number',
            editable: 'never',
            render: rowData => {
                return (
                    <div>
                        <Link to={'/orders/' + rowData.order_id}>{rowData.order_number}</Link>
                    </div>
                )
            }
        },
        {
            title: 'Style name', field: 'style_name', editable: 'never',
            headerStyle: {
                paddingRight: "100px"
            }
        },
        {title: 'Index', field: 'index', editable: 'never'}, // EDITABLE
        {title: 'Barcode check', field: 'barcode_check', type: 'boolean'}, // EDITABLE
        {title: 'Port', field: 'port', editable: 'never'},
        {
            title: 'Quantity per order',
            field: 'quantity',
            editable: 'never',
            customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Shipment date as per order',
            field: 'shipment_date',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.shipment_date)
            },
            editable: 'never',
            customSort: (a, b, c, d) => sortByDate(a, b, d, 'shipment_date')
        },  // EDITABLE
        {title: 'M', field: 'm', type: 'boolean'},  // EDITABLE
        {title: 'Accessory', field: 'accessory', type: 'boolean', editable: 'never'},
        {title: 'Comments', field: 'comments', editable: 'never'}
    ]
}