import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import classes from "./OrderDropzone.module.scss";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 5,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  margin: 5,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  zIndex: 10,
};

const orderDropzone = {
  height: "35px",
  textAlign: "center",
  padding: 10,
  cursor: "pointer",
};

const paragraph = {
  fontSize: 10,
  margin: 0,
  padding: 0,
};

const OrderDropzone = forwardRef((props, ref) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const imageRightSize = (image) => {
        return new Promise((resolve, reject) => {
          try {
            const fileReader = new FileReader();

            fileReader.onload = () => {
              const img = new Image();
              img.onload = () => {
                if (img.width > 3000 || img.height > 3000) {
                  reject();
                } else {
                  resolve();
                }
              };
              img.src = fileReader.result;
            };

            fileReader.readAsDataURL(image);
          } catch (e) {
            reject(e);
          }
        });
      };

      Promise.all(
        acceptedFiles.map(async (file) => {
          await imageRightSize(file);
        })
      )
        .then(() => {
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          props.onDrop(acceptedFiles);
        })
        .catch(() => {
          toast.error("Image is too large and needs to be resized! Max width/height equals 3000px!");
        });
    },
  });

  useImperativeHandle(ref, () => ({
    setInitialFiles() {
      setFiles([]);
    },
  }));

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img alt={file.name} src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  //   <a href={/*process.env.REACT_APP_BACKEND_URL + */ file.url} rel="noopener noreferrer" target="_blank">
  //     <img alt="File" src={/*process.env.REACT_APP_BACKEND_URL + */ file.url} style={img} />
  //   </a>;

  const showPreview = (files) => {
    const result = [];
    files.forEach((file, i) => {
      result.push(
        <div style={thumb} key={i}>
          <div className={classes.ModalWrapper}>
            <ModalImage rel="noopener noreferrer" small={file.url} large={file.url} style={img} />
          </div>
        </div>
      );
    });
    return result;
  };

  return (
    <section style={{ width: "100%", height: "100%" }}>
      <div {...getRootProps({ className: "dropzone" })} style={orderDropzone}>
        <input disabled={props.disabled} {...getInputProps()} />
        {files.length === 0 && props.files.length === 0 ? null : (
          <p style={paragraph}>Change file{files.length > 1 || props.files.length > 1 ? "s" : null}</p>
        )}{" "}
        {/* Nagłówek */}
        {files.length > 0 ? <aside style={thumbsContainer}>{thumbs}</aside> : null}
        {files.length === 0 && props.files.length === 0 && !props.disabled ? (
          <p style={paragraph}>Add files</p>
        ) : null}{" "}
        {/* Nagłówek dla przypadku gdy nie ma plików */}
      </div>
      {files.length === 0 && props.files.length > 0 ? (
        <aside style={thumbsContainer}>{showPreview(props.files)}</aside>
      ) : null}{" "}
      {/* showPreview tylko jeżeli nie ma tymczasowych plików w tablicy */}
    </section>
  );
});

export default OrderDropzone;
