/* Auth */

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const AUTH_ACCOUNT_UPDATE = 'AUTH_ACCOUNT_UPDATE';


/*System Info*/

export const SET_SEASON = 'SET_SEASON';
export const SET_TIME_REMAINING = 'SET_TIME_REMAINING';