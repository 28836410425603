import React, { useState, useEffect } from "react";
import { Input } from "@material-ui/core";

const FilterInput = ({ onChange, term, inputComponent }) => {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue(term.columnDef.tableData.initialValue);
  }, [term.columnDef.tableData.initialValue]);

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      onChange(e.target.value);
    }
    if (e.keyCode === 8 && e.target.value === "") {
      onChange(e.target.value);
    }
  };

  return (
    <Input
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value.split("'").join(""))}
      onKeyDown={keyPress}
      inputComponent={inputComponent}
    />
  );
};

export default FilterInput;
