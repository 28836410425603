import React, { Component } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialTable, {
  MTableBody,
  MTableBodyRow,
  MTableEditRow,
  MTableFilterRow,
  MTableToolbar,
} from "material-table";
import { Button, Chip, TablePagination, CircularProgress, LinearProgress, Checkbox } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import {
  ArrowDownward,
  ArrowUpward,
  Check,
  Close,
  HighlightOff,
  Sync,
  Description,
  Settings,
  ImportExport,
  AddPhotoAlternate,
  GetApp,
  Videocam,
  Replay,
} from "@material-ui/icons";
import { Picky } from "react-picky";
import { json2excel } from "js2excel";
import { Helmet } from "react-helmet";

// Custom
import axios from "../../axios-sw";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";

import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  nonAdminRedirect,
  formatDate,
} from "../../shared/utility";

import Spinner from "../../components/UI/Spinner/Spinner";
import Modal from "../../components/UI/Modal/Modal";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import classes from "../Bookings/Bookings.module.scss";
import SyncCombinations from "./SyncCombinations";

import { sortingPhraseHelper } from "./SyncProductsDefs";
import { Redirect } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import FilterInput from "../../components/System/FilterInput/FilterInput";
import FilterDateRange from "../../components/System/FilterDateRange/FilterDateRange";

import { checkDateRange } from "../../shared/tables/helperTables";

const syncColumns = {
  mainTable: [
    { title: "ID", field: "id", editable: "never", hidden: true },
    { title: "Product ID", field: "product_id", editable: "never", hidden: true },
    { title: "Categories (Raw)", field: "categories", hidden: true },
    {
      title: "Images",
      field: "images",
      sorting: false,
      filtering: false,
      editable: "never",
      render: (rowData) => {
        if (rowData.in_progress) {
          return <CircularProgress size={24} />;
        }
        const content = [];
        rowData.images.forEach((image, key) => {
          content.push(
            <div key={key}>
              <img src={image.url} alt={rowData.reference} />
            </div>
          );
        });
        return (
          <Carousel width={100} showStatus={false} showThumbs={false}>
            {content}
          </Carousel>
        );
      },
    },
    {
      title: "Synced",
      field: "synced",
      editable: "never",
      type: "boolean",
    },
    {
      title: "Video",
      field: "video",
      editable: "never",
      type: "boolean",
    },
    {
      title: "Synced date",
      field: "sync_at",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sync_at);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "sync_at"),
    },
    {
      title: "Shop ID",
      field: "shop_id",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Reference",
      field: "reference",
      editable: "never",
      type: "multiSelect",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (terms, rowData) => {
        return rowData;
      },
    },
    {
      title: "Product name",
      field: "product_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Short description",
      field: "short_description",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Friendly URL",
      field: "friendly_url",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Default category",
      field: "default_category_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Categories",
      field: "display_categories",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
  ],
};

class SyncProducts extends Component {
  state = {
    columns: syncColumns.mainTable,
    columnsHidden: syncColumns.mainTable,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalRow: 0,
    filterPhrase: "",
    filterByColumn: null,
    filterByConditional: { value: "all" },
    sortBy: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    tableRef: React.createRef(),
    loaded: false,
    isModal: false,
    rowData: null,
    editable: {},
    actions: [],
    blockedSaveColumnsButton: false,
    syncingProductId: [],
    limit: 20,
    selectedReferences: [],
    filterComponentSet: false,
    exportChosen: false,
    resetModalShow: false,
    resetSyncProductId: null,
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );

    const localStorageLimit = localStorage.getItem(`${this.constructor.name}Limit`);
    if (localStorageLimit === null) {
      this.indexSyncProducts(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    } else {
      this.setState({ limit: parseInt(localStorageLimit) });
      this.indexSyncProducts(page - 1, localStorageLimit, search, sortingColumnsList, singleColumnFilterList);
    }

    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        this.state.tableRef.current.state.columns[index].tableData.initialValue = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
      } else if (filter.type === "number") {
        this.state.tableRef.current.state.columns[index].tableData.initialValue = filter.value.replace("=", "").trim();
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "barcode_status" && filter.value.includes(successQuery))
          table.onFilterChange(index, "success");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes("IS NULL AND combinations.in_progress = true")
        )
          table.onFilterChange(index, "in_progress");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes(`IS NOT NULL OR ${filter.column.path} <> ''`) &&
          !filter.value.includes(successQuery)
        )
          table.onFilterChange(index, "error");
        if (filter.column.key === "barcode_status" && filter.value.includes(readyQuery))
          table.onFilterChange(index, "ready");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        this.setState({ selectedReferences: valueArray });
        table.onFilterChange(index, valueArray);
      }
    });
  }

  indexSyncProducts = (
    page = 0,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);
    let advencedFilterByColumn = null;
    let advencedFilterByConditional = { value: "all" };
    const tableColumns = syncColumns.mainTable;

    axios
      .get(`/sync-products/${page}/${limit}.json`, {
        params: {
          page: page,
          limit: limit,
          phrase: phrase,
          sortPath: sortPath,
          filterPath: filterPath,
          currentSeasons: currentSeasons,
        },
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const advencedFilterIndex = singleColumnFilterList.findIndex((filter) => filter.type === "advenced");
        if (advencedFilterIndex !== -1) {
          const column = tableColumns.filter(
            (column) => column.field === singleColumnFilterList[advencedFilterIndex].column.key
          );
          advencedFilterByColumn = { label: column[0].title, value: column[0].field };

          if (
            singleColumnFilterList[advencedFilterIndex].value.includes("<> '' AND") ||
            singleColumnFilterList[advencedFilterIndex].value.includes("<> STR_TO_DATE(\"\",'%m-%d-%y') AND")
          )
            advencedFilterByConditional = {
              label: "Full Field",
              value: "full",
            };
          else if (
            singleColumnFilterList[advencedFilterIndex].value.includes('="" OR') ||
            singleColumnFilterList[advencedFilterIndex].value.includes("= STR_TO_DATE(\"\",'%m-%d-%y') OR")
          )
            advencedFilterByConditional = {
              label: "Empty Field",
              value: "empty",
            };
          else if (singleColumnFilterList[advencedFilterIndex].value.includes('LIKE "%%" OR'))
            advencedFilterByConditional = {
              label: "All Field",
              value: "all",
            };
        }

        const columnSettings = JSON.parse(window.localStorage.getItem(`user_sync_products_${this.props.user_id}`));
        let columnsHidden = [];
        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        // Tworzenie customowego filterComponent dla reference
        const newColumns = [...this.state.columns];
        if (!this.state.filterComponentSet) {
          const index = newColumns.findIndex((column) => column.field === "reference");
          if (index > -1) {
            newColumns[index].filterComponent = (term) => {
              return (
                <Picky
                  options={res.data.all_references}
                  labelKey="label"
                  valueKey="value"
                  multiple={true}
                  includeFilter
                  includeSelectAll={false}
                  value={this.state.selectedReferences || []}
                  onChange={(values) => {
                    this.setState({ selectedReferences: values });
                    term.onFilterChanged(term.columnDef.tableData.id, values);
                  }}
                />
              );
            };
          }
        }

        this.setState({
          columns: newColumns,
          columnsHidden: newColumns,
          data: res.data.sync_products,
          filteredData: res.data.sync_products,
          totalRow: res.data.rows_quantity,
          totalQuantity: res.data.total_quantity,
          page: page,
          limit: limit,
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          loaded: true,
          filterByColumn: advencedFilterByColumn,
          filterByConditional: advencedFilterByConditional,
          filterComponentSet: true,
        });
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        actions: [
          {
            position: "row",
            action: (rowData) =>
              rowData.shop_id !== null && {
                icon: Replay,
                tooltip: "Reset synchro",
                onClick: () => {
                  this.setState({ resetModalShow: true, resetSyncProductId: rowData.id });
                },
              },
          },
          {
            position: "row",
            action: () => ({
              icon: Description,
              tooltip: "Descriptions",
              onClick: (event, rowData) => {
                this.setState({
                  redirection: (
                    <Redirect
                      to={{
                        pathname: `/sync-products/${rowData.product_id}/form-descriptions`,
                        state: {
                          productId: rowData.product_id,
                          source: window.location.pathname + this.props.location.search,
                        },
                      }}
                    />
                  ),
                });
              },
            }),
          },
          {
            position: "row",
            action: () => ({
              icon: Settings,
              tooltip: "Details",
              onClick: (event, rowData) => {
                this.setState({
                  redirection: (
                    <Redirect
                      to={{
                        pathname: `/sync-products/${rowData.product_id}/form-details`,
                        state: {
                          productId: rowData.product_id,
                          source: window.location.pathname + this.props.location.search,
                        },
                      }}
                    />
                  ),
                });
              },
            }),
          },
          {
            position: "row",
            action: (rowData) => ({
              icon: () => {
                if (this.state.syncingProductId.includes(rowData.product_id)) {
                  return <CircularProgress size={24} />;
                } else {
                  return <Sync />;
                }
              },
              tooltip: "Sync with Carry",
              onClick: () => {
                if (!this.state.syncingProductId.includes(rowData.product_id)) {
                  this.syncWithCarry(rowData.product_id);
                }
              },
            }),
          },
          () => ({
            icon: Sync,
            tooltip: "Sync with Carry",
            onClick: (e, rowData) => {
              const setSyncingProductIds = async (indexes) => {
                this.setState({ syncingProductId: indexes });
              };
              const indexes = [];
              rowData.map((row) => {
                indexes.push(row.product_id);
              });
              setSyncingProductIds(indexes).then(() => {
                this.massSync(rowData);
              });
            },
          }),
          () => ({
            icon: AddPhotoAlternate,
            tooltip: "Sync photos",
            onClick: (e, rowData) => {
              rowData.map(async (product) => {
                const newData = [...this.state.data];
                const index = newData.findIndex((stateProduct) => stateProduct.id === product.id);
                if (index > -1) {
                  newData[index].in_progress = true;
                }
                this.setState({ data: newData, filteredData: newData });
                await this.massAddImages(product.combinations, product.id);
              });
            },
          }),
          () => ({
            icon: Videocam,
            tooltip: "Add and sync videos",
            onClick: (e, rowData) => {
              rowData.map(async (product) => {
                const newData = [...this.state.data];
                const index = newData.findIndex((stateProduct) => stateProduct.id === product.id);
                if (index > -1) {
                  newData[index].in_progress = true;
                }
                this.setState({ data: newData, filteredData: newData });
                await this.massSyncVideos(product.combinations, product.id);
              });
            },
          }),
        ],
      });
    }
  };

  massSync = async (rowData) => {
    const promiseArray = [];
    rowData.map((row) => {
      promiseArray.push(this.syncWithCarry(row.product_id));
    });
    await Promise.all(promiseArray);
  };

  massAddImages = async (combinations, productId) => {
    const syncRequest = async (hz_id) => {
      const data = {
        hz_id: hz_id,
        user_id: this.props.user_id,
        ip_address: this.props.ip_address,
      };
      await axios
        .post("/sync-images.json", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
        .then((res) => {
          const newData = [...this.state.data];
          const index = newData.findIndex((stateProduct) => stateProduct.id === productId);
          if (index > -1) {
            if (res.data.combinations.length > 0) {
              newData[index].images = res.data.combinations[0].images;
            }
            newData[index].in_progress = false;
          }
          this.setState({ syncLoading: false, data: newData, filteredData: newData });
        })
        .catch((err) => {
          catchResponse(err);
        });
    };

    this.setState({ syncLoading: true });
    const hzIdArray = [];
    combinations.forEach((combination) => {
      hzIdArray.push(combination.hz_id.substring(0, combination.hz_id.length - 1));
    });
    const uniqHzIdArray = hzIdArray.filter((v, i, a) => a.indexOf(v) === i);
    if (uniqHzIdArray.length !== 0) {
      uniqHzIdArray.map((hzId) => {
        syncRequest(hzId);
      });
    }
  };

  massSyncVideos = async (combinations, productId) => {
    const syncRequest = async (hz_id) => {
      const data = {
        hz_id: hz_id,
        user_id: this.props.user_id,
        ip_address: this.props.ip_address,
      };
      await axios
        .post("/sync-video.json", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
        .then((res) => {
          console.log(res);
          const newData = [...this.state.data];
          const index = newData.findIndex((stateProduct) => stateProduct.id === productId);
          console.log(index);
          if (index > -1) {
            if (typeof res.data.combinations !== "undefined" && res.data.combinations.length > 0) {
              newData[index].video = res.data.combinations[0].video;
            } else {
              toast.warn(`Video doesn't exists for combination HZ ID: ${hz_id}`);
            }
            newData[index].in_progress = false;
          }
          this.setState({ syncLoading: false, data: newData, filteredData: newData });
        })
        .catch((err) => {
          catchResponse(err);
        });
    };

    this.setState({ syncLoading: true });
    const hzIdArray = [];
    combinations.forEach((combination) => {
      hzIdArray.push(combination.hz_id.substring(0, combination.hz_id.length - 1));
    });
    const uniqHzIdArray = hzIdArray.filter((v, i, a) => a.indexOf(v) === i);
    if (uniqHzIdArray.length !== 0) {
      uniqHzIdArray.map((hzId) => {
        syncRequest(hzId);
      });
    }
  };

  syncWithCarry = async (productId) => {
    // Ustawienie ID synchronizowanych produktów
    if (!this.state.syncingProductId.includes(productId)) {
      const newSyncingProducts = [...this.state.syncingProductId, productId];
      this.setState({ syncingProductId: newSyncingProducts });
    }

    // Globalne zmienne dla GET'ów
    const globalGetParams = {
      ws_key: process.env.REACT_APP_CARRY_WS,
      schema: "blank",
    };

    // Funkcja do usuwania ID synchronizowanego produktu
    const removeProductIdFromSync = () => {
      const newSyncingProducts = [...this.state.syncingProductId];
      const index = newSyncingProducts.indexOf(productId);
      if (index > -1) {
        newSyncingProducts.splice(index, 1);
      }
      this.setState({ syncingProductId: newSyncingProducts });
    };

    // Weryfikacja czy tylko jedna kombinacja jest default
    axios
      .get(`/sync-products/${productId}/check-defaults.json`, {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then(() => {
        axios
          .get(`/sync-products/${productId}/prepared.json`, {
            headers: { Authorization: `Bearer ${this.props.token}` },
          })
          .then((syncData) => {
            // Walidacja produktu przed sprawdzenie czy jest synchronizowany
            let validated = true;
            const product = syncData.data.product;
            const productParametersToCheck = [
              "id_category_default",
              "name",
              "description",
              "description_short",
              "categories",
            ];
            const productWrongParameters = [];
            productParametersToCheck.map((param) => {
              if (param === "categories" || param === "images") {
                if (product[param].length === 0) {
                  productWrongParameters.push(param);
                  validated = false;
                }
              } else {
                if (product[param] === null || product[param] === "") {
                  productWrongParameters.push(param);
                  validated = false;
                }
              }
            });

            const atLeastOnePhoto = syncData.data.combinations.some((combination) => combination.images.length > 0);

            if (validated && atLeastOnePhoto) {
              // Sprawdzenie czy produkt był synchronizowany
              const synced = syncData.data.product.id !== null;

              if (!synced) {
                // Dodanie produktu
                axios
                  .get(`${process.env.REACT_APP_CARRY_API_URL}/products/`, { params: globalGetParams })
                  .then((res) => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(res.data, "text/xml");
                    const exceptionArray = ["link_rewrite", "name", "description", "description_short"];

                    // Propagacja parametrów z backendu na nowy produkt
                    Object.entries(syncData.data.product).forEach(([key, value]) => {
                      const param = xmlDoc.getElementsByTagName(key)[0];
                      if (key !== "categories" && key !== "images") {
                        if (exceptionArray.includes(key)) {
                          param.removeChild(param.children[0]);
                          const languageElement = xmlDoc.createElement("language");
                          const languageElementText = xmlDoc.createTextNode(value);
                          languageElement.setAttribute("id", "1");
                          languageElement.appendChild(languageElementText);
                          param.appendChild(languageElement);
                        } else {
                          param.innerHTML = value;
                        }
                      }
                    });

                    // Dodanie kategorii
                    const categories = xmlDoc.getElementsByTagName("categories")[0];
                    categories.removeChild(categories.children[0]);
                    syncData.data.product.categories.map((categoryId) => {
                      const categoryElement = xmlDoc.createElement("category");
                      const idElement = xmlDoc.createElement("id");
                      const idElementText = xmlDoc.createTextNode(categoryId);
                      idElement.appendChild(idElementText);
                      categoryElement.appendChild(idElement);
                      categories.appendChild(categoryElement);
                    });

                    // Dodanie nowego produktu do sklepu
                    axios
                      .post(
                        `${process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL}/products?ws_key=${
                          process.env.REACT_APP_CARRY_WS
                        }`,
                        new XMLSerializer().serializeToString(xmlDoc),
                        {
                          headers: { "Content-Type": "text/xml" },
                        }
                      )
                      .then(async (syncedProduct) => {
                        const parser = new DOMParser();
                        const syncedXml = parser.parseFromString(syncedProduct.data, "text/xml");
                        // ID produktu dodanego na sklep
                        const syncedProductId = syncedXml.getElementsByTagName("id")[0].textContent;

                        // START: Wgrywam wszystkie zdjęcia do produktu po dodaniu
                        const fetchImages = (url) =>
                          fetch(url).then(async (res) => {
                            const imageBlob = await res.blob();
                            const formData = new FormData();
                            formData.append("image", imageBlob, "test.jpg");
                            return await axios
                              .post(
                                `${
                                  process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL
                                }/images/products/${syncedProductId}?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                formData,
                                {
                                  headers: { "Content-Type": "multipart/form-data" },
                                }
                              )
                              .then(async (postImageRes) => {
                                const postImageXml = await parser.parseFromString(postImageRes.data, "text/xml");
                                return await postImageXml.getElementsByTagName("id")[0].textContent;
                              });
                          });

                        const promiseArray = [];
                        const references = [];
                        syncData.data.combinations.map((combination) => {
                          if (
                            combination.reference !== null &&
                            combination.reference !== "" &&
                            !references.some(
                              (element) =>
                                element.ref === combination.reference.substring(0, combination.reference.length - 1)
                            )
                          ) {
                            references.push({
                              ref: combination.reference.substring(0, combination.reference.length - 1),
                              first_ref: combination.reference,
                              countImages: combination.images.length,
                            });

                            combination.images.map((image) => {
                              return promiseArray.push(fetchImages(image.url));
                            });
                          }
                        });

                        const imagesIds = await Promise.all(promiseArray).then((arrayOfIds) => {
                          return arrayOfIds;
                        });

                        const combinationsImagesIds = [];
                        let deepCloneImagesIds = _.cloneDeep(imagesIds);
                        references.map((reference) => {
                          combinationsImagesIds.push(deepCloneImagesIds.slice(0, reference.countImages));
                          deepCloneImagesIds = deepCloneImagesIds.slice(reference.countImages);
                        });
                        // END: Wgrywam wszystkie zdjęcia do produktu po dodaniu

                        // Callback do backendu po synchronizacji dla PRODUCT
                        axios
                          .put(
                            `/sync-products/${productId}/synced.json`,
                            {
                              shop_id: syncedProductId,
                              shop_images: imagesIds,
                            },
                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                          )
                          .then(() => {
                            axios
                              .get(`${process.env.REACT_APP_CARRY_API_URL}/combinations/`, { params: globalGetParams })
                              .then((combinationSchema) => {
                                const parser = new DOMParser();

                                // Wgrywanie pojedynczo kazdej kombinacji
                                syncData.data.combinations.forEach((combination, key) => {
                                  // Walidacja pojedynczej kombinacji
                                  let validatedCombination = true;
                                  const combinationsParametersToCheck = [
                                    "images",
                                    "color_attribute",
                                    "size_attribute",
                                    "name",
                                    "description",
                                    "composition",
                                  ];
                                  const combinationsWrongParameters = [];

                                  combinationsParametersToCheck.map((param) => {
                                    if (param === "images") {
                                      if (combination[param].length === 0) {
                                        combinationsWrongParameters.push(`${param} (Ref: ${combination.reference})`);
                                        validatedCombination = false;
                                      }
                                    } else {
                                      if (combination[param] === null || combination[param] === "") {
                                        combinationsWrongParameters.push(`${param} (Ref: ${combination.reference})`);
                                        validatedCombination = false;
                                      }
                                    }
                                  });

                                  if (validatedCombination) {
                                    const givenCombinationXml = parser.parseFromString(
                                      combinationSchema.data,
                                      "text/xml"
                                    );
                                    givenCombinationXml.getElementsByTagName("id_product")[0].innerHTML =
                                      syncedProductId;
                                    const combinationExceptionArray = [
                                      "id",
                                      "system_id",
                                      "images",
                                      "color",
                                      "color_attribute",
                                      "size_attribute",
                                      "name",
                                      "description",
                                      "composition",
                                      "description_id",
                                      "video",
                                    ];

                                    // Dopisanie parametrów do kombinacji
                                    Object.entries(combination).forEach(([key, value]) => {
                                      if (!combinationExceptionArray.includes(key)) {
                                        givenCombinationXml.getElementsByTagName(key)[0].innerHTML = value;
                                      }
                                    });

                                    // Dopisanie zdjęć do kombinacji
                                    const images = givenCombinationXml.getElementsByTagName("images")[0];
                                    images.removeChild(images.children[0]);
                                    const combinationsImagesIdsIndex = references.findIndex(
                                      (element) =>
                                        element.ref ===
                                        combination.reference.substring(0, combination.reference.length - 1)
                                    );
                                    combinationsImagesIds[combinationsImagesIdsIndex].map((imageId) => {
                                      const imageElement = givenCombinationXml.createElement("image");
                                      const idElement = givenCombinationXml.createElement("id");
                                      const idElementText = givenCombinationXml.createTextNode(imageId);
                                      idElement.appendChild(idElementText);
                                      imageElement.appendChild(idElement);
                                      images.appendChild(imageElement);
                                    });

                                    // Dopisanie product_option_values
                                    const options = [combination.color_attribute, combination.size_attribute];
                                    const productOptionValues =
                                      givenCombinationXml.getElementsByTagName("product_option_values")[0];
                                    productOptionValues.removeChild(productOptionValues.children[0]);
                                    options.map((option) => {
                                      const optionElement = givenCombinationXml.createElement("product_option_value");
                                      const idElement = givenCombinationXml.createElement("id");
                                      const idElementText = givenCombinationXml.createTextNode(option);
                                      idElement.appendChild(idElementText);
                                      optionElement.appendChild(idElement);
                                      productOptionValues.appendChild(optionElement);
                                    });

                                    // Dodanie kombinacji do produktu
                                    axios
                                      .post(
                                        `${
                                          process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL
                                        }/combinations?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                        new XMLSerializer().serializeToString(givenCombinationXml),
                                        {
                                          headers: { "Content-Type": "text/xml" },
                                        }
                                      )
                                      .then((syncedCombination) => {
                                        const syncedParser = new DOMParser();
                                        const syncedCombinationXml = syncedParser.parseFromString(
                                          syncedCombination.data,
                                          "text/xml"
                                        );
                                        const syncedCombinationId =
                                          syncedCombinationXml.getElementsByTagName("id")[0].textContent;

                                        axios
                                          .get(`${process.env.REACT_APP_CARRY_API_URL}/descriptions/`, {
                                            params: globalGetParams,
                                          })
                                          .then((descriptionsSchema) => {
                                            const parser = new DOMParser();

                                            // Synchronizacja opisów kombinacji na sklepie
                                            const descriptionsCombinationXml = parser.parseFromString(
                                              descriptionsSchema.data,
                                              "text/xml"
                                            );
                                            descriptionsCombinationXml.getElementsByTagName("id_product")[0].innerHTML =
                                              syncedProductId;
                                            descriptionsCombinationXml.getElementsByTagName(
                                              "combination_name"
                                            )[0].innerHTML = combination.name;
                                            descriptionsCombinationXml.getElementsByTagName(
                                              "description"
                                            )[1].innerHTML = `<![CDATA[${combination.description}]]>`;
                                            descriptionsCombinationXml.getElementsByTagName(
                                              "combination_reference"
                                            )[0].innerHTML = combination.reference;
                                            descriptionsCombinationXml.getElementsByTagName(
                                              "composition"
                                            )[0].innerHTML = combination.composition;
                                            if (combination.video !== null) {
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "video_url"
                                              )[0].innerHTML = `<![CDATA[${combination.video}]]>`;
                                            }

                                            // Dodanie nowego opisu pod daną kombinacje
                                            axios
                                              .post(
                                                `${
                                                  process.env.REACT_APP_HEROKU_PROXY +
                                                  process.env.REACT_APP_CARRY_API_URL
                                                }/descriptions?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                                new XMLSerializer().serializeToString(descriptionsCombinationXml),
                                                {
                                                  headers: {
                                                    "Content-Type": "text/xml",
                                                  },
                                                }
                                              )
                                              .then((syncedDescription) => {
                                                const syncedParser = new DOMParser();
                                                const syncedDescriptionXml = syncedParser.parseFromString(
                                                  syncedDescription.data,
                                                  "text/xml"
                                                );
                                                const syncedDescriptionId =
                                                  syncedDescriptionXml.getElementsByTagName("id")[0].textContent;

                                                const syncedData = {
                                                  shop_id: syncedCombinationId,
                                                  shop_images: combinationsImagesIds[combinationsImagesIdsIndex],
                                                  description_id: syncedDescriptionId,
                                                };

                                                // Callback do backendu po dodaniu kombinacji
                                                axios
                                                  .put(
                                                    `/sync-combinations/${combination.system_id}/synced.json`,
                                                    syncedData,
                                                    {
                                                      headers: {
                                                        Authorization: `Bearer ${this.props.token}`,
                                                      },
                                                    }
                                                  )
                                                  .then((res) => {
                                                    const newProductData = [...this.state.data];
                                                    const indexProduct = newProductData.findIndex(
                                                      (product) => product.product_id === productId
                                                    );
                                                    newProductData[indexProduct].synced = res.data.synced;
                                                    newProductData[indexProduct].shop_id = syncedProductId;

                                                    const newSyncingProducts = [...this.state.syncingProductId];
                                                    const index = newSyncingProducts.indexOf(productId);
                                                    if (index > -1) {
                                                      newSyncingProducts.splice(index, 1);
                                                    }

                                                    this.setState({
                                                      syncingProductId: newSyncingProducts,
                                                      data: newProductData,
                                                    });
                                                  });
                                              })
                                              .catch((err) => {
                                                catchResponse(err);
                                                toast.error(JSON.stringify(err));
                                                removeProductIdFromSync();
                                              });
                                          })
                                          .catch((err) => {
                                            catchResponse(err);
                                            toast.error(JSON.stringify(err));
                                            removeProductIdFromSync();
                                          });
                                      })
                                      .catch((err) => {
                                        catchResponse(err);
                                        toast.error(JSON.stringify(err));
                                        removeProductIdFromSync();
                                      });
                                  } else {
                                    if (combinationsWrongParameters.length > 0) {
                                      toast.warn(
                                        `Wrong COMBINATIONS parameters: ${combinationsWrongParameters.join(" | ")}`
                                      );
                                    }
                                    removeProductIdFromSync();
                                  }
                                });
                              });
                          });
                      })
                      .catch((err) => {
                        catchResponse(err);
                        toast.error(JSON.stringify(err));
                        removeProductIdFromSync();
                      });
                  });
              } else {
                // ! AKTUALIZACJA
                // Aktualizacja wgranego produktu
                axios
                  .get(`${process.env.REACT_APP_CARRY_API_URL}/products/${syncData.data.product.id}`, {
                    params: globalGetParams,
                  })
                  .then((res) => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(res.data, "text/xml");
                    const exceptionArray = ["link_rewrite", "name", "description", "description_short"];
                    const exceptionOnUpdate = [
                      "active",
                      "price",
                      "quantity",
                      "priority",
                      "categories",
                      "images",
                      "manufacturer_name",
                      "id_category_default",
                      "categories",
                    ];

                    // Propagacja parametrów z backendu
                    Object.entries(syncData.data.product).forEach(([key, value]) => {
                      const param = xmlDoc.getElementsByTagName(key)[0];
                      if (!exceptionOnUpdate.includes(key)) {
                        if (exceptionArray.includes(key)) {
                          param.removeChild(param.children[0]);
                          const languageElement = xmlDoc.createElement("language");
                          const languageElementText = xmlDoc.createTextNode(value);
                          languageElement.setAttribute("id", "1");
                          languageElement.appendChild(languageElementText);
                          param.appendChild(languageElement);
                        } else {
                          param.innerHTML = value;
                        }
                      }
                    });

                    // Usunięcie wybranych parametrów ponieważ sklep zwraca error
                    const paramsToRemove = ["manufacturer_name", "quantity", "position_in_category"];
                    paramsToRemove.forEach((param) => {
                      const paramName = xmlDoc.getElementsByTagName(param)[0];
                      paramName.parentNode.removeChild(paramName);
                    });

                    // Dodanie kategorii
                    const categories = xmlDoc.getElementsByTagName("categories")[0];
                    categories.removeChild(categories.children[0]);
                    syncData.data.product.categories.map((categoryId) => {
                      const categoryElement = xmlDoc.createElement("category");
                      const idElement = xmlDoc.createElement("id");
                      const idElementText = xmlDoc.createTextNode(categoryId);
                      idElement.appendChild(idElementText);
                      categoryElement.appendChild(idElement);
                      categories.appendChild(categoryElement);
                    });

                    axios
                      .put(
                        `${process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL}/products/${
                          syncData.data.product.id
                        }?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                        new XMLSerializer().serializeToString(xmlDoc),
                        {
                          headers: { "Content-Type": "text/xml" },
                        }
                      )
                      .then(async () => {
                        // START: Wgrywam wszystkie zdjęcia do produktu
                        // Usunięcie poprzednich zdjęć
                        const removeImages = async () => {
                          syncData.data.product.images.map(async (imageId) => {
                            await axios.delete(
                              `${
                                process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL
                              }/images/products/${syncData.data.product.id}/${imageId}?ws_key=${
                                process.env.REACT_APP_CARRY_WS
                              }`
                            );
                          });
                        };
                        await removeImages();

                        const fetchImages = (url) =>
                          fetch(url).then(async (res) => {
                            const imageBlob = await res.blob();
                            const formData = new FormData();
                            formData.append("image", imageBlob, "test.jpg");
                            return await axios
                              .post(
                                `${
                                  process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL
                                }/images/products/${syncData.data.product.id}?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                formData,
                                {
                                  headers: { "Content-Type": "multipart/form-data" },
                                }
                              )
                              .then(async (postImageRes) => {
                                const postImageXml = await parser.parseFromString(postImageRes.data, "text/xml");
                                return await postImageXml.getElementsByTagName("id")[0].textContent;
                              });
                          });

                        const promiseArray = [];
                        const references = [];
                        syncData.data.combinations.map((combination) => {
                          if (
                            !references.some(
                              (element) =>
                                element.ref === combination.reference.substring(0, combination.reference.length - 1)
                            )
                          ) {
                            references.push({
                              ref: combination.reference.substring(0, combination.reference.length - 1),
                              first_ref: combination.reference,
                              countImages: combination.images.length,
                            });

                            combination.images.map((image) => {
                              return promiseArray.push(fetchImages(image.url));
                            });
                          }
                        });

                        const imagesIds = await Promise.all(promiseArray).then((arrayOfIds) => {
                          return arrayOfIds;
                        });
                        // END: Wgrywam wszystkie zdjęcia do produktu

                        // Mapuje indeksy wgranych zdjęć na grupy kolorystyczne
                        const combinationsImagesIds = [];
                        let deepCloneImagesIds = _.cloneDeep(imagesIds);
                        references.map((reference) => {
                          combinationsImagesIds.push(deepCloneImagesIds.slice(0, reference.countImages));
                          deepCloneImagesIds = deepCloneImagesIds.slice(reference.countImages);
                        });

                        // Callback po synchronizacji dla PRODUCT
                        axios
                          .put(
                            `/sync-products/${productId}/synced.json`,
                            {
                              shop_id: syncData.data.product.id,
                              shop_images: imagesIds,
                            },
                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                          )
                          .then(() => {
                            // Aktualizacja lub wgranie nowych kombinacji
                            syncData.data.combinations.forEach((combination, key) => {
                              // Walidacja pojedynczej kombinacji
                              let validatedCombination = true;
                              const combinationsParametersToCheck = [
                                "images",
                                "color_attribute",
                                "size_attribute",
                                "name",
                                "description",
                                "composition",
                              ];
                              const combinationsWrongParameters = [];

                              combinationsParametersToCheck.map((param) => {
                                if (param === "images") {
                                  if (combination[param].length === 0) {
                                    combinationsWrongParameters.push(`${param} (Ref: ${combination.reference})`);
                                    validatedCombination = false;
                                  }
                                } else {
                                  if (combination[param] === null || combination[param] === "") {
                                    combinationsWrongParameters.push(`${param} (Ref: ${combination.reference})`);
                                    validatedCombination = false;
                                  }
                                }
                              });

                              if (validatedCombination) {
                                // Weryfikacja czy kombinacja została wgrana na sklep
                                const combinationSynced = combination.id !== null;

                                if (combinationSynced) {
                                  axios
                                    .get(`${process.env.REACT_APP_CARRY_API_URL}/combinations/${combination.id}`, {
                                      params: globalGetParams,
                                    })
                                    .then((combinationSchema) => {
                                      const parser = new DOMParser();
                                      const givenCombinationXml = parser.parseFromString(
                                        combinationSchema.data,
                                        "text/xml"
                                      );
                                      givenCombinationXml.getElementsByTagName("id_product")[0].innerHTML =
                                        syncData.data.product.id;
                                      const combinationExceptionArray = [
                                        "id",
                                        "system_id",
                                        "images",
                                        "color",
                                        "color_attribute",
                                        "size_attribute",
                                        "name",
                                        "description",
                                        "composition",
                                        "description_id",
                                        "quantity",
                                        "price",
                                        "video",
                                      ];

                                      // Dopisanie parametrów do kombinacji
                                      Object.entries(combination).forEach(([key, value]) => {
                                        if (!combinationExceptionArray.includes(key)) {
                                          givenCombinationXml.getElementsByTagName(key)[0].innerHTML = value;
                                        }
                                      });

                                      // Dopisanie zdjęć do kombinacji
                                      const images = givenCombinationXml.getElementsByTagName("images")[0];
                                      if (images.children[0]) {
                                        images.removeChild(images.children[0]);
                                      }

                                      const combinationsImagesIdsIndex = references.findIndex(
                                        (element) =>
                                          element.ref ===
                                          combination.reference.substring(0, combination.reference.length - 1)
                                      );
                                      combinationsImagesIds[combinationsImagesIdsIndex].map((imageId) => {
                                        const imageElement = givenCombinationXml.createElement("image");
                                        const idElement = givenCombinationXml.createElement("id");
                                        const idElementText = givenCombinationXml.createTextNode(imageId);
                                        idElement.appendChild(idElementText);
                                        imageElement.appendChild(idElement);
                                        images.appendChild(imageElement);
                                      });

                                      axios
                                        .put(
                                          `${
                                            process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL
                                          }/combinations/${combination.id}?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                          new XMLSerializer().serializeToString(givenCombinationXml),
                                          {
                                            headers: { "Content-Type": "text/xml" },
                                          }
                                        )
                                        .then((syncedCombination) => {
                                          const syncedParser = new DOMParser();
                                          const syncedCombinationXml = syncedParser.parseFromString(
                                            syncedCombination.data,
                                            "text/xml"
                                          );
                                          const syncedCombinationId =
                                            syncedCombinationXml.getElementsByTagName("id")[0].textContent;

                                          axios
                                            .get(
                                              `${process.env.REACT_APP_CARRY_API_URL}/descriptions/${combination.description_id}`,
                                              {
                                                params: globalGetParams,
                                              }
                                            )
                                            .then((descriptionsSchema) => {
                                              const parser = new DOMParser();

                                              const descriptionsCombinationXml = parser.parseFromString(
                                                descriptionsSchema.data,
                                                "text/xml"
                                              );

                                              descriptionsCombinationXml.getElementsByTagName("id")[0].innerHTML =
                                                combination.description_id;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "id_product"
                                              )[0].innerHTML = syncData.data.product.id;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "combination_name"
                                              )[0].innerHTML = combination.name;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "description"
                                              )[1].innerHTML = `<![CDATA[${combination.description}]]>`;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "combination_reference"
                                              )[0].innerHTML = combination.reference;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "composition"
                                              )[0].innerHTML = combination.composition;
                                              if (combination.video !== null) {
                                                descriptionsCombinationXml.getElementsByTagName(
                                                  "video_url"
                                                )[0].innerHTML = `<![CDATA[${combination.video}]]>`;
                                              }

                                              axios
                                                .put(
                                                  `${
                                                    process.env.REACT_APP_HEROKU_PROXY +
                                                    process.env.REACT_APP_CARRY_API_URL
                                                  }/descriptions/${combination.description_id}?ws_key=${
                                                    process.env.REACT_APP_CARRY_WS
                                                  }`,
                                                  new XMLSerializer().serializeToString(descriptionsCombinationXml),
                                                  {
                                                    headers: {
                                                      "Content-Type": "text/xml",
                                                    },
                                                  }
                                                )
                                                .then(() => {
                                                  axios
                                                    .put(
                                                      `/sync-combinations/${combination.system_id}/synced.json`,
                                                      {
                                                        shop_id: syncedCombinationId,
                                                        shop_images: combinationsImagesIds[combinationsImagesIdsIndex],
                                                        description_id: combination.description_id,
                                                      },
                                                      {
                                                        headers: {
                                                          Authorization: `Bearer ${this.props.token}`,
                                                        },
                                                      }
                                                    )
                                                    .then((res) => {
                                                      const newProductData = [...this.state.data];
                                                      const indexProduct = newProductData.findIndex(
                                                        (product) => product.product_id === productId
                                                      );
                                                      newProductData[indexProduct].synced = res.data.synced;

                                                      const newSyncingProducts = [...this.state.syncingProductId];
                                                      const index = newSyncingProducts.indexOf(productId);
                                                      if (index > -1) {
                                                        newSyncingProducts.splice(index, 1);
                                                      }

                                                      this.setState({
                                                        syncingProductId: newSyncingProducts,
                                                        data: newProductData,
                                                      });
                                                    });
                                                })
                                                .catch((err) => {
                                                  catchResponse(err);
                                                  toast.error(JSON.stringify(err));
                                                  removeProductIdFromSync();
                                                });
                                            })
                                            .catch((err) => {
                                              console.log("Brak opisu kombinacji");

                                              if (err.response.status === 404) {
                                                axios
                                                  .get(`${process.env.REACT_APP_CARRY_API_URL}/descriptions`, {
                                                    params: globalGetParams,
                                                  })
                                                  .then((descriptionsSchema) => {
                                                    const parser = new DOMParser();

                                                    const descriptionsCombinationXml = parser.parseFromString(
                                                      descriptionsSchema.data,
                                                      "text/xml"
                                                    );

                                                    descriptionsCombinationXml.getElementsByTagName(
                                                      "id_product"
                                                    )[0].innerHTML = syncData.data.product.id;
                                                    descriptionsCombinationXml.getElementsByTagName(
                                                      "combination_name"
                                                    )[0].innerHTML = combination.name;
                                                    descriptionsCombinationXml.getElementsByTagName(
                                                      "description"
                                                    )[1].innerHTML = `<![CDATA[${combination.description}]]>`;
                                                    descriptionsCombinationXml.getElementsByTagName(
                                                      "combination_reference"
                                                    )[0].innerHTML = combination.reference;
                                                    descriptionsCombinationXml.getElementsByTagName(
                                                      "composition"
                                                    )[0].innerHTML = combination.composition;
                                                    if (combination.video !== null) {
                                                      descriptionsCombinationXml.getElementsByTagName(
                                                        "video_url"
                                                      )[0].innerHTML = `<![CDATA[${combination.video}]]>`;
                                                    }

                                                    axios
                                                      .post(
                                                        `${
                                                          process.env.REACT_APP_HEROKU_PROXY +
                                                          process.env.REACT_APP_CARRY_API_URL
                                                        }/descriptions?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                                        new XMLSerializer().serializeToString(
                                                          descriptionsCombinationXml
                                                        ),
                                                        {
                                                          headers: {
                                                            "Content-Type": "text/xml",
                                                          },
                                                        }
                                                      )
                                                      .then((syncedDescription) => {
                                                        const syncedParser = new DOMParser();
                                                        const syncedDescriptionXml = syncedParser.parseFromString(
                                                          syncedDescription.data,
                                                          "text/xml"
                                                        );
                                                        const syncedDescriptionId =
                                                          syncedDescriptionXml.getElementsByTagName("id")[0]
                                                            .textContent;

                                                        axios
                                                          .put(
                                                            `/sync-combinations/${combination.system_id}/synced.json`,
                                                            {
                                                              shop_id: syncedCombinationId,
                                                              shop_images:
                                                                combinationsImagesIds[combinationsImagesIdsIndex],
                                                              description_id: syncedDescriptionId,
                                                            },
                                                            {
                                                              headers: {
                                                                Authorization: `Bearer ${this.props.token}`,
                                                              },
                                                            }
                                                          )
                                                          .then((res) => {
                                                            const newProductData = [...this.state.data];
                                                            const indexProduct = newProductData.findIndex(
                                                              (product) => product.product_id === productId
                                                            );
                                                            newProductData[indexProduct].synced = res.data.synced;

                                                            const newSyncingProducts = [...this.state.syncingProductId];
                                                            const index = newSyncingProducts.indexOf(productId);
                                                            if (index > -1) {
                                                              newSyncingProducts.splice(index, 1);
                                                            }

                                                            this.setState({
                                                              syncingProductId: newSyncingProducts,
                                                              data: newProductData,
                                                            });
                                                          });
                                                      });
                                                  });
                                              } else {
                                                catchResponse(err);
                                                toast.error(JSON.stringify(err));
                                                removeProductIdFromSync();
                                              }
                                            });
                                        })
                                        .catch((err) => {
                                          catchResponse(err);
                                          toast.error(JSON.stringify(err));
                                          removeProductIdFromSync();
                                        });
                                    });
                                } else {
                                  axios
                                    .get(`${process.env.REACT_APP_CARRY_API_URL}/combinations/`, {
                                      params: globalGetParams,
                                    })
                                    .then((combinationSchema) => {
                                      const parser = new DOMParser();
                                      const givenCombinationXml = parser.parseFromString(
                                        combinationSchema.data,
                                        "text/xml"
                                      );
                                      givenCombinationXml.getElementsByTagName("id_product")[0].innerHTML =
                                        syncData.data.product.id;
                                      const combinationExceptionArray = [
                                        "id",
                                        "system_id",
                                        "images",
                                        "color",
                                        "color_attribute",
                                        "size_attribute",
                                        "name",
                                        "description",
                                        "composition",
                                        "description_id",
                                        "default_on",
                                        "video",
                                      ];

                                      // Dopisanie parametrów do kombinacji
                                      Object.entries(combination).forEach(([key, value]) => {
                                        if (!combinationExceptionArray.includes(key)) {
                                          givenCombinationXml.getElementsByTagName(key)[0].innerHTML = value;
                                        }
                                      });

                                      // Dopisanie zdjęć do kombinacji
                                      const images = givenCombinationXml.getElementsByTagName("images")[0];
                                      images.removeChild(images.children[0]);
                                      const combinationsImagesIdsIndex = references.findIndex(
                                        (element) =>
                                          element.ref ===
                                          combination.reference.substring(0, combination.reference.length - 1)
                                      );
                                      combinationsImagesIds[combinationsImagesIdsIndex].map((imageId) => {
                                        const imageElement = givenCombinationXml.createElement("image");
                                        const idElement = givenCombinationXml.createElement("id");
                                        const idElementText = givenCombinationXml.createTextNode(imageId);
                                        idElement.appendChild(idElementText);
                                        imageElement.appendChild(idElement);
                                        images.appendChild(imageElement);
                                      });

                                      // Dopisanie product_option_values
                                      const options = [combination.color_attribute, combination.size_attribute];
                                      const productOptionValues =
                                        givenCombinationXml.getElementsByTagName("product_option_values")[0];
                                      productOptionValues.removeChild(productOptionValues.children[0]);
                                      options.map((option) => {
                                        const optionElement = givenCombinationXml.createElement("product_option_value");
                                        const idElement = givenCombinationXml.createElement("id");
                                        const idElementText = givenCombinationXml.createTextNode(option);
                                        idElement.appendChild(idElementText);
                                        optionElement.appendChild(idElement);
                                        productOptionValues.appendChild(optionElement);
                                      });

                                      // Dodanie kombinacji do produktu
                                      axios
                                        .post(
                                          `${
                                            process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL
                                          }/combinations?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                          new XMLSerializer().serializeToString(givenCombinationXml),
                                          {
                                            headers: { "Content-Type": "text/xml" },
                                          }
                                        )
                                        .then((syncedCombination) => {
                                          const syncedParser = new DOMParser();
                                          const syncedCombinationXml = syncedParser.parseFromString(
                                            syncedCombination.data,
                                            "text/xml"
                                          );
                                          const syncedCombinationId =
                                            syncedCombinationXml.getElementsByTagName("id")[0].textContent;

                                          axios
                                            .get(`${process.env.REACT_APP_CARRY_API_URL}/descriptions/`, {
                                              params: globalGetParams,
                                            })
                                            .then((descriptionsSchema) => {
                                              const parser = new DOMParser();

                                              // Synchronizacja opisów kombinacji na sklepie
                                              const descriptionsCombinationXml = parser.parseFromString(
                                                descriptionsSchema.data,
                                                "text/xml"
                                              );
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "id_product"
                                              )[0].innerHTML = syncData.data.product.id;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "combination_name"
                                              )[0].innerHTML = combination.name;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "description"
                                              )[1].innerHTML = `<![CDATA[${combination.description}]]>`;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "combination_reference"
                                              )[0].innerHTML = combination.reference;
                                              descriptionsCombinationXml.getElementsByTagName(
                                                "composition"
                                              )[0].innerHTML = combination.composition;
                                              if (combination.video !== null) {
                                                descriptionsCombinationXml.getElementsByTagName(
                                                  "video_url"
                                                )[0].innerHTML = `<![CDATA[${combination.video}]]>`;
                                              }

                                              // Dodanie nowego opisu pod daną kombinacje
                                              axios
                                                .post(
                                                  `${
                                                    process.env.REACT_APP_HEROKU_PROXY +
                                                    process.env.REACT_APP_CARRY_API_URL
                                                  }/descriptions?ws_key=${process.env.REACT_APP_CARRY_WS}`,
                                                  new XMLSerializer().serializeToString(descriptionsCombinationXml),
                                                  {
                                                    headers: {
                                                      "Content-Type": "text/xml",
                                                    },
                                                  }
                                                )
                                                .then((syncedDescription) => {
                                                  const syncedParser = new DOMParser();
                                                  const syncedDescriptionXml = syncedParser.parseFromString(
                                                    syncedDescription.data,
                                                    "text/xml"
                                                  );
                                                  const syncedDescriptionId =
                                                    syncedDescriptionXml.getElementsByTagName("id")[0].textContent;

                                                  const syncedData = {
                                                    shop_id: syncedCombinationId,
                                                    shop_images: combinationsImagesIds[combinationsImagesIdsIndex],
                                                    description_id: syncedDescriptionId,
                                                  };

                                                  // Callback do backendu po dodaniu kombinacji
                                                  axios
                                                    .put(
                                                      `/sync-combinations/${combination.system_id}/synced.json`,
                                                      syncedData,
                                                      {
                                                        headers: {
                                                          Authorization: `Bearer ${this.props.token}`,
                                                        },
                                                      }
                                                    )
                                                    .then((res) => {
                                                      const newProductData = [...this.state.data];
                                                      const indexProduct = newProductData.findIndex(
                                                        (product) => product.product_id === productId
                                                      );
                                                      newProductData[indexProduct].synced = res.data.synced;
                                                      newProductData[indexProduct].shop_id = syncData.data.product.id;

                                                      const newSyncingProducts = [...this.state.syncingProductId];
                                                      const index = newSyncingProducts.indexOf(productId);
                                                      if (index > -1) {
                                                        newSyncingProducts.splice(index, 1);
                                                      }

                                                      this.setState({
                                                        syncingProductId: newSyncingProducts,
                                                        data: newProductData,
                                                      });
                                                    });
                                                })
                                                .catch((err) => {
                                                  catchResponse(err);
                                                  toast.error(JSON.stringify(err));
                                                  removeProductIdFromSync();
                                                });
                                            })
                                            .catch((err) => {
                                              catchResponse(err);
                                              toast.error(JSON.stringify(err));
                                              removeProductIdFromSync();
                                            });
                                        })
                                        .catch((err) => {
                                          catchResponse(err);
                                          toast.error(JSON.stringify(err));
                                          removeProductIdFromSync();
                                        });
                                    })
                                    .catch((err) => {
                                      catchResponse(err);
                                      toast.error(JSON.stringify(err));
                                      removeProductIdFromSync();
                                    });
                                }
                              } else {
                                if (combinationsWrongParameters.length > 0) {
                                  toast.warn(
                                    `Wrong COMBINATIONS parameters: ${combinationsWrongParameters.join(" | ")}`
                                  );
                                }
                                removeProductIdFromSync();
                              }
                            });
                          });
                      })
                      .catch((err) => {
                        catchResponse(err);
                        toast.error(JSON.stringify(err));
                        removeProductIdFromSync();
                      });
                  });
              }
            } else {
              if (productWrongParameters.length > 0) {
                toast.error(`Wrong PRODUCT parameters: ${productWrongParameters.join(" | ")}`);
              }
              if (!atLeastOnePhoto) {
                toast.error(`Lack of photos for all combinations`);
              }
              removeProductIdFromSync();
            }
          })
          .catch((err) => {
            catchResponse(err);
            toast.error(JSON.stringify(err));
            removeProductIdFromSync();
          });
      })
      .catch((err) => {
        catchResponse(err);
        toast.error(JSON.stringify(err));
        removeProductIdFromSync();
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexSyncProducts(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
    if (this.props.role !== prevProps.role) this.checkActionsAndEditablePermissions();
  }

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_sync_products_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", () => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSearch = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/sync-products?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexSyncProducts(
      page,
      limit,
      search,
      sortingColumnsList,
      singleColumnFilterList,
      this.state.currentStateColumns
    );
  };

  fetchDataCombinations = async (productsData) => {
    const fetchURL = (url) => axios.get(url, { headers: { Authorization: `Bearer ${this.props.token}` } });

    let promiseArray = productsData.map((productData) => {
      return fetchURL(`/sync-combinations/${productData.product_id}.json`);
    });

    let combinationsData = [];
    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          combinationsData = [...combinationsData, ...item.data.sync_combinations];
        });
      })
      .catch((err) => {
        toast.error(err);
      });

    return combinationsData;
  };

  resetSynchro = async (syncProductId) => {
    axios
      .post(`/sync-products/${syncProductId}/reset-synchro.json`, null, {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then(async (response) => {
        const newData = [...this.state.data];
        const index = newData.findIndex((syncProduct) => syncProduct.id === syncProductId);
        if (index > -1) {
          newData[index].shop_id = null;
          newData[index].sync_at = null;
          newData[index].shop_images = null;
          newData[index].synced = null;
        }

        await response.data.description_ids.map((descriptionId) => {
          axios.delete(
            `${
              process.env.REACT_APP_HEROKU_PROXY + process.env.REACT_APP_CARRY_API_URL
            }/descriptions/${descriptionId}?ws_key=${process.env.REACT_APP_CARRY_WS}`
          );
        });

        this.setState({ data: newData, filteredData: newData, resetModalShow: false });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        <Helmet>
          <title>Sync Products | Carry System</title>
        </Helmet>

        {!this.state.loaded && <div className={"overlay"} />}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        {this.state.redirection}
        <ToastContainer />
        <HotKeys />

        <Modal
          show={this.state.resetModalShow}
          modalClosed={() => this.setState({ resetModalShow: false, resetSyncProductId: null })}
        >
          Have you checked if the product has not been sold in the online store and you still want to remove it?
          <div className={classes.ButtonModalWrapper}>
            <Button onClick={() => this.resetSynchro(this.state.resetSyncProductId)} variant={"contained"}>
              Yes
            </Button>

            <Button
              onClick={() => this.setState({ resetModalShow: false, resetSyncProductId: null })}
              variant={"outlined"}
            >
              No
            </Button>
          </div>
        </Modal>

        {this.state.syncingProductId.length > 0 && (
          <LinearProgress
            style={{
              marginBottom: "-4px",
              zIndex: 1,
              borderRadius: "4px 4px 0 0",
            }}
          />
        )}

        {this.state.limit !== null && (
          <MaterialTable
            tableRef={this.state.tableRef}
            title={titleTemplate("Sync products with carry.pl")}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataMessage,
                editRow: {
                  cancelTooltip: "Back",
                },
              },
            }}
            icons={tableIcons}
            columns={this.state.columnsHidden}
            data={this.state.filteredData}
            actions={this.state.actions}
            style={{ width: "100%" }}
            options={{
              search: false,
              pageSize: this.state.limit,
              filtering: true,
              selection: true,
              pageSizeOptions: [20, 50, 100],
            }}
            detailPanel={(rowData) => {
              return (
                <div className="detailPanel">
                  <SyncCombinations
                    user_id={this.props.user_id}
                    ip_address={this.props.ip_address}
                    productId={rowData.product_id}
                    productIndex={rowData.reference}
                    role={this.props.role}
                    token={this.props.token}
                  />
                </div>
              );
            }}
            onChangeRowsPerPage={(pages) => {
              this.setState({ limit: pages, loaded: false, data: [], filteredData: [] });
              localStorage.setItem(`${this.constructor.name}Limit`, pages);
              this.indexSyncProducts(
                0,
                pages,
                this.state.filterPhrase,
                this.state.sortingColumnsList,
                this.state.singleColumnFilterList
              );
            }}
            components={{
              Body: (props) => {
                return (
                  <MTableBody
                    {...props}
                    onFilterChanged={(columnId, value) => {
                      const columnName = this.state.columnsHidden[columnId].field;
                      let singleColumnFilterList = this.state.singleColumnFilterList;
                      let indexIsExist = this.state.singleColumnFilterList.findIndex(
                        (filter) => filter.column.key === columnName
                      );
                      const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                      const table = this.state.tableRef.current;

                      if (this.state.columnsHidden[columnId].type === "dateType") {
                        if (indexIsExist !== -1) {
                          if (value.isEmpty) {
                            singleColumnFilterList[indexIsExist].value = "IS NULL";
                          } else if (value.isFull) {
                            singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                          } else {
                            const start = moment(value.start).format("YYYY-MM-DD");
                            const end = moment(value.end).format("YYYY-MM-DD");
                            singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                          }
                        } else {
                          if (value.isEmpty) {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: "IS NULL",
                                type: "dateType",
                              },
                            ];
                          } else if (value.isFull) {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: "IS NOT NULL",
                                type: "dateType",
                              },
                            ];
                          } else {
                            const start = moment(value.start).format("YYYY-MM-DD");
                            const end = moment(value.end).format("YYYY-MM-DD");
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: `BETWEEN "${start}" AND "${end}"`,
                                type: "dateType",
                              },
                            ];
                          }
                        }
                      } else if (this.state.columnsHidden[columnId].type === "text") {
                        if (indexIsExist !== -1) {
                          singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                          table.state.columns[columnId].tableData.initialValue = value;
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "text",
                            },
                          ];
                          table.state.columns[columnId].tableData.initialValue = value;
                        }
                      } else if (this.state.columnsHidden[columnId].type === "boolean") {
                        if (indexIsExist !== -1) {
                          if (value === "checked") {
                            singleColumnFilterList.splice(indexIsExist, 1);
                            table.onFilterChange(columnId, null);
                          } else if (value === "unchecked") {
                            singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                            table.onFilterChange(columnId, undefined);
                          } else if (!value) {
                            singleColumnFilterList[indexIsExist].value = "=true";
                            table.onFilterChange(columnId, "checked");
                          }
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "=true",
                              type: "boolean",
                            },
                          ];
                          table.onFilterChange(columnId, "checked");
                        }
                      } else if (this.state.columnsHidden[columnId].type === "number") {
                        const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                        if (indexIsExist !== -1) {
                          singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                          table.state.columns[columnId].tableData.initialValue = convertValue;
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `= ${convertValue}`,
                              type: "number",
                            },
                          ];
                          table.state.columns[columnId].tableData.initialValue = convertValue;
                        }
                      } else if (this.state.columnsHidden[columnId].type === "select") {
                        if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                        else if (value === "empty" || value === "unadded")
                          value = `="" OR ${sqlColumn[0].path} IS NULL`;
                        else if (value === "reference" || value === "added")
                          value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                        else value = `LIKE "%${value}%"`;

                        if (indexIsExist !== -1) {
                          singleColumnFilterList[indexIsExist].value = value;
                          table.state.columns[columnId].tableData.initialValue = value;
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: value,
                              type: "select",
                            },
                          ];
                          table.state.columns[columnId].tableData.initialValue = value;
                        }
                      } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                        if (indexIsExist !== -1) {
                          let multiFiltersValues = "";
                          value.forEach((filter, index) => {
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                          });
                          singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                          table.onFilterChange(columnId, value);
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "multiSelect",
                            },
                          ];
                          table.onFilterChange(columnId, value);
                        }
                      }

                      this.setState({
                        singleColumnFilterList: singleColumnFilterList,
                        filterPhrase: "",
                        filterByConditional: { value: "all" },
                        filterByColumn: null,
                      });
                      this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                    }}
                  />
                );
              },
              Pagination: (props) => {
                return (
                  <TablePagination
                    {...props}
                    rowsPerPage={this.state.limit}
                    count={this.state.totalRow}
                    page={this.state.page}
                    onChangePage={(e, page) => {
                      this.state.tableRef.current.onChangePage(e, page);
                      this.setState({ page: page });
                      this.goPage(
                        page,
                        this.state.limit,
                        this.state.filterPhrase,
                        this.state.sortingColumnsList,
                        this.state.singleColumnFilterList
                      );
                    }}
                  />
                );
              },
              FilterRow: (props) => {
                return <MTableFilterRow className="filterRow" {...props} />;
              },
              Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
              EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
              Toolbar: (props) => {
                const table = this.state.tableRef.current;
                return (
                  <div className="mainToolbar">
                    <MTableToolbar {...props} />

                    {/* Usuwanie filtra z tabeli */}
                    <Chip
                      variant="outlined"
                      onClick={() => {
                        this.goPage();
                        this.props.history.push(`/sync-products?page=${1}&limit=${20}`);

                        table.state.columns.forEach((column, index) => {
                          if (column.field === "reference") {
                            table.state.columns[index].tableData.filterValue = [];
                            column.tableData.initialValue = [];
                          } else {
                            table.onFilterChange(index, null);
                            table.state.columns[index].tableData.filterValue = null;
                            column.tableData.initialValue = "";
                          }
                        });
                      }}
                      style={{ marginLeft: 24, marginTop: 5 }}
                      avatar={<HighlightOff />}
                      label="Clear filters"
                    />

                    {/* Eksportowanie danych z tabeli */}
                    <div className={classes.exportToExcelWrapper}>
                      <Chip
                        variant="outlined"
                        style={{ marginLeft: 24, marginTop: 10 }}
                        avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                        label="Export data to Excel"
                        onClick={() => {
                          const startTime = Date.now();
                          this.setState({ loaded: false, filteredData: [] });
                          const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                          const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                            this.props.location.search,
                            sortingPhraseHelper
                          );
                          const sortPath = getSortPath(sortingColumnsList);
                          const filterPath = getFilterPath(singleColumnFilterList);

                          axios
                            .get(`/export-sync-products-to-excel.json`, {
                              params: {
                                phrase: search,
                                sortPath: sortPath,
                                filterPath: filterPath,
                                currentSeasons: currentSeasons,
                              },
                              headers: { Authorization: `Bearer ${this.props.token}` },
                            })
                            .then((res) => {
                              console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                              this.fetchDataCombinations(res.data.sync_products).then((combinationsData) => {
                                let filteredSyncProductsData = res.data.sync_products.map((sync_product) => {
                                  return {
                                    id: sync_product.id,
                                    synced: sync_product.synced,
                                    sync_at: sync_product.sync_at ? new Date(sync_product.sync_at) : null,
                                    shop_id: sync_product.shop_id,
                                    product_id: sync_product.product_id,
                                    reference: sync_product.reference,
                                    product_name: sync_product.product_name,
                                    description: sync_product.description,
                                    short_description: sync_product.short_description,
                                    friendly_url: sync_product.friendly_url,
                                    display_categories: sync_product.display_categories,
                                    default_category_name: sync_product.default_category_name,
                                    fully_synced: sync_product.fully_synced,
                                  };
                                });

                                // Ustalenie kolumn
                                if (this.state.exportChosen) {
                                  const exportColumns = this.state.columnsHidden.map((column) => {
                                    return column.field;
                                  });

                                  filteredSyncProductsData = filteredSyncProductsData.map((row) => {
                                    const toDelete = exportColumns;
                                    const filtered = Object.keys(row)
                                      .filter((key) => toDelete.includes(key))
                                      .reduce((obj, key) => {
                                        obj[key] = row[key];
                                        return obj;
                                      }, {});

                                    return filtered;
                                  });
                                }

                                const filteredCombinationsData = combinationsData.map((sync_combination) => {
                                  return {
                                    id: sync_combination.id,
                                    hz_id: sync_combination.hz_id,
                                    shop_id: sync_combination.shop_id,
                                    default_combination: sync_combination.default_combination,
                                    combination_description: sync_combination.combination_description,
                                  };
                                });

                                json2excel({
                                  data: filteredSyncProductsData,
                                  name: "sync_products_" + formatDate(Date.now()),
                                  formateDate: "dd/mm/yyyy",
                                });

                                json2excel({
                                  data: filteredCombinationsData,
                                  name: "sync_combinations_" + formatDate(Date.now()),
                                  formateDate: "dd/mm/yyyy",
                                });

                                this.setState({ loaded: true, filteredData: this.state.data });
                              });
                            })
                            .catch((err) => {
                              catchResponse(err);
                            });
                        }}
                      />
                      <div className={classes.checkboxWrapper}>
                        <Checkbox
                          checked={this.state.exportChosen}
                          size={"small"}
                          onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                          color="primary"
                        />
                        <span>Export only chosen columns</span>
                      </div>
                    </div>

                    {/* Generowanie tagu wyszukania */}
                    {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          this.goPage(
                            0,
                            this.state.limit,
                            "",
                            this.state.sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ filterPhrase: "" });
                        }}
                        label={`search: ${this.state.filterPhrase}`}
                        color="primary"
                      />
                    )}

                    {/* Generowanie tagów filtrów kolumn */}
                    {this.state.singleColumnFilterList.map((filter, key) => {
                      let value;
                      let icon = "";
                      if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                      else if (filter.type === "text")
                        value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                      else if (filter.type === "select")
                        value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                      else if (filter.type === "multiSelect")
                        value = filter.value
                          .split("LIKE")
                          .join("")
                          .split("'%")
                          .join("")
                          .split("%'")
                          .join("")
                          .split(`OR ${filter.column.path}`)
                          .join(",");
                      else if (filter.type === "number") value = filter.value.replace("=", "");
                      else if (filter.type === "boolean") {
                        if (filter.value.includes("true")) {
                          value = "checked";
                          icon = <Check />;
                        } else {
                          value = "unchecked";
                          icon = <Close />;
                        }
                      }
                      return (
                        <Chip
                          key={key}
                          style={{ marginLeft: 24, marginTop: 10 }}
                          onDelete={() => {
                            if (filter.column.key === "reference") {
                              this.setState({ selectedReferences: [] });
                            }
                            const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                              (item) => item.column.key !== filter.column.key
                            );
                            this.goPage(
                              0,
                              this.state.limit,
                              this.state.filterPhrase,
                              this.state.sortingColumnsList,
                              singleColumnFilterList
                            );
                            this.setState({ singleColumnFilterList: singleColumnFilterList });
                            const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                            if (index !== -1) {
                              if (table.state.columns[index].field === "reference") {
                                table.state.columns[index].tableData.initialValue = [];
                              } else {
                                table.state.columns[index].tableData.initialValue = "";
                                table.onFilterChange(index, null);
                              }
                            }
                          }}
                          label={`${this.state.columns
                            .find((item) => item.field === filter.column.key)
                            .title.toUpperCase()}: ${value.toUpperCase()}`}
                          icon={icon}
                          variant="outlined"
                        />
                      );
                    })}

                    {/* Generowanie tagów sortowania */}
                    {this.state.sortingColumnsList.map((column) => {
                      let icon = "";

                      if (column.order === "ASC") icon = <ArrowDownward />;
                      if (column.order === "DESC") icon = <ArrowUpward />;

                      return (
                        <Chip
                          style={{ marginLeft: 24, marginTop: 10 }}
                          onDelete={() => {
                            const sortingColumnsList = this.state.sortingColumnsList.filter(
                              (item) => item.sortBy.key !== column.sortBy.key
                            );
                            this.goPage(
                              0,
                              this.state.limit,
                              this.state.filterPhrase,
                              sortingColumnsList,
                              this.state.singleColumnFilterList
                            );
                            this.setState({ sortingColumnsList: sortingColumnsList });
                          }}
                          label={`${column.sortBy.key.toUpperCase().replace("_", " ")}`}
                          icon={icon}
                        />
                      );
                    })}

                    <div className={classes.ColumnsMultiSelectWrapper}>
                      <ColumnsMultiSelect
                        onOpen={() =>
                          !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                        }
                        value={this.state.columnsHidden
                          .filter((column) => column.field !== "id")
                          .map((column) => {
                            return {
                              label: column.title,
                              value: column.field,
                              table: "sync_products",
                            };
                          })}
                        options={syncColumns.mainTable
                          .filter((column) => column.field !== "id")
                          .map((column) => {
                            return {
                              label: column.title,
                              value: column.field,
                              table: "sync_products",
                            };
                          })}
                        onChangeColumns={async (columns) => {
                          await this.setState({ columnsHidden: [], currentStateColumns: null });
                          await this.setSelectedColumns(columns);
                          this.setState({ blockedSaveColumnsButton: false });
                          this.setSortingForColumns();
                        }}
                      />
                      <Button
                        disabled={this.state.blockedSaveColumnsButton}
                        onClick={() => {
                          if (this.props.user_id) {
                            window.localStorage.setItem(
                              `user_sync_products_${this.props.user_id}`,
                              JSON.stringify(this.state.columnsHidden)
                            );
                            toast.success("The selected columns have been saved!");
                            this.setState({ blockedSaveColumnsButton: true });
                          } else {
                            toast.error("You must be logged in!");
                          }
                        }}
                        variant={"outlined"}
                      >
                        SAVE
                      </Button>
                      <Button
                        onClick={async () => {
                          if (this.props.user_id) {
                            window.localStorage.removeItem(`user_sync_products_${this.props.user_id}`);
                            await this.setState({
                              columnsHidden: this.state.columns,
                              currentStateColumns: null,
                            });
                            toast.success("Column settings have been removed!");
                            this.setSortingForColumns();
                          } else {
                            toast.error("You must be logged in!");
                          }
                        }}
                        color={"secondary"}
                        variant={"outlined"}
                      >
                        RESET
                      </Button>
                    </div>

                    <div className={classes.SearchWrapper}>
                      <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSearch} />
                    </div>

                    <div className={classes.ToolbarLinkRow}>
                      <Button
                        onClick={() => {
                          this.setState({
                            redirection: (
                              <Redirect
                                to={{
                                  pathname: "/sync-products/form-csv",
                                  state: {
                                    source: window.location.pathname,
                                  },
                                }}
                              />
                            ),
                          });
                        }}
                        variant={"outlined"}
                      >
                        <ImportExport /> Combinations CSV Sync
                      </Button>
                    </div>
                  </div>
                );
              },
            }}
            editable={this.state.editable}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(SyncProducts);
