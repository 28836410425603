import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  token: localStorage.getItem("token") || null,
  user_id: localStorage.getItem("user_id") || null,
  role: localStorage.getItem("user_role") || null,
  full_name: null,
  avatar: null,
  position: null,
  ip_address: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    user_id: action.user_id,
    role: action.role,
    full_name: action.full_name,
    avatar: action.avatar,
    position: action.position,
    ip_address: action.ip_address,
    authRedirectPath: action.authRedirectPath,
    /* Dodatkowe pola */
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    user_id: null,
    role: null,
    full_name: null,
    avatar: null,
    position: null,
    ip_address: null,
  });
};

const authAccountUpdate = (state, action) => {
  return updateObject(state, {
    full_name: action.full_name,
    avatar: action.avatar,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_ACCOUNT_UPDATE:
      return authAccountUpdate(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    default:
      return state;
  }
};

export default reducer;
