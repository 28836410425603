import React from "react";

import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import axios from "../axios-sw";
import MaskedInput from "react-text-mask";
import moment from "moment";

import { isChrome, isIOS, isFirefox, isIE, isSafari, isOpera, isEdge, browserVersion } from "react-device-detect";
import { chromeVersion, firefoxVersion } from "./defines";

export const authRedirect = (token) => {
  if (token === null && process.env.REACT_APP_AUTH) return <Redirect to="/login" />;
};

export const disabledOrderRedirection = (user_id, role, accepted) => {
  if (role === "designer" && accepted) {
    toast.error("Order is disabled to edit!");
    return <Redirect to={"/designer-dashboard/" + user_id} />;
  }
};

export const nonAdminRedirect = (user_id, role, catalog) => {
  let iga_id;
  (user_id === "26" || user_id === 26) && user_id !== null ? (iga_id = true) : (iga_id = false);
  if (role === "designer") {
    return !iga_id && !catalog ? <Redirect to={"/designer-dashboard/" + user_id} /> : null;
  } else if (role === "vm") {
    if (!catalog) return <Redirect to={"/catalog"} />;
  } else if (role === "marketing") {
    return <Redirect to={"/modelokolory"} />;
  } else if (role === "accounting") {
    return <Redirect to={"/accounting/invoices"} />;
  } else if (role === "warehouse") {
    return <Redirect to={"/warehouse/containers"} />;
  } else if (role === "ecommerce") {
    return !catalog ? <Redirect to={"/sync-products"} /> : null;
  }
};

/*
    Funkcja pomocnicza pod limitowanie widoków dla poszczególnych ról. Nie bierze
    pod uwagę admin i importer.
    Role w systemie:
        1. Admin
        2. Importer
        3. Designer
        4. VM
*/
export const roleLimitedView = (limited_role, user_role, user_id) => {
  if (limited_role !== user_role) {
    if (user_role === "designer") {
      return <Redirect to={"/designer-dashboard/" + user_id} />;
    } else if (user_role === "vm") {
      return <Redirect to={"/catalog"} />;
    } else if (user_role === "accounting") {
      return <Redirect to={"/accounting/invoices"} />;
    } else if (user_role === "warehouse") {
      return <Redirect to={"/warehouse/containers"} />;
    }
  }
};

export const orderDesignerRedirect = (user_id, designer_id, role) => {
  let run = /*process.env.REACT_APP_AUTH*/ "true";
  if (role === "designer" && user_id !== null && designer_id !== null) {
    if (user_id !== designer_id) {
      return run === "true" ? /*<Redirect to={'/designer-dashboard/' + user_id}/>*/ "test" : null;
    }
  }
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const thenResponse = (res) => {
  toast.success(res.data.message);
};

export const catchResponse = (err) => {
  if (typeof err.response !== "undefined") {
    if (err.response.data.custom_message != null) {
      toast.error(err.response.data.custom_message);
    } else if (typeof err.response.data.details !== "undefined") {
      if (err.response.data.details.length > 0) {
        err.response.data.details.forEach(function(item) {
          toast.error(item);
        });
      }
    } else {
      toast.error(err.response.data.error);
    }
  } else {
    console.log(err);
  }
};

// Indeksowanie pomocniczych modeli
export const indexDetails = (detail, columnPos, columnToMap, token, columns, updateColumns) => {
  axios
    .get("/" + detail + ".json", { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      let map = new Map();
      for (var i = 0; i < res.data.length; i++) {
        map.set(res.data[i].id, res.data[i][columnToMap]);
      }
      let obj = Object.fromEntries(map);
      let newState = { ...columns };
      newState[columnPos].lookup = obj;
      updateColumns(Object.values(newState));
    })
    .catch((err) => {
      catchResponse(err);
    });
};

export const getModel = (model, token, callback) => {
  const startTime = Date.now();
  axios
    .get("/" + model + ".json", { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
      callback(res.data);
    })
    .catch((err) => {
      catchResponse(err);
    });
};

export const postFile = (url, file_name, newData, user_id, ip_address, token, callback) => {
  if (
    newData[file_name] !== null &&
    typeof newData[file_name] !== "undefined" &&
    typeof newData[file_name].name === "string"
  ) {
    const formData = new FormData();
    formData.append(file_name, newData[file_name]);
    formData.append("user_id", user_id);
    formData.append("ip_address", ip_address);
    axios
      .post(url + newData.id, formData, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        callback();
      })
      .catch((err) => {
        catchResponse(err);
      });
  }
};

export const formatDate = (date, minusDays = null) => {
  var d = new Date(date);
  d.setDate(d.getDate() - minusDays);
  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const DateMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholder="Date"
      style={{ textTransform: "uppercase" }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/]}
      guide={false}
    />
  );
};

export const StyleNameMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      keepCharPositions={false}
      style={{ textTransform: "uppercase" }}
      mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/]}
      guide={false}
    />
  );
};

export const StyleNameMaskNumber = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      keepCharPositions={false}
      style={{ textTransform: "uppercase" }}
      mask={[/[0-9]/, /[0-9]/]}
      guide={false}
    />
  );
};

export const AssortmentGroupMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      keepCharPositions={false}
      style={{ textTransform: "uppercase" }}
      mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
      guide={false}
    />
  );
};

{
  /*Zaokrąglanie zawsze w górę do 2 miejsc po przecinku*/
}
export const roundNumber = (num) => {
  return Math.round(num * 100) / 100;
};

export const multiSort = (array, sortObject = {}, numberColumns) => {
  const sortKeys = Object.keys(sortObject);

  if (!sortKeys.length) {
    return array;
  }

  for (let key in sortObject) {
    sortObject[key] =
      sortObject[key] === "desc" || sortObject[key] === -1
        ? -1
        : sortObject[key] === "skip" || sortObject[key] === 0
        ? 0
        : 1;
  }

  const keySort = (a, b, key, direction) => {
    direction = direction !== null ? direction : 1;

    if (numberColumns.includes(key)) {
      if (parseFloat(a[key].replace(" ", "")) === parseFloat(b[key].replace(" ", ""))) {
        return 0;
      }
      return parseFloat(a[key].replace(" ", "")) > parseFloat(b[key].replace(" ", "")) ? direction : -1 * direction;
    } else {
      if (!b[key] && direction === -1) return 1 * direction;
      if (!a[key] && direction === 1) return 1 * direction;
      if (a[key] === b[key]) {
        return 0;
      }

      return a[key] > b[key] ? direction : -1 * direction;
    }
  };
  return array.sort((a, b) => {
    let sorted = 0;
    let index = 0;

    while (sorted === 0 && index < sortKeys.length) {
      const key = sortKeys[index];

      if (key) {
        const direction = sortObject[key];

        sorted = keySort(a, b, key, direction);
        index++;
      }
    }

    return sorted;
  });
};

/*UTILITY DLA PAGINACJI */
export const getAndConvertULRParams = (url, sortingPhraseHelper) => {
  let searchParams = new URLSearchParams(url);
  const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
  const limit = searchParams.get("limit") ? parseInt(searchParams.get("limit")) : 20;
  const search = searchParams.get("search");
  const sortBy = searchParams.getAll("sortBy");
  const order = searchParams.getAll("order");
  const filterBy = searchParams.getAll("filterBy");
  const value = searchParams.getAll("value");
  const type = searchParams.getAll("type");
  let sortingColumnsList = [];
  let singleColumnFilterList = [];

  sortBy.forEach((sortBy, index) => {
    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === sortBy);
    sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: order[index] }];
  });

  filterBy.forEach((filterBy, index) => {
    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === filterBy);
    let convertValue = value[index];

    singleColumnFilterList = [
      ...singleColumnFilterList,
      { column: sqlColumn[0], value: convertValue, type: type[index] },
    ];
  });

  return { page, limit, search, sortingColumnsList, singleColumnFilterList };
};

export const getSortPath = (sortingColumnsList) => {
  let sortPath = "";
  sortingColumnsList.forEach((column, index) => {
    if (column.sortBy.type && column.sortBy.type === "boolean") {
      if (index + 1 === sortingColumnsList.length) sortPath += `${column.sortBy.path} ${column.order}`;
      else sortPath += `${column.sortBy.path} ${column.order}, `;
    } else {
      if (index + 1 === sortingColumnsList.length)
        sortPath += `${column.sortBy.path} IS NULL, ${column.sortBy.path} ${column.order}`;
      else sortPath += `${column.sortBy.path} IS NULL, ${column.sortBy.path} ${column.order}, `;
    }
  });
  return sortPath;
};

const createExceptionFilterPath = (filter, index, length) => {
  if (filter.column.exception === "INVOICES") {
    if (filter.column.key === "bank_name" && index + 1 === length)
      return `((payment_terms.code != 'LC' AND invoices_bank.name ${filter.value}) OR (payment_terms.code = 'LC' AND lcs_bank.name ${filter.value})) `;
    else if (filter.column.key === "bank_name" && index + 1 !== length)
      return `((payment_terms.code != 'LC' AND invoices_bank.name ${filter.value}) OR (payment_terms.code = 'LC' AND lcs_bank.name ${filter.value})) AND `;
  }
};

export const getFilterPath = (singleColumnFilterList) => {
  let filterPath = "";
  singleColumnFilterList.forEach((filter, index) => {
    if (filter.column.exception) filterPath += createExceptionFilterPath(filter, index, singleColumnFilterList.length);
    else {
      if (index + 1 === singleColumnFilterList.length) filterPath += `(${filter.column.path} ${filter.value}) `;
      else filterPath += `(${filter.column.path} ${filter.value}) AND `;
    }
  });
  return filterPath;
};

export const createSortPath = (sortingColumnsList) => {
  let sortString = "";
  sortingColumnsList.forEach((column) => {
    sortString += `&sortBy=${column.sortBy.key}&order=${column.order}`;
  });
  return sortString;
};

export const createSingleColumnFilterVal = (singleColumnFilterList) => {
  let filterString = "";
  singleColumnFilterList.forEach((filter) => {
    filterString += `&filterBy=${filter.column.key}&type=${filter.type}&value=${encodeURIComponent(filter.value)}`;
  });
  return filterString;
};

/*WERSJA PRZEGLĄDARKI*/

export const checkMyBrowser = () => {
  if (isChrome) return "chrome";
  if (isSafari) return "safari";
  if (isIOS) return "ios";
  if (isFirefox) return "firefox";
  if (isEdge) return "edge";
  if (isIE) return "ie";
  if (isOpera) return "opera";
};

export const checkMyBrowserVersion = () => {
  if (isFirefox) return browserVersion >= firefoxVersion;
  if (isChrome) return browserVersion >= chromeVersion;
};

/*WYSYŁANIE MAILÓW Z OPÓŹNIENIAMI*/

export const sendEmailAboutLoadingDelay = (
  view,
  user_id,
  response,
  phrase,
  sortPath,
  filterPath,
  currentSeasons,
  page,
  limit,
  ids,
  token
) => {
  axios.post(
    `/mails.json`,
    {
      view: view,
      user_id: user_id,
      response: response,
      phrase: phrase,
      sortPath: sortPath,
      filterPath: filterPath,
      currentSeasons: currentSeasons,
      page: page,
      limit: limit,
      ids: ids,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const calculateTimeLeft = (time) => {
  let timeLeft;

  if (time > 0) {
    const days = Math.floor((time / (1000 * 60 * 60)) % 24);
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    timeLeft = {
      days: days >= 10 ? days : `0${days}`,
      hours: hours >= 10 ? hours : `0${hours}`,
      minutes: minutes >= 10 ? minutes : `0${minutes}`,
      seconds: seconds >= 10 ? seconds : `0${seconds}`,
    };
  } else {
    timeLeft = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
    };
  }

  return timeLeft;
};

const calculateRecursivelyDate = (date, day, freeDays) => {
  const number_of_day = new Date(
    moment(date)
      .add(day, "days")
      .format("YYYY-MM-DD")
  ).getDay();
  const include_in_day_free = freeDays.includes(
    moment(date)
      .add(day, "days")
      .format("YYYY-MM-DD")
  );

  //If day is Saturday or Sunday or day is on free days list (list from database)
  if (number_of_day === 0 || number_of_day === 6 || include_in_day_free) {
    return calculateRecursivelyDate(date, day + 1, freeDays);
  } else {
    return moment(date)
      .add(day, "days")
      .format("YYYY-MM-DD");
  }
};

export const addWorkingDaysToDate = (date, days = 1, freeDays = []) => {
  let currentDate = date;

  for (let i = 0; i < days; i++) {
    currentDate = calculateRecursivelyDate(currentDate, 1, freeDays);
  }

  return currentDate;
};
