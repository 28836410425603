import React, { Component } from "react";
import axios from "../../../axios-sw";
import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { catchResponse } from "../../../shared/utility";

class LCDetails extends Component {
  state = {
    dataOrders: [],
    dataInvoices: [],
  };

  componentDidMount() {
    this.indexOrdersInLc();
    this.indexInvoicesInLc();
  }

  createOrdersData = (order_id, order_number, assortment, total_quantity, total_value, shipment_date) => {
    return {
      order_id,
      order_number,
      assortment,
      total_quantity,
      total_value,
      shipment_date,
    };
  };

  createInvoicesData = (
    number,
    container_number,
    etd,
    warehouse,
    invoice_quantity,
    invoice_value,
    contact_person,
    payment_date,
    styles,
    amount_to_pay_lc
  ) => {
    return {
      number,
      container_number,
      etd,
      warehouse,
      invoice_quantity,
      invoice_value,
      contact_person,
      payment_date,
      styles,
      amount_to_pay_lc,
    };
  };

  indexOrdersInLc = () => {
    axios
      .get("/show-orders-in-lc/" + this.props.lcId + ".json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        res.data.forEach((el) => {
          this.setState({
            dataOrders: [
              ...this.state.dataOrders,
              this.createOrdersData(
                el.order_id,
                el.order_number,
                el.assortment,
                el.total_quantity,
                el.total_value,
                el.shipment_date
              ),
            ],
          });
        });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexInvoicesInLc = () => {
    axios
      .get("/show-invoices-in-lc/" + this.props.lcId + ".json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        res.data.invoices.forEach((el) => {
          this.setState({
            dataInvoices: [
              ...this.state.dataInvoices,
              this.createInvoicesData(
                el.number,
                el.container_number,
                el.etd,
                el.warehouse,
                el.invoice_quantity,
                el.invoice_value,
                el.contact_person,
                el.payment_date,
                el.styles,
                el.amount_to_pay_lc
              ),
            ],
            totalInvoiceValue: res.data.invoicesUniqTotalValue,
          });
        });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    return (
      <div>
        <Paper>
          <h3 style={{ padding: 15 }}>Orders</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order number</TableCell>
                <TableCell align="right">Assortment</TableCell>
                <TableCell align="right">Total quantity</TableCell>
                <TableCell align="right">Total value</TableCell>
                <TableCell align="right">Shipment date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataOrders.map((row) => (
                <TableRow key={row.order_number}>
                  <TableCell component="th" scope="row">
                    <Link to={"/orders/" + row.order_id}>{row.order_number}</Link>
                  </TableCell>
                  <TableCell align="right">{row.assortment}</TableCell>
                  <TableCell align="right">{row.total_quantity}</TableCell>
                  <TableCell align="right">{row.total_value}</TableCell>
                  <TableCell align="right">{row.shipment_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Paper style={{ marginTop: 20 }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "138px" }}>
            <h3 style={{ padding: 15 }}>Invoices</h3>
            <p>Total Amount To Pay Lc: {this.state.totalInvoiceValue}</p>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Invoice number</TableCell>
                <TableCell align="right">Container number</TableCell>
                <TableCell align="right">ETD</TableCell>
                <TableCell align="right">Warehouse</TableCell>
                <TableCell align="right">Invoice quantity</TableCell>
                <TableCell align="right">Invoice value</TableCell>
                <TableCell align="right">Contact person</TableCell>
                <TableCell align="right">Payment date</TableCell>
                <TableCell align="right">Amount To Pay Lc</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataInvoices.map((row) => (
                <TableRow key={row.number} className={row.styles}>
                  <TableCell component="th" scope="row">
                    {row.number}
                  </TableCell>
                  <TableCell align="right">{row.container_number}</TableCell>
                  <TableCell align="right">{row.etd}</TableCell>
                  <TableCell align="right">{row.warehouse}</TableCell>
                  <TableCell align="right">{row.invoice_quantity}</TableCell>
                  <TableCell align="right">{row.invoice_value}</TableCell>
                  <TableCell align="right">{row.contact_person}</TableCell>
                  <TableCell align="right">{row.payment_date}</TableCell>
                  <TableCell align="right">{row.amount_to_pay_lc}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default LCDetails;
