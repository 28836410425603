import {Link} from "react-router-dom";
import FilterDateRange from "../../../components/System/FilterDateRange/FilterDateRange";
import {checkDateRange, sortByDate} from "../helperTables";
import React from "react";
import PreviewDropzone from "../../../components/System/PreviewDropzone/PreviewDropzone";
import FilterInput from "../../../components/System/FilterInput/FilterInput";

export default {
    designerProducts: [
        /* Tabela nieedytowalna tylko do podglądu i filtrowania */
        {title: 'Order id', field: 'order_id', hidden: true},
        {title: 'Order accepted', field: 'order_accepted', editable: 'never'},
        {
            title: 'Order number',
            field: 'order_number',
            editable: 'never',
            render: rowData => {
                return (
                    <div>
                        <Link to={'/orders/' + rowData.order_id}>{rowData.order_number}</Link>
                    </div>
                )
            }
        },
        {
            title: 'Style name',
            field: 'style_name',
            editable: 'never',
            headerStyle: {
                paddingRight: "100px"
            }
        },
        {title: 'Index', field: 'index', editable: 'never'},
        {
            title: 'Quantity',
            field: 'quantity',
            editable: 'never',
            customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Average price',
            field: 'average_price',
            editable: 'never',
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Total value',
            editable: 'never',
            field: 'total',
            customSort: (a, b) => parseFloat(a.total.replace(" ", "")) - parseFloat(b.total.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Shipment date',
            editable: 'never',
            field: 'shipment_date',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.shipment_date)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'shipment_date')
        },
        {title: 'LC', field: 'lc_number', editable: 'never'},
        {
            title: 'Comments',
            field: 'comments',
            editable: 'never',
            type: 'text'
        },
        {
            title: 'File',
            field: 'file',
            editable: 'always',
            editComponent: props => <PreviewDropzone file={props.rowData.file} onChange={files => {
                props.rowData.file = files ? files[0] : null
            }}/>,
            render: data => data.file && data.file.length > 0 ? <PreviewDropzone previewFiles={data.file}/> :
                <p style={{fontStyle: "italic"}}>No file uploaded</p>
        }
    ]
}