import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../axios-sw";
import { Helmet } from "react-helmet";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableToolbar } from "material-table";

import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import Columns from "../../shared/tables";

import { Chip } from "@material-ui/core";

import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import { authRedirect, catchResponse, formatDate, nonAdminRedirect } from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../shared/defines";
import { GetApp, HighlightOff } from "@material-ui/icons";
import ModelokoloryIndexes from "./ModelokoloryIndexes/ModelokoloryIndexes";
import { json2excel } from "js2excel";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

class Modelokolory extends Component {
  state = {
    columns: Columns.modelsColorsColumns,
    data: [],
    loaded: false,
    tableRef: React.createRef(),
  };

  componentDidMount() {
    this.indexModelsColors();
  }

  indexModelsColors() {
    axios
      .get("/modelokolory.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.setState({ data: res.data, loaded: true });
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  }

  fetchDataModelColorIndexes = async () => {
    const { data } = this.state;
    const fetchURL = (url) => axios.get(url, { headers: { Authorization: `Bearer ${this.props.token}` } });
    let modelColorIndexes = [];

    let promiseArray = data.map((item) => {
      return fetchURL(
        "/modelokolory-indexes/" +
          item.color_id +
          "/" +
          encodeURIComponent(item.model_color_code) +
          "/" +
          item.product_id +
          ".json"
      );
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          modelColorIndexes = [...modelColorIndexes, ...item.data];
        });
        this.setState({ loaded: true });
      })
      .catch((err) => {
        toast.error(err);
      });

    return modelColorIndexes;
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }
    const table = (
      <MaterialTable
        tableRef={this.state.tableRef}
        title={titleTemplate("Modelokolory")}
        localization={{
          body: {
            emptyDataSourceMessage: emptyDataMessage,
          },
        }}
        icons={tableIcons}
        style={{ width: "100%" }}
        columns={this.state.columns}
        data={this.state.data}
        options={{
          pageSize: defaultPageSize,
          filtering: true,
          actions: true,
        }}
        detailPanel={(rowData) => {
          return (
            <div className="detailPanel">
              <ModelokoloryIndexes
                token={this.props.token}
                user_id={this.props.user_id}
                ip_address={this.props.ip_address}
                productId={rowData.product_id}
                colorId={rowData.color_id}
                modelColorCode={rowData.model_color_code}
              />
            </div>
          );
        }}
        components={{
          Toolbar: (props) => {
            return (
              <div className="mainToolbar">
                <MTableToolbar {...props} />

                {/* Usuwanie filtra z tabeli */}
                <Chip
                  variant="outlined"
                  style={{ marginLeft: 24 }}
                  avatar={<HighlightOff />}
                  label="Clear filters"
                  onClick={() => {
                    const table = this.state.tableRef.current;
                    for (let i = 0; i < table.state.columns.length; i++) {
                      table.onFilterChange(i, null);
                      table.state.columns[i].tableData.filterValue = null;
                    }
                  }}
                />
                {/* Eksportowanie danych z tabeli */}
                <Chip
                  variant="outlined"
                  style={{ marginLeft: 24 }}
                  avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                  label="Export data to Excel"
                  onClick={async () => {
                    let dataModelsColors = this.state.tableRef.current.state.data;
                    let dataModelsColorsColumns = this.state.tableRef.current.state.columns;
                    this.setState({ loaded: false });
                    let modelColorIndexesData = await this.fetchDataModelColorIndexes();

                    const filteredModelsColorsData = dataModelsColors.map((model) => {
                      return {
                        model_color_code: model.model_color_code,
                        description: model.description,
                        sex: model.sex,
                        quantity: model.quantity ? parseInt(model.quantity.replace(/\s/g, "").replace(",", ".")) : 0,
                        shipment_date: model.shipment_date,
                        forwarder_confirm_date: model.forwarder_confirm_date,
                        is_session: model.is_session,
                        photo_form: model.photo_form,
                        photo_pattern: model.photo_pattern,
                        composition: model.composition,
                        colour_for_website: model.colour_for_website,
                        product_color: model.color_title,
                        fit_slim_regular: model.fit_slim_regular,
                        product_description: model.product_description,
                        accepted: model.accepted,
                        season: model.season,
                        date_of_create_photo: model.date_of_create_photo,
                      };
                    });

                    json2excel({
                      data: filteredModelsColorsData,
                      name: "modelokolory_" + formatDate(Date.now()),
                      formateDate: "dd/mm/yyyy",
                    });

                    json2excel({
                      data: modelColorIndexesData,
                      name: "modelokolory_indexes_" + formatDate(Date.now()),
                      formateDate: "dd/mm/yyyy",
                    });
                  }}
                />
              </div>
            );
          },
        }}
      />
    );
    return (
      <div>
        <Helmet>
          <title>Modelokolory | Carry System</title>
        </Helmet>

        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        <HotKeys />

        {table}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Modelokolory);
