import React, {Component} from 'react';
import axios from "../../../axios-sw";

import {
    Paper
} from '@material-ui/core';

import { tableIcons, titleTemplate} from "../../../shared/tableVariables";
import { role } from "../../../config";

import MaterialTable, {MTableEditRow, MTableToolbar} from "material-table";
import {toast, ToastContainer} from "react-toastify";
import Columns from "../../../shared/tables";
import {HotKeysSubtable} from "../../../components/System/HotKeys/HotKeys";
import {catchResponse} from "../../../shared/utility";

class OrderProducts extends Component {
    state = {
        columns: Columns.ordersProductsColumns,
        data: [],
        editable: {}

    };

    componentDidMount() {
        this.indexProducts();
        this.checkActionsAndEditablePermissions();
    }

    checkActionsAndEditablePermissions = () => {
        if (this.props.role === role.MANAGEMENT) this.setState({editable: {}});
        else this.setState({
            editable: {
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        resolve();
                        this.destroyProduct(oldData.id);
                    })
            }
        })
    }

    indexProducts = () => {
        axios.get('/orders/' + this.props.orderId + '/products/orders-page.json', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.setState({data: res.data});
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    destroyProduct = (id) => {
        const data = {
            user_id: this.props.user_id,
            ip_address: this.props.ip_address
        };
        axios.delete('/product_combinations/' + id, {
            data: data,
            headers: {'Authorization': `Bearer ${this.props.token}`}
        })
            .then(res => {
                this.indexProducts();
                toast.success(res.data.message);
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    // updateProduct = (id, newData) => {
    //     const data = {
    //         product_combination: {
    //             style_name: newData.style_name.toUpperCase(),
    //             sex: newData.sex,
    //             fabric_id: newData.fabric_id
    //         },
    //         style_name: newData.style_name,
    //         user_id: this.props.user_id,
    //         ip_address: this.props.ip_address
    //     };
    //     const url = '/product_combinations/' + id + '.json';
    //     axios.put(url, data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //         .then(res => {
    //             this.indexProducts();
    //             toast.success(res.data.message);
    //         })
    //         .catch(err => {
    //             catchResponse(err)
    //         })
    // };

    render() {
        return (
            <Paper>
                <ToastContainer/>
                <HotKeysSubtable/>

                <MaterialTable
                    title={titleTemplate(
                        <div>
                            Products in order {this.props.orderNumber}
                        </div>
                    )}
                    icons={tableIcons}
                    style={{width: '100%'}}
                    columns={this.state.columns}
                    data={this.state.data}
                    actions={this.state.actions}
                    localization={{
                        editRow: {
                            cancelTooltip: 'Back'
                        }
                    }}
                    options={{
                        pageSize: 5,
                        exportButton: false,
                        filtering: false,
                        search: false,
                        paging: false
                    }}
                    components={{
                        EditRow: props => (
                            <MTableEditRow autoFocus className='subtableEditingRow uppercase ' {...props} />
                        ),
                        Toolbar: props => {
                            return (
                                <div className='subtableToolbar'>
                                    <MTableToolbar {...props} />
                                </div>
                            )
                        }
                    }}
                    editable={this.state.editable}
                />
            </Paper>
        )
    }
}

export default OrderProducts;