import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const  ValueLabelComponent = props => {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={1} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const CustomSlider =  ({defaultValue, maxValue, tooltipLabel, onChange, ...props})  => {

    return (
        <div>
            <Typography gutterBottom>{tooltipLabel}</Typography>
            <Slider
                ValueLabelComponent={ValueLabelComponent}
                aria-label="custom thumb label"
                onChangeCommitted={(e,val) => onChange(val)}
                max={maxValue}
                defaultValue={defaultValue || 0}
            />
        </div>
    );
}

CustomSlider.propTypes = {
    maxValue: PropTypes.number.isRequired,
};


export default CustomSlider;