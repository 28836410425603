import React, { Component } from "react";
import axios from "../../../axios-sw";

import { toast } from "react-toastify";

import { formatDate, DateMask, catchResponse } from "../../../shared/utility";
import Columns from "../../../shared/tables";

import { Table, TableBody, TableCell, TableRow, FormControl, Input, Button, Paper } from "@material-ui/core";

import CarryLogo from "../../../assets/img/carryLogo.jpeg";
import { Done, PictureAsPdf } from "@material-ui/icons";
import classes from "./OrderTable.module.scss";

// Form
import { Form, Formik } from "formik";
import ReactSelect from "react-select";
import * as Yup from "yup";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { withRouter } from "react-router-dom";
import $ from "jquery";

class OrderTable extends Component {
  state = {
    orderData: [],
    orderColumns: Columns.designerFormTables.orderPage.order,
    seasons: [],
    contacts: [],
    payment_terms: [],
    designerCode: null,
    redirection: null,
  };

  componentDidMount() {
    this.getOrderData();
    this.getSeasons();
    this.getContacts();
    this.getPaymentTerms();
  }

  getOrderData = () => {
    axios
      .get("/orders/" + this.props.orderId + ".json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get("/designers/" + res.data.designer_id + ".json", {
            headers: { Authorization: `Bearer ${this.props.token}` },
          })
          .then((res) => {
            this.setState({ designerCode: res.data.code });
          })
          .catch((err) => {
            catchResponse(err);
          });
        this.setState({ orderData: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  getSeasons = () => {
    axios
      .get("/seasons.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function(obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ seasons: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  getContacts = () => {
    axios
      .get("/contacts.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function(obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ contacts: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  getPaymentTerms = () => {
    axios
      .get("/payment_terms.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function(obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ payment_terms: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        keepCharPositions={false}
        style={{ textTransform: "uppercase" }}
        mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/]}
        guide={false}
      />
    );
  };

  render() {
    DateMask.propTypes = {
      inputRef: PropTypes.func.isRequired,
    };

    const OrderFormSchema = Yup.object().shape({
      season: Yup.object()
        .typeError("Season is required!")
        .required("Season is required!"),
      contact: Yup.object()
        .typeError("Contact is required!")
        .required("Contact is required!"),
      order_number: Yup.string().required("Order number is required!"),
      order_date: Yup.date()
        .typeError("Order date must have right format!")
        .required("Order date is required!"),
      // .min(formatDate(Date.now(), 30), "Order date can not be earlier than 30 days ago"),
      payment_terms: Yup.object()
        .typeError("Payment terms is required!")
        .required("Payment terms is required!"),
      shipment_date: Yup.string().required("Shipment date is required!"),
      country: Yup.object()
        .typeError("Country is required!")
        .required("Country is required!"),
    });

    let content = null;
    if (this.state.orderData.length !== 0) {
      content = (
        <Paper style={{ padding: 0, width: "100%" }}>
          <Formik
            initialValues={{
              season: { value: this.state.orderData.season_id, label: this.state.orderData.season_code },
              contact: { value: this.state.orderData.contact_id, label: this.state.orderData.contact_code },
              order_number: this.state.orderData.part_number,
              order_date: formatDate(this.state.orderData.order_date),
              payment_terms: { value: this.state.orderData.payment_term_id, label: this.state.orderData.payment_term },
              shipment_date: formatDate(this.state.orderData.shipment_date),
              country: { value: this.state.orderData.country, label: this.state.orderData.country },
              proforma: false,
            }}
            validationSchema={OrderFormSchema}
            onSubmit={(values, { setSubmitting }) => {
              $(`.submitSection button`).click();
              $(`.save-color-button`).click();
              if (this.state.designerCode !== null) {
                const number =
                  values.season.label + this.state.designerCode + "-" + values.contact.label + values.order_number;
                const data = {
                  order: {
                    number: number == null ? null : number.toUpperCase(),
                    season_id: values.season.value,
                    order_date: values.order_date,
                    payment_term_id: values.payment_terms.value,
                    shipment_date: values.shipment_date,
                    country: values.country.value,
                  },
                  /* Activity logging */
                  user_id: this.props.user_id,
                  ip_address: this.props.ip_address,
                };
                axios
                  .put("/orders/" + this.props.orderId, data, {
                    headers: { Authorization: `Bearer ${this.props.token}` },
                  })
                  .then((res) => {
                    this.props.setSubmittingTotals(true);
                    setTimeout(() => {
                      axios
                        .get(`/orders/${this.props.orderId}/totals.json`, {
                          headers: { Authorization: `Bearer ${this.props.token}` },
                        })
                        .then((resTotals) => {
                          this.props.updateTotal(resTotals.data.total_quantity, resTotals.data.total_value);
                          this.props.setSubmittingTotals(false);
                        })
                        .catch(() => {
                          this.props.setSubmittingTotals(false);
                        });
                    }, 5000);

                    toast.success("Order updated!");
                    if (res.data.custom_data.barcodes_generated) {
                      toast.warn("Note, you need to change products data directly in HZ!");
                    }
                    this.props.updateDefinedPart(res.data.custom_data.defined_part);
                    setSubmitting(false);
                  })
                  .catch((err) => {
                    catchResponse(err);
                    setSubmitting(false);
                  });
              } else {
                toast.error("Something is wrong with your designer code!");
                setSubmitting(false);
              }
            }}
          >
            {({
              submitForm,
              setFieldValue,
              setFieldTouched,
              touched,
              errors,
              isSubmitting,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form className={classes.Form}>
                <Table className={classes.Table}>
                  <TableBody>
                    <TableRow>
                      <TableCell rowSpan="3" className={classes.LogoCell}>
                        <img src={CarryLogo} alt="Carry" width="160px" />
                      </TableCell>

                      <TableCell style={{ width: "60%" }} className={classes.RegularCell}>
                        <div className={classes.Title}>Order number:</div>
                        <FormControl className={classes.Content}>
                          <div className={classes.ReactSelect}>
                            <ReactSelect
                              isDisabled={this.props.disabled}
                              placeholder=""
                              onChange={(e) => setFieldValue("season", e)}
                              value={values.season}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                              options={this.state.seasons}
                            />
                          </div>
                          <div className={classes.DesignerCode}>
                            <Input disabled={true} value={this.state.designerCode + "-"} />
                          </div>
                          <div className={classes.ReactSelectContact}>
                            <ReactSelect
                              isDisabled={this.props.disabled}
                              placeholder=""
                              onChange={(e) => setFieldValue("contact", e)}
                              value={values.contact}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                              options={this.state.contacts}
                            />
                          </div>
                          <div className={classes.StyleInput}>
                            <Input
                              disabled={this.props.disabled}
                              name="order_number"
                              id="order_number"
                              placeholder={"GVG23"}
                              value={values.order_number}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputComponent={this.TextMaskCustom}
                            />
                          </div>
                          <div className={classes.MultiInputFeedback}>
                            {errors.order_number && touched.order_number && (
                              <div className={classes.InputFeedback}>{errors.order_number}</div>
                            )}
                            {errors.season && <div className={classes.InputFeedback}>{errors.season}</div>}
                          </div>
                        </FormControl>
                      </TableCell>

                      <TableCell rowSpan="6" style={{ width: "20%" }} className={classes.Shipment}>
                        <div className={classes.Title}>Shipment date:</div>
                        <FormControl className={classes.Content}>
                          <Input
                            disabled={this.props.disabled}
                            className={classes.Input}
                            name="shipment_date"
                            id="shipment_date"
                            value={values.shipment_date}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputComponent={DateMask}
                          />
                          {errors.shipment_date && touched.shipment_date && (
                            <div className={classes.InputFeedback}>{errors.shipment_date}</div>
                          )}
                        </FormControl>

                        <FormControl className={classes.Content}>
                          <div style={{ width: 200, margin: "20px auto" }}>
                            <ReactSelect
                              isDisabled={this.props.disabled}
                              name="country"
                              id="country"
                              value={values.country}
                              onChange={(e) => setFieldValue("country", e)}
                              onBlur={() => setFieldTouched("country", true)}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                              options={[
                                { value: "CHINA", label: "CHINA" },
                                { value: "BANGLADESH", label: "BANGLADESH" },
                                { value: "INDIA", label: "INDIA" },
                                { value: "MYANMAR", label: "MYANMAR" },
                                { value: "POLSKA", label: "POLSKA" },
                                { value: "LITWA", label: "LITWA" },
                                { value: "PAKISTAN", label: "PAKISTAN" },
                              ]}
                            />
                          </div>
                          {/*<Input*/}
                          {/*    style={{textTransform: 'uppercase !important'}}*/}
                          {/*    className={classes.Input}*/}
                          {/*    name="country"*/}
                          {/*    id="country"*/}
                          {/*    placeholder='COUNTRY'*/}
                          {/*    value={values.country}*/}
                          {/*    onBlur={handleBlur}*/}
                          {/*    onChange={handleChange}*/}
                          {/*/>*/}
                          {errors.country && touched.country && (
                            <div className={classes.InputFeedback}>{errors.country}</div>
                          )}
                        </FormControl>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.RegularCell}>
                        <div className={classes.Title}>Order date:</div>
                        <FormControl className={classes.Content}>
                          <Input
                            disabled={this.props.disabled}
                            name="order_date"
                            id="order_date"
                            value={values.order_date}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputComponent={DateMask}
                          />
                          {errors.order_date && touched.order_date && (
                            <div className={classes.InputFeedback}>{errors.order_date}</div>
                          )}
                        </FormControl>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.RegularCell}>
                        <div className={classes.Title}>From/designer:</div>
                        <div className={classes.Content}>
                          <Input disabled={true} value={this.state.orderData.designer} />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell rowSpan="3" className={classes.CollectionCell}>
                        {typeof this.state.orderData.collection_sex !== "undefined" &&
                        this.state.orderData.collection_sex !== null
                          ? this.state.orderData.collection_sex.toLowerCase()
                          : null}
                        {typeof this.state.orderData.collection_sex !== "undefined" &&
                        this.state.orderData.collection_sex !== null &&
                        this.state.orderData.collection_sex === "MEN"
                          ? "'s"
                          : null}
                        {typeof this.state.orderData.collection_sex !== "undefined" &&
                        this.state.orderData.collection_sex !== null
                          ? " collection"
                          : null}
                      </TableCell>
                      <TableCell className={classes.RegularCell}>
                        <div className={classes.Title}>To:</div>
                        <div className={classes.Content}>
                          <Input disabled={true} value={this.state.orderData.contact} />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.RegularCell}>
                        <div className={classes.Title}>Email:</div>
                        <div className={classes.Content}>
                          <Input disabled={true} value={this.state.orderData.contact_email} />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.RegularCell}>
                        <div className={classes.Title}>Payment:</div>
                        <FormControl className={classes.Content}>
                          <ReactSelect
                            isDisabled={this.props.disabled}
                            classNamePrefix="react-select"
                            className={classes.Payment}
                            name="payment_terms"
                            id="payment_terms"
                            value={values.payment_terms}
                            onChange={(e) => setFieldValue("payment_terms", e)}
                            onBlur={() => setFieldTouched("payment_terms", true)}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                            options={this.state.payment_terms}
                          />
                          {errors.payment_terms && touched.payment_terms && (
                            <div className={classes.InputFeedback}>{errors.payment_terms}</div>
                          )}
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <div className={classes.ButtonsContainer}>
                  <Button
                    className={classes.PdfButton}
                    variant="contained"
                    onClick={() => {
                      this.props.history.push("/orders/" + this.state.orderData.id + "/pdf-preview");
                    }}
                  >
                    <PictureAsPdf fontSize="small" />
                  </Button>
                  <Button
                    id="save-order-button"
                    className={classes.SubmitButton}
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    <Done fontSize="small" />
                    Save all
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Paper>
      );
    }

    return (
      <>
        {this.state.redirection}
        {content}
      </>
    );
  }
}

export default withRouter(OrderTable);
