import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import axios from "../../../axios-sw";

import { authRedirect, catchResponse } from "../../../shared/utility";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import OrderDocument from "./OrderDocument/OrderDocument";
import { Button, Paper } from "@material-ui/core";
import { ToastContainer } from "react-toastify";

import { ArrowBack } from "@material-ui/icons";
import classes from "../OrderTable/OrderTable.module.scss";
import Spinner from "../../../components/UI/Spinner/Spinner";

let blobUrl = "";

class PDFPreview extends Component {
  state = {
    orderData: [],
    productsData: [],
    combinationsData: [],
    blobUrl: "",
    redirection: null,
    loading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    await this.showOrder();
    await this.getProductCombinations();
    await this.getProducts();
  };

  componentWillUnmount() {
    // window.URL.revokeObjectURL(blobUrl);
    // window.URL.revokeObjectURL(this.blobUrl);
  }

  showOrder = () => {
    axios
      .get("/orders/" + this.props.match.params.id + "/pdf.json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.setState({ orderData: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  getProductCombinations = () => {
    axios
      .get("/orders/" + this.props.match.params.id + "/products/pdf.json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.setState({ productsData: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  getProducts = () => {
    axios
      .get("/orders/" + this.props.match.params.id + "/combinations.json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        const sorted = res.data.sort(function(a, b) {
          let o1 = a.order;
          let o2 = b.order;
          let p1 = a.size_id;
          let p2 = b.size_id;

          if (o1 < o2) return -1;
          if (o1 > o2) return 1;
          if (p1 < p2) return -1;
          if (p1 > p2) return 1;
          return 0;
        });

        this.setState({ combinationsData: sorted, loading: false });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    return (
      <Paper style={{ padding: 20, minHeight: "1000px" }}>
        <Helmet>
          <title>PDF Preview | Carry System</title>
        </Helmet>


        {authRedirect(this.props.token)}
        <ToastContainer />
        {this.state.redirection}

        <div className={classes.PDFTitle}>
          <div className={classes.PDFButton}>
            <Button className={classes.BackButton} variant="contained" onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Button>
          </div>

          <h3>PDFPreview for order {this.state.orderData.number}</h3>
        </div>
        {this.state.loading && (
          <>
            <Spinner />
            <div style={{ textAlign: "center" }}>
              <p>The document is being loaded... </p>
            </div>
          </>
        )}
        {!this.props.token && (
          <div style={{ textAlign: "center" }}>
            <p>You need to login </p>
          </div>
        )}

        {!this.state.loading &&
          this.state.orderData.length === 0 &&
          this.state.productsData.length === 0 &&
          this.state.combinationsData.length === 0 && (
            <div style={{ textAlign: "center" }}>
              <p>Order does not contain any products! </p>
            </div>
          )}

        {!this.state.loading &&
          this.props.token &&
          this.state.orderData.length !== 0 &&
          this.state.productsData.length > 0 &&
          this.state.combinationsData.length > 0 && (
            <>
              <Button style={{ margin: "0 auto 20px auto", display: "block" }} variant="contained" color="primary">
                <PDFDownloadLink
                  document={
                    <OrderDocument
                      orderData={this.state.orderData}
                      productsData={this.state.productsData}
                      combinationsData={this.state.combinationsData}
                      setBlob={(blob) => {
                        this.setState({ blobUrl: blob });
                      }}
                    />
                  }
                  fileName={this.state.orderData.number + ".pdf"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {({ url, loading }) => {
                    if (loading) {
                      return "Loading document...";
                    } else {
                      blobUrl = url;
                      return "Download PDF";
                    }
                  }}
                </PDFDownloadLink>
              </Button>

              <PDFViewer style={{ width: "100%", minHeight: "820px" }}>
                <OrderDocument
                  orderData={this.state.orderData}
                  productsData={this.state.productsData}
                  combinationsData={this.state.combinationsData}
                  setBlob={(blob) => {
                    this.setState({ blobUrl: blob });
                  }}
                />
              </PDFViewer>
            </>
          )}
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(PDFPreview);
