import Idle from "react-idle";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/actions";
import { useHistory } from "react-router";
import { timeRemaining } from "../../../shared/defines";
import { setTimeRemaining } from "../../../store/reducers/systemInfo";

const IdleTimer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let timeLeft = timeRemaining || 1000 * 60 * 59;
  let interval;

  const { role } = useSelector((state) => state.auth);

  const handleIdle = (idle) => {
    if (idle) {
      interval = setInterval(() => {
        if (timeLeft > 0) {
          timeLeft = timeLeft - 1000;
          dispatch(setTimeRemaining(timeLeft));
        } else {
          clearInterval(interval);
          localStorage.setItem("rolePath", role);
          localStorage.setItem("originPath", window.location.pathname + window.location.search);
          dispatch(logout());
          history.push("/login");
        }
      }, 1000);
    } else {
      timeLeft = timeRemaining || 1000 * 60 * 59;
      dispatch(setTimeRemaining(timeLeft));
      clearInterval(interval);
    }
  };

  return <Idle onChange={({ idle }) => handleIdle(idle)} />;
};

export default IdleTimer;
