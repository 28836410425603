import React, { Component } from "react";
import axios from "../../../axios-sw";

import Paper from "@material-ui/core/Paper";
import { tableIcons, titleTemplate } from "../../../shared/tableVariables";
import MaterialTable from "material-table";
import Columns from "../../../shared/tables";
import { HotKeysSubtable } from "../../../components/System/HotKeys/HotKeys";

import { catchResponse } from "../../../shared/utility";

class ModelokoloryIndexes extends Component {
  state = {
    columns: Columns.modelsColorsIndexesColumns,
    data: [],
  };

  componentDidMount() {
    this.indexModelsColorsIndexes();
  }

  indexModelsColorsIndexes = () => {
    axios
      .get(
        "/modelokolory-indexes/" +
          this.props.colorId +
          "/" +
          encodeURIComponent(this.props.modelColorCode) +
          "/" +
          this.props.productId +
          ".json",
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        const modelsColors = res.data.sort((a, b) =>
          a.model_color_index_code > b.model_color_index_code
            ? 1
            : b.model_color_index_code > a.model_color_index_code
            ? -1
            : 0
        );
        this.setState({ data: modelsColors });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    const title = <div>Indexes for Model Color: {this.props.modelColorCode}</div>;

    return (
      <Paper>
        <HotKeysSubtable />
        <MaterialTable
          title={titleTemplate(title)}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={this.state.data}
          options={{
            pageSize: 5,
            exportButton: false,
            filtering: false,
            search: false,
            paging: false,
          }}
        />
      </Paper>
    );
  }
}

export default ModelokoloryIndexes;
