import React from "react";
import MaskedInput from "react-text-mask";
import { formatDate } from "../../shared/utility";

export const DateMask = (props) => {
    const {inputRef, ...other} = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            placeholder={formatDate(Date.now())}
            style={{textTransform: 'uppercase'}}
            mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/,]}
            guide={false}
        />
    );
};

export const TextMask = (props) => {
    const {inputRef, ...other} = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            keepCharPositions={false}
            style={{textTransform: 'uppercase'}}
            mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/]}
            guide={false}
        />
    );
};

export const FriendlyUrl = (props) => {
    const {inputRef, ...other} = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            keepCharPositions={false}
            style={{textTransform: 'lowercase'}}
            mask={value => {
                return value.split('').map(() => /./)
            }}
            pipe={value => {
                return value.replaceAll(' ', '-')
            }}
            guide={false}
            disabled
        />
    );
};

