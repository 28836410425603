export const sortingPhraseHelper = [
    {key: "index", path: "products.index"},
    {key: "season", path: "seasons.code"},
    {key: "style_name", path: "products.style_name"},
    {key: "collection_ref", path: "combinations.collection_ref"},
    {key: "main_color", path: "colors.title"},
    {key: "for_hz", path: "combinations.colour_for_hz"},
    {key: "size", path: "sizes.code"},
    {key: "quantity", path: "combinations.code"}
]
