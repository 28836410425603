import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Paper } from "@material-ui/core";
import MaterialTable, { MTableEditRow, MTableToolbar, MTableBodyRow } from "material-table";
import _ from "lodash";
import { Carousel } from "react-responsive-carousel";
import { Check, Clear } from "@material-ui/icons";
import ReactPlayer from "react-player";

import axios from "../../axios-sw";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";
import { HotKeysSubtable } from "../../components/System/HotKeys/HotKeys";
import { catchResponse, thenResponse } from "../../shared/utility";

export const combinationsColumns = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Images",
    field: "images",
    editable: "never",
    render: (rowData) => {
      if (typeof rowData.images !== "undefined") {
        const content = [];
        rowData.images.forEach((image) => {
          content.push(
            <div>
              <img src={image.url} />
            </div>
          );
        });

        return (
          <Carousel width={100} showStatus={false} showThumbs={false}>
            {content}
          </Carousel>
        );
      }
    },
  },
  {
    title: "Video",
    field: "video",
    render: (rowData) => {
      if (rowData.video.length > 0) {
        return <ReactPlayer width="100px" height="150px" controls url={rowData.video[0].url} />;
      }
      return <Clear />;
    },
  },
  { title: "Index (HZ ID)", field: "hz_id" },
  { title: "Shop ID", field: "shop_id" },
  {
    title: "Default combination",
    field: "default_combination",
    type: "boolean",
  },
  { title: "Combination description", field: "combination_description" },
  { title: "Color", field: "color" },
  { title: "Attribute Group: Size", field: "attribute_size", hidden: true },
  { title: "Attribute Group: Size trousers", field: "attribute_size_trousers", hidden: true },
  { title: "Attribute Group: Size kids", field: "attribute_size_kids", hidden: true },
  { title: "Attribute Group: Size shoes", field: "attribute_size_shoes", hidden: true },
  { title: "Attribute Group: Size hats", field: "attribute_size_hats", hidden: true },
  { title: "Attribute Group: Size shorts", field: "attribute_size_shorts", hidden: true },
  { title: "Attribute Group: Size socks", field: "attribute_size_socks", hidden: true },
];

class SyncCombinations extends Component {
  state = {
    actions: [],
    editable: {},
    combinationsColumns: [],
    data: [],
  };

  componentDidMount() {
    this.indexOptions();
    this.indexSyncCombinations();
    this.checkActionsAndEditablePermissions();
  }

  indexSyncCombinations = () => {
    const { productId } = this.props;

    axios
      .get(`/sync-combinations/${productId}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        console.log(res.data);
        const sizeProduct = res.data.sync_combinations[0].product_size_attribute;
        const localCombinationsColumns = _.cloneDeep(combinationsColumns);
        if (sizeProduct !== null) {
          localCombinationsColumns.forEach((column) => {
            const index = localCombinationsColumns.findIndex(({ field }) => field === sizeProduct);
            localCombinationsColumns[index].hidden = column.field === sizeProduct;
          });
        }
        this.setState({ data: res.data.sync_combinations, combinationsColumns: localCombinationsColumns });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexOptions = () => {
    let product_options = [];
    let product_option_values = [];
    const params = {
      output_format: "JSON",
      ws_key: "WR5NPJ885P44DWTF99H6WA173SPX9R8Z",
      display: "full",
    };

    const setOptions = (object) => {
      let map = new Map();
      for (var i = 0; i < object.options.length; i++) {
        map.set(object.options[i]["id"], object.options[i]["name"]);
      }
      let obj = Object.fromEntries(map);
      let newState = { ...this.state.combinationsColumns };

      switch (object.name) {
        case "Kolor":
          newState[this.state.combinationsColumns.findIndex((column) => column.field === "color")].lookup = obj;
          break;
        case "Rozmiar":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size")
          ].lookup = obj;
          break;
        case "Rozmiar spodni":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_trousers")
          ].lookup = obj;
          break;
        case "Rozmiar dziecięcy":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_kids")
          ].lookup = obj;
          break;
        case "Rozmiar butów":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_shoes")
          ].lookup = obj;
          break;
        case "Rozmiar kapelusza":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_hats")
          ].lookup = obj;
          break;
        case "Rozmiar szortów":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_shorts")
          ].lookup = obj;
          break;
        case "Rozmiar skarpet":
          newState[
            this.state.combinationsColumns.findIndex((column) => column.field === "attribute_size_socks")
          ].lookup = obj;
          break;
        default:
          break;
      }

      this.setState({ combinationsColumns: Object.values(newState) });
    };

    axios.get(`https://carry.pl/api/product_options`, { params: params }).then((res) => {
      product_options = res.data.product_options;
      axios.get(`https://carry.pl/api/product_option_values`, { params: params }).then((res) => {
        product_option_values = res.data.product_option_values;

        for (var i = 0; i < product_options.length; i++) {
          for (var j = 0; j < product_option_values.length; j++) {
            if (product_options[i]["id"] === parseInt(product_option_values[j]["id_attribute_group"])) {
              if (typeof product_options[i]["options"] !== "undefined") {
                product_options[i]["options"] = [...product_options[i]["options"], product_option_values[j]];
              } else {
                product_options[i]["options"] = [product_option_values[j]];
              }
            }
          }

          setOptions(product_options[i]);
        }
      });
    });
  };

  updateBarcodeCombination = (id, newData) => {
    const data = {
      combination: {
        barcode_comments: newData.barcode_comments,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };

    const url = `/combinations/${id}.json`;

    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedOrder = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedOrder.id);
            if (index !== -1) {
              array[index].barcode_comments = fetchedOrder.barcode_comments;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({});
    }
  };

  render() {
    return (
      <Paper>
        <ToastContainer />
        <HotKeysSubtable />

        <MaterialTable
          title={titleTemplate(<div>Combinations in product {this.props.productIndex}</div>)}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.combinationsColumns}
          data={this.state.data}
          actions={this.state.actions}
          localization={{
            editRow: {
              cancelTooltip: "Back",
            },
          }}
          options={{
            pageSize: 5,
            exportButton: false,
            filtering: false,
            search: false,
            paging: false,
            actionsColumnIndex: -1,
          }}
          components={{
            EditRow: (props) => <MTableEditRow autoFocus className="subtableEditingRow uppercase " {...props} />,
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              return (
                <div className="subtableToolbar">
                  <MTableToolbar {...props} />
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </Paper>
    );
  }
}

export default SyncCombinations;
