import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Document, Page} from 'react-pdf/dist/entry.webpack';

const thumbsContainer = {
    display: 'flex',
    marginTop: 5
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #efefefef',
    marginBottom: 8,
    marginRight: 8,
    width: 50,
    height: 75,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const paragraph = {
    fontSize: 10,
    margin: 0,
    padding: 0
};

const container = {
    width: '100px'
};

const deleteButton = {
    marginTop: '15px',
    display: "inline-block",
    padding: "5px",
    fontSize: 10,
    textAlign: "center",
    backgroundColor: "#e2325a",
    color: "#fff",
    cursor: 'pointer'
}

const PreviewDropzone = (props) => {
    const [files, setFiles] = useState([]);
    const [remove, setRemove] = useState(true);
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*, application/pdf, application/vnd.ms-excel, .csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        onDrop: acceptedFiles => {
            setRemove(true)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            props.onChange(acceptedFiles);
        }
    });

    const deleteFiles = () => {
        setFiles([]);
        setRemove(false);
        props.onChange(null);
    }

    const thumbs = files.map((file, i) => {
        return (
            <div style={thumb} key={i}>
                <div style={thumbInner}>
                    {file.type === 'application/pdf' ?
                        <Document file={file}>
                            <Page width={100} pageNumber={1}/>
                        </Document>
                        :
                        <img
                            alt='File'
                            src={file.preview}
                            style={img}
                        />
                    }
                </div>
            </div>
        )
    });

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const showPreview = (files) => {
        let single;
        typeof files === 'string' ? single = true : single = false;
        if (typeof files !== 'undefined' && files !== null && files.length > 0) {
            return (
                <section style={container}>
                    <aside style={thumbsContainer}>
                        <a href={files} target="_blank">
                            Document
                        </a>
                    </aside>
                </section>
            )
        }

        // if (typeof files !== 'undefined' && files !== null && files.length > 0) {
        //     if (typeof files[0].preview !== 'undefined') {
        //         return (
        //             <section style={container}>
        //                 <aside style={thumbsContainer}>
        //                     {files.map((file, i) => (
        //                             <div style={thumb} key={i}>
        //                                 <div style={thumbInner}>
        //                                     {/*{file.preview.split('.').pop() === 'pdf' ?*/}
        //                                         <a href={file.preview}
        //                                            rel="noopener noreferrer" target="_blank">
        //                                             Document
        //                                         </a>
        //                                     {/*    :*/}
        //                                     {/*    <a href={file.preview}*/}
        //                                     {/*       rel="noopener noreferrer" target="_blank">*/}
        //                                     {/*        <img*/}
        //                                     {/*            alt='File'*/}
        //                                     {/*            src={file.preview}*/}
        //                                     {/*            style={img}*/}
        //                                     {/*        />*/}
        //                                     {/*    </a>*/}
        //                                     {/*}*/}
        //                                 </div>
        //                             </div>
        //                         ))}
        //                 </aside>
        //             </section>
        //         )
        //     } else {
        //         return (
        //             <section style={container}>
        //                 <aside style={thumbsContainer}>
        //                     {single ?
        //                         <div style={thumb}>
        //                             <div style={thumbInner}>
        //                                 {/*{files.split('.').pop() === 'pdf' ?*/}
        //                                     <a href={files.url}
        //                                        rel="noopener noreferrer" target="_blank">
        //                                         Document
        //                                     </a>
        //                                 {/*:*/}
        //                                 {/*<a href={files}*/}
        //                                 {/*   rel="noopener noreferrer" target="_blank">*/}
        //                                 {/*    <img*/}
        //                                 {/*        alt='File'*/}
        //                                 {/*        src={files}*/}
        //                                 {/*        style={img}*/}
        //                                 {/*    />*/}
        //                                 {/*</a>}*/}
        //                             </div>
        //                         </div>
        //                         :
        //                         files.map((file, i) => {
        //                             return (
        //                                 <div style={thumb} key={i}>
        //                                     <div style={thumbInner}>
        //                                         {/*{file.url.split('.').pop() === 'pdf' ?*/}
        //                                             <a href={file.url}
        //                                                rel="noopener noreferrer" target="_blank">
        //                                                 Document
        //                                             </a>
        //                                             :
        //                                         {/*    <a href={file.url}*/}
        //                                         {/*       rel="noopener noreferrer" target="_blank">*/}
        //                                         {/*        <img*/}
        //                                         {/*            alt='File'*/}
        //                                         {/*            src={file.url}*/}
        //                                         {/*            style={img}*/}
        //                                         {/*        />*/}
        //                                         {/*    </a>*/}
        //                                         }
        //                                     </div>
        //                                 </div>
        //                             )
        //                         })}
        //                 </aside>
        //             </section>
        //         )
        //     }
        // }
    };

    const dropzone = (
        <section style={container}>
            <div {...getRootProps({className: 'dropzone'})}>
                <input accept='image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' {...getInputProps()} />
                {((props.file !== null || files.length > 0) && remove) ?
                    <p style={paragraph}>Change file</p> :
                    <p style={paragraph}>Add files</p>
                }
                {files.length > 0 ?
                    <aside style={thumbsContainer}>
                        {thumbs}
                    </aside> : null}
                {(files.length === 0 && props.fileCheck !== null && remove) ?
                    showPreview(props.file, props.fileUrl) : null
                }
            </div>
            {((props.file || files.length > 0) && remove) &&
            <p onClick={deleteFiles} style={deleteButton}>Remove file</p>}
        </section>
    );

    return typeof props.previewFiles !== 'undefined' ? showPreview(props.previewFiles) : dropzone
};

export default PreviewDropzone;