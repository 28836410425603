export const sortingPhraseHelper = [
  { key: "index", path: "combinations.hz_id" },
  { key: "style_name", path: "products.style_name" },
  { key: "sex", path: "sexes.code" },
  { key: "warehouse", path: "containers.warehouse" },
  { key: "product_name", path: "sync_combinations.name" },
  { key: "description", path: "sync_combinations.combination_description" },
  { key: "short_description", path: "sync_combinations.composition" },
  { key: "store_price", path: "products.store_price" },
  { key: "season", path: "seasons.code" },
  { key: "order_number", path: "orders.number" },
  { key: "designer", path: "designers.code" },
  { key: "collection_ref", path: "combinations.collection_ref" },
  { key: "main_color", path: "colors.title" },
];
