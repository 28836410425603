import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane/lib/Pane";

import {
  Button,
  FormControl,
  Input,
  Paper,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableFooter,
  TableCell,
  TextField,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import _ from "lodash";
import $ from "jquery";
import ReactSelect, { createFilter } from "react-select";
import { ChromePicker } from "react-color";

// Components
import Dropzone from "../../../../components/System/OrderDropzone/OrderDropzone";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Styles
import classes from "./CombinationForm.module.scss";

const CombinationFormSchema = Yup.object().shape({
  main_color: Yup.object().typeError("Main color is required!").required("Main color is required!"),
  price: Yup.number()
    .typeError("Price does not have right format!")
    .min(0.01, "Price is required!")
    .required("Price is required!"),
  collection_ref: Yup.string()
    .typeError("Collection ref. does not have right format!")
    .required("Collection ref. is required!"),
  colour_for_hz: Yup.object().typeError("Colour for HZ is required!").required("Colour for HZ is required!"),
  colour_for_website: Yup.array()
    .typeError("Colour for website is required!")
    .required("Colour for website is required!"),
});

const CombinationsForm = (props) => {
  const dropzone = useRef();

  const [combinationSizes, setCombinationSizes] = useState(_.cloneDeep(props.sizes));

  const compData = props.combinationData;
  let unchangedInitialValues;

  const [added, setAdded] = useState(typeof compData !== "undefined" && compData.new !== true);
  const [added_product_id, setAddedProductId] = useState(
    typeof compData !== "undefined" && compData.new !== true ? compData.id : null
  );
  const [swatchFiles, setSwatchFiles] = useState(typeof compData !== "undefined" ? compData.swatch_files : []);

  const [sizeTotal, setSizeTotal] = useState();

  // React Color
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(null);

  if (typeof compData !== "undefined" && (compData.new !== true || compData.copied === true)) {
    const colour_for_website = [];
    if (typeof compData.colour_for_website !== "undefined" && compData.colour_for_website !== null) {
      for (const [key, value] of Object.entries(compData.colour_for_website)) {
        colour_for_website.push({ value: key, label: value });
      }
    }
    unchangedInitialValues = {
      id: compData.id,

      main_color: { value: compData.main_color.value, label: compData.main_color.label },
      composition: compData.composition,
      composition_2: compData.composition_2,
      collection_ref: compData.collection_ref,
      fabric_name: compData.fabric_name,

      price: compData.price,

      swatch_files: [],
      swatch_color: color,
      more_information: compData.more_information,

      colour_for_hz:
        typeof compData.colour_for_hz !== "undefined" && compData.colour_for_hz !== null
          ? { value: compData.colour_for_hz.value, label: compData.colour_for_hz.label }
          : "",
      colour_for_website: colour_for_website,
      product_type:
        typeof compData.product_type !== "undefined" && compData.product_type !== null
          ? { value: compData.product_type.value, label: compData.product_type.label }
          : "",
    };
  } else {
    unchangedInitialValues = {
      id: "",

      main_color: "",
      composition: "",
      composition_2: "",
      collection_ref: "",
      fabric_name: "",

      price: "",

      swatch_files: [],
      swatch_color: "",
      more_information: "",

      colour_for_hz: "",
      colour_for_website: "",
      product_type: "",
    };
  }

  useEffect(() => {
    setSizeTotal(sumTotal(combinationSizes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (typeof props.combinationData !== "undefined") {
      setColor(props.combinationData.swatch_color);
    }
  }, []);

  const sumTotal = (sizes, color_id = null) => {
    let totalQuantity = 0;
    if (color_id === null) {
      for (let i = 0; i < sizes.length; i++) {
        if (sizes[i].main_color === color_id && color_id !== null) {
          totalQuantity += parseInt(sizes[i].quantity);
        } else if (typeof sizes[i].quantity !== "undefined") {
          totalQuantity += sizes[i].quantity;
        }
      }
    } else {
      const quantities = $(`.sizeInputToTotal_${props.productId}_${color_id} input`);
      for (let i = 0; i < quantities.length; i++) {
        if (quantities[i].value !== "") {
          totalQuantity += parseInt(quantities[i].value);
        }
      }
    }
    return totalQuantity;
  };

  const setCombinationsData = (array, values, productID, callback) => {
    props.updateCombinations(array);

    for (let i = 0; i < combinationSizes.length; i++) {
      combinationSizes[i].id = (Math.random() * 1e6) | 1;
      combinationSizes[i].main_color = values.main_color.value;
      combinationSizes[i].product_id = productID;
      combinationSizes[i].swatch_color = color;
    }

    let fullSizesQuantities = [...props.sizesPricesCombinationsData]; // W celu dodania do ogólnej listy rozmiarów
    props.updateSizesPricesData(fullSizesQuantities.concat(combinationSizes));

    setAdded(true); // Żeby warunkowo wyświetlać buttony
    callback();
  };

  let content = <Spinner />;
  if (typeof unchangedInitialValues !== "undefined") {
    content = (
      <Formik
        initialValues={unchangedInitialValues}
        validationSchema={CombinationFormSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          // Sprawdzenie czy w tablicy nie ma identycznego koloru
          const array = [...props.combinationsData];
          const index = array.findIndex((x) => x.main_color.value === values.main_color.value);
          if (index !== -1 && typeof values.main_color !== "undefined") {
            if (typeof values.main_color !== "undefined") {
              setFieldError("main_color", "Unique main color is required!");
            }
            toast.error("You can not add the same or empty colour!");
            setSubmitting(false);
          } else {
            // Tylko w przypadku gdy mamy do czynienia ze skopiowanym produktem
            if (typeof compData !== "undefined" && compData.copied === true) {
              const copiedIndex = array.findIndex((x) => x.id === compData.id);

              let product_id = (Math.random() * 1e6) | 1;
              setAddedProductId(product_id);

              let colour_for_website = "";
              for (let i = 0; i < values.colour_for_website.length; i++) {
                if (colour_for_website !== "") {
                  colour_for_website += ", " + values.colour_for_website[i].label;
                } else {
                  colour_for_website += values.colour_for_website[i].label;
                }
              }

              array[copiedIndex] = {
                id: product_id,

                main_color: values.main_color,
                composition: typeof values.composition !== "undefined" ? values.composition?.toUpperCase() : null,
                composition_2: typeof values.composition_2 !== "undefined" ? values.composition_2?.toUpperCase() : null,
                collection_ref:
                  typeof values.collection_ref !== "undefined" ? values.collection_ref?.toUpperCase() : null,
                fabric_name: values.fabric_name == null ? null : values.fabric_name?.toUpperCase(),

                price: values.price,

                swatch_files: values.swatch_files,
                swatch_color: color,
                more_information:
                  typeof values.more_information !== "undefined" ? values.more_information?.toUpperCase() : null,

                colour_for_hz: values.colour_for_hz,
                colour_for_website: colour_for_website,
                product_type: "--",

                new: true,
                copied: false,
              };

              setCombinationsData(array, values, product_id, () => setSubmitting(false));
              setSizeTotal(sumTotal(combinationSizes, values.main_color.value));
            } else {
              let product_id = (Math.random() * 1e6) | 1;
              setAddedProductId(product_id);

              let colour_for_website = "";
              for (let i = 0; i < values.colour_for_website.length; i++) {
                if (colour_for_website !== "") {
                  colour_for_website += ", " + values.colour_for_website[i].label;
                } else {
                  colour_for_website += values.colour_for_website[i].label;
                }
              }

              const combinationsData = props.combinationsData.concat([
                {
                  id: product_id,

                  main_color: values.main_color,
                  composition: typeof values.composition !== "undefined" ? values.composition.toUpperCase() : null,
                  composition_2:
                    typeof values.composition_2 !== "undefined" ? values.composition_2.toUpperCase() : null,
                  collection_ref:
                    typeof values.collection_ref !== "undefined" ? values.collection_ref.toUpperCase() : null,
                  fabric_name: values.fabric_name == null ? null : values.fabric_name.toUpperCase(),

                  price: values.price,

                  swatch_files: values.swatch_files,
                  swatch_color: color,
                  more_information:
                    typeof values.more_information !== "undefined" ? values.more_information.toUpperCase() : null,

                  colour_for_hz: values.colour_for_hz,
                  colour_for_website: colour_for_website,
                  product_type: "--",

                  new: true,
                },
              ]);

              setCombinationsData(combinationsData, values, product_id, () => setSubmitting(false));
              setSizeTotal(sumTotal(combinationSizes, values.main_color.value));
            }
          }
        }}
      >
        {({
          submitForm,
          touched,
          errors,
          isSubmitting,
          values,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          setFieldError,
        }) => (
          <Form className={classes.Form}>
            <h2>{added ? "Edit" : "Add"} combination</h2>
            <SplitPane split="vertical" className={classes.AddColourSection}>
              <Pane initialSize="33%">
                <FormControl className={classes.FormControlSelect}>
                  <p className={classes.Label}>
                    Main color<span className={classes.RequireDot}>*</span>
                  </p>
                  <ReactSelect
                    className={errors.main_color && touched.main_color && classes.errorBorder}
                    isDisabled={props.disabled}
                    name="main_color"
                    id="main_color"
                    value={values.main_color}
                    onChange={(e) => {
                      const array = [...props.combinationsData];
                      const checkIndexColour = array.findIndex((x) => x.main_color.value === e.value);
                      if (checkIndexColour !== -1) {
                        setFieldValue("main_color", "");
                        toast.error("You can not add the same colour!");
                      } else {
                        setFieldValue("main_color", e);
                        if (added) {
                          const index = array.findIndex((x) => x.id === props.combinationData.id);
                          if (index !== -1) {
                            // Podmiana main_color ID w tabeli rozmiarów i ilości
                            const sizesPricesCombinationsData = [...props.sizesPricesCombinationsData];
                            for (let i = 0; i < props.sizesPricesCombinationsData.length; i++) {
                              if (compData.main_color.value === props.sizesPricesCombinationsData[i].main_color) {
                                sizesPricesCombinationsData[i].main_color = e.value;
                                props.updateSizesPricesData(sizesPricesCombinationsData);
                              }
                            }
                            array[index].main_color = e;

                            // If main_color is changed colour_for_hz, colour_for_website and collection_ref are cleared
                            // Only if ADDED
                            array[index].colour_for_hz = "";
                            array[index].colour_for_website = "";
                            array[index].collection_ref = "";
                            setFieldValue("colour_for_hz", "");
                            setFieldError("colour_for_hz", "Colour for HZ is required!");
                            setFieldTouched("colour_for_hz", true);

                            setFieldValue("colour_for_website", "");
                            setFieldError("colour_for_website", "Colour for website is required!");
                            setFieldTouched("colour_for_website", true);

                            setFieldValue("collection_ref", "");
                            setFieldError("collection_ref", "Collection ref. is required!");
                            setFieldTouched("collection_ref", true);

                            props.updateCombinations(array);
                          }
                        }
                      }
                    }}
                    filterOption={createFilter({
                      ignoreCase: true,
                      matchFrom: "start",
                    })}
                    onBlur={() => setFieldTouched("main_color", true)}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                    options={props.colors.sort((a, b) => {
                      if (a.label < b.label) {
                        return -1;
                      }
                      if (a.label > b.label) {
                        return 1;
                      }
                      return 0;
                    })}
                  />
                  {errors.main_color && touched.main_color && (
                    <div className={classes.InputFeedback}>{errors.main_color}</div>
                  )}
                </FormControl>

                <FormControl className={classes.FormControl}>
                  <p className={classes.Label}>Composition</p>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    name="composition"
                    id="composition"
                    placeholder="Main"
                    value={values.composition}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("composition", e.target.value);
                      if (added) {
                        const array = [...props.combinationsData];
                        const index = array.findIndex((x) => x.id === props.combinationData.id);
                        if (index !== -1) {
                          array[index].composition = e.target.value;
                          props.updateCombinations(array);
                        }
                      }
                    }}
                  />
                  <Input
                    disabled={props.disabled}
                    name="composition_2"
                    id="composition_2"
                    placeholder="Other"
                    value={values.composition_2}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("composition_2", e.target.value);
                      if (added) {
                        const array = [...props.combinationsData];
                        const index = array.findIndex((x) => x.id === props.combinationData.id);
                        if (index !== -1) {
                          array[index].composition_2 = e.target.value;
                          props.updateCombinations(array);
                        }
                      }
                    }}
                  />
                  {errors.composition && touched.composition && (
                    <div className={classes.InputFeedback}>{errors.composition}</div>
                  )}
                </FormControl>

                <FormControl className={classes.FormControl}>
                  <p className={classes.Label}>Collection ref.</p>
                  <Input
                    disabled={props.disabled}
                    name="collection_ref"
                    id="collection_ref"
                    placeholder="C1"
                    value={values.collection_ref}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("collection_ref", e.target.value);
                      if (added) {
                        const array = [...props.combinationsData];
                        const index = array.findIndex((x) => x.id === props.combinationData.id);
                        if (index !== -1) {
                          array[index].collection_ref = e.target.value;
                          props.updateCombinations(array);
                        }
                      }
                    }}
                  />
                  {errors.collection_ref && touched.collection_ref && (
                    <div className={classes.InputFeedback}>{errors.collection_ref}</div>
                  )}
                </FormControl>

                <FormControl className={classes.FormControl}>
                  <p className={classes.Label}>Producer's swatch no.</p>
                  <Input
                    disabled={props.disabled}
                    name="fabric_name"
                    id="fabric_name"
                    value={values.fabric_name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("fabric_name", e.target.value);
                      if (added) {
                        const array = [...props.combinationsData];
                        const index = array.findIndex((x) => x.id === props.combinationData.id);
                        if (index !== -1) {
                          array[index].fabric_name = e.target.value;
                          props.updateCombinations(array);
                        }
                      }
                    }}
                  />
                  {errors.fabric_name && touched.fabric_name && (
                    <div className={classes.InputFeedback}>{errors.fabric_name}</div>
                  )}
                </FormControl>
              </Pane>

              <Pane initialSize="33%">
                <Paper>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Size</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {combinationSizes.map((size) => {
                        if (typeof compData !== "undefined" && compData.new !== true) {
                          // Sprawdzam czy jest dodany ze względu na kopie produktu, który ma zdefiniowany compData
                          for (let i = 0; i < props.sizesPricesCombinationsData.length; i++) {
                            if (compData.main_color.value === props.sizesPricesCombinationsData[i].main_color) {
                              if (props.sizesPricesCombinationsData[i].size_code === size.code) {
                                size.quantity = props.sizesPricesCombinationsData[i].quantity;
                                break;
                              }
                            }
                          }
                        }
                        return (
                          <TableRow>
                            <TableCell className={classes.Cell}>{size.code}</TableCell>
                            <TableCell className={classes.Cell}>
                              <TextField
                                disabled={props.disabled}
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                className={"sizeInputToTotal_" + props.productId + "_" + values.main_color.value}
                                defaultValue={size.quantity}
                                onChange={(e) => {
                                  const sizes = [...props.sizes];
                                  const index = sizes.findIndex((x) => x.size_id === size.size_id);
                                  if (index !== -1) {
                                    const sizesPricesCombinationsData = [...props.sizesPricesCombinationsData];
                                    if (typeof compData !== "undefined" && compData.new !== true) {
                                      // Sprawdzam czy jest dodany ze względu na kopie produktu, który ma zdefiniowany compData
                                      for (let i = 0; i < props.sizesPricesCombinationsData.length; i++) {
                                        if (
                                          compData.main_color.value === props.sizesPricesCombinationsData[i].main_color
                                        ) {
                                          if (
                                            props.sizesPricesCombinationsData[i].size_code === size.code ||
                                            props.sizesPricesCombinationsData[i].code === size.code
                                          ) {
                                            sizesPricesCombinationsData[i].quantity = parseInt(e.target.value);
                                            setSizeTotal(
                                              sumTotal(sizesPricesCombinationsData, values.main_color.value)
                                            );
                                            props.updateSizesPricesData(sizesPricesCombinationsData);
                                            break;
                                          }
                                        }
                                      }
                                    } else if (
                                      typeof compData !== "undefined" &&
                                      compData.new === true &&
                                      compData.copied === false
                                    ) {
                                      // Oznacza to, że produkt został skopiowany, ale nie został jeszcze zapisany w bazie danych
                                      for (let i = 0; i < props.sizesPricesCombinationsData.length; i++) {
                                        if (
                                          compData.main_color.value === props.sizesPricesCombinationsData[i].main_color
                                        ) {
                                          if (props.sizesPricesCombinationsData[i].code === size.code) {
                                            // code zamiast size_code, ponieważ jest to nie zfetchowane
                                            sizesPricesCombinationsData[i].quantity = parseInt(e.target.value);
                                            setSizeTotal(
                                              sumTotal(sizesPricesCombinationsData, values.main_color.value)
                                            );
                                            props.updateSizesPricesData(sizesPricesCombinationsData);
                                            break;
                                          }
                                        }
                                      }
                                    } else {
                                      if (!isNaN(e.target.value) && e.target.value !== "") {
                                        const unAddedIndex = combinationSizes.findIndex(
                                          (x) => x.size_id === size.size_id
                                        );
                                        if (unAddedIndex !== -1) {
                                          setSizeTotal(sumTotal(sizesPricesCombinationsData, values.main_color.value));
                                          const combinationSizesCopy = [...combinationSizes];
                                          combinationSizesCopy[unAddedIndex].quantity = parseInt(e.target.value);
                                          setCombinationSizes(combinationSizesCopy);
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {typeof values.main_color.value !== "undefined" ? (
                      <TableFooter>
                        <TableRow>
                          <TableCell align="center" style={{ fontWeight: 900 }}>
                            Total sum
                          </TableCell>
                          <TableCell>{sizeTotal}</TableCell>
                        </TableRow>
                      </TableFooter>
                    ) : null}
                  </Table>
                </Paper>

                <FormControl className={classes.FormControl}>
                  <p className={classes.Label}>
                    Price<span className={classes.RequireDot}>*</span>
                  </p>
                  <Input
                    className={errors.price && touched.price && classes.errorBorder}
                    disabled={props.disabled}
                    type="number"
                    name="price"
                    id="price"
                    value={values.price}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("price", e.target.value);
                      if (added) {
                        const array = [...props.combinationsData];
                        const index = array.findIndex((x) => x.id === props.combinationData.id);
                        if (index !== -1) {
                          array[index].price = e.target.value;
                          props.updateCombinations(array);
                        }
                      }
                    }}
                  />
                  {errors.price && touched.price && <div className={classes.InputFeedback}>{errors.price}</div>}
                </FormControl>
              </Pane>

              <Pane initialSize="33%">
                <div className={classes.SwatchUpperWrapper}>
                  <p className={classes.Label}>Swatch file</p>
                  <div className={classes.SwatchWrapper}>
                    <div className={classes.DropzoneContainer}>
                      <div className={classes.Dropzone}>
                        <Button
                          className={classes.DropzoneDelete}
                          onClick={(e) => {
                            e.preventDefault();
                            dropzone.current.setInitialFiles();
                            setSwatchFiles([]);
                            setFieldValue("swatch_files", "");
                            if (added) {
                              const array = [...props.combinationsData];
                              const index = array.findIndex((x) => x.id === props.combinationData.id);
                              if (index !== -1) {
                                array[index].swatch_files = null;
                                props.updateCombinations(array);
                              }
                            }
                          }}
                        >
                          <Delete />
                        </Button>
                        <Dropzone
                          files={swatchFiles}
                          disabled={false}
                          ref={dropzone}
                          onDrop={(files) => {
                            setFieldValue("swatch_files", files);
                            setSwatchFiles(files);
                            if (added) {
                              const array = [...props.combinationsData];
                              const index = array.findIndex((x) => x.id === props.combinationData.id);
                              if (index !== -1) {
                                array[index].swatch_files = files;
                                props.updateCombinations(array);
                              }
                            }
                          }}
                        />
                      </div>
                      {errors.swatch_files && touched.swatch_files && (
                        <div className={classes.InputFeedback} style={{ textAlign: "center" }}>
                          {errors.swatch_files}
                        </div>
                      )}
                    </div>

                    <div className={classes.SwatchColor}>
                      <div className={classes.Swatch} onClick={() => setDisplayColorPicker(!displayColorPicker)}>
                        <div className={classes.Color} style={{ backgroundColor: `${color}` }} />
                        {color === null && <p>Select color</p>}
                      </div>
                      {displayColorPicker ? (
                        <div className={classes.Popover}>
                          <div className={classes.Cover} onClick={() => setDisplayColorPicker(false)} />
                          <ChromePicker
                            color={color === null ? "white" : color}
                            onChange={(color) => {
                              setColor(color.hex);
                              if (added) {
                                const array = [...props.combinationsData];
                                const index = array.findIndex((x) => x.id === props.combinationData.id);
                                if (index !== -1) {
                                  array[index].swatch_color = color.hex;
                                  props.updateCombinations(array);
                                }
                              }
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <FormControl className={classes.FormControlTextArea}>
                  <p className={classes.Label}>More information</p>
                  <TextField
                    disabled={props.disabled}
                    multiline
                    rows="6"
                    variant="outlined"
                    name="more_information"
                    id="more_information"
                    value={values.more_information}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("more_information", e.target.value);
                      if (added) {
                        const array = [...props.combinationsData];
                        const index = array.findIndex((x) => x.id === props.combinationData.id);
                        if (index !== -1) {
                          array[index].more_information = e.target.value;
                          props.updateCombinations(array);
                        }
                      }
                    }}
                  />
                  {errors.more_information && touched.more_information && (
                    <div className={classes.InputFeedback}>{errors.more_information}</div>
                  )}
                </FormControl>
              </Pane>
            </SplitPane>

            <div className={classes.SystemInformation}>
              <h3>System information</h3>
              <SplitPane split="vertical" className={classes.SystemInformation}>
                <Pane initialSize="33%">
                  <FormControl className={classes.FormControlSelect}>
                    <p className={classes.Label}>
                      Colour for HZ<span className={classes.RequireDot}>*</span>
                    </p>
                    <ReactSelect
                      className={errors.colour_for_hz && touched.colour_for_hz && classes.errorBorder}
                      isDisabled={props.disabled}
                      name="colour_for_hz"
                      id="colour_for_hz"
                      value={values.colour_for_hz}
                      onChange={(e) => {
                        setFieldValue("colour_for_hz", e);
                        if (added) {
                          const array = [...props.combinationsData];
                          const index = array.findIndex((x) => x.id === props.combinationData.id);
                          if (index !== -1) {
                            array[index].colour_for_hz = e;
                            props.updateCombinations(array);
                          }
                        }
                      }}
                      onBlur={() => setFieldTouched("colour_for_hz", true)}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={props.subColors.sort((a, b) => {
                        if (a.label < b.label) {
                          return -1;
                        }
                        if (a.label > b.label) {
                          return 1;
                        }
                        return 0;
                      })}
                    />
                    {errors.colour_for_hz && touched.colour_for_hz && (
                      <div className={classes.InputFeedback}>{errors.colour_for_hz}</div>
                    )}
                  </FormControl>
                </Pane>

                <Pane initialSize="33%">
                  <FormControl className={classes.FormControlSelect}>
                    <p className={classes.Label}>
                      Colour for website<span className={classes.RequireDot}>*</span>
                    </p>
                    <ReactSelect
                      className={errors.colour_for_website && touched.colour_for_website && classes.errorBorder}
                      isDisabled={props.disabled}
                      name="colour_for_website"
                      id="colour_for_website"
                      isMulti
                      value={values.colour_for_website}
                      onChange={(e) => {
                        setFieldValue("colour_for_website", e);
                        let colour_for_website = "";
                        for (let i = 0; i < e.length; i++) {
                          if (colour_for_website !== "") {
                            colour_for_website += ", " + e[i].label;
                          } else {
                            colour_for_website += e[i].label;
                          }
                        }
                        if (added) {
                          const array = [...props.combinationsData];
                          const index = array.findIndex((x) => x.id === props.combinationData.id);
                          if (index !== -1) {
                            array[index].colour_for_website = colour_for_website;
                            props.updateCombinations(array);
                          }
                        }
                      }}
                      onBlur={() => setFieldTouched("colour_for_website", true)}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={props.subColors.sort((a, b) => {
                        if (a.label < b.label) {
                          return -1;
                        }
                        if (a.label > b.label) {
                          return 1;
                        }
                        return 0;
                      })}
                    />
                    {errors.colour_for_website && touched.colour_for_website && (
                      <div className={classes.InputFeedback}>{errors.colour_for_website}</div>
                    )}
                  </FormControl>
                </Pane>
              </SplitPane>
            </div>

            {!props.disabled ? (
              <>
                {added ? (
                  <div className={classes.ButtonsContainer}>
                    <FormControl className={classes.FormControl}>
                      <Button
                        style={{ margin: "10px 0" }}
                        className={classes.SubmitButton}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          props.addNewColour();
                        }}
                      >
                        Add new colour
                      </Button>
                    </FormControl>
                    <FormControl className={classes.FormControl}>
                      <Button
                        style={{ margin: "10px 0" }}
                        variant="contained"
                        color="default"
                        onClick={() => {
                          props.copyColour(added_product_id);
                        }}
                      >
                        Copy colour
                      </Button>
                    </FormControl>
                    <FormControl className={classes.FormControl}>
                      <Button
                        style={{ margin: "10px 0" }}
                        className={classes.DeleteButton}
                        variant="contained"
                        color="secondary"
                        onClick={() => props.removeProduct(added_product_id, !compData.new)}
                      >
                        Delete
                      </Button>
                    </FormControl>
                  </div>
                ) : (
                  <div className={classes.ButtonsContainer}>
                    <FormControl style={{ textAlign: "center" }} className={classes.FormControl}>
                      <Button
                        style={{ margin: "10px 0", display: "none" }}
                        className={`${classes.SubmitButton} save-color-button`}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        {isSubmitting ? "Saving..." : "Save colour"}
                      </Button>
                    </FormControl>
                    <FormControl className={classes.FormControl}>
                      <Button
                        style={{ margin: "10px 0" }}
                        className={classes.DeleteButton}
                        variant="contained"
                        color="secondary"
                        onClick={() => props.removeUnaddedProduct()}
                      >
                        Delete
                      </Button>
                    </FormControl>
                  </div>
                )}
              </>
            ) : null}
          </Form>
        )}
      </Formik>
    );
  }

  return content;
};

export default CombinationsForm;
