import React from 'react';

import classes from './Input.module.scss';

import TextField from '@material-ui/core/TextField';

import NativeSelect from '@material-ui/core/NativeSelect';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles(theme => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
}))(InputBase);

const input = (props) => {

    let inputElement = null;
    const inputClasses = ['custom-input', classes.InputElement, classes.WithoutPadding];

    let validationError = null;

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.errorMessage}</p>;
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <TextField
                margin='normal'
                variant='outlined'
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ('textarea'):
            inputElement = <TextField
                    id='outlined-dense-multiline'
                    classes={classes.InputElement}
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                    margin='dense'
                    variant='outlined'
                    multiline
                    rowsMax='4'
                />;
            break;
        case ('switch'):
            inputElement =(
                <FormControlLabel
                    className={classes.InputElement}
                    control={
                        <Switch
                            checked={props.checked}
                            onChange={props.changed}
                            value={props.value}
                        />
                    }
                    label={props.label}
                />
            );
            break;
        case ('select'):
            inputElement = (
                <div style={{position: 'relative'}}>
                    <NativeSelect
                        className={classes.SelectElement}
                        value={props.value}
                        onChange={props.changed}
                        input={<BootstrapInput name='age' id='age-customized-native-simple' />}
                    >
                        {props.elementConfig.options.map(option => (
                            <option key={option.value} value={option.value}>{option.displayValue}</option>
                        ))}
                    </NativeSelect>
                </div>

            );
            break;
        default:
            inputElement = <input
                className={classes.InputElement}
                {...props.elementConfig}
                value={props.value} />;
    }

    const globalInputClasses = ['custom-input', classes.Input];

    return (
        <div className={globalInputClasses.join(' ')}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
            {validationError}
        </div>
    )
};

export default input;