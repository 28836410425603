import React, {useEffect, useState} from 'react';
import classes from "./AdvencedFilters.module.scss";
import ReactSelect from "react-select";
import {Button} from "@material-ui/core";


const AdvencedFilters = ({onFilterField, options, conditionalOptions, filters, title, multi}) => {
    const [filterByColumn, setFilterByColumn] = useState(null);
    const [filterByConditional, setFilterByConditional] = useState(null);

    useEffect(() => {
        setFilterByColumn(filters.filterByColumn)
        setFilterByConditional(filters.filterByConditional)
    }, [filters])

    return (
        <div className={classes.filtersWrapper}>
            {title && <p className={classes.title}>{title}</p>}
            <>
                <span>Column Filter: </span>
                <ReactSelect
                    className={multi ? classes.columnsSelectMulti :classes.columnsSelect}
                    placeholder=""
                    menuPortalTarget={document.body}
                    onChange={(e) => {
                        setFilterByColumn(e)
                    }}
                    value={filterByColumn}
                    styles={{menuPortal: base => ({...base, zIndex: 8000})}}
                    options={options}
                    isMulti={multi ? true : false}
                />
            </>
            <span>Conditional: </span>
            <ReactSelect
                className={classes.conditionalSelect}
                placeholder=""
                menuPortalTarget={document.body}
                onChange={(e) => {
                    setFilterByConditional(e)
                }}
                value={filterByConditional}
                styles={{menuPortal: base => ({...base, zIndex: 8000})}}
                options={conditionalOptions}
            />
            <Button onClick={() => {
                onFilterField(filterByColumn, filterByConditional)
            }} className={classes.sendButton} variant="contained"> Apply </Button>
        </div>
    )
}

export default AdvencedFilters;