import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { nonAdminRedirect } from "../../shared/utility";
import DesignerOrdersTable from "./DesignerOrdersTable/DesignerOrdersTable";

import { Grid, Paper, Button } from "@material-ui/core";

import classes from "./DesignerDashboard.module.scss";
import { ToastContainer } from "react-toastify";
import Spinner from "../../components/UI/Spinner/Spinner";

class DesignerDashboard extends Component {
  render() {
    let content = (
      <>
        {nonAdminRedirect(this.props.token, this.props.user_id, this.props.match.params.id, this.props.role)}
        <ToastContainer />
        <Grid
          container
          spacing={6}
          mt="20px"
          justify="center"
          direction="row"
          alignItems="center"
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <Paper style={{ padding: 20 }} className={classes.InformationContainer}>
              <h3>Designer Dashboard</h3>
              <p>
                Full name (ID): {this.props.full_name} ({this.props.user_id})
              </p>
              <p>
                Role:{" "}
                {this.props.role !== null ? this.props.role.charAt(0).toUpperCase() + this.props.role.slice(1) : null}
              </p>
              <Button className={classes.CreateButton} variant="contained" size="medium" color="primary">
                <Link to={"/order-form/" + this.props.user_id}>Create new order</Link>
              </Button>
            </Paper>
          </Grid>
        </Grid>

        <DesignerOrdersTable token={this.props.token} user_id={this.props.user_id} ip_address={this.props.ip_address} />
      </>
    );

    if (this.props.user_id === null) {
      content = <Spinner />;
    }

    return (
      <>
        <Helmet>
          <title>Designer dashboard | Carry System</title>
        </Helmet>

        {content}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    full_name: state.auth.full_name,
  };
};

export default connect(mapStateToProps)(DesignerDashboard);
