import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { ToastContainer } from "react-toastify";

import { authRedirect, nonAdminRedirect } from "../../shared/utility";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

class Settings extends Component {
  render() {
    return (
      <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: "50vh" }}>
        <Helmet>
          <title>Settings | Carry System</title>
        </Helmet>

        <Paper style={{ width: "40%", height: 300, padding: 20 }}>
          <ToastContainer />
          {authRedirect(this.props.token)}
          {nonAdminRedirect(this.props.user_id, this.props.role)}
          Settings
        </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Settings);
