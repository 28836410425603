import React from "react";
import Auxi from "../../../../hoc/Auxi/Auxi";

import { Link as NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import AccountIcon from "@material-ui/icons/AccountCircle";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import ListAlt from "@material-ui/icons/ListAlt";
import Settings from "@material-ui/icons/Settings";

const IconLinks = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    props.onClickLinkHandler();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton color="inherit" aria-controls="account-menu" aria-haspopup="true" onClick={handleClick}>
        <AccountIcon />
      </IconButton>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ top: 60 }}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <Link style={{ textDecoration: "none", color: "black" }} component={NavLink} to={"/account/" + props.user_id}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            Account
          </MenuItem>
        </Link>

        {props.role === "admin" ? (
          <Auxi>
            <Link style={{ textDecoration: "none", color: "black" }} component={NavLink} to="/admin">
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <SupervisorAccount fontSize="small" />
                </ListItemIcon>
                Admin
              </MenuItem>
            </Link>
            <Link style={{ textDecoration: "none", color: "black" }} component={NavLink} to="/user-logs">
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <ListAlt fontSize="small" />
                </ListItemIcon>
                User logs
              </MenuItem>
            </Link>
            <Link style={{ textDecoration: "none", color: "black" }} component={NavLink} to="/settings">
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
            </Link>
          </Auxi>
        ) : null}

        <Link
          style={{ textDecoration: "none", color: "black" }}
          component={NavLink}
          to="/logout"
          onClick={() => {
            localStorage.setItem("rolePath", props.role);
            localStorage.setItem("originPath", window.location.pathname + window.location.search);
          }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            Wyloguj się
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default IconLinks;
