import DateRangeIcon from "@material-ui/icons/DateRange";
import React, {useState, useRef} from "react";
import Button from "@material-ui/core/Button";
import {Checkbox, ClickAwayListener, Paper, Popper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
    DatePickerRange: {
        fontFamily: 'Verdana',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    title: {
        fontSize: "0.75em",
        margin: "0",
        marginRight: "3px",
        minWidth: "30%"
    },
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "5px",
        marginBottom: "5px",
    },
    checkbox: {
        margin: "0"
    },
    typography: {
        padding: theme.spacing(1),
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px"
    },
    openButton: {
        display: "inline-block",
        cursor: "pointer"
    },
    poperWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    poper: {
        zIndex: "8000"
    }
}));

const FilterDateRange = ({onChange}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(Date.now());
    const [empty, setEmpty] = useState(false);
    const [full, setFull] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div className={classes.poperWrapper}>
            <DateRangeIcon className={classes.openButton} onClick={handleClick}/>
            <Popper
                className={classes.poper}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper className={classes.paper}>
                        <Typography className={classes.typography}>
                            <div className={classes.DatePickerRange}>
                                {!empty && !full &&
                                <>
                                    <div className={classes.wrapper}>
                                        <p className={classes.title}>Start Date:</p>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>
                                    <div className={classes.wrapper}>
                                        <p className={classes.title}>End Date:</p>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={date => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>
                                </>
                                }
                                {!full &&
                                <div className={classes.wrapper}>
                                    <p className={classes.title}>Empty data field:</p>
                                    <Checkbox className={classes.checkbox} checked={empty}
                                              onChange={() => setEmpty(!empty)}/>
                                </div>
                                }
                                {!empty &&
                                <div className={classes.wrapper}>
                                    <p className={classes.title}>Full data field:</p>
                                    <Checkbox className={classes.checkbox} checked={full}
                                              onChange={() => setFull(!full)}/>
                                </div>
                                }
                                <Button onClick={() => {
                                    const dataRange = {start: startDate, end: endDate, isEmpty: empty, isFull: full}
                                    onChange(dataRange)
                                    handleClose()
                                }} variant="contained" color="primary">
                                    {empty ? 'Check empty data field' : full ? 'Check full data field' : 'Apply data range'}
                                </Button>
                            </div>
                        </Typography>
                        <Button variant="contained" color="secondary" onClick={handleClose}>close</Button>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

export default FilterDateRange;
