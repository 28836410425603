import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactSelect from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { Chip, TablePagination } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, GetApp } from "@material-ui/icons";
import { json2excel } from "js2excel";
import { Picky } from "react-picky";
import { Helmet } from "react-helmet";

import MaterialTable, { MTableBody, MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";

import { tableIcons, titleTemplate } from "../../shared/tableVariables";

import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import {
  authRedirect,
  catchResponse,
  formatDate,
  createSingleColumnFilterVal,
  createSortPath,
  getSortPath,
  getFilterPath,
  getAndConvertULRParams,
} from "../../shared/utility";

import { Spinner, ProgressCircular, SearchInput } from "../../components/UI";
import { FilterInput } from "../../components/System";
import { editComponent } from "../../shared/tables/helperTables";

import ForecastProducts from "./ForecastProducts";
import ForecastsSummary from "./ForecastsSummary/ForecastsSummary";

const SEASON_STORAGE = "user_forecasts_season_";

export const sortingPhraseHelper = [
  { key: "assortment_group", path: "forecasts.assortment_group" },
  { key: "budget", path: "forecasts.budget" },
  { key: "budget_value", path: "forecasts.budget_value" },
];

class Forecasts extends Component {
  // State
  state = {
    data: [],
    totals: null,
    loaded: false,
    seasons: [],
    season: null,
    notAddedAssortmentGroups: "",
    isLoading: false,
    filterComponentSet: false,
    selectedReferences: [],
    columns: [
      { title: "ID", field: "id", editable: "never", hidden: true },
      {
        title: "Assortment group",
        field: "assortment_group",
        type: "multiSelect",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (terms, rowData) => {
          return rowData;
        },
        editComponent: (props) => editComponent(props, true),
        cellStyle: {
          width: "60px",
          borderRight: "2px solid #000",
        },
      },
      // Planned
      {
        title: "Planned QTY in PCS",
        field: "budget",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
        },
      },
      {
        title: "Planned VALUE USD",
        field: "budget_value",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
          borderRight: "2px solid #000",
        },
      },
      // Ordered
      {
        title: "Ordered QTY in PCS",
        field: "ordered",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
        },
      },
      {
        title: "Ordered VALUE USD",
        field: "ordered_value",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
          borderRight: "2px solid #000",
        },
      },
      // Accepted
      {
        title: "Accepted QTY in PCS",
        field: "accepted",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
        },
        render: (rowData) =>
          typeof rowData !== "undefined" && (
            <AcceptedCell>
              {rowData.accepted}
              <span>{`${rowData.accepted_percent_ordered}%`}</span>
            </AcceptedCell>
          ),
      },
      {
        title: "Accepted VALUE USD",
        field: "accepted_value",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
          borderRight: "2px solid #000",
        },
        render: (rowData) =>
          typeof rowData !== "undefined" && (
            <AcceptedCell>
              {rowData.accepted_value}
              <span>{`${rowData.accepted_value_percent_ordered}%`}</span>
            </AcceptedCell>
          ),
      },
      // Left to accept
      {
        title: "Left to accept QTY in PCS",
        field: "budget_to_accept",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
        },
      },
      {
        title: "Left to accept VALUE USD",
        field: "budget_value_to_accept",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
          borderRight: "2px solid #000",
        },
      },
      // Left to order
      {
        title: "Left to order QTY in PCS",
        field: "budget_to_order",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
        },
      },
      {
        title: "Left to order VALUE USD",
        field: "budget_value_to_order",
        editable: "never",
        filtering: false,
        sorting: false,
        type: "number",
        headerStyle: {
          textAlign: "right",
        },
        cellStyle: {
          width: "120px",
          textAlign: "right",
        },
      },
    ],

    // Custom filtering and pagination
    page: 0,
    limit: 20,
    totalRow: 0,
    filterPhrase: "",
    filterByColumn: null,
    filterByConditional: { value: "all" },
    sortingColumnsList: [],
    singleColumnFilterList: [],
    tableRef: React.createRef(),
    onlyLeftToAccept: false,
  };

  componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );

    this.indexSeasons();
    this.indexForecasts(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);

    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        this.state.tableRef.current.state.columns[index].tableData.initialValue = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
      } else if (filter.type === "number") {
        this.state.tableRef.current.state.columns[index].tableData.initialValue = filter.value.replace("=", "").trim();
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "barcode_status" && filter.value.includes(successQuery))
          table.onFilterChange(index, "success");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes("IS NULL AND combinations.in_progress = true")
        )
          table.onFilterChange(index, "in_progress");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes(`IS NOT NULL OR ${filter.column.path} <> ''`) &&
          !filter.value.includes(successQuery)
        )
          table.onFilterChange(index, "error");
        if (filter.column.key === "barcode_status" && filter.value.includes(readyQuery))
          table.onFilterChange(index, "ready");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        this.setState({ selectedReferences: valueArray });
        table.onFilterChange(index, valueArray);
      }
    });
  }

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  indexSeasons = () => {
    axios
      .get("/seasons.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ seasons: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexForecasts = (page = 0, limit = 20, phrase = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    let advancedFilterByColumn = null;
    let advancedFilterByConditional = { value: "all" };

    const season = JSON.parse(window.localStorage.getItem(`${SEASON_STORAGE}${this.props.user_id}`))
      ? JSON.parse(window.localStorage.getItem(`${SEASON_STORAGE}${this.props.user_id}`))
      : { value: 7, label: "W21" };

    axios
      .get("/products/forecasts.json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
        params: {
          page: page,
          limit: limit,
          phrase: phrase,
          sortPath: sortPath,
          filterPath: filterPath,
          season_id: season.value,
          onlyLeftToAccept: this.state.onlyLeftToAccept,
        },
      })
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        // Advanced filter
        const advancedFilterIndex = singleColumnFilterList.findIndex((filter) => filter.type === "advanced");
        if (advancedFilterIndex !== -1) {
          const column = this.state.columns.filter(
            (column) => column.field === singleColumnFilterList[advancedFilterIndex].column.key
          );
          advancedFilterByColumn = { label: column[0].title, value: column[0].field };

          if (
            singleColumnFilterList[advancedFilterIndex].value.includes("<> '' AND") ||
            singleColumnFilterList[advancedFilterIndex].value.includes("<> STR_TO_DATE(\"\",'%m-%d-%y') AND")
          )
            advancedFilterByConditional = {
              label: "Full Field",
              value: "full",
            };
          else if (
            singleColumnFilterList[advancedFilterIndex].value.includes('="" OR') ||
            singleColumnFilterList[advancedFilterIndex].value.includes("= STR_TO_DATE(\"\",'%m-%d-%y') OR")
          )
            advancedFilterByConditional = {
              label: "Empty Field",
              value: "empty",
            };
          else if (singleColumnFilterList[advancedFilterIndex].value.includes('LIKE "%%" OR'))
            advancedFilterByConditional = {
              label: "All Field",
              value: "all",
            };
        }

        // Creating custom filterComponent dla assortment group
        const newColumns = [...this.state.columns];
        if (!this.state.filterComponentSet) {
          const index = newColumns.findIndex((column) => column.field === "assortment_group");
          if (index > -1) {
            newColumns[index].filterComponent = (term) => {
              return (
                <Picky
                  options={res.data.assortment_groups}
                  labelKey="label"
                  valueKey="value"
                  multiple={true}
                  includeFilter
                  includeSelectAll={false}
                  value={this.state.selectedReferences || []}
                  onChange={(values) => {
                    this.setState({ selectedReferences: values });
                    term.onFilterChanged(term.columnDef.tableData.id, values);
                  }}
                  renderSelectAll={({ filtered, tabIndex, allSelected, toggleSelectAll, multiple }) => {
                    if (multiple) {
                      return (
                        <div
                          tabIndex={tabIndex}
                          role="option"
                          className="option"
                          onClick={toggleSelectAll}
                          onKeyPress={toggleSelectAll}
                          style={{ cursor: "pointer", padding: "8px 12px" }}
                        >
                          Select all
                        </div>
                      );
                    }
                  }}
                />
              );
            };
          }
        }

        this.setState({
          columns: newColumns,
          data: res.data.forecasts,
          totals: res.data.totals,
          loaded: true,
          season: season,
          notAddedAssortmentGroups: res.data.not_added_assortment_groups.toString(),

          // Advanced filter
          totalRow: res.data.rows_quantity,
          page: page,
          limit: limit,
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filterByColumn: advancedFilterByColumn,
          filterByConditional: advancedFilterByConditional,
          filterComponentSet: true,
        });
        window.localStorage.setItem(`${SEASON_STORAGE}${this.props.user_id}`, JSON.stringify(season));
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  handleSearch = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/forecasts?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexForecasts(page, limit, search, sortingColumnsList, singleColumnFilterList);
  };

  updateForecast = (assortment_group, newData) => {
    if (
      typeof newData.budget === "undefined" ||
      typeof newData.budget_value === "undefined" ||
      newData.budget === "" ||
      newData.budget_value === ""
    ) {
      toast.error("Please fill all fields");
    } else {
      this.setState({ isLoading: true });

      const data = {
        assortment_group: assortment_group.toUpperCase(),
        budget: newData.budget.replace(/\s/g, "").replace(",", "."),
        budget_value: newData.budget_value.replace(/\s/g, "").replace(",", "."),
        season_id: this.state.season.value,
        user_id: this.props.user_id,
        ip_address: this.props.ip_address,

        // Advanced filter
        filterPath: getFilterPath(this.state.singleColumnFilterList),
        phrase: this.state.filterPhrase,
      };
      axios
        .post(`/products/forecasts.json`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
        .then((res) => {
          const fetchedForecast = res.data.forecast;
          const array = [...this.state.data];
          const index = array.findIndex((x) => x.assortment_group === fetchedForecast.assortment_group);
          if (index !== -1) {
            array[index] = fetchedForecast;
            this.setState({ data: array, totals: res.data.totals, isLoading: false });
          } else {
            this.setState({ data: [...this.state.data, fetchedForecast], totals: res.data.totals, isLoading: false });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          catchResponse(err);
        });
    }
  };

  exportToExcel = () => {
    this.setState({ loaded: false });

    const season = JSON.parse(window.localStorage.getItem(`${SEASON_STORAGE}${this.props.user_id}`))
      ? JSON.parse(window.localStorage.getItem(`${SEASON_STORAGE}${this.props.user_id}`))
      : { value: 7, label: "W21" };

    axios
      .get("/products/forecasts.json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
        params: {
          page: 0,
          limit: 10,
          phrase: "",
          sortPath: "",
          filterPath: "",
          season_id: season.value,
          onlyLeftToAccept: this.state.onlyLeftToAccept,
          export: true,
        },
      })
      .then((res) => {
        if (res.data.forecasts.length === 0) {
          this.setState({ loaded: true });
          toast.error("No data to export");
          return;
        }

        const formattedData = res.data.forecasts.map((forecast) => {
          return {
            assortment_group: forecast.assortment_group,
            budget: parseFloat(forecast.budget.replace(/\s/g, "").replace(",", ".")),
            budget_value: parseFloat(forecast.budget_value.replace(/\s/g, "").replace(",", ".")),
            ordered: parseFloat(forecast.ordered.replace(/\s/g, "").replace(",", ".")),
            ordered_value: parseFloat(forecast.ordered_value.replace(/\s/g, "").replace(",", ".")),
            accepted: parseFloat(forecast.accepted.replace(/\s/g, "").replace(",", ".")),
            accepted_value: parseFloat(forecast.accepted_value.replace(/\s/g, "").replace(",", ".")),
            budget_to_accept: parseFloat(forecast.budget_to_accept.replace(/\s/g, "").replace(",", ".")),
            budget_value_to_accept: parseFloat(forecast.budget_value_to_accept.replace(/\s/g, "").replace(",", ".")),
            budget_to_order: parseFloat(forecast.budget_to_order.replace(/\s/g, "").replace(",", ".")),
            budget_value_to_order: parseFloat(forecast.budget_value_to_order.replace(/\s/g, "").replace(",", ".")),
          };
        });

        // Forecasts
        if (formattedData.length > 0) {
          json2excel({
            data: formattedData,
            name: `forecasts_${this.state.season.label}_${formatDate(Date.now())}`,
            formateDate: "dd/mm/yyyy",
          });
        }

        this.setState({ loaded: true });
        toast.success("Data exported successfully");
      })
      .catch((err) => {
        catchResponse(err);
      });

    // axios
    //   .get("/products/forecasts/products.json", {
    //     headers: { Authorization: `Bearer ${this.props.token}` },
    //     params: { season_id: this.state.season.value },
    //   })
    //   .then((res) => {
    //     const formattedProducts = res.data.map((product) => {
    //       return {
    //         index: product.index,
    //         style_name: product.style_name,
    //         order_id: product.order_id,
    //         order_number: product.order_number,
    //         quantity: parseFloat(product.quantity.replace(/\s/g, "").replace(",", ".")),
    //         value: parseFloat(product.value.replace(/\s/g, "").replace(",", ".")),
    //       };
    //     });

    //     // Forecasts
    //     if (formattedData.length > 0) {
    //       json2excel({
    //         data: formattedData,
    //         name: `forecasts_${this.state.season.label}_${formatDate(Date.now())}`,
    //         formateDate: "dd/mm/yyyy",
    //       });
    //     }

    //     // Products
    //     if (formattedProducts.length > 0) {
    //       json2excel({
    //         data: formattedProducts,
    //         name: `products_${this.state.season.label}_${formatDate(Date.now())}`,
    //         formateDate: "dd/mm/yyyy",
    //       });
    //     }

    //     this.setState({ loaded: true });
    //   })
    //   .catch((err) => {
    //     catchResponse(err);
    //   });
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        <Helmet>
          <title>Forecasts | Carry System</title>
        </Helmet>

        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        <ToastContainer />
        <HotKeys />

        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Forecasts")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={this.state.data}
          options={{
            search: false,
            pageSize: 20,
            exportButton: false,
            filtering: true,
          }}
          isLoading={this.state.isLoading}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columns[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columns[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columns[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columns[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columns[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columns[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "reference" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columns[columnId].type === "multiSelect") {
                      if (indexIsExist !== -1) {
                        let multiFiltersValues = "";
                        value.forEach((filter, index) => {
                          multiFiltersValues +=
                            index === 0
                              ? `LIKE '%${value[index]}%'`
                              : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                        });
                        singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                        table.onFilterChange(columnId, value);
                      } else {
                        let multiFiltersValues = "";
                        value.forEach((filter, index) => {
                          multiFiltersValues +=
                            index === 0
                              ? `LIKE '%${value[index]}%'`
                              : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                        });
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: multiFiltersValues,
                            type: "multiSelect",
                          },
                        ];
                        table.onFilterChange(columnId, value);
                      }
                    }

                    this.setState({
                      singleColumnFilterList: singleColumnFilterList,
                      filterPhrase: "",
                      filterByConditional: { value: "all" },
                      filterByColumn: null,
                    });

                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              return (
                <Toolbar className="mainToolbar">
                  <div>
                    <MTableToolbar {...props} />

                    <SearchWrapper>
                      <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSearch} />
                    </SearchWrapper>

                    {this.state.totals && (
                      <ForecastsSummary
                        totals={this.state.totals}
                        onlyLeftToAccept={this.state.onlyLeftToAccept}
                        setOnlyLeftToAccept={(onlyLeftToAccept) => {
                          this.setState({ onlyLeftToAccept: onlyLeftToAccept }, () => {
                            this.goPage(
                              0,
                              this.state.limit,
                              "",
                              this.state.sortingColumnsList,
                              this.state.singleColumnFilterList
                            );
                          });
                        }}
                      />
                    )}
                  </div>

                  <TagsWrapper>
                    {/* Generowanie tagów filtrów kolumn */}
                    {this.state.singleColumnFilterList.map((filter, key) => {
                      let value;
                      let icon = "";
                      if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                      else if (filter.type === "text")
                        value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                      else if (filter.type === "select")
                        value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                      else if (filter.type === "multiSelect") {
                        value = filter.value
                          .split("LIKE")
                          .join("")
                          .split("'%")
                          .join("")
                          .split("%'")
                          .join("")
                          .split(`OR ${filter.column.path}`)
                          .join("");
                      } else if (filter.type === "number") value = filter.value.replace("=", "");
                      else if (filter.type === "boolean") {
                        if (filter.value.includes("true")) {
                          value = "checked";
                          icon = <Check />;
                        } else {
                          value = "unchecked";
                          icon = <Close />;
                        }
                      }

                      const formattedTitle = this.state.columns
                        .find((item) => item.field === filter.column.key)
                        .title.toUpperCase();

                      return (
                        <Chip
                          key={key}
                          style={{ marginLeft: 24, marginTop: 10 }}
                          onDelete={() => {
                            const table = this.state.tableRef.current;
                            if (filter.column.key === "reference") {
                              this.setState({ selectedReferences: [] });
                            }
                            const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                              (item) => item.column.key !== filter.column.key
                            );
                            this.goPage(
                              0,
                              this.state.limit,
                              this.state.filterPhrase,
                              this.state.sortingColumnsList,
                              singleColumnFilterList
                            );

                            this.setState({ singleColumnFilterList: singleColumnFilterList });
                            const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                            if (index !== -1) {
                              // Remove selected references
                              if (formattedTitle === "ASSORTMENT GROUP") {
                                this.setState({ selectedReferences: [] });
                              }

                              if (table.state.columns[index].field === "reference") {
                                table.state.columns[index].tableData.initialValue = [];
                              } else {
                                table.state.columns[index].tableData.initialValue = "";
                                table.onFilterChange(index, null);
                              }
                            }
                          }}
                          label={`${formattedTitle}: ${value.toUpperCase()}`}
                          icon={icon}
                          variant="outlined"
                        />
                      );
                    })}

                    {/* Generowanie tagów sortowania */}
                    {this.state.sortingColumnsList.map((column) => {
                      let icon = "";

                      if (column.order === "ASC") icon = <ArrowDownward />;
                      if (column.order === "DESC") icon = <ArrowUpward />;

                      return (
                        <Chip
                          style={{ marginLeft: 24, marginTop: 10 }}
                          onDelete={() => {
                            const sortingColumnsList = this.state.sortingColumnsList.filter(
                              (item) => item.sortBy.key !== column.sortBy.key
                            );
                            this.goPage(
                              0,
                              this.state.limit,
                              this.state.filterPhrase,
                              sortingColumnsList,
                              this.state.singleColumnFilterList
                            );
                            this.setState({ sortingColumnsList: sortingColumnsList });
                          }}
                          label={`${column.sortBy.key.toUpperCase().replace("_", " ")}`}
                          icon={icon}
                        />
                      );
                    })}
                  </TagsWrapper>

                  <StyledSeasonSelect>
                    {/* Select season */}
                    <ReactSelect
                      placeholder=""
                      onChange={(e) => {
                        window.localStorage.setItem(`${SEASON_STORAGE}${this.props.user_id}`, JSON.stringify(e));
                        this.setState({
                          data: [],
                          loaded: false,
                          page: 0,
                          filterPhrase: "",
                          filterByColumn: null,
                          filterByConditional: { value: "all" },
                          sortingColumnsList: [],
                          singleColumnFilterList: [],
                          selectedReferences: [],
                        });
                        this.indexForecasts();
                      }}
                      value={this.state.season}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={this.state.seasons}
                    />

                    {/* Export data to Excel */}
                    <StyledChip
                      variant="outlined"
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => this.exportToExcel()}
                    />

                    {this.state.notAddedAssortmentGroups !== "" && this.state.notAddedAssortmentGroups !== "[]" && (
                      <NotAdded>Not added assortment groups: {this.state.notAddedAssortmentGroups}</NotAdded>
                    )}
                  </StyledSeasonSelect>
                </Toolbar>
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                resolve();
                this.updateForecast(oldData.assortment_group, newData);
              }),
            onRowAdd: (newData) => {
              return new Promise((resolve) => {
                resolve();
                this.updateForecast(newData.assortment_group, newData);
              });
            },
          }}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <ForecastProducts
                  token={this.props.token}
                  forecastAssortmentGroup={rowData.assortment_group}
                  seasonId={this.state.season.value}
                />
              </div>
            );
          }}
        />
      </>
    );
  }
}

const TagsWrapper = styled.div`
  margin: 25px;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: auto;
`;

const NotAdded = styled.div`
  inline-size: 200px;
  overflow-wrap: break-word;
`;

const AcceptedCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  span {
    font-size: 10px;
  }
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledChip = styled(Chip)`
  width: 200px;
`;

const StyledSeasonSelect = styled.div`
  width: 200px;
  margin: 25px;

  > div {
    margin-bottom: 20px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Forecasts);
