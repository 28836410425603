export const sortingPhraseHelper = [
  { key: "reference", path: "products.index" },
  { key: "synced", path: "sync_products.synced" },
  { key: "sync_at", path: "sync_products.sync_at" },
  { key: "shop_id", path: "sync_products.shop_id" },
  { key: "synced_details", path: "sync_products.synced_at" },
  { key: "product_name", path: "sync_products.product_name" },
  { key: "description", path: "sync_products.description" },
  { key: "short_description", path: "sync_products.short_description" },
  { key: "friendly_url", path: "sync_products.friendly_url" },
  { key: "default_category_name", path: "sync_products.default_category_name" },
  { key: "display_categories", path: "sync_products.display_categories" },
];
