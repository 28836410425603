import React from "react";
import { TablePagination } from "@material-ui/core";

type PaginationProps = {
  props: any;
  limit: number;
  totalRows: number;
  page: number;
  goPageCallback: (page: number, limit: number | string) => void;
  setState: (Object: any) => void;
  tableRef: any;
};

const Pagination: React.FC<PaginationProps> = ({
  props,
  limit,
  totalRows,
  page,
  goPageCallback,
  setState,
  tableRef,
}) => {
  return (
    <TablePagination
      {...props}
      rowsPerPage={limit}
      count={totalRows}
      page={page}
      onChangePage={(e, changedPage) => {
        tableRef.current.onChangePage(e, changedPage);
        setState({ page: page });
        goPageCallback(changedPage, limit);
      }}
    />
  );
};

export default Pagination;
