import React, { Component } from "react";
import { connect } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Close from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import IconLinks from "./IconLinks/IconLinks";
import Drawer from "@material-ui/core/Drawer";
import AccountPreview from "./AccountPreview/AccountPreview";
import DrawerLinks from "./DrawerLinks/DrawerLinks";

import logo from "../../../assets/img/carryLogo.jpeg";

import { withStyles } from "@material-ui/core";
import MultiSelect from "../../System/MultiSelect/MultiSelect";
import axios from "../../../axios-sw";
import { calculateTimeLeft, catchResponse } from "../../../shared/utility";

import { setSeasons } from "../../../store/reducers/systemInfo";
import pkj from "../../../../package.json";

const drawerWidth = 170;

const styles = (theme) => ({
  appBar: {
    backgroundColor: "black",
    marginTop: -2,
    zIndex: 9000,
    paddingRight: "0 !important",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButtonHidden: {
    display: "none",
  },
  wrapper: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },

  counter: {
    fontSize: 17,
    fontWeight: "bold",
  },

  counterWarning: {
    color: "red",
  },

  drawerPaper: {
    whiteSpace: "nowrap",
    width: drawerWidth,
    paddingTop: 80,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  accountPreview: {
    alignItems: "center",
    justifyContent: "center",
  },
  contentPreview: {
    width: "100%",
    textAlign: "center",
    paddingBottom: "20px",
  },
  multiSelectWrapper: {
    width: "300px",
    color: "#000",
  },
});

class Navbar extends Component {
  state = {
    open: false,
    seasons: [],
  };

  onClickDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  componentDidMount() {
    this.indexSeasons();
  }

  indexSeasons = () => {
    axios
      .get("/seasons.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function(obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ seasons: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            {/* Otwieranie drawer */}
            {this.props.token === null ? null : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.onClickDrawer}
                edge="start"
                className={clsx(classes.menuButton, this.state.open && classes.hide)}
              >
                {this.state.open ? <Close /> : <MenuIcon />}
              </IconButton>
            )}

            <div className={classes.wrapper}>
              <Typography variant="body1" color="inherit" noWrap className={classes.title}>
                <Link to="/">
                  <img style={{ height: "35px", marginTop: 5 }} src={logo} alt="Account Avatar" />
                </Link>
              </Typography>

              <Typography variant="subtitle1" style={{ paddingRight: 10, fontSize: 11 }}>
                v{pkj.version}
              </Typography>

              {this.props.token && (
                <p
                  className={
                    calculateTimeLeft(this.props.timeRemaining).seconds <= 10 &&
                    calculateTimeLeft(this.props.timeRemaining).minutes === "00"
                      ? `${classes.counter} ${classes.counterWarning}`
                      : classes.counter
                  }
                >
                  {calculateTimeLeft(this.props.timeRemaining).minutes} :{" "}
                  {calculateTimeLeft(this.props.timeRemaining).seconds}
                </p>
              )}
            </div>
            {this.props.token && typeof window !== "undefined" && window.location.pathname !== "/forecasts" && (
              <div className={classes.multiSelectWrapper}>
                <MultiSelect
                  value={this.props.currentSeasons}
                  options={this.state.seasons}
                  onChangeValue={(selectedValues) => {
                    window.localStorage.setItem(`user_seasons_${this.props.user_id}`, JSON.stringify(selectedValues));
                    this.props.setSeasons(selectedValues);
                  }}
                />
              </div>
            )}
            {this.props.token === null ? null : (
              <IconLinks
                onClickLinkHandler={() => this.setState({ open: false })}
                style={{ float: "right" }}
                avatar={this.props.avatar}
                user_id={this.props.user_id}
                role={this.props.role}
                full_name={this.props.full_name}
              />
            )}
          </Toolbar>

          {this.props.token === null && this.props.user_id === null ? null : (
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
              open={this.state.open}
              onClose={this.onClickDrawer}
              style={{ height: "100%" }}
            >
              <div className={classes.accountPreview}>
                <div className={classes.contentPreview}>
                  <AccountPreview
                    user_id={this.props.user_id}
                    avatar_url={this.props.avatar}
                    full_name={this.props.full_name}
                    position={this.props.position}
                    onClick={this.onClickDrawer}
                  />
                </div>
              </div>

              <DrawerLinks
                userId={this.props.user_id}
                userRole={this.props.role}
                onClickLinkHandler={this.onClickDrawer}
              />
            </Drawer>
          )}
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    role: state.auth.role,
    full_name: state.auth.full_name,
    avatar: state.auth.avatar,
    position: state.auth.position,
    timeRemaining: state.systemInfo.timeRemaining,
    currentSeasons: JSON.parse(window.localStorage.getItem(`user_seasons_${localStorage.getItem("user_id")}`)) || [
      {
        value: 4,
        label: "S21",
      },
    ],
  };
};

const mapDispatchToProps = { setSeasons };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Navbar));
