import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import { Grid, Paper, FormControl, Button, Input, TextareaAutosize } from "@material-ui/core";
import MaterialTable from "material-table";
import { Helmet } from "react-helmet";

// Custom components
import axios from "../../../axios-sw";
import { authRedirect, catchResponse } from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import classes from "./SyncProductsFormDesc.module.scss";
import { DateMask, TextMask, FriendlyUrl } from "../../../components/InputMasks/InputMasks";
import { tableIcons } from "../../../shared/tableVariables";
import { ArrowBackIos, Sync } from "@material-ui/icons";
import { role } from "../../../config";

DateMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

FriendlyUrl.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class SyncProductsFormDesc extends Component {
  state = {
    productData: {},
    combinations: [],
    loading: true,
    editable: {},
    redirection: null,
  };

  componentDidMount() {
    this.checkActionsAndEditablePermissions();
    if (typeof this.props.location.state !== "undefined") {
      axios
        .get(`/sync-products/${this.props.location.state.productId}.json`, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .then((res) => {
          this.setState({
            productData: res.data.product,
            combinations: res.data.combinations,
            loading: false,
          });
        })
        .catch((err) => {
          catchResponse(err);
        });
    } else {
      this.setState({ loading: false });
    }
  }

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
      });
    else {
      this.setState({
        editable: {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateCombination(oldData.id, newData);
            }),
        },
      });
    }
  };

  updateCombination = (id, newData) => {
    const data = {
      default_combination: newData.default_combination,
      name: newData.name,
      combination_description: newData.combination_description,
      composition: newData.composition,
      description_form: true,
      // Potrzebne do synchronizacji default combination
      product_id: this.state.productData.product_id,
    };
    axios
      .put(`/sync-combinations/${id}`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then(() => {
        let newCombinations = [...this.state.combinations];
        newCombinations.forEach((combination) => {
          if (combination.id === id) {
            combination.name = data.name;
            combination.combination_description = data.combination_description;
            combination.composition = data.composition;
          }
          if (data.default_combination) {
            if (combination.id === id) {
              combination.default_combination = data.default_combination;
            } else {
              combination.default_combination = false;
            }
          }
        });
        this.setState({ combinations: newCombinations });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    const SyncProductsFormDescSchema = Yup.object().shape({
      product_name: Yup.string().required("Product name is required!"),
      description: Yup.string().required("Description is required!"),
      short_description: Yup.string().required("Short description is required!"),
    });

    const combinationsColumns = [
      { title: "ID", field: "index", hidden: true },
      { title: "HZ ID", field: "hz_id", editable: false },
      { title: "Default?", field: "default_combination", type: "boolean" },
      { title: "Combination name", field: "name", type: "text" },
      { title: "Combination description", field: "combination_description", type: "text" },
      { title: "Composition", field: "composition", type: "text" },
    ];

    let content = <Spinner />;
    if (this.state.loading === false) {
      content = (
        <>
          <Helmet>
            <title>Sync Products Form Descriptions | Carry System</title>
          </Helmet>

          <ToastContainer />
          {authRedirect(this.props.token)}
          {this.state.redirection}

          <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: "30vh" }}>
            <Grid item>
              <Paper style={{ padding: 20, width: 900 }}>
                <Formik
                  initialValues={{
                    product_name:
                      this.state.productData.product_name === null ? "" : this.state.productData.product_name,
                    description: this.state.productData.description === null ? "" : this.state.productData.description,
                    short_description:
                      this.state.productData.short_description === null ? "" : this.state.productData.short_description,
                    friendly_url:
                      this.state.productData.friendly_url === null ? "" : this.state.productData.friendly_url,
                  }}
                  validationSchema={SyncProductsFormDescSchema}
                  onSubmit={(values, { setSubmitting, setFieldError }) => {
                    const friendly_url = this.state.productData.reference + " " + values.product_name.trim();
                    const data = {
                      product_name: values.product_name,
                      description: values.description,
                      short_description: values.short_description,
                      friendly_url: friendly_url.replaceAll(" ", "-").toLowerCase(),
                      categories: this.state.productData.categories,
                      display_categories: this.state.productData.display_categories,
                      default_category: this.state.productData.default_category.value,
                      default_category_name: this.state.productData.default_category.label,
                    };

                    this.state.combinations.forEach((combination) => {
                      // Name
                      if (this.state.productData.product_name !== values.product_name) {
                        this.updateCombination(combination.id, {
                          default_combination: combination.default_combination,
                          name: values.product_name,
                          combination_description: combination.combination_description,
                          composition: combination.composition,
                        });
                      }

                      // Description = combination_description
                      if (this.state.productData.description !== values.description) {
                        this.updateCombination(combination.id, {
                          default_combination: combination.default_combination,
                          name: combination.name,
                          combination_description: values.description,
                          composition: combination.composition,
                        });
                      }

                      // Short description = composition
                      if (this.state.productData.short_description !== values.short_description) {
                        this.updateCombination(combination.id, {
                          default_combination: combination.default_combination,
                          name: combination.name,
                          combination_description: combination.combination_description,
                          composition: values.short_description,
                        });
                      }
                    });

                    axios
                      .put(`/sync-products/${this.state.productData.id}`, data, {
                        headers: { Authorization: `Bearer ${this.props.token}` },
                      })
                      .then(() => {
                        this.setState({
                          redirection: <Redirect to={this.props.location.state.source} />,
                        });
                        toast.success("Sync product updated!");
                        setSubmitting(false);
                      })
                      .catch((err) => {
                        catchResponse(err);
                      });
                  }}
                >
                  {({ submitForm, touched, errors, isSubmitting, values, handleChange, handleBlur }) => (
                    <Form>
                      <div className={classes.FormNavigation}>
                        <ArrowBackIos onClick={() => this.props.history.push(this.props.location.state.source)} />
                        {/* <Sync onClick={this.handlerSyncImages}/> */}
                      </div>

                      <div className={classes.FormInfo}>
                        <h1>Product and combinations descriptions form</h1>
                        <p>
                          Below you have the product and combinations descriptions form. On this stage we are focused on
                          delivery descriptions data and images to product and combinations.
                        </p>
                      </div>

                      <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Product reference</p>
                        <Input
                          name="reference"
                          id="reference"
                          value={this.state.productData.reference}
                          disabled={true}
                        />
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Product name</p>
                        <Input
                          name="product_name"
                          id="product_name"
                          value={values.product_name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div className={classes.Info}>
                          Nazwa produktu wyświetlana na sklepie internetowym. Dla klienta widoczna jest w katalogu i na
                          stronie produktu.
                        </div>
                        {errors.product_name && touched.product_name && (
                          <div className={classes.InputFeedback}>{errors.product_name}</div>
                        )}
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Friendly URL</p>
                        <Input
                          name="friendly_url"
                          id="friendly_url"
                          value={this.state.productData.reference + "-" + values.product_name}
                          inputComponent={FriendlyUrl}
                        />
                        <div className={classes.Info}>
                          Wykorzystywany do tworzenia linku do produktu. Automatycznie generowany na podstawie indeksu
                          produktu i jego nazwy.
                        </div>
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Product description</p>
                        <TextareaAutosize
                          name="description"
                          id="description"
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          rows={4}
                          maxRows={4}
                        />
                        <div className={classes.Info}>
                          Opis prouduktu. Powininen być uzupełniony, ponieważ w razie braku opisu pojedynczej kombinacji
                          lub błędu zostanie wyświetlony na stronie produktu.
                        </div>
                        {errors.description && touched.description && (
                          <div className={classes.InputFeedback}>{errors.description}</div>
                        )}
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Product short description</p>
                        <TextareaAutosize
                          name="short_description"
                          id="short_description"
                          value={values.short_description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          rows={4}
                          maxRows={4}
                        />
                        <div className={classes.Info}>Wykorzystywany do wyświetlania składu produktu.</div>
                        {errors.short_description && touched.short_description && (
                          <div className={classes.InputFeedback}>{errors.short_description}</div>
                        )}
                      </FormControl>

                      <MaterialTable
                        title={<>Combinations in product {this.state.productData.reference}</>}
                        icons={tableIcons}
                        style={{ width: "100%", marginBottom: 10 }}
                        columns={combinationsColumns}
                        data={this.state.combinations}
                        actions={this.state.actions}
                        localization={{ editRow: { cancelTooltip: "Back" } }}
                        options={{
                          pageSize: 5,
                          exportButton: false,
                          filtering: false,
                          search: false,
                          paging: false,
                          actionsColumnIndex: -1,
                        }}
                        editable={this.state.editable}
                      />
                      {errors.combinations_table && (
                        <div className={classes.InputFeedback}>{errors.combinations_table}</div>
                      )}

                      <FormControl style={{ textAlign: "center" }} className={classes.FormControl}>
                        <Button
                          type="submit"
                          style={{ margin: "10px 0" }}
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          {isSubmitting ? "Submitting" : "Submit"}
                        </Button>
                      </FormControl>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grid>
          </Grid>
        </>
      );
    }

    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    full_name: state.auth.full_name,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(withRouter(SyncProductsFormDesc));
