import React, {Component} from 'react';
import axios from "../../../../axios-sw";

import {
    Paper
} from '@material-ui/core';

import {tableIcons, titleTemplate} from "../../../../shared/tableVariables";
import MaterialTable, {MTableEditRow, MTableToolbar} from "material-table";

import Columns from "../../../../shared/tables";

import {HotKeysSubtable} from "../../../../components/System/HotKeys/HotKeys";
import {catchResponse} from "../../../../shared/utility";

class OrderProducts extends Component {

    state = {
        columns: Columns.vm.orderProducts,
        data: []
    };

    componentDidMount() {
        this.indexProducts();
    }

    indexProducts = () => {
        axios.get('/orders/' + this.props.orderId + '/products/orders-page.json', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.setState({data: res.data});
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    render() {
        return (
            <Paper>
                <HotKeysSubtable/>

                <MaterialTable
                    title={titleTemplate(
                        <div>
                            Products in order {this.props.orderNumber}
                        </div>
                    )}
                    icons={tableIcons}
                    style={{width: '100%'}}
                    columns={this.state.columns}
                    data={this.state.data}
                    actions={this.state.actions}
                    options={{
                        pageSize: 5,
                        exportButton: false,
                        filtering: false,
                        search: false,
                        paging: false
                    }}
                    components={{
                        EditRow: props => (
                            <MTableEditRow autoFocus className='subtableEditingRow uppercase ' {...props} />
                        ),
                        Toolbar: props => {
                            return (
                                <div className='subtableToolbar'>
                                    <MTableToolbar {...props} />
                                </div>
                            )
                        }
                    }}
                />
            </Paper>
        )
    }
}

export default OrderProducts;