import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { ToastContainer } from "react-toastify";

import { Grid } from "@material-ui/core";
import { authRedirect, nonAdminRedirect } from "../../shared/utility";

import DetailTable from "./DetailTable/DetailTable";
import FeaturesTable from "./FeaturesTable/FeaturesTable";
import Spinner from "../../components/UI/Spinner/Spinner";
import axios from "../../axios-sw";
import Columns from "../../shared/tables";

class ProductDetails extends Component {
  state = {
    columns: Columns.productDetailsColumns,
    productFeatureValues: [],
  };

  componentDidMount() {
    this.indexOptions();
    this.indexCategories();
  }

  indexOptions = () => {
    let product_options = [];
    let product_option_values = [];
    let product_features = [];
    let product_feature_values = [];
    const params = {
      output_format: "JSON",
      ws_key: "WR5NPJ885P44DWTF99H6WA173SPX9R8Z",
      display: "full",
    };

    const setOptions = (object) => {
      let map = new Map();
      for (var i = 0; i < object.options.length; i++) {
        if (object.name === "Kolor") {
          map.set(object.options[i]["id"], object.options[i]["name"]);
        } else {
          map.set(object.options[i]["id"], `${object.options[i]["name"]} (${object.name})`);
        }
      }
      let obj = Object.fromEntries(map);
      let newColumns = { ...this.state.columns };

      switch (object.name) {
        case "Kolor":
          newColumns["colors"][
            this.state.columns["colors"].findIndex((column) => column.field === "shop_color")
          ].lookup = obj;
          newColumns["sub_colors"][
            this.state.columns["sub_colors"].findIndex((column) => column.field === "shop_color")
          ].lookup = obj;
          break;
        case "Rozmiar":
        case "Rozmiar spodni":
        case "Rozmiar dziecięcy":
        case "Rozmiar butów":
        case "Rozmiar kapelusza":
        case "Rozmiar szortów":
        case "Rozmiar skarpet":
          newColumns["sizes"][this.state.columns["sizes"].findIndex((column) => column.field === "shop_size")].lookup =
            {
              ...newColumns["sizes"][this.state.columns["sizes"].findIndex((column) => column.field === "shop_size")]
                .lookup,
              ...obj,
            };
          break;
        default:
          break;
      }

      this.setState({ columns: newColumns });
    };

    axios.get(`https://carry.pl/api/product_features`, { params: params }).then((res) => {
      let newColumns = { ...this.state.columns };
      product_features = res.data.product_features;

      let map = new Map();
      for (var i = 0; i < product_features.length; i++) {
        map.set(product_features[i]["id"], product_features[i]["name"]);
      }
      let obj = Object.fromEntries(map);
      newColumns["features"][
        this.state.columns["features"].findIndex((column) => column.field === "shop_feature")
      ].lookup = {
        ...newColumns["features"][this.state.columns["features"].findIndex((column) => column.field === "shop_feature")]
          .lookup,
        ...obj,
      };
      this.setState({ columns: newColumns });

      axios.get(`https://carry.pl/api/product_feature_values`, { params: params }).then((res) => {
        product_feature_values = res.data.product_feature_values;

        this.setState({ productFeatureValues: product_feature_values });
      });
    });

    axios.get(`https://carry.pl/api/product_options`, { params: params }).then((res) => {
      product_options = res.data.product_options;
      axios.get(`https://carry.pl/api/product_option_values`, { params: params }).then((res) => {
        product_option_values = res.data.product_option_values;

        for (var i = 0; i < product_options.length; i++) {
          for (var j = 0; j < product_option_values.length; j++) {
            if (product_options[i]["id"] === parseInt(product_option_values[j]["id_attribute_group"])) {
              if (typeof product_options[i]["options"] !== "undefined") {
                product_options[i]["options"] = [...product_options[i]["options"], product_option_values[j]];
              } else {
                product_options[i]["options"] = [product_option_values[j]];
              }
            }
          }

          setOptions(product_options[i]);
        }
      });
    });
  };

  indexCategories = () => {
    const params = {
      output_format: "JSON",
      ws_key: "WR5NPJ885P44DWTF99H6WA173SPX9R8Z",
      display: "[id,id_parent,name]",
    };
    axios.get(`https://carry.pl/api/categories`, { params: params }).then((res) => {
      const categories = res.data.categories;

      for (var i = 0; i < categories.length; i++) {
        // Renaming
        categories[i]["label"] = categories[i]["name"];
        categories[i]["value"] = categories[i]["id"];
        delete categories[i]["name"];

        for (var j = 0; j < categories.length; j++) {
          if (categories[i]["id"] === parseInt(categories[j]["id_parent"])) {
            if (typeof categories[i]["children"] !== "undefined") {
              categories[i]["children"] = [...categories[i]["children"], categories[j]];
              delete categories[i]["id_parent"];
            } else {
              categories[i]["children"] = [categories[j]];
              delete categories[i]["id_parent"];
            }
          }
        }
      }

      const returnSexObject = (label) => {
        const sexCategories = categories.find((category) => category.label === label);
        const newCategories = new Map();
        for (var i = 0; i < sexCategories.children.length; i++) {
          for (var j = 0; j < sexCategories.children.length; j++) {
            newCategories.set(sexCategories.children[j]["id"], `${sexCategories.children[j]["label"]} (${label})`);
          }
        }
        return Object.fromEntries(newCategories);
      };

      const sexLookupMap = [
        { field: "men_categories", label: "Mężczyzna" },
        { field: "ladies_categories", label: "Kobieta" },
        { field: "boys_categories", label: "Chłopiec" },
        { field: "girls_categories", label: "Dziewczynka" },
        { field: "kids_categories", label: "Kids" },
        { field: "unisex_categories", label: "Unisex" },
      ];
      let newColumns = { ...this.state.columns };

      sexLookupMap.map((category) => {
        switch (category.field) {
          case "kids_categories":
            newColumns["descriptions"][
              this.state.columns["descriptions"].findIndex((column) => column.field === category.field)
            ].lookup = {
              ...returnSexObject("Dziewczynka"),
              ...returnSexObject("Chłopiec"),
            };
            break;
          case "unisex_categories":
            newColumns["descriptions"][
              this.state.columns["descriptions"].findIndex((column) => column.field === category.field)
            ].lookup = {
              ...returnSexObject("Mężczyzna"),
              ...returnSexObject("Kobieta"),
            };
            break;
          default:
            newColumns["descriptions"][
              this.state.columns["descriptions"].findIndex((column) => column.field === category.field)
            ].lookup = returnSexObject(category.label);
            break;
        }
      });

      this.setState({
        columns: newColumns,
      });
    });
  };

  render() {
    let content = (
      <Grid container spacing={3}>
        <Helmet>
          <title>Product details | Carry System</title>
        </Helmet>

        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />

        <FeaturesTable
          classTitle="features" // FEATURES
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          productFeatureValues={this.state.productFeatureValues}
          setFeatureValues={(newColumns) => this.setState({ columns: newColumns })}
          classParams={(newData) => {
            return {
              feature: {
                title: newData.title == null ? null : newData.title.toUpperCase(),
                shop_feature: typeof newData.shop_feature.id === "undefined" ? null : parseInt(newData.shop_feature.id),
              },
            };
          }}
        />

        <DetailTable
          classTitle="fabrics" // FABRICS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              fabric: {
                title: newData.title == null ? null : newData.title.toUpperCase(),
              },
            };
          }}
        />

        <DetailTable
          classTitle="descriptions" // DESCRIPTIONS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              description: {
                title: newData.title == null ? null : newData.title.toUpperCase(),
                hz_title: newData.hz_title == null ? null : newData.hz_title.toUpperCase(),
                short_hz_title: newData.short_hz_title == null ? null : newData.short_hz_title.toUpperCase(),
                code: newData.code == null ? null : newData.code.toUpperCase(),
                accessory: newData.accessory,
                men_categories: JSON.stringify(newData.men_categories),
                ladies_categories: JSON.stringify(newData.ladies_categories),
                boys_categories: JSON.stringify(newData.boys_categories),
                girls_categories: JSON.stringify(newData.girls_categories),
                kids_categories: JSON.stringify(newData.kids_categories),
                unisex_categories: JSON.stringify(newData.unisex_categories),
              },
            };
          }}
        />

        <DetailTable
          classTitle="compositions" // COMPOSITIONS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              composition: {
                title: newData.title == null ? null : newData.title.toUpperCase(),
              },
            };
          }}
        />

        <DetailTable
          classTitle="seasons" // SEASONS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              season: {
                code: newData.code == null ? null : newData.code.toUpperCase(),
                hz_code: newData.hz_code == null ? null : newData.hz_code.toUpperCase(),
              },
            };
          }}
        />

        <DetailTable
          classTitle="ports" // PORTS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              port: {
                code: newData.code == null ? null : newData.code.toUpperCase(),
                description: newData.description == null ? null : newData.description.toUpperCase(),
              },
            };
          }}
        />

        <DetailTable
          classTitle="sexes" // SEXES
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              sex: {
                code: newData.code == null ? null : newData.code.toUpperCase(),
                description: newData.description == null ? null : newData.description.toUpperCase(),
              },
            };
          }}
        />

        <DetailTable
          classTitle="colors" // COLORS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              color: {
                title: newData.title == null ? null : newData.title.toUpperCase(),
                title_pl: newData.title_pl == null ? null : newData.title_pl.toUpperCase(),
                shop_color: typeof newData.shop_color.id === "undefined" ? null : parseInt(newData.shop_color.id),
              },
            };
          }}
        />

        <DetailTable
          classTitle="sub_colors" // COLORS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              sub_color: {
                title: newData.title == null ? null : newData.title.toUpperCase(),
                title_pl: newData.title_pl == null ? null : newData.title_pl.toUpperCase(),
                shop_color: typeof newData.shop_color.id === "undefined" ? null : parseInt(newData.shop_color.id),
              },
            };
          }}
        />

        <DetailTable
          classTitle="sizes" // SIZES
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              size: {
                code: newData.code == null ? null : newData.code.toUpperCase(),
                size_type: newData.size_type == null ? null : newData.size_type,
                shop_size: newData.shop_size,
              },
            };
          }}
        />

        <DetailTable
          classTitle="payment_terms" // PAYMENT TERMS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              payment_term: {
                code: newData.code == null ? null : newData.code.toUpperCase(),
              },
            };
          }}
        />
        <DetailTable
          classTitle="free_days" // FREE DAYS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              free_day: {
                local_name: newData.local_name == null ? null : newData.local_name.toUpperCase(),
                name: newData.name == null ? null : newData.name.toUpperCase(),
                country_code: newData.country_code == null ? null : newData.country_code.toUpperCase(),
                date: newData.date,
                year: newData.year,
              },
            };
          }}
        />
        <DetailTable
          classTitle="banks" // BANKS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              bank: {
                name: newData.name == null ? null : newData.name.toUpperCase(),
              },
            };
          }}
        />
      </Grid>
    );

    if (this.props.token === null) {
      content = <Spinner />;
    }

    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(ProductDetails);
