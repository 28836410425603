import React, { useEffect, useState } from "react";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import classes from "./ColumnsMultiSelect.module.scss";

const ColumnsMultiSelect = ({ value, options, onChangeColumns, onOpen }) => {
  const [selectColumns, setSelectColumns] = useState(null);

  useEffect(() => {
    setSelectColumns(value);
  }, []);

  return (
    <Picky
      onOpen={onOpen}
      className={classes.columnsSelect}
      options={options}
      labelKey="label"
      valueKey="value"
      multiple={true}
      includeFilter
      includeSelectAll
      value={selectColumns}
      onClose={() => onChangeColumns(selectColumns)}
      onChange={(e) => {
        setSelectColumns(e);
      }}
    />
  );
};

export default ColumnsMultiSelect;
