import {Warning} from "@material-ui/icons";
import React from "react";

const WarningInfo = ({text}) => {
    return (
        <div style={{display: 'flex', alignItems:'center', marginTop: 15, height: 20}}>
            <Warning color={"error"} fontSize={"large"} style={{position: "absolute", marginLeft: 22, marginRight: 8}} />
            <span style={{position: "absolute", left: "70px"}}><b>{text}</b></span>
        </div>
    )
}

export default  WarningInfo;
