import React from 'react';
import Auxi from '../../../hoc/Auxi/Auxi';

import Hotkeys from "react-hot-keys";
import $ from "jquery";

const onAdd = (keyName, e, handle) => {
    $(`.mainToolbar button[title='Add']`).click()
};

const onSave = (keyName, e, handle) => {
    $(`.editingRow button[title='Save']`).click()
};

const onCancel = (keyName, e, handle) => {
    $(`.editingRow button[title='Cancel']`).click()
};

const onSubtableAdd = () => {
    $(`.subtableToolbar button[title='Add']`).click()
};

const onSubtableSave = () => {
    $(`.subtableEditingRow button[title='Save']`).click()
};

const onSubtableCancel = () => {
    $(`.subtableEditingRow button[title='Cancel']`).click()
};

const onNextPage = () => {
    $(`.MuiTablePagination-toolbar span[title='Next Page'] button`).click()
};

const onPreviousPage = () => {
    $(`.MuiTablePagination-toolbar span[title='Previous Page'] button`).click()
};

export const HotKeys = () => {
    return (
        <Auxi>
            <Hotkeys
                keyName="alt+a"
                onKeyDown={onAdd.bind(this)}
            />
            <Hotkeys
                keyName="alt+s"
                onKeyDown={onSave.bind(this)}
            />
            <Hotkeys
                keyName="alt+c"
                onKeyDown={onCancel.bind(this)}
            />
            <Hotkeys
                keyName="shift+right"
                onKeyDown={onNextPage.bind(this)}
            />
            <Hotkeys
                keyName="shift+left"
                onKeyDown={onPreviousPage.bind(this)}
            />
        </Auxi>
    )
};

export const HotKeysSubtable = () => {
    return (
        <Auxi>
            <Hotkeys
                keyName="alt+shift+a"
                onKeyDown={onSubtableAdd.bind(this)}
            />
            <Hotkeys
                keyName="alt+shift+s"
                onKeyDown={onSubtableSave.bind(this)}
            />
            <Hotkeys
                keyName="alt+shift+c"
                onKeyDown={onSubtableCancel.bind(this)}
            />
        </Auxi>
    )
};
