import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, {
  MTableBody,
  MTableBodyRow,
  MTableEditRow,
  MTableFilterRow,
  MTableToolbar,
} from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";

import {
  Button,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff, Beenhere } from "@material-ui/icons";

import Columns from "../../shared/tables";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";

import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  formatDate,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  indexDetails,
  nonAdminRedirect,
  sendEmailAboutLoadingDelay,
  thenResponse,
} from "../../shared/utility";

import Spinner from "../../components/UI/Spinner/Spinner";

import { defaultPageSize, defaultPageSizeOptions } from "../../shared/defines";

import { json2excel } from "js2excel";
import classes from "../Bookings/Bookings.module.scss";
import _ from "lodash";
import moment from "moment";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";
import BarcodesCombinations from "./BarcodesCombinations";

import { formatterQuantity, formatterCurrency } from "../../helpers";
import { sortingPhraseHelper, successQuery, readyQuery, barcodesOrdersColumns } from "./table";

class BarcodesOrders extends Component {
  state = {
    columns: barcodesOrdersColumns,
    columnsHidden: barcodesOrdersColumns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalQuantity: 0,
    quantityPercentage: 0,
    totalValue: 0,
    valuePercentage: 0,
    totalRow: 0,
    totalFinalBarcodesOrders: 0,
    filterPhrase: "",
    sortBy: null,
    barcodesOrder: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    tableRef: React.createRef(),
    exportChosen: false,
    ports: [],
    contacts: [],
    seasons: [],
    barcodesOrder_number: React.createRef(),
    season: null,
    designer: null,
    contact: null,
    designers: [],
    loaded: false,
    isModal: false,
    rowData: null,
    repeatBarcodesOrder: false,
    editable: {},
    actions: [],
    blockedSaveColumnsButton: true,
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexOrders(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    indexDetails("seasons", 4, "code", this.props.token, this.state.columns, (newState) => this.setState({ newState }));
    this.indexPorts();
    indexDetails("payment_terms", 9, "code", this.props.token, this.state.columns, (newState) =>
      this.setState({ newState })
    );
    this.indexSeasons();
    this.indexDesigners();
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;
    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "barcode_status" && filter.value.includes(successQuery))
          table.onFilterChange(index, "success");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes("IS NULL AND combinations.in_progress = true")
        )
          table.onFilterChange(index, "in_progress");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes(`IS NOT NULL OR ${filter.column.path} <> ''`) &&
          !filter.value.includes(successQuery)
        )
          table.onFilterChange(index, "error");
        if (filter.column.key === "barcode_status" && filter.value.includes(readyQuery))
          table.onFilterChange(index, "ready");
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_barcode_orders_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/barcodes?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexOrders(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexOrders(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
    if (this.props.role !== prevProps.role) this.checkActionsAndEditablePermissions();
  }

  indexOrders = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();

    axios
      .get(`/barcodes-orders.json`, {
        params: {
          page: page,
          limit: limit,
          phrase: phrase,
          sortPath: sortPath,
          filterPath: filterPath,
          currentSeasons: currentSeasons,
        },
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");
        const columnSettings = JSON.parse(window.localStorage.getItem(`user_barcodesOrders_${this.props.user_id}`));
        let columnsHidden = [];

        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          data: res.data.orders,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          totalFinalBarcodesOrders: res.data.total_final_orders,
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.orders,
          columnsHidden: columnsHidden,
          loaded: true,
        });

        if (responseTime > 10) {
          let ids = "";
          if (typeof res.data !== "undefined") {
            res.data.barcodesOrders.forEach((barcodesOrder) => (ids += `${barcodesOrder.id}, `));
          }
          sendEmailAboutLoadingDelay(
            "BarcodesOrders",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  mapOrder = (array, order, key) => {
    array.sort((a, b) => {
      let A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  };

  fetchDataBarcodesCombinations = async (barcodesOrdersData) => {
    const barcodesOrdersNumbers = barcodesOrdersData.map((item) => item.number);

    const fetchURL = (url, params) => axios.get(url, params);
    let barcodesCombinationsData = [];

    let promiseArray = barcodesOrdersData.map((item) => {
      return fetchURL(`/barcodes-combinations/${item.id}.json`, {
        params: {
          filterByColumn: this.state.filterByColumn ? this.state.filterByColumn.value : null,
          filterByConditional: this.state.filterByConditional ? this.state.filterByConditional.value : null,
        },
        headers: { Authorization: `Bearer ${this.props.token}` },
      });
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          const sorted = item.data.sort(function (a, b) {
            let a1 = a.product_id;
            let a2 = b.product_id;
            let o1 = a.order;
            let o2 = b.order;
            let p1 = a.size_id;
            let p2 = b.size_id;

            if (a1 < a2) return -1;
            if (a1 > a2) return 1;
            if (o1 < o2) return 1;
            if (o1 > o2) return -1;
            if (p1 < p2) return 1;
            if (p1 > p2) return -1;
            return 0;
          });
          barcodesCombinationsData = [...barcodesCombinationsData, ...sorted];
        });
      })
      .catch((err) => {
        toast.error(err);
      });
    /*FILTROWANIE DANYCH NA PODSTAWIE FILTRÓW*/
    let filteredBarcodesCombinationsData = [];
    barcodesCombinationsData.map((barcodesCombination) => {
      barcodesOrdersNumbers.map((number) => {
        if (barcodesCombination.order_number === number) {
          filteredBarcodesCombinationsData = [...filteredBarcodesCombinationsData, barcodesCombination];
        }
      });
    });

    /*SORTOWANIE DANYCH NA PODSTAWIE KOLEJNOŚCI KONTENERÓW*/
    filteredBarcodesCombinationsData = this.mapOrder(
      filteredBarcodesCombinationsData,
      barcodesOrdersNumbers,
      "order_number"
    );

    return filteredBarcodesCombinationsData;
  };

  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        let localMap = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
          localMap.set(res.data[i]["id"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let localObj = Object.fromEntries(localMap);
        let newState = { ...this.state.columns };
        const portIndex = this.state.columns.findIndex((column) => column.field === "port");
        newState[portIndex].lookup = obj;
        this.setState({ columns: Object.values(newState), ports: localObj });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexSeasons = () => {
    axios
      .get("/seasons.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ seasons: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexDesigners = () => {
    axios
      .get("/designers.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ designers: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateBarcodeOrder = (id, old_lc_number, newData) => {
    const data = {
      order: {
        sent_to_supplier: newData.sent_to_supplier,
        ready_to_sync: newData.ready_to_sync,
      },

      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };

    const url = "/orders/" + id + ".json";

    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedOrder = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedOrder.id);
            if (index !== -1) {
              array[index].sent_to_supplier = fetchedOrder.sent_to_supplier;
              array[index].ready_to_sync = fetchedOrder.ready_to_sync;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  solveAllCombinationsErrors = (event, rowData) => {
    const confirm = window.confirm("Do you want to remove all errors?");

    if (confirm) {
      axios
        .post(`/resolve-barcodes-errors/${rowData.id}`, { headers: { Authorization: `Bearer ${this.props.token}` } })
        .then((res) => {
          thenResponse(res);
        })
        .catch((err) => {
          catchResponse(err);
        });
    }
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateBarcodeOrder(oldData.id, oldData.lc_number, newData);
            }),
        },
        actions: [
          () => ({
            icon: Beenhere,
            tooltip: "Solve all barcodes errors",
            onClick: (event, rowData) => {
              this.solveAllCombinationsErrors(event, rowData);
            },
          }),
        ],
      });
    }
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        <Helmet>
          <title>Barcodes orders | Carry System</title>
        </Helmet>

        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role, true)}
        <ToastContainer />
        <HotKeys />
        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Barcodes")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          columns={this.state.columnsHidden}
          data={this.state.filteredData}
          actions={this.state.actions}
          style={{ width: "100%" }}
          options={{
            search: false,
            filtering: true,
            pageSize: defaultPageSize,
            pageSizeOptions: defaultPageSizeOptions,
          }}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <BarcodesCombinations
                  user_id={this.props.user_id}
                  ip_address={this.props.ip_address}
                  orderId={rowData.id}
                  role={this.props.role}
                  token={this.props.token}
                />
              </div>
            );
          }}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          if (this.state.columnsHidden[columnId].field === "barcode_error")
                            singleColumnFilterList[indexIsExist].value = `=false`;
                          else singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "ready") value = `IS NULL AND ${readyQuery}`;
                      else if (value === "in_progress") value = `IS NULL AND combinations.in_progress = true`;
                      else if (value === "error") value = `IS NOT NULL OR ${sqlColumn[0].path} <> ''`;
                      else if (value === "success") value = `IS NULL AND ${successQuery}`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      if (this.state.columnsHidden[columnId].field === "accepted") {
                        let convertValue = "";
                        value.forEach((item, index) => {
                          if (item === "all")
                            convertValue +=
                              index < value.length - 1
                                ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                          else if (item === "empty" || item === "unadded")
                            convertValue +=
                              index < value.length - 1
                                ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                : `="" OR ${sqlColumn[0].path} IS NULL`;
                          else if (item === "accepted" || item === "added")
                            convertValue +=
                              index < value.length - 1
                                ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                                : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                          else
                            convertValue +=
                              index < value.length - 1
                                ? `LIKE "%${item}%" OR ${sqlColumn[0].path} `
                                : `LIKE "%${item}%"`;
                        });

                        if (value.length > 0) {
                          if (indexIsExist !== -1) {
                            singleColumnFilterList[indexIsExist].value = convertValue;
                            table.state.columns[columnId].tableData.initialValue = value;
                          } else {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: convertValue,
                                type: "multiSelect",
                              },
                            ];
                            table.state.columns[columnId].tableData.initialValue = value;
                          }
                        } else {
                          singleColumnFilterList = singleColumnFilterList.filter(
                            (filter) => filter.column.key !== "accepted"
                          );
                          table.state.columns[columnId].tableData.initialValue = ["all"];
                        }
                      } else {
                        if (indexIsExist !== -1) {
                          let multiFiltersValues = "";
                          value.forEach((filter, index) => {
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                          });
                          singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                          table.onFilterChange(columnId, value);
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "multiSelect",
                            },
                          ];
                          table.onFilterChange(columnId, value);
                        }
                      }
                    }
                    this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.setState({ limit: event.target.value });
                    this.goPage(
                      this.state.page,
                      event.target.value,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
            FilterRow: (props) => {
              return <MTableFilterRow className="filterRow" {...props} />;
            },
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;

              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/barcodes?page=${1}&limit=${20}`);

                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.state.columns[index].tableData.filterValue = "all";
                          column.tableData.initialValue = "all";
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />
                  <div className={classes.exportToExcelWrapper}>
                    {/* Eksportowanie danych z tabeli */}
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(`/barcodes-orders.json`, {
                            params: {
                              page: 0,
                              limit: 0,
                              phrase: search,
                              sortPath: sortPath,
                              filterPath: filterPath,
                              currentSeasons: currentSeasons,
                              export: true,
                            },
                            headers: { Authorization: `Bearer ${this.props.token}` },
                          })
                          .then(async (res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            const barcodesOrdersData = res.data.orders;
                            let dataBarcodesCombinations = await this.fetchDataBarcodesCombinations(barcodesOrdersData);

                            let filteredBarcodesOrdersData = barcodesOrdersData.map((barcodesOrder) => {
                              return {
                                id: barcodesOrder.id,
                                number: barcodesOrder.number,
                                contact_name: barcodesOrder.contact,
                                company: barcodesOrder.company,
                                designer: barcodesOrder.designer_code,
                                season: barcodesOrder.season_code,
                                barcode_error: barcodesOrder.barcode_error ? "TAK" : "NIE",
                                sent_to_supplier: barcodesOrder.sent_to_supplier
                                  ? new Date(barcodesOrder.sent_to_supplier)
                                  : null,
                              };
                            });

                            let filteredDataBarcodesCombinations = dataBarcodesCombinations.map((item) => {
                              return {
                                order_number: item.order_number,
                                contact_name: item.contact,
                                company: item.company,
                                style_no: item.style_name,
                                store_price: item.store_price,
                                "hz_id/index": item.hz_id,
                                barcode: item.barcode,
                                "title/hz_title": `${item.hz_title_en}/${item.hz_title}`,
                                collection_ref: item.collection_ref,
                                color_hz: item.color_hz_en,
                                main_color: item.color,
                                size: item.size,
                                quantity_in_pcs: item.quantity_in_pcs,
                              };
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden.map((column) => {
                                if (column.field === "contact") column.field = "contact_name";
                                if (column.field === "designer_code") column.field = "designer";
                                if (column.field === "season_id") column.field = "season";
                                return column.field;
                              });
                              filteredBarcodesOrdersData = filteredBarcodesOrdersData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            json2excel({
                              data: filteredBarcodesOrdersData,
                              name: "barcodes_orders_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            json2excel({
                              data: filteredDataBarcodesCombinations,
                              name: "barcode_com_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>

                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          0,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    /*WYJĄTKI*/
                    if (filter.column.key === "barcode_status" && filter.value.includes(successQuery))
                      value = "SUCCESS";
                    if (
                      filter.column.key === "barcode_status" &&
                      filter.value.includes("IS NULL AND combinations.in_progress = true")
                    )
                      value = "IN PROGRESS";
                    if (
                      filter.column.key === "barcode_status" &&
                      filter.value.includes(`IS NOT NULL OR ${filter.column.path} <> ''`) &&
                      !filter.value.includes(successQuery)
                    )
                      value = "ERROR";
                    if (filter.column.key === "barcode_status" && filter.value.includes(readyQuery)) value = "READY";
                    filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                    if (filter.column.key === "payment_term_id") {
                      let currentValue = "";
                      const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                      const paymnetValue = value.replace(/\s/g, "").split(",");
                      paymnetValue.forEach((item, index) =>
                        index + 1 === paymnetValue.length
                          ? (currentValue += `${column[parseInt(item)]}`)
                          : (currentValue += `${column[parseInt(item)]}, `)
                      );
                      value = currentValue;
                      key = "PAYMENT";
                    }

                    /*MULTISELECT ACCEPTED WYJĄTKI*/
                    if (filter.column.key === "accepted") {
                      value = "";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)
                      )
                        value += "ALL ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`="" OR ${filter.column.path} IS NULL`)
                      )
                        value += "EMPTY ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
                      )
                        value += "ACCEPTED ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value += "HOLD ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
                        value += "CANCEL ";
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.state.columns[index].tableData.initialValue = ["all"];
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${this.state.columns
                          .find((item) => item.field === key)
                          .title.toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key;
                    let icon = "";

                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    /*WYJĄTKI*/
                    column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${key.toUpperCase().replace("_", " ")}`}
                        icon={icon}
                      />
                    );
                  })}

                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "barcodesOrder_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "barcodesOrders",
                          };
                        })}
                      options={Columns.barcodesOrdersColumns
                        .filter((column) => column.field !== "barcodesOrder_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "barcodesOrders",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_barcodesOrders_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_barcodesOrders_${this.props.user_id}`);
                          await this.setState({ columnsHidden: this.state.columns, currentStateColumns: null });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>

                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>

                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Final Orders</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Value</TableCell>
                        <TableCell align="right">Quantity Percentange</TableCell>
                        <TableCell align="right">Value Percentange</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "20%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterQuantity.format(this.state.totalFinalBarcodesOrders)}/{this.state.totalRow}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterQuantity.format(this.state.totalQuantity)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterCurrency.format(this.state.totalValue)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {`${this.state.quantityPercentage}`.replace(".", ",")} %
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {`${this.state.valuePercentage}`.replace(".", ",")} %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(BarcodesOrders);
