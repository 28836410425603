import React, {useEffect, useState} from 'react';
import {Picky} from 'react-picky';
import 'react-picky/dist/picky.css';

const MultiSelect = ({value, options, onChangeValue}) => {

    const [selectValues, setSelectValues] = useState(null);

    useEffect(() => {
        setSelectValues(value)
    },[])

    return (
        <Picky
            //className={classes.columnsSelect}
            options={options}
            labelKey="label"
            valueKey="value"
            multiple={true}
            includeFilter
            includeSelectAll
            value={selectValues}
            onClose={() => onChangeValue(selectValues)}
            onChange={(e) => {
                setSelectValues(e)
            }}
        />)
}

export default MultiSelect;