import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import * as ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/* Redux */
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";

/* Reducers */
import authReducer from "./store/reducers/auth";
import systemInfoReducer from "./store/reducers/systemInfo";
import IdleTimer from "./components/System/Idle/IdleTimer";

// @ts-ignore
const composeEnhancers =
  (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  systemInfo: systemInfoReducer,
});

/* REDUX store */
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <IdleTimer />
      <App />
    </BrowserRouter>
  </Provider>
);

// @ts-ignore
document.body.style.zoom = 1;

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister(); // TODO: Change before deploy
