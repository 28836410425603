import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { Divider } from "@material-ui/core";
import {
  Sync,
  ShoppingCart,
  DirectionsBoat,
  BarChart,
  Cancel,
  AccountCircle,
  SupervisorAccount,
  ListAlt,
  PermContactCalendar,
  Face,
  ViewList,
  Settings,
  AttachMoney,
  PlaylistAddCheck,
  Description,
  InvertColors,
  Collections,
  LocalOffer,
  Filter9Plus,
  MenuBook,
  ShowChart,
} from "@material-ui/icons";

import { role } from "../../../../config";
import DrawerItem from "./DrawerItem";

interface LinksProps {
  userId: number | string;
  userRole: string;
  onClickLinkHandler: () => void;
}

interface ItemProps {
  label: string;
  link: string;
  icon: ReactElement;
  bottomDivider: boolean;
}

const DrawerLinks: FC<LinksProps> = ({ userId, userRole, onClickLinkHandler }) => {
  const adminItems: ItemProps[] = [
    // Main views
    { label: "Orders", link: "/", icon: <ViewList />, bottomDivider: false },
    { label: "Products", link: "/products", icon: <ShoppingCart />, bottomDivider: false },
    { label: "Cancelled", link: "/cancelled-products", icon: <Cancel />, bottomDivider: false },
    { label: "Bookings", link: "/bookings", icon: <PlaylistAddCheck />, bottomDivider: false },
    { label: "Barcodes", link: "/barcodes", icon: <LocalOffer />, bottomDivider: false },
    { label: "Multipacks", link: "/multipacks", icon: <Filter9Plus />, bottomDivider: false },
    {
      label: "Status of product",
      link: "/designers/status-of-product",
      icon: <PlaylistAddCheck />,
      bottomDivider: false,
    },
    { label: "Catalog", link: "/catalog", icon: <MenuBook />, bottomDivider: true },
    // Finance
    { label: "LCs", link: "/lcs", icon: <AttachMoney />, bottomDivider: false },
    { label: "Containers", link: "/containers", icon: <DirectionsBoat />, bottomDivider: false },
    { label: "Invoices", link: "/invoices", icon: <BarChart />, bottomDivider: true },
    // Definitions
    { label: "Contacts", link: "/contacts", icon: <PermContactCalendar />, bottomDivider: false },
    { label: "Designers", link: "/designers", icon: <Face />, bottomDivider: false },
    { label: "Product details", link: "/product-details", icon: <Description />, bottomDivider: true },
    // Account
    { label: "Admin", link: "/admin", icon: <SupervisorAccount />, bottomDivider: false },
    { label: "User logs", link: "/user-logs", icon: <ListAlt />, bottomDivider: false },
    { label: "Settings", link: "/settings", icon: <Settings />, bottomDivider: true },
    // Sync
    { label: "Sync Products", link: "/sync-products", icon: <Sync />, bottomDivider: true },
    { label: "Account", link: `/account/${userId}`, icon: <AccountCircle />, bottomDivider: true },
    // Forecasts
    { label: "Forecasts", link: `/forecasts`, icon: <ShowChart />, bottomDivider: true },
  ];

  const headOfDesignersItems: ItemProps[] = [
    { label: "All orders", link: "/", icon: <ViewList />, bottomDivider: false },
    { label: "All products", link: "/products", icon: <ShoppingCart />, bottomDivider: false },
    { label: "Cancelled", link: "/cancelled-products", icon: <Cancel />, bottomDivider: false },
  ];

  const designerItems: ItemProps[] = [
    { label: "Your orders", link: `/designer-dashboard/${userId}`, icon: <ViewList />, bottomDivider: false },
    { label: "Your products", link: `/designers/${userId}/products`, icon: <ShoppingCart />, bottomDivider: false },
    { label: "Product details", link: "/designers/product-details", icon: <Description />, bottomDivider: false },
    { label: "Cancelled", link: "/designers/cancelled-products", icon: <Cancel />, bottomDivider: false },
    {
      label: "Status of product",
      link: "/designers/status-of-product",
      icon: <PlaylistAddCheck />,
      bottomDivider: true,
    },
    { label: "Contacts", link: "/contacts", icon: <PermContactCalendar />, bottomDivider: true },
    { label: "Catalog", link: "/catalog", icon: <MenuBook />, bottomDivider: false },
    { label: "Account", link: `/account/${userId}`, icon: <AccountCircle />, bottomDivider: true },
  ];

  const vmItems: ItemProps[] = [
    { label: "Catalog", link: "/catalog", icon: <MenuBook />, bottomDivider: false },
    { label: "Barcodes", link: "/barcodes", icon: <LocalOffer />, bottomDivider: true },
    { label: "Products", link: "/vm/products", icon: <ShoppingCart />, bottomDivider: false },
    { label: "Orders", link: "/vm/orders", icon: <ViewList />, bottomDivider: false },
    { label: "Cancelled", link: "/vm/cancelled-products", icon: <Cancel />, bottomDivider: false },
    { label: "Bookings", link: "/vm/bookings", icon: <PlaylistAddCheck />, bottomDivider: true },
    { label: "Collections", link: "/vm/collections", icon: <Collections />, bottomDivider: false },
    { label: "Multipacks", link: "/multipacks", icon: <Filter9Plus />, bottomDivider: true },
    { label: "Containers", link: "/vm/containers", icon: <DirectionsBoat />, bottomDivider: false },
    { label: "Account", link: `/account/${userId}`, icon: <AccountCircle />, bottomDivider: true },
  ];

  const marketingItems: ItemProps[] = [
    { label: "Modelokolory", link: "/modelokolory", icon: <InvertColors />, bottomDivider: false },
    { label: "Account", link: `/account/${userId}`, icon: <AccountCircle />, bottomDivider: true },
  ];

  const warehouseItems: ItemProps[] = [
    { label: "Containers", link: "/warehouse/containers", icon: <DirectionsBoat />, bottomDivider: false },
    { label: "Multipacks", link: "/multipacks", icon: <Filter9Plus />, bottomDivider: true },
    { label: "Account", link: `/account/${userId}`, icon: <AccountCircle />, bottomDivider: true },
  ];

  const ecommerceItems: ItemProps[] = [
    { label: "Sync Products", link: "/sync-products", icon: <Sync />, bottomDivider: false },
    { label: "Catalog", link: "/catalog", icon: <MenuBook />, bottomDivider: true },
    { label: "Account", link: `/account/${userId}`, icon: <AccountCircle />, bottomDivider: true },
  ];

  return (
    <StyledDrawerWrapper>
      <Divider />

      {/* ADMIN */}
      {userRole === role.ADMIN &&
        adminItems.map((item) => {
          return (
            <DrawerItem
              label={item.label}
              link={item.link}
              onClick={onClickLinkHandler}
              icon={item.icon}
              bottomDivider={item.bottomDivider}
            />
          );
        })}

      {/* DESIGNER */}
      {userRole === role.DESIGNER && (
        <>
          {(userId === 26 || userId === "26") &&
            headOfDesignersItems.map((item) => {
              return (
                <DrawerItem
                  label={item.label}
                  link={item.link}
                  onClick={onClickLinkHandler}
                  icon={item.icon}
                  bottomDivider={item.bottomDivider}
                />
              );
            })}

          {designerItems.map((item) => {
            return (
              <DrawerItem
                label={item.label}
                link={item.link}
                onClick={onClickLinkHandler}
                icon={item.icon}
                bottomDivider={item.bottomDivider}
              />
            );
          })}
        </>
      )}

      {/* VM */}
      {userRole === role.VM &&
        vmItems.map((item) => {
          return (
            <DrawerItem
              label={item.label}
              link={item.link}
              onClick={onClickLinkHandler}
              icon={item.icon}
              bottomDivider={item.bottomDivider}
            />
          );
        })}

      {/* MARKETING */}
      {userRole === role.MARKETING &&
        marketingItems.map((item) => {
          return (
            <DrawerItem
              label={item.label}
              link={item.link}
              onClick={onClickLinkHandler}
              icon={item.icon}
              bottomDivider={item.bottomDivider}
            />
          );
        })}

      {/* WAREHOUSE */}
      {userRole === role.WAREHOUSE &&
        warehouseItems.map((item) => {
          return (
            <DrawerItem
              label={item.label}
              link={item.link}
              onClick={onClickLinkHandler}
              icon={item.icon}
              bottomDivider={item.bottomDivider}
            />
          );
        })}

      {/* ECOMMERCE */}
      {userRole === role.ECOMMERCE &&
        ecommerceItems.map((item) => {
          return (
            <DrawerItem
              label={item.label}
              link={item.link}
              onClick={onClickLinkHandler}
              icon={item.icon}
              bottomDivider={item.bottomDivider}
            />
          );
        })}
    </StyledDrawerWrapper>
  );
};

const StyledDrawerWrapper = styled.div`
  svg {
    font-size: 20px;
  }
`;

export default DrawerLinks;
