import {checkDateRange, sortByDate} from "../helperTables";
import FilterDateRange from "../../../components/System/FilterDateRange/FilterDateRange";
import React from "react";
import PreviewDropzone from "../../../components/System/PreviewDropzone/PreviewDropzone";
import {Link} from "react-router-dom";
import FilterInput from "../../../components/System/FilterInput/FilterInput";

export default {
    containersColumns: [
        {title: 'Container ID', field: 'id', hidden: true},
        {title: 'Container number', field: 'number', editable: 'never'},
        {title: 'Container type', field: 'container_type', editable: 'never'},
        {
            title: 'ETD',
            field: 'etd',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.etd)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'etd')
        },
        {
            title: 'ETA',
            field: 'eta',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.eta)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'eta')
        },
        {
            title: 'Warehouse',
            field: 'warehouse',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.warehouse)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'warehouse')
        },
        {title: 'Port', field: 'port_id'},
        {title: 'DOX AC', field: 'dox_ac', editable: 'never'},
        {title: 'FV', field: 'fv', type: 'boolean', editable: 'never'},
        {title: 'FV Back', field: 'fv_gave_back', editable: 'never'},
        {title: 'Delivered', field: 'supplied', type: 'boolean'},
        {title: 'Comments', field: 'comments', editable: 'never'},
        {
            title: 'Season',
            field: 'season',
            editable: 'never',
        },
        {
            title: 'Season as per Invoice',
            field: 'season_invoice',
            type: 'text',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return (
                    <FilterInput term={term} onChange={onChange}/>
                )
            },
        },
        {
            title: 'File',
            field: 'file',
            editable: 'never',
            render: data => data.file ? <PreviewDropzone previewFiles={data.file}/> :
                <p style={{fontStyle: "italic"}}>No file uploaded</p>
        }
    ],
    containerInvoicesColumns: [
        {title: 'Container invoice id', field: 'id', hidden: true},
        {title: 'Shipper', field: 'shipper', editable: 'never'},
        {title: 'Contact person', field: 'contact_person', editable: 'never'},
        {
            title: 'CTNS',
            field: 'ctns',
            editable: 'never',
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'CBM',
            field: 'cbm',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.cbm.replace(" ", "")) - parseFloat(b.cbm.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Quantity in pcs',
            field: 'quantity_in_pcs',
            editable: 'never',
            customSort: (a, b) => parseInt(a.quantity_in_pcs.replace(" ", "")) - parseInt(b.quantity_in_pcs.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Value',
            field: 'value',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.value.replace(" ", "")) - parseFloat(b.value.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Invoice number',
            field: 'invoice_number',
            editable: 'never',
            render: rowData => {
                return (
                    <div>
                        <Link to={`/invoices?${rowData.invoice_number}`}>{rowData.invoice_number}</Link>
                    </div>
                )
            },
            headerStyle: {
                textAlign: "center",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "center",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Invoice date',
            field: 'invoice_date',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.invoice_date)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'invoice_date')
        },
        {
            title: 'Check in request date',
            field: 'check_in_request_date',
            editable: 'never'
        },
        {title: 'DPL', field: 'dpl', editable: 'never'},
        {
            title: 'NET',
            field: 'net',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.net.replace(" ", "")) - parseFloat(b.net.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'GROSS',
            field: 'gross',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.gross.replace(" ", "")) - parseFloat(b.gross.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Average carton weight',
            field: 'average_carton_weight',
            editable: 'never',
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Average item weight',
            field: 'average_item_weight',
            editable: 'never',
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Documents',
            field: 'documents_approved',
            type: 'boolean',
            headerStyle: {
                textAlign: "center",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "center",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Originals approved',
            field: 'originals_approved_date',
            editable: 'never'
        },
        {
            title: 'Season as per invoice',
            field: 'season',
            editable: 'never',
        },
        {
            title: 'File',
            field: 'file',
            editable: 'never',
            render: data => data.file ? <PreviewDropzone previewFiles={data.file}/> :
                <p style={{fontStyle: "italic"}}>No file uploaded</p>
        }
    ]
}