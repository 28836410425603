import React, { Component } from "react";
import axios from "../../../axios-sw";

import Paper from "@material-ui/core/Paper";
import { tableIcons, titleTemplate } from "../../../shared/tableVariables";
import { role } from "../../../config";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import Columns from "../../../shared/tables";
import { HotKeysSubtable } from "../../../components/System/HotKeys/HotKeys";

import { catchResponse, postFile, thenResponse } from "../../../shared/utility";

const sortingPhraseHelper = [
  { key: "contact_person", path: "container_invoices.contact_person" },
  { key: "shipper", path: "container_invoices.shipper" },
  { key: "ctns", path: "container_invoices.ctns" },
  { key: "cbm", path: "container_invoices.cbm" },
  {
    key: "quantity_in_pcs",
    path: "bookings.quantity_in_pcs",
  },
  {
    key: "value",
    path: "bookings.value",
  },
  { key: "invoice_number", path: "container_invoices.invoice_number" },
  { key: "payment_ref", path: "invoices.payment_ref" },
  { key: "paid", path: "invoices.validated" },
  { key: "net", path: "container_invoices.net" },
  { key: "code", path: "container_invoices.code" },
  { key: "gross", path: "container_invoices.gross" },
  { key: "originals_approved_date", path: "container_invoices.originals_approved_date" },
  { key: "documents_approved", path: "container_invoices.documents_approved" },
  { key: "file", path: "container_invoices.file" },
  { key: "season", path: "seasons.code" },
];

class ContainerInvoices extends Component {
  state = {
    columns: Columns.containerInvoicesColumns,
    data: [],
    editable: {},
    actions: [],
  };

  componentDidMount() {
    this.indexContainerInvoices();
    this.checkActionsAndEditablePermissions();
  }

  indexContainerInvoices = () => {
    let index;
    let filterByColumnSql;
    if (this.props.filterByColumn) {
      index = this.state.columns.findIndex((column) => column.field === this.props.filterByColumn.value);
      if (index !== -1)
        filterByColumnSql = sortingPhraseHelper.find((helper) => helper.key === this.props.filterByColumn.value).path;
    }

    axios
      .get("/container-invoices/" + this.props.containerId + ".json", {
        params: {
          filterByColumn: this.props.filterByColumn && index !== -1 ? filterByColumnSql : null,
          filterByConditional:
            this.props.filterByConditional && index !== -1 ? this.props.filterByConditional.value : null,
        },
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.setState({ data: res.data });
        if (this.props.filteredPhrase && this.props.filteredPhrase.trim().length > 0)
          this.props.colorFilteredPhrase(this.props.filteredPhrase);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  createContainerInvoice = (newData) => {
    const data = {
      container_invoice: {
        //contact_person: newData.contact_person == null ? null : newData.contact_person.toUpperCase(),
        code: newData.code == null ? null : newData.code.toUpperCase().trim(),
        shipper: newData.shipper == null ? null : newData.shipper.toUpperCase(),
        cbm:
          typeof newData.cbm !== "undefined"
            ? newData.cbm !== null
              ? newData.cbm.toString().replace(",", ".")
              : null
            : null,
        ctns:
          typeof newData.ctns !== "undefined"
            ? newData.ctns !== null
              ? newData.ctns.toString().replace(",", ".")
              : null
            : null,
        /*quantity_in_pcs: typeof newData.quantity_in_pcs !== 'undefined' ?
                    newData.quantity_in_pcs !== null ? newData.quantity_in_pcs.toString().replace(" ", "") : null
                    : null,
                value: typeof newData.value !== 'undefined' ?
                    newData.value !== null ? newData.value.toString().replace(" ", "").replace(",", ".") : null
                    : null,*/
        invoice_number: newData.invoice_number == null ? null : newData.invoice_number.toUpperCase(),
        /*invoice_date: newData.invoice_date,*/
        check_in_request_date: newData.check_in_request_date,
        dpl: newData.dpl == null ? null : newData.dpl.toUpperCase(),
        net: newData.net,
        gross: newData.gross,
        documents_approved: newData.documents_approved,
        originals_approved_date: newData.originals_approved_date,
        packing_lists_date: newData.packing_lists_date,
        container_number: this.props.containerNumber,
        container_id: this.props.containerId,
        file: newData.file ? { url: newData.file } : null,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .post("/container_invoices.json", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexContainerInvoices();
        thenResponse(res);
      })
      .then(() => {
        postFile(
          "/container-invoices-file-update/",
          "file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => this.indexContainerInvoices()
        );
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  destroyContainerInvoice = (id) => {
    const data = {
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/container_invoices/" + id, {
        data: data,
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.indexContainerInvoices();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateContainerInvoice = (id, newData) => {
    const data = {
      container_invoice: {
        //contact_person: newData.contact_person == null ? null : newData.contact_person.toUpperCase(),
        code: newData.code == null ? null : newData.code.toUpperCase().trim(),
        shipper: newData.shipper == null ? null : newData.shipper.toUpperCase(),
        cbm:
          typeof newData.cbm !== "undefined"
            ? newData.cbm !== null
              ? newData.cbm.toString().replace(" ", "").replace(",", ".")
              : null
            : null,
        ctns:
          typeof newData.ctns !== "undefined"
            ? newData.ctns !== null
              ? newData.ctns.toString().replace(",", ".")
              : null
            : null,
        quantity_in_pcs:
          typeof newData.quantity_in_pcs !== "undefined"
            ? newData.quantity_in_pcs !== null
              ? newData.quantity_in_pcs.toString().replace(" ", "")
              : null
            : null,
        value:
          typeof newData.value !== "undefined"
            ? newData.value !== null
              ? newData.value.toString().replace(" ", "").replace(",", ".")
              : null
            : null,
        invoice_number:
          newData.invoice_number == null || newData.invoice_number.trim().length === 0
            ? null
            : newData.invoice_number.replace(/\s/g, "").toUpperCase(),
        invoice_date: newData.invoice_date,
        check_in_request_date: newData.check_in_request_date,
        dpl: newData.dpl == null ? null : newData.dpl.toUpperCase(),
        net:
          typeof newData.net !== "undefined"
            ? newData.net !== null
              ? newData.net.toString().replace(" ", "").replace(",", ".")
              : null
            : null,
        gross:
          typeof newData.gross !== "undefined"
            ? newData.gross !== null
              ? newData.gross.toString().replace(" ", "").replace(",", ".")
              : null
            : null,
        documents_approved: newData.documents_approved,
        originals_approved_date: newData.originals_approved_date,
        packing_lists_date: newData.packing_lists_date,
        file: newData.file ? { url: newData.file } : null,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/container_invoices/" + id + ".json";
    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedContainerInvoice = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedContainerInvoice.id);
            if (index !== -1) {
              array[index].contact_person = fetchedContainerInvoice.contact_person;
              array[index].code = fetchedContainerInvoice.code;
              array[index].shipper = fetchedContainerInvoice.shipper;
              array[index].cbm = fetchedContainerInvoice.cbm;
              array[index].ctns = fetchedContainerInvoice.ctns;
              array[index].quantity_in_pcs = fetchedContainerInvoice.quantity_in_pcs;
              array[index].value = fetchedContainerInvoice.value;
              array[index].invoice_number = fetchedContainerInvoice.invoice_number;
              array[index].invoice_date = fetchedContainerInvoice.invoice_date;
              array[index].check_in_request_date = fetchedContainerInvoice.check_in_request_date;
              array[index].dpl = fetchedContainerInvoice.dpl;
              array[index].net = fetchedContainerInvoice.net;
              array[index].gross = fetchedContainerInvoice.gross;
              array[index].documents_approved = fetchedContainerInvoice.documents_approved;
              array[index].originals_approved_date = fetchedContainerInvoice.originals_approved_date;
              array[index].packing_lists_date = fetchedContainerInvoice.packing_lists_date;
              array[index].file = fetchedContainerInvoice.file;
              array[index].average_carton_weight = fetchedContainerInvoice.average_carton_weight;
              array[index].average_item_weight = fetchedContainerInvoice.average_item_weight;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .then(() => {
        postFile(
          "/container-invoices-file-update/",
          "file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => {
            axios
              .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
              .then((res) => {
                const fetchedContainerInvoice = res.data;
                const array = [...this.state.data];
                const index = array.findIndex((x) => x.id === fetchedContainerInvoice.id);
                if (index !== -1) {
                  array[index].contact_person = fetchedContainerInvoice.contact_person;
                  array[index].shipper = fetchedContainerInvoice.shipper;
                  array[index].cbm = fetchedContainerInvoice.cbm;
                  array[index].ctns = fetchedContainerInvoice.ctns;
                  array[index].quantity_in_pcs = fetchedContainerInvoice.quantity_in_pcs;
                  array[index].value = fetchedContainerInvoice.value;
                  array[index].invoice_number = fetchedContainerInvoice.invoice_number;
                  array[index].invoice_date = fetchedContainerInvoice.invoice_date;
                  array[index].check_in_request_date = fetchedContainerInvoice.check_in_request_date;
                  array[index].dpl = fetchedContainerInvoice.dpl;
                  array[index].net = fetchedContainerInvoice.net;
                  array[index].gross = fetchedContainerInvoice.gross;
                  array[index].documents_approved = fetchedContainerInvoice.documents_approved;
                  array[index].originals_approved_date = fetchedContainerInvoice.originals_approved_date;
                  array[index].packing_lists_date = fetchedContainerInvoice.packing_lists_date;
                  array[index].file = fetchedContainerInvoice.file.url
                    ? fetchedContainerInvoice.file.url
                    : fetchedContainerInvoice.file;
                  array[index].average_carton_weight = fetchedContainerInvoice.average_carton_weight;
                  array[index].average_item_weight = fetchedContainerInvoice.average_item_weight;
                  this.setState({ data: array });
                }
              })
              .catch((err) => {
                catchResponse(err);
              });
          }
        );
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              resolve();
              this.createContainerInvoice(newData);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateContainerInvoice(oldData.id, newData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              this.destroyContainerInvoice(oldData.id);
            }),
        },
        actions: [],
      });
    }
  };

  render() {
    const title = <div>Container invoices in container: {this.props.containerNumber}</div>;

    return (
      <Paper>
        <HotKeysSubtable />
        <MaterialTable
          title={titleTemplate(title)}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={this.state.data}
          localization={{
            editRow: {
              cancelTooltip: "Back",
            },
          }}
          options={{
            pageSize: 5,
            exportButton: false,
            filtering: false,
            search: false,
            paging: false,
          }}
          components={{
            EditRow: (props) => <MTableEditRow autoFocus className="subtableEditingRow uppercase " {...props} />,
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              return (
                <div className="subtableToolbar">
                  <MTableToolbar {...props} />
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </Paper>
    );
  }
}

export default ContainerInvoices;
