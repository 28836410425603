import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "../../../axios-sw";
import { toast } from "react-toastify";

const paragraph = {
  cursor: "pointer",
  fontSize: 10,
  margin: 0,
  marginTop: 5,
  padding: 0,
};

const container = {
  width: "100px",
  textAlign: "center",
  padding: 3,
};

const confirmDialog = (msg) => {
  return new Promise(function (resolve, reject) {
    let confirmed = window.confirm(msg);
    return confirmed ? resolve(true) : reject(false);
  });
};

const ProformaDocumentDropzone = (props) => {
  const [file, setFile] = useState(props.file.url);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "application/*, image/*, text/*",
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      const formData = new FormData();
      formData.append("proforma_document", acceptedFiles[0]);
      axios
        .post("/orders/" + props.order_id + "/proforma-document-update", formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}`, "Content-Type": "application/json" },
        })
        .then((res) => {
          setFile(res.data.proforma_document.url);
          toast.success("Proforma document successfully updated!");
        })
        .catch(() => {
          toast.error("Something went wrong!");
        });
    },
  });

  const removeFunc = () => {
    confirmDialog("You want to remove proforma invoice from the order?").then(() => {
      const formData = new FormData();
      formData.append("proforma_document", null);
      axios
        .post("/orders/" + props.order_id + "/proforma-document-update", formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}`, "Content-Type": "application/json" },
        })
        .then(() => {
          setFile(null);
          toast.success("Proforma document successfully updated!");
        })
        .catch(() => {
          toast.error("Something went wrong!");
        });
    });
  };

  return (
    <section style={container}>
      <div>
        {file !== null ? (
          <a href={file} target="_blank">
            Document
          </a>
        ) : null}
      </div>
      {/*{!props.disabled ?*/}
      <>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {file !== null ? <p style={paragraph}>Change document</p> : <p style={paragraph}>Add document</p>}
        </div>
        <div>
          {file !== null ? (
            <p onClick={() => removeFunc()} style={{ ...paragraph, color: "error" }}>
              Remove
            </p>
          ) : null}
        </div>
      </>
      {/*: null}*/}
    </section>
  );
};

export default ProformaDocumentDropzone;
