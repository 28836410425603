import React, { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import './editTags.css';

const KeyCodes = {
    semicolon: 186,
    enter: 13,
};

const CategoriesTags = ({ columnDef, onChange, value, placeholder }) => {
    const [tags, setTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const suggestions = Object.keys(columnDef.lookup).map((key) => {
            return {
                id: key,
                text: columnDef.lookup[key]
            }
        });
        setTags(value)
        setSuggestions(suggestions)
    }, [])

    const delimiters = [KeyCodes.semicolon, KeyCodes.enter];

    const handleDelete = i => {
        const tagsArr = [...tags].filter((tag, index) => index !== i);

        setTags(tagsArr)
        onChange(tagsArr)
    }

    const handleAddition = (tag) => {
        console.log(tag)
        const tagsArr = [...tags, tag];

        setTags(tagsArr)
        onChange(tagsArr)
    }

    const handleDrag = (tag, currPos, newPos) => {
        const tagsArr = [...tags];
        const newTags = tagsArr.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setTags(newTags)
        onChange(newTags)
    }

    return (
        <div>
            <ReactTags
                tags={tags}
                suggestions={suggestions}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                delimiters={delimiters}
                placeholder={placeholder || "Entry the tag"}
                minQueryLength={1}
                allowDeleteFromEmptyInput={true}
                autocomplete={true}
                readOnly={false}
                allowUnique={true}
                allowDragDrop={true}
                inline={true}
                allowAdditionFromPaste={true}
                editable={true}
            />
        </div>
    )
}

export default CategoriesTags;