import React, { Component } from "react";
import axios from "../../../axios-sw";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../../shared/tableVariables";

import Columns from "../../../shared/tables/accountingTables/invoicesTable";
import InvoiceProducts from "./InvoiceProducts/InvoiceProducts";
import { HotKeys } from "../../../components/System/HotKeys/HotKeys";
import { authRedirect, catchResponse, formatDate, nonAdminRedirect, thenResponse } from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../../shared/defines";
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { GetApp, HighlightOff } from "@material-ui/icons";
import { json2excel } from "js2excel";
import { roleLimitedView } from "../../../shared/utility";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

class Invoices extends Component {
  state = {
    columns: Columns.invoicesColumns,
    data: [],
    filteredData: [],
    parentChildData: "",
    loaded: false,
    tableRef: React.createRef(),
  };

  componentDidMount() {
    const localStorageLimit = localStorage.getItem(`${this.constructor.name}Limit`);
    if (localStorageLimit === null) {
      this.indexInvoices(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    } else {
      this.setState({ limit: parseInt(localStorageLimit) });
      this.indexInvoices(page - 1, localStorageLimit, search, sortingColumnsList, singleColumnFilterList);
    }

    const table = this.state.tableRef.current;
    if (this.props.location.search && this.props.location.search.trim() !== "") {
      table.onSearchChange(this.props.location.search.substr(1));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexInvoices();
    }
  }

  indexInvoices = () => {
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    axios
      .get(
        "/invoices.json",
        { params: { currentSeasons: currentSeasons } },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        const invoices = res.data.map((data) => {
          const amountToPayDp = parseFloat(data.amount_to_pay_dp.replace(/\s/g, "").replace(",", "."));
          const amountToPayLc = parseFloat(data.amount_to_pay_lc.replace(/\s/g, "").replace(",", "."));
          const amountToPayTt = parseFloat(data.amount_to_pay_tt.replace(/\s/g, "").replace(",", "."));

          if (amountToPayDp === 0 && data.payment_terms === "DP") {
            return { ...data, amount_to_pay_dp: data.invoice_value };
          } else if (amountToPayLc === 0 && data.payment_terms === "LC") {
            return { ...data, amount_to_pay_lc: data.invoice_value };
          } else if (amountToPayTt === 0 && data.payment_terms.replace(/\s/g, "").includes("100%TT")) {
            return { ...data, amount_to_pay_tt: data.invoice_value };
          } else {
            return data;
          }
        });
        this.setState({ data: invoices, filteredData: invoices, loaded: true });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  fetchDataProducts = async () => {
    const { data } = this.state;
    const fetchURL = (url) => axios.get(url, { headers: { Authorization: `Bearer ${this.props.token}` } });
    let productsData = [];

    let promiseArray = data.map((item) => {
      return fetchURL(`/products-in-invoice/${item.id}.json`);
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          productsData = [...productsData, ...item.data];
        });
      })
      .catch((err) => {
        toast.error(err);
      });

    return productsData;
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <div>
        <Helmet>
          <title>Invoices | Accounting | Carry System</title>
        </Helmet>

        {authRedirect(this.props.token)}
        {roleLimitedView("accounting", this.props.role, this.props.user_id)}
        <ToastContainer />
        <HotKeys />

        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Invoices")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          data={this.state.filteredData}
          columns={this.state.columns}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <InvoiceProducts token={this.props.token} invoiceId={rowData.id} />
              </div>
            );
          }}
          options={{
            filtering: true,
            pageSize: defaultPageSize,
            exportButton: true,
            exportFileName: "invoices_" + formatDate(Date.now()),
          }}
          onChangeRowsPerPage={(pages) => {
            this.setState({ limit: pages, loaded: false, data: [], filteredData: [] });
            localStorage.setItem(`${this.constructor.name}Limit`, pages);
            this.indexInvoices(
              0,
              pages,
              this.state.filterPhrase,
              this.state.sortingColumnsList,
              this.state.singleColumnFilterList
            );
          }}
          components={{
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              let totalQuantity = 0,
                totalValue = 0;

              for (let i = 0; i < props.data.length; i++) {
                totalQuantity += isNaN(props.data[i].invoice_quantity)
                  ? parseInt(props.data[i].invoice_quantity.replace(/\s/g, ""))
                  : parseInt(props.data[i].invoice_quantity);
                totalValue += isNaN(props.data[i].invoice_value)
                  ? parseFloat(props.data[i].invoice_value.replace(",", ".").replace(/\s/g, ""))
                  : parseFloat(props.data[i].invoice_value);
              }

              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      const table = this.state.tableRef.current;
                      table.onSearchChange("");
                      for (let i = 0; i < table.state.columns.length; i++) {
                        table.onFilterChange(i, null);
                        table.state.columns[i].tableData.filterValue = null;
                      }
                    }}
                  />
                  {/* Eksportowanie danych z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<GetApp style={{ padding: 5 }} />}
                    label="Export data to Excel"
                    onClick={async () => {
                      let invoicesData = this.state.tableRef.current.state.data;
                      let productsData = await this.fetchDataProducts();

                      const filteredInvoicesData = invoicesData.map((invoice) => {
                        return {
                          id: invoice.id,
                          container_number: invoice.container_number,
                          invoice_number: invoice.number,
                          invoice_date: invoice.invoice_date,
                          contact_person: invoice.contact_person,
                          invoice_issuer: invoice.invoice_shipper,
                          invoice_quantity: invoice.invoice_quantity
                            ? parseFloat(invoice.invoice_quantity.replace(/\s/g, "").replace(",", "."))
                            : 0,
                          invoice_value: invoice.invoice_value
                            ? parseFloat(invoice.invoice_value.replace(/\s/g, "").replace(",", "."))
                            : 0,
                          amount_to_pay_dp: invoice.amount_to_pay_dp
                            ? parseFloat(invoice.amount_to_pay_dp.replace(/\s/g, "").replace(",", "."))
                            : 0,
                          amount_to_pay_lc: invoice.amount_to_pay_lc
                            ? parseFloat(invoice.amount_to_pay_lc.replace(/\s/g, "").replace(",", "."))
                            : 0,
                          amount_to_pay_tt: invoice.amount_to_pay_tt
                            ? parseFloat(invoice.amount_to_pay_tt.replace(/\s/g, "").replace(",", "."))
                            : 0,
                          payment_terms: invoice.payment_terms,
                          payment_ref: invoice.payment_ref,
                          eta: invoice.eta,
                          warehouse: invoice.warehouse,
                          bank: invoice.bank,
                          payment_date: invoice.payment_date,
                          dox_received: invoice.dox_received,
                          currency_date: invoice.currency_date,
                          season: invoice.season,
                          payment_ref_lc_number: invoice.payment_ref_lc_number,
                          payment_terms_lc_date: invoice.payment_terms_lc_date,
                          comments: invoice.comments,
                          validated: invoice.validated,
                        };
                      });

                      const filteredProductsData = productsData.map((product) => {
                        return {
                          id: product.product_id,
                          order_number: product.order_number,
                          sex: product.sex,
                          fabric: product.fabric,
                          description: product.description,
                          style_name: product.style_name,
                          quantity_invoice: product.quantity_invoice
                            ? parseFloat(product.quantity_invoice.replace(/\s/g, "").replace(",", "."))
                            : 0,
                          price_invoice: product.price_invoice
                            ? parseFloat(product.price_invoice.replace(/\s/g, "").replace(",", "."))
                            : 0,
                          value: product.value ? parseFloat(product.value.replace(/\s/g, "").replace(",", ".")) : 0,
                        };
                      });

                      json2excel({
                        data: filteredInvoicesData,
                        name: "invoices_" + formatDate(Date.now()),
                        formateDate: "dd/mm/yyyy",
                      });

                      json2excel({
                        data: filteredProductsData,
                        name: "products_" + formatDate(Date.now()),
                        formateDate: "dd/mm/yyyy",
                      });
                    }}
                  />
                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Invoice quantity</TableCell>
                        <TableCell align="right">Invoice value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(totalQuantity)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterCurrency.format(totalValue)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              );
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Invoices);
