import React from "react";
import styled from "styled-components";
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, FormControlLabel } from "@material-ui/core";

import { formatterQuantity, formatterCurrency } from "../../../helpers";

type ForecastsSummaryProps = {
  totals: any;
  onlyLeftToAccept: boolean;
  setOnlyLeftToAccept: (value: boolean) => void;
};

const ForecastsSummary: React.FC<ForecastsSummaryProps> = ({ totals, onlyLeftToAccept, setOnlyLeftToAccept }) => {
  const forecastStates = [
    { title: "Planned", showPercentage: false },
    { title: "Ordered", showPercentage: false },
    { title: "Accepted", showPercentage: true },
    {
      title: "Left to accept",
      showPercentage: true,
      checkbox: (
        <StyledFormControlLabel
          label="Acceptance only"
          control={
            <Checkbox
              checked={onlyLeftToAccept}
              size="small"
              onChange={() => setOnlyLeftToAccept(!onlyLeftToAccept)}
              color="primary"
            />
          }
        />
      ),
    },
    { title: "Left to order", showPercentage: true },
  ];

  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell align="center">Summary</TableCell>
          <TableCell align="right">Quantity</TableCell>
          <TableCell align="right">Value</TableCell>
          <TableCell align="right">Quantity (%)</TableCell>
          <TableCell align="right">Value (%)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {forecastStates.map((forecastState, key) => {
          const formattedForecastState = forecastState.title.toLowerCase().replace(/ /g, "_");
          return (
            <TableRow key={`forecast_summary_row_${key}`}>
              <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                {forecastState.title}
                {forecastState.checkbox}
              </TableCell>
              <TableCell align="right" style={{ width: "25%" }}>
                {formatterQuantity.format(totals[formattedForecastState].quantity)}
              </TableCell>
              <TableCell align="right" style={{ width: "25%" }}>
                {formatterCurrency.format(totals[formattedForecastState].value)}
              </TableCell>
              {forecastState.showPercentage && (
                <>
                  <TableCell align="right" style={{ width: "15%" }}>
                    {`${
                      totals[formattedForecastState].quantityPercentage !== null
                        ? totals[formattedForecastState].quantityPercentage
                        : 0
                    }%`}
                  </TableCell>
                  <TableCell align="right" style={{ width: "15%" }}>
                    {`${
                      totals[formattedForecastState].valuePercentage !== null
                        ? totals[formattedForecastState].valuePercentage
                        : 0
                    }%`}
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: 10px;
  }
`;

const StyledTable = styled(Table)`
  width: 25%;
`;

export default ForecastsSummary;
