import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";

// Axios
import axios from "../../../axios-sw";

// Utilities
import { catchResponse } from "../../../shared/utility";

type ForecastProduct = {
  id: number;
  index: string;
  style_name: string;
  order_id: number;
  order_number: string;
  quantity: string;
  value: string;
};

type ForecastProductsProps = {
  forecastAssortmentGroup: string;
  token: string;
  seasonId: number;
};

const ForecastProducts: React.FC<ForecastProductsProps> = ({ forecastAssortmentGroup, token, seasonId }) => {
  const [data, setData] = useState<ForecastProduct[]>([]);

  const indexForecastProducts = () => {
    axios
      .get(`/products/forecasts/${forecastAssortmentGroup}/products.json`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { season_id: seasonId },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  useEffect(() => {
    indexForecastProducts();
  }, []);

  const tableColumns = ["Product index", "Style name", "Order number", "Quantity per order", "Value per order"];

  return (
    <StyledPaper>
      <h3>Products</h3>
      <Table>
        <TableHead>
          <TableRow>
            {tableColumns.map((column, key) => (
              <TableCell key={`forecast_products_column_${key}`}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((product) => (
            <TableRow key={product.style_name}>
              <TableCell>{product.index}</TableCell>
              <TableCell>{product.style_name}</TableCell>
              <TableCell>
                <Link to={`/orders/${product.order_id}`}>{product.order_number}</Link>
              </TableCell>
              <TableCell>{product.quantity}</TableCell>
              <TableCell>{product.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  h3 {
    padding: 15px;
  }
`;

export default ForecastProducts;
