import React from 'react';

import classes from './Footer.module.scss';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

const footer = (props) => (
    <footer className={classes.Footer}>
        <Container maxWidth='sm'>
            <Typography variant='body1'>System wewnętrzny Carry {new Date().getFullYear()}</Typography>
            <a style={{fontSize: 10, textDecoration: 'none', color: 'inherit'}} href="https://ulam.tech">
                ULAM Tech
            </a>
        </Container>
    </footer>
);

export default footer;