import * as actionTypes from "../actions/actionTypes";
import { timeRemaining } from "../../shared/defines";

//INITIAL STATE
const initialState = {
  currentSeasons: JSON.parse(window.localStorage.getItem(`user_seasons_${localStorage.getItem("user_id")}`)) || [
    { value: 4, label: "S21" },
  ],
  timeRemaining: timeRemaining || 1000 * 60 * 15,
};

//ACTIONS
export const setSeasons = (seasons) => ({ type: actionTypes.SET_SEASON, seasons });
export const setTimeRemaining = (timeRemaining) => ({ type: actionTypes.SET_TIME_REMAINING, timeRemaining });

//REDUCER
const systemInfoReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_SEASON:
      return {
        ...state,
        currentSeasons: action.seasons,
      };
    case actionTypes.SET_TIME_REMAINING:
      return {
        ...state,
        timeRemaining: action.timeRemaining,
      };
    default:
      return state;
  }
};

export default systemInfoReducer;
