import {checkDateRange, sortByDate} from "../helperTables";
import FilterDateRange from "../../../components/System/FilterDateRange/FilterDateRange";
import React from "react";

export default {
    invoicesColumns: [
        {title: 'Invoice ID', field: 'id', hidden: true, editable: 'never'},
        {title: 'Container number', field: 'container_number', editable: 'never'},
        {title: 'Invoice number', field: 'number', editable: 'never'},
        {
            title: 'Invoice date',
            field: 'invoice_date',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.invoice_date)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'invoice_date')
        },
        {title: 'Contact person', field: 'contact_person', editable: 'never'},
        {title: 'Invoice issuer', field: 'invoice_shipper', editable: 'never'},
        {
            title: 'Invoice quantity',
            field: 'invoice_quantity',
            editable: 'never',
            customSort: (a, b) => parseInt(a.invoice_quantity.replace(" ", "")) - parseInt(b.invoice_quantity.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Invoice value',
            field: 'invoice_value',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.invoice_value.replace(" ", "")) - parseFloat(b.invoice_value.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Amount to pay DP', field: 'amount_to_pay_dp',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.amount_to_pay_dp.replace(" ", "")) - parseFloat(b.amount_to_pay_dp.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Amount to pay LC', field: 'amount_to_pay_lc',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.amount_to_pay_lc.replace(" ", "")) - parseFloat(b.amount_to_pay_lc.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Amount to pay TT', field: 'amount_to_pay_tt',
            editable: 'never',
            customSort: (a, b) => parseFloat(a.amount_to_pay_tt.replace(" ", "")) - parseFloat(b.amount_to_pay_tt.replace(" ", "")),
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "right",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Payment terms',
            field: 'payment_terms',
            editable: 'never',
            render: rowData => {
                let date;
                if (typeof rowData !== 'undefined') {
                    rowData.payment_terms === 'LC' ? date = rowData.payment_terms_lc_date : date = rowData.payment_date;
                    return (
                        <div>
                            <p>{rowData.payment_terms}</p>
                            {date !== null || '' ? <p style={{marginTop: -10, fontSize: 10}}>({date})</p> : null}
                        </div>
                    )
                }
            },
            headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px"
            },
            cellStyle: {
                textAlign: "center",
            },
            filterCellStyle: {
                paddingRight: "0",
            }
        },
        {
            title: 'Payment ref.',
            field: 'payment_ref',
            editable: 'never',
            render: rowData => {
                let payment;
                if (typeof rowData !== 'undefined') {
                    rowData.payment_ref_lc_number === null ? payment = rowData.payment_ref : payment = rowData.payment_ref_lc_number;
                    return (
                        <div>
                            <p>{payment}</p>
                        </div>
                    )
                }
            },
        },
        {
            title: 'ETA',
            field: 'eta',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.eta)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'eta')
        },
        {
            title: 'Warehouse',
            field: 'warehouse',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.warehouse)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'warehouse')
        },
        {title: 'Bank', field: 'bank', type:'boolean', editable: 'never'},
        {
            title: 'Payment date',
            field: 'payment_date',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.payment_date)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'payment_date')
        },
        {
            title: 'Dox received',
            field: 'dox_received',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.dox_received)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'dox_received')
        },
        {
            title: 'Currency date',
            field: 'currency_date',
            editable: 'never',
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return (
                    <FilterDateRange onChange={onChange}/>
                )
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.currency_date)
            },
            customSort: (a,b,c,d) => sortByDate(a,b,d,'currency_date')
        },
        {title: 'Season', field: 'season', editable: 'never'},
        {title: 'Comments', field: 'comments', editable: 'never'},
        {title: 'Validated', field: 'validated', type: 'boolean', editable: 'never'},
    ]
}