import React, { Component } from "react";
import axios from "../../axios-sw";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import MaterialTable from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import Columns from "../../shared/tables";

import { ToastContainer } from "react-toastify";
import { catchResponse, indexDetails, thenResponse } from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";

class Admin extends Component {
  state = {
    columns: Columns.adminColumns,
    data: [],
    loaded: false,
  };

  componentDidMount() {
    this.indexAccounts();
  }

  indexAccounts = () => {
    axios
      .get("/users.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        indexDetails("roles", 4, "name", this.props.token, this.state.columns, (newState) =>
          this.setState({ newState })
        );
        this.setState({ data: res.data, loaded: true });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  onUpdateHandler = (id, newData) => {
    const data = {
      user: newData,
      user_id: this.props.userId,
      ip_address: this.props.ip_address,
      role_id: newData.role_id,
    };
    const url = "/users/" + id + ".json";
    axios
      .post(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexAccounts();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  onAccountDeleteHandler = (id) => {
    const data = {
      user_id: this.props.userId,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/users/" + id + ".json", { data: data, headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexAccounts();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  onAccountCreate = (newData) => {
    let password = "",
      b = "abcdefghijklmnopqrstuvwxyz1234567890";
    for (let ma = 0; ma < 10; ma++) {
      password += b[Math.floor(Math.random() * b.length)];
    }
    const data = {
      user: {
        email: newData.email,
        name: newData.name,
        surname: newData.surname,
        password: password,
        password_confirmation: password,
      },
      role_id: newData.role_id,
      user_id: this.props.userId,
      ip_address: this.props.userId,
    };
    axios
      .post("/signup", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexAccounts();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    let adminRedirect = null;
    if (this.props.role !== "admin") {
      adminRedirect = <Redirect to="/" />;
    }

    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        <Helmet>
          <title>Admin | Carry System</title>
        </Helmet>

        {adminRedirect}
        <ToastContainer />
        <MaterialTable
          title={titleTemplate("Accounts list", "Available to update or create account for the system users")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={this.state.data}
          options={{
            pageSize: 15,
            exportButton: false,
            search: false,
          }}
          editable={{
            // TODO: Przygotować logikę pod możliwość edytowania kont
            /*onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                resolve();
                                this.onUpdateHandler(oldData.id, newData);
                            }),*/
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                resolve();
                this.onAccountCreate(newData);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                resolve();
                this.onAccountDeleteHandler(oldData.id);
              }),
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user_id,
    role: state.auth.role,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(Admin);
