import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";

import axios from "../../../axios-sw";
import DetailTable from "./DetailTable/DetailTable";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { authRedirect } from "../../../shared/utility";
import Columns from "../../../shared/tables";
class DesignerProductDetails extends Component {
  state = {
    columns: Columns.productDetailsColumns,
  };

  componentDidMount() {
    this.indexOptions();
  }

  indexOptions = () => {
    let product_options = [];
    let product_option_values = [];
    const params = {
      output_format: "JSON",
      ws_key: "WR5NPJ885P44DWTF99H6WA173SPX9R8Z",
      display: "full",
    };

    const setOptions = (object) => {
      let map = new Map();
      for (var i = 0; i < object.options.length; i++) {
        if (object.name === "Kolor") {
          map.set(object.options[i]["id"], object.options[i]["name"]);
        } else {
          map.set(object.options[i]["id"], `${object.options[i]["name"]} (${object.name})`);
        }
      }
      let obj = Object.fromEntries(map);
      let newColumns = { ...this.state.columns };

      switch (object.name) {
        case "Kolor":
          newColumns["colors"][
            this.state.columns["colors"].findIndex((column) => column.field === "shop_color")
          ].lookup = obj;
          newColumns["sub_colors"][
            this.state.columns["sub_colors"].findIndex((column) => column.field === "shop_color")
          ].lookup = obj;
          break;
        default:
          break;
      }

      this.setState({ columns: newColumns });
    };

    axios.get(`https://carry.pl/api/product_options`, { params: params }).then((res) => {
      product_options = res.data.product_options;
      axios.get(`https://carry.pl/api/product_option_values`, { params: params }).then((res) => {
        product_option_values = res.data.product_option_values;

        for (var i = 0; i < product_options.length; i++) {
          for (var j = 0; j < product_option_values.length; j++) {
            if (product_options[i]["id"] === parseInt(product_option_values[j]["id_attribute_group"])) {
              if (typeof product_options[i]["options"] !== "undefined") {
                product_options[i]["options"] = [...product_options[i]["options"], product_option_values[j]];
              } else {
                product_options[i]["options"] = [product_option_values[j]];
              }
            }
          }

          setOptions(product_options[i]);
        }
      });
    });
  };

  render() {
    let content = (
      <Grid container spacing={3}>
        {authRedirect(this.props.token)}
        <ToastContainer />

        <DetailTable
          classTitle="colors" // COLOURS
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          columns={this.state.columns}
          classParams={(newData) => {
            return {
              color: {
                title: newData.title == null ? null : newData.title.toUpperCase(),
                title_pl: newData.title_pl == null ? null : newData.title_pl.toUpperCase(),
                shop_color: typeof newData.shop_color.id === "undefined" ? null : parseInt(newData.shop_color.id),
              },
            };
          }}
        />
      </Grid>
    );

    if (this.props.token === null) {
      content = <Spinner />;
    }

    return (
      <>
        <Helmet>
          <title>Designer | Product details | Carry System</title>
        </Helmet>

        {content}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(DesignerProductDetails);
