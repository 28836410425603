import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Barcode from "react-barcode";
import { Carousel } from "react-responsive-carousel";
import { Input, MenuItem, Select, Tooltip } from "@material-ui/core";

import PreviewDropzone from "../components/System/PreviewDropzone/PreviewDropzone";
import ProformaDocumentDropzone from "../components/System/ProformaDocumentDropzone/ProformaDocumentDropzone";
import FilterDateRange from "../components/System/FilterDateRange/FilterDateRange";
import {
  cellStyle,
  checkboxCellStyle,
  checkboxHeaderStyle,
  checkDateRange,
  editComponent,
  headerStyle,
  sortByDate,
  multiEditSeasonComponent,
  editColorsComponent,
} from "./tables/helperTables";
import FilterInput from "../components/System/FilterInput/FilterInput";
import MultiSelect from "../components/System/MultiSelect/MultiSelect";
import CustomTooltip from "../components/UI/CustomTooltip/CustomTooltip";
import EditTags from "../components/System/EditTags/EditTags";
import CategoriesTags from "../components/System/CategoriesTags/CategoriesTags";

export default {
  vm: {
    orders: [
      { title: "ID", field: "id", editable: "never", hidden: true },
      {
        title: "Order number",
        editable: "never",
        field: "number",
        render: (rowData) => {
          return (
            <div>
              <Link to={"/orders/" + rowData.id}>{rowData.number}</Link>
            </div>
          );
        },
      },
      {
        title: "Accepted",
        field: "accepted",
        defaultFilter: "all",
        filterComponent: (term) => {
          return (
            <Select
              value={term.columnDef.tableData.filterValue}
              onChange={(e) => {
                term.columnDef.tableData.filterValue = e.target.value;
                term.onFilterChanged(0, e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="empty">Empty</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="hold">Hold</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
            </Select>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "empty"
            ? rowData.accepted === null
            : term === "cancel"
            ? rowData.accepted === "CANCEL"
            : term === "hold"
            ? rowData.accepted === "HOLD"
            : term === "accepted"
            ? rowData.accepted !== null &&
              rowData.accepted !== "" &&
              rowData.accepted !== "CANCEL" &&
              rowData.accepted !== "HOLD"
            : rowData.accepted !== null
            ? rowData.accepted.includes(term)
            : null,
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b, c, d) => sortByDate(a, b, d, "accepted"),
      },
      { title: "Contact person", field: "contact", editable: "never" },
      { title: "Company", field: "company", editable: "never" },
      { title: "Designer", field: "designer_code", editable: "never" },
      { title: "Season", field: "season_id" },
      {
        title: "Shipment date",
        field: "shipment_date",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
      },
      {
        title: "Country",
        field: "country",
        lookup: {
          BANGLADESH: "BANGLADESH",
          CHINA: "CHINA",
          INDIA: "INDIA",
          LITWA: "LITWA",
          MYANMAR: "MYANMAR",
          PAKISTAN: "PAKISTAN",
          POLSKA: "POLSKA",
        },
      },
      { title: "Final order", field: "final_order", type: "boolean" },
      {
        title: "Total quantity",
        field: "total_quantity",
        editable: "never",
        customSort: (a, b) => parseInt(a.total_quantity.replace(" ", "")) - parseInt(b.total_quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
    ],
    orderProducts: [
      { title: "ID", field: "id", hidden: true },
      {
        title: "Style name",
        field: "style_name",
        editable: "never",
        headerStyle: {
          paddingRight: "100px",
        },
      },
      { title: "Index", field: "index", editable: "never" },
      { title: "Sex", field: "sex", editable: "never" },
      { title: "Accessory", field: "accessory", type: "boolean", editable: "never" },
      { title: "Fabric", field: "fabric", editable: "never" },
      { title: "Description", field: "description", editable: "never" },
      { title: "HZ description", field: "hz_description", editable: "never" },
      {
        title: "Quantity per order",
        field: "quantity",
        editable: "never",
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
    ],
    products: [
      { title: "Product id", field: "id", editable: "never", hidden: true },
      {
        title: "Accepted",
        field: "accepted",
        editable: "never",
        defaultFilter: "all",
        type: "select",
        filterComponent: (term) => {
          return (
            <Select
              value={term.columnDef.tableData.filterValue}
              onChange={(e) => {
                term.columnDef.tableData.filterValue = e.target.value;
                term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="empty">Empty</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="hold">Hold</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
            </Select>
          );
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "accepted"),
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "empty"
            ? rowData.accepted === null
            : term === "cancel"
            ? rowData.accepted === "CANCEL"
            : term === "hold"
            ? rowData.accepted === "HOLD"
            : term === "accepted"
            ? rowData.accepted !== null &&
              rowData.accepted !== "" &&
              rowData.accepted !== "CANCEL" &&
              rowData.accepted !== "HOLD"
            : rowData.accepted !== null
            ? rowData.accepted.includes(term)
            : null,
      },
      {
        title: "Contact person",
        field: "contact_name",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.contact_name);
        },
      },
      {
        title: "Company",
        field: "company",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.company);
        },
      },
      {
        title: "Designer",
        field: "designer",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.designer);
        },
      },
      {
        title: "Season",
        field: "season",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.season);
        },
      },
      {
        title: "Order number",
        field: "order_number",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.order_number);
        },
        render: (rowData) => {
          return (
            <div>
              <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
            </div>
          );
        },
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "70px",
        },
      },
      {
        title: "Style name",
        field: "style_name",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.style_nam);
        },
        render: (rowData) => {
          return (
            <Tooltip
              arrow
              placement="bottom"
              title={
                rowData.image !== null ? (
                  <img style={{ padding: 5, width: "200px", height: "auto" }} src={rowData.image} />
                ) : (
                  <p>No preview photo</p>
                )
              }
            >
              <span style={{ textDecoration: "underline dotted", cursor: "pointer" }}>{rowData.style_name}</span>
            </Tooltip>
          );
        },
        headerStyle: {
          paddingRight: "100px",
        },
      },
      {
        title: "Index",
        field: "index",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.index);
        },
        editComponent: (props) => editComponent(props, true),
      },
      {
        title: "Store Price",
        field: "store_price",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.index);
        },
        editComponent: (props) => editComponent(props, true),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Shipping date to stores",
        field: "shipping_date_to_stores",
        type: "dateType",
        editable: "never",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipping_date_to_stores);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipping_date_to_stores"),
      },
      {
        title: "Barcode check",
        field: "barcode_check",
        type: "boolean",
      },
      {
        title: "Port",
        field: "port",
        editable: "never",
        type: "multiSelect",
      },
      {
        title: "Quantity per order",
        field: "quantity",
        editable: "never",
        type: "number",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.quantity);
        },
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Shipment date as per order",
        field: "shipment_date",
        type: "dateType",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
        editComponent: (props) => editComponent(props, false),
        headerStyle: {
          textAlign: "center",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "center",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      { title: "Container Number", field: "container_number" },
      {
        title: "ETA",
        field: "eta",
        type: "dateType",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.eta);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "eta"),
      },
      {
        title: "Warehouse",
        field: "warehouse",
        type: "dateType",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.warehouse);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
      },
      {
        title: "M",
        field: "m",
        type: "boolean",
      },
      {
        title: "Sex",
        field: "sex",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.sex);
        },
      },
      { title: "Description", type: "multiSelect", field: "description", editable: "never" },
      {
        title: "Fabric",
        field: "fabric_title",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.sex);
        },
      },
      {
        title: "Comments",
        type: "text",
        field: "comments",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.comments);
        },
        editComponent: (props) => editComponent(props, false),
      },
    ],
    cancelledProducts: [
      { title: "Product id", field: "id", editable: "never", hidden: true },
      {
        title: "Accepted",
        field: "accepted",
        editable: "never",
        defaultFilter: "all",
        filterComponent: (term) => {
          return (
            <Select
              value={term.columnDef.tableData.filterValue}
              onChange={(e) => {
                term.columnDef.tableData.filterValue = e.target.value;
                term.onFilterChanged(2, e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="empty">Empty</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="hold">Hold</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
            </Select>
          );
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "accepted"),
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "empty"
            ? rowData.accepted === null
            : term === "cancel"
            ? rowData.accepted === "CANCEL"
            : term === "hold"
            ? rowData.accepted === "HOLD"
            : term === "accepted"
            ? rowData.accepted !== null &&
              rowData.accepted !== "" &&
              rowData.accepted !== "CANCEL" &&
              rowData.accepted !== "HOLD"
            : rowData.accepted !== null
            ? rowData.accepted.includes(term)
            : null,
      },
      { title: "Contact person", field: "contact_name", editable: "never" },
      { title: "Company", field: "company", editable: "never" },
      { title: "Designer", field: "designer", editable: "never" },
      { title: "Season", field: "season", editable: "never" },
      {
        title: "Order number",
        field: "order_number",
        editable: "never",
        render: (rowData) => {
          return (
            <div>
              <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
            </div>
          );
        },
      },
      {
        title: "Style name",
        field: "style_name",
        editable: "never",
        headerStyle: {
          paddingRight: "100px",
        },
      },
      { title: "Index", field: "index", editComponent: (props) => editComponent(props, true) }, // EDITABLE
      { title: "Barcode check", field: "barcode_check", type: "boolean" }, // EDITABLE
      { title: "Port", field: "port", editable: "never" },
      {
        title: "Quantity per order",
        field: "quantity",
        editable: "never",
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Shipment date as per order",
        field: "shipment_date",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
      }, // EDITABLE
      { title: "M", field: "m", type: "boolean" }, // EDITABLE
      { title: "Accessory", field: "accessory", type: "boolean", editable: "never" },
      { title: "Comments", field: "comments", editComponent: (props) => editComponent(props, false) },
    ],
    bookings: [
      {
        title: "Accepted",
        field: "accepted",
        editable: "never",
        defaultFilter: "all",
        filterComponent: (term) => {
          return (
            <Select
              value={term.columnDef.tableData.filterValue}
              onChange={(e) => {
                term.columnDef.tableData.filterValue = e.target.value;
                term.onFilterChanged(2, e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="empty">Empty</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="hold">Hold</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
            </Select>
          );
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "accepted"),
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "empty"
            ? rowData.accepted === null
            : term === "cancel"
            ? rowData.accepted === "CANCEL"
            : term === "hold"
            ? rowData.accepted === "HOLD"
            : term === "accepted"
            ? rowData.accepted !== null &&
              rowData.accepted !== "" &&
              rowData.accepted !== "CANCEL" &&
              rowData.accepted !== "HOLD"
            : rowData.accepted !== null
            ? rowData.accepted.includes(term)
            : null,
      },
      { title: "ID", field: "id", hidden: true },
      {
        title: "Style name",
        field: "style_name",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.style_name);
        },
        render: (rowData) => {
          return (
            <Tooltip
              arrow
              placement="bottom"
              title={
                rowData.image !== null ? (
                  <img style={{ padding: 5, width: "200px", height: "auto" }} src={rowData.image} />
                ) : (
                  <p>No preview photo</p>
                )
              }
            >
              <span style={{ textDecoration: "underline dotted", cursor: "pointer" }}>{rowData.style_name}</span>
            </Tooltip>
          );
        },
        headerStyle: {
          paddingRight: "100px",
        },
      },
      {
        title: "Index",
        field: "index",
        editable: "never",
        defaultFilter: "all",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "unadded"
            ? rowData.index === null
            : term === "added"
            ? rowData.index !== null
            : rowData.index !== null
            ? rowData.index.includes(term)
            : null,
      },
      {
        title: "Order number",
        field: "order_number",
        editable: "never",
        type: "text",
        render: (rowData) => {
          return (
            <div>
              <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
            </div>
          );
        },
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Sex",
        field: "sex",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Fabric",
        field: "fabric",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Description",
        field: "description",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Shipment per order",
        field: "shipment_date",
        editable: "never",
        type: "dateType",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
      },
      {
        title: "Shipper name",
        field: "contact_name",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Port",
        field: "port",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Quantity per order",
        field: "quantity",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Booking date",
        field: "booking_date",
        type: "dateType",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.booking_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "booking_date"),
      },
      {
        title: "Readiness date",
        field: "readiness_date",
        type: "dateType",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.readiness_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "readiness_date"),
      },
      {
        title: "Container Number",
        field: "container_number",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "ETA",
        field: "eta",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Warehouse",
        field: "warehouse",
        editable: "never",
        type: "dateType",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.warehouse);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
      },
      {
        title: "Forwarder confirm date",
        field: "forwarder_confirm_date",
        type: "dateType",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.forwarder_confirm_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "forwarder_confirm_date"),
      },
    ],
    containers: [
      { title: "Container number", field: "number", editComponent: (props) => editComponent(props, true) },
      { title: "Container type", field: "container_type", editComponent: (props) => editComponent(props, false) },
      {
        title: "ETD",
        field: "etd",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.etd);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "etd"),
      },
      {
        title: "ETA",
        field: "eta",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.eta);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "eta"),
      },
      {
        title: "Warehouse",
        field: "warehouse",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.warehouse);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
      },
      { title: "Delivered", field: "supplied", type: "boolean" },
      { title: "Comments", field: "comments", editComponent: (props) => editComponent(props, false) },
      { title: "Season", field: "season", editable: "never" },
      {
        title: "File",
        field: "file",
        editable: "always",
        editComponent: (props) => (
          <PreviewDropzone
            file={props.rowData.file}
            onDelete={() => {
              props.rowData.file = null;
            }}
            onChange={(files) => {
              props.rowData.file = files[0];
            }}
          />
        ),
        render: (data) =>
          data.file ? (
            <PreviewDropzone previewFiles={data.file} />
          ) : (
            <p style={{ fontStyle: "italic" }}>No file uploaded</p>
          ),
      },
    ],
    containerInvoicesColumns: [
      { title: "Container invoice id", field: "id", hidden: true },
      { title: "Shipper", field: "shipper", editComponent: (props) => editComponent(props, true) },
      { title: "Contact person", field: "contact_person", editComponent: (props) => editComponent(props, false) },
      {
        title: "CTNS",
        field: "ctns",
        editComponent: (props) => editComponent(props, false),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "CBM",
        field: "cbm",
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b) => parseFloat(a.cbm.replace(" ", "")) - parseFloat(b.cbm.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Quantity in pcs",
        field: "quantity_in_pcs",
        editable: "never",
        customSort: (a, b) =>
          parseInt(a.quantity_in_pcs.replace(" ", "")) - parseInt(b.quantity_in_pcs.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "NET",
        field: "net",
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b) => parseFloat(a.net.replace(" ", "")) - parseFloat(b.net.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "GROSS",
        field: "gross",
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b) => parseFloat(a.gross.replace(" ", "")) - parseFloat(b.gross.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Average carton weight",
        field: "average_carton_weight",
        editable: "never",
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Average item weight",
        field: "average_item_weight",
        editable: "never",
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Documents",
        field: "documents_approved",
        type: "boolean",
        headerStyle: {
          textAlign: "center",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "center",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Originals approved",
        field: "originals_approved_date",
        editComponent: (props) => editComponent(props, false),
      },
      {
        title: "File",
        field: "file",
        editable: "always",
        editComponent: (props) => (
          <PreviewDropzone
            file={props.rowData.file}
            onChange={(files) => {
              props.rowData.file = files ? files[0] : null;
            }}
          />
        ),
        render: (data) =>
          data.file ? (
            <PreviewDropzone previewFiles={data.file} />
          ) : (
            <p style={{ fontStyle: "italic" }}>No file uploaded</p>
          ),
      },
    ],
  },

  modelsColorsColumns: [
    { title: "Model Color Code", field: "model_color_code", editable: "never" },
    { title: "Description", field: "description", editable: "never" },
    { title: "Sex", field: "sex", editable: "never" },
    { title: "Quantity", field: "quantity", editable: "never" },
    {
      title: "Shipment Date",
      field: "shipment_date",
      editable: "never",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.shipment_date);
      },
    },
    {
      title: "Forwarder Confirm Date",
      field: "forwarder_confirm_date",
      editable: "never",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.forwarder_confirm_date);
      },
    },
    { title: "Is Session", field: "is_session", editable: "never", type: "boolean" },
    { title: "Photo Form", field: "photo_form", editable: "never" },
    {
      title: "Photo Pattern",
      field: "photo_pattern",
      editable: "never",
    },
    { title: "Composition", field: "composition", editable: "never" },
    { title: "Colour For Website", field: "colour_for_website", editable: "never" },
    { title: "Product Color", field: "color_title", editable: "never" },
    { title: "Fit Slim Regular", field: "fit_slim_regular", editable: "never" },
    { title: "Product Description", field: "product_description", editable: "never" },
    { title: "Accepted", field: "accepted", editable: "never", type: "boolean" },
    { title: "Season", field: "season", editable: "never" },
    { title: "Date Of Create Photo", field: "date_of_create_photo", editable: "never" },
  ],

  modelsColorsIndexesColumns: [
    { title: "Model Color Index Code", field: "model_color_index_code", editable: "never" },
    { title: "Quantity", field: "quantity", editable: "never" },
  ],

  collectionsColumns: [
    {
      title: "Index",
      field: "index",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Season",
      field: "season",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Style Name",
      field: "style_name",
      editable: "never",
      headerStyle: {
        paddingRight: "100px",
      },
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Collection Ref",
      field: "collection_ref",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Main Color",
      field: "main_color",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "For Hz",
      field: "for_hz",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Size",
      field: "size",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Quantity",
      field: "quantity",
      editable: "never",
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
  ],

  // Strona orders
  ordersColumns: [
    {
      title: "Accepted",
      field: "accepted",
      type: "multiSelect",
      filterComponent: (term) => {
        return (
          <MultiSelect
            value={term.columnDef.tableData.initialValue || ["all"]}
            onChangeValue={(values) => {
              console.log(values);
              term.onFilterChanged(term.columnDef.tableData.id, values);
            }}
            options={["all", "empty", "cancel", "hold", "accepted"]}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        term === "all"
          ? rowData
          : term === "empty"
          ? rowData.accepted === null
          : term === "cancel"
          ? rowData.accepted === "CANCEL"
          : term === "hold"
          ? rowData.accepted === "HOLD"
          : term === "accepted"
          ? rowData.accepted !== null &&
            rowData.accepted !== "" &&
            rowData.accepted !== "CANCEL" &&
            rowData.accepted !== "HOLD"
          : rowData.accepted !== null
          ? rowData.accepted.includes(term)
          : null,
      editComponent: (props) => editComponent(props, false),
    },
    {
      title: "Order number",
      field: "number",
      editable: "never",
      editComponent: (props) => editComponent(props, true),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <div>
            <Link to={"/orders/" + rowData.id}>{rowData.number}</Link>
          </div>
        );
      },
    },
    {
      title: "Contact person",
      field: "contact",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Company",
      field: "company",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Designer",
      field: "designer_code",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Season",
      field: "season_id",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    { title: "Port", field: "port", type: "multiSelect" }, // Zmienione z port_id na potrzeby sortowania po nazwie
    {
      title: "Shipment date",
      field: "shipment_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.shipment_date);
      },
    },
    {
      title: "Country",
      field: "country",
      type: "multiSelect",
      lookup: {
        BANGLADESH: "BANGLADESH",
        CHINA: "CHINA",
        INDIA: "INDIA",
        LITWA: "LITWA",
        MYANMAR: "MYANMAR",
        PAKISTAN: "PAKISTAN",
        POLSKA: "POLSKA",
      },
    },
    { title: "Payment", field: "payment_term_id", type: "multiSelect" },
    {
      title: "LC number",
      field: "lc_number",
      editable: "onUpdate",
      editComponent: (props) => editComponent(props, false),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Total quantity",
      field: "total_quantity",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Total value (USD)",
      field: "total_value",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    { title: "Final order", field: "final_order", type: "boolean" },
    {
      title: "Proforma document",
      field: "proforma_document",
      defaultFilter: "all",
      type: "select",
      filterComponent: (term) => {
        return (
          <Select
            value={term.columnDef.tableData.filterValue}
            onChange={(e) => {
              term.columnDef.tableData.filterValue = e.target.value;
              term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="unadded">Unadded</MenuItem>
            <MenuItem value="added">Added</MenuItem>
          </Select>
        );
      },
      customFilterAndSearch: (term, rowData) =>
        term === "all"
          ? rowData.proforma_document
          : term === "unadded"
          ? rowData.proforma_document.url === null
          : term === "added"
          ? rowData.proforma_document.url !== null
          : null,
      editComponent: () => <p style={{ fontSize: 9, textAlign: "center" }}>Editable just on the typical view!</p>,
      render: (props) => <ProformaDocumentDropzone order_id={props.id} file={props.proforma_document} />,
    },
    { title: "Barcodes in process", field: "barcodes_in_process", type: "boolean" },
  ],

  barcodesCombinationsColumns: [
    { title: "Hz id / Index", field: "hz_id", type: "text", editable: "never" },
    { title: "Barcode", field: "barcode", type: "text", editable: "never" },
    { title: "Article (Hz Title)", field: "hz_title", type: "text", editable: "never" },
    { title: "Collection Ref.", field: "collection_ref", type: "text", editable: "never" },
    { title: "Style Name", field: "style_name", type: "text", editable: "never" },
    { title: "Store Price PLN", field: "store_price", type: "text", editable: "never" },
    { title: "Color For Hz", field: "color_hz", type: "text", editable: "never" },
    { title: "Size", field: "size", type: "text", editable: "never" },
    { title: "Error", field: "error", type: "text", editable: "never" },
    {
      title: "Comments",
      field: "barcode_comments",
      type: "text",
      editComponent: (props) => editComponent(props, false),
    },
  ],
  barcodesOrdersColumns: [
    {
      title: "Order number",
      field: "number",
      editable: "never",
      editComponent: (props) => editComponent(props, true),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <div>
            <Link to={"/orders/" + rowData.id}>{rowData.number}</Link>
          </div>
        );
      },
    },
    {
      title: "Contact person",
      field: "contact",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Company",
      field: "company",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Designer",
      field: "designer_code",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Season",
      field: "season_id",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Shipment Date",
      field: "shipment_date",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Barcode Status",
      field: "barcode_status",
      editable: "never",
      type: "select",
      filterComponent: (term) => {
        return (
          <Select
            value={term.columnDef.tableData.filterValue}
            onChange={(e) => {
              term.columnDef.tableData.filterValue = e.target.value;
              term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
            }}
          >
            <MenuItem value="ready">Ready to accept</MenuItem>
            <MenuItem value="in_progress">In progress</MenuItem>
            <MenuItem value="error">Error</MenuItem>
            <MenuItem value="success">Success</MenuItem>
          </Select>
        );
      },
      render: (rowData) => {
        return (
          <div>
            {rowData.barcode_status === "ready" && <p style={{ color: "#465798" }}>Ready to accept</p>}
            {rowData.barcode_status === "in_progress" && <p style={{ color: "#ffc000" }}>In progress</p>}
            {rowData.barcode_status === "error" && <p style={{ color: "#C00000" }}>Error</p>}
            {rowData.barcode_status === "success" && <p style={{ color: "darkgreen" }}>Success</p>}
          </div>
        );
      },
    },
    {
      title: "Sent to supplier",
      field: "sent_to_supplier",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sent_to_supplier);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "sent_to_supplier"),
    },
    {
      title: "Ready To Sync",
      field: "ready_to_sync",
      type: "boolean",
      editable: (term, rowData) =>
        (rowData && rowData.barcode_status === "ready") ||
        rowData.barcode_status === "success" ||
        rowData.barcode_status === "in_progress",
    },
  ],

  multipacksColumns: [
    { title: "ID", field: "id", hidden: true },
    {
      title: "Barcode multi",
      field: "barcode_multi",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Barcode Check",
      field: "barcode_check",
      type: "boolean",
    },
    {
      title: "Contact person",
      field: "contact_person",
      type: "text",
      editable: "never",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Quantity of multi",
      field: "quantity_of_multi",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, true),
    },
    {
      title: "Total pcs per one ctn",
      field: "total_pcs_per_one_ctn",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Total pcs",
      field: "total_pcs",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Sent to shipper",
      field: "sent_to_shipper",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sent_to_supplier);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "sent_to_shipper"),
    },
    {
      title: "Confirmed by shipper",
      field: "confirmed_by_shipper",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sent_to_supplier);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "confirmed_by_shipper"),
    },
    { title: "Shipped", field: "shipped", type: "boolean" },
    {
      title: "Season",
      field: "season",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Attachment",
      field: "attachment",
      editable: "always",
      type: "text",
      filtering: false,
      editComponent: (props) => (
        <PreviewDropzone
          file={props.rowData.attachment}
          onChange={(files) => {
            props.rowData.attachment = files ? files[0] : null;
          }}
        />
      ),
      render: (data) =>
        data.attachment ? (
          <PreviewDropzone previewFiles={data.attachment} />
        ) : (
          <p style={{ fontStyle: "italic" }}>No file uploaded</p>
        ),
    },
    {
      title: "Dimesion of carton",
      field: "dimesion_of_carton",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Comments",
      field: "comments",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
  ],
  multipackProductsColumns: [
    { title: "ID", field: "id", hidden: true },
    { title: "Order number", field: "order_number", editable: "never" },
    { title: "Style Name", field: "style_name", editable: "never" },
    { title: "Index", field: "index", editable: "never" },
    { title: "Quantity per each style, per one carton", field: "quantity_per_each_style_per_one_carton" },
    {
      title: "Quantity of each style in all cartons",
      field: "quantity_of_each_style_in_all_cartons",
      editable: "never",
    },
  ],
  ordersProductsColumns: [
    { title: "ID", field: "id", hidden: true },
    {
      title: "Style name",
      field: "style_name",
      editable: "never",
      headerStyle: {
        paddingRight: "100px",
      },
    },
    { title: "Index", field: "index", editable: "never" },
    { title: "Sex", field: "sex", editable: "never" },
    { title: "Accessory", field: "accessory", type: "boolean", editable: "never" },
    { title: "Fabric", field: "fabric", editable: "never" },
    { title: "Description", field: "description", editable: "never" },
    { title: "HZ description", field: "hz_description", editable: "never" },
    {
      title: "Quantity per order",
      field: "quantity",
      editable: "never",
      customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Total value (USD)",
      field: "total",
      editable: "never",
      customSort: (a, b) => parseFloat(a.total.replace(" ", "")) - parseFloat(b.total.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
  ],

  // Definicja wszystkich kolumn związanych z formularzem dla projektantek
  designerFormTables: {
    designerOrders: [
      /* Tabela nieedytowalna tylko do podglądu i filtrowania */
      { title: "Order accepted", field: "accepted" },
      { title: "Order number", field: "number", editable: "never" },
      { title: "Order date", field: "order_date" },
      { title: "Season", field: "season" },
      { title: "Designer", field: "designer_name" },
      { title: "Contact name", field: "contact" },
      { title: "Email address", field: "contact_email" },
      { title: "Port", field: "port" },
      { title: "Payment terms", field: "payment_term" },
      {
        title: "Shipment date",
        field: "shipment_date",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
      },
      { title: "Country", field: "country" },
      {
        title: "Total quantity",
        field: "total_quantity",
        customSort: (a, b) => parseInt(a.total_quantity.replace(" ", "")) - parseInt(b.total_quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Total value",
        field: "total_value",
        customSort: (a, b) => parseFloat(a.total_value.replace(" ", "")) - parseFloat(b.total_value.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      { title: "Final order", field: "final_order", type: "boolean" },
      {
        title: "Proforma document",
        field: "proforma_document",
        filtering: false,
        render: (props) => (
          <ProformaDocumentDropzone disabled={props.accepted} order_id={props.id} file={props.proforma_document} />
        ),
      },
      { title: "Barcodes in process", field: "barcodes_in_process", type: "boolean" },
    ],
    designerProducts: [
      /* Tabela nieedytowalna tylko do podglądu i filtrowania */
      { title: "Order id", field: "order_id", hidden: true },
      { title: "Order accepted", field: "order_accepted" },
      {
        title: "Order number",
        field: "order_number",
        editable: "never",
        render: (rowData) => {
          return (
            <div>
              <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
            </div>
          );
        },
      },
      {
        title: "Style name",
        field: "style_name",
        headerStyle: {
          paddingRight: "100px",
        },
      },
      { title: "Index", field: "index" },
      {
        title: "Quantity",
        field: "quantity",
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Average price",
        field: "average_price",
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Total value",
        field: "total",
        customSort: (a, b) => parseFloat(a.total.replace(" ", "")) - parseFloat(b.total.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Shipment date",
        field: "shipment_date",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
      },
      { title: "LC", field: "lc_number" },
    ],
    orderPage: {
      order: [
        { title: "ID", field: "id", hidden: true },
        {
          title: "Order number",
          editable: "never",
          field: "number",
          editComponent: (props) => editComponent(props, true),
        },
        { title: "Order date", field: "order_date", editComponent: (props) => editComponent(props, false) },
        { title: "Season", field: "season_id" },
        { title: "Designer", field: "designer", editable: "never" },
        { title: "Designer ID", field: "designer_id", hidden: true },
        { title: "Contact name", field: "contact", editable: "never" },
        { title: "Email address", field: "contact_email", editable: "never" },
        { title: "Payment terms", field: "payment_term_id" },
        {
          title: "Shipment date",
          field: "shipment_date",
          editComponent: (props) => editComponent(props, false),
          filterComponent: (term) => {
            const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
            return <FilterDateRange onChange={onChange} />;
          },
          customFilterAndSearch: (term, rowData) => {
            return checkDateRange(term, rowData.shipment_date);
          },
          customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
        },
        { title: "Country", field: "country", editComponent: (props) => editComponent(props, false) },
        {
          title: "Total quantity",
          field: "total_quantity",
          editable: "never",
          customSort: (a, b) =>
            parseInt(a.total_quantity.replace(" ", "")) - parseInt(b.total_quantity.replace(" ", "")),
          headerStyle: {
            textAlign: "right",
            paddingRight: "0",
            paddingLeft: "50px",
          },
          cellStyle: {
            textAlign: "right",
          },
          filterCellStyle: {
            paddingRight: "0",
          },
        },
        {
          title: "Total value",
          field: "total_value",
          editable: "never",
          customSort: (a, b) => parseFloat(a.total_value.replace(" ", "")) - parseFloat(b.total_value.replace(" ", "")),
          headerStyle: {
            textAlign: "right",
            paddingRight: "0",
            paddingLeft: "50px",
          },
          cellStyle: {
            textAlign: "right",
          },
          filterCellStyle: {
            paddingRight: "0",
          },
        },
        { title: "Proforma invoice", field: "proforma_invoice", type: "boolean" },
      ],
      productCombinations: [
        { title: "ID", field: "id", hidden: true },
        {
          title: "Style name",
          field: "style_name",
          editComponent: (props) => editComponent(props, true),
          headerStyle: {
            paddingRight: "100px",
          },
        },
        { title: "Sex", field: "sex_id" },
        { title: "Description", field: "description", editable: "never" },
        { title: "HZ description", field: "hz_description", editable: "never" },
        // {title: 'Accessory', field: 'accessory', editable: 'never', type: 'boolean'},
        {
          title: "Product details",
          field: "product_details",
          editComponent: (props) => editComponent(props, false),
        },
        {
          title: "Quantity",
          field: "quantity_by_products",
          editable: "never",
          customSort: (a, b) =>
            parseInt(a.quantity_by_products.replace(" ", "")) - parseInt(b.quantity_by_products.replace(" ", "")),
          headerStyle: {
            textAlign: "right",
            paddingRight: "0",
            paddingLeft: "50px",
          },
          cellStyle: {
            textAlign: "right",
          },
          filterCellStyle: {
            paddingRight: "0",
          },
        },
        {
          title: "Total value (USD)",
          field: "total_by_products",
          editable: "never",
          customSort: (a, b) =>
            parseFloat(a.total_by_products.replace(" ", "")) - parseFloat(b.total_by_products.replace(" ", "")),
          headerStyle: {
            textAlign: "right",
            paddingRight: "0",
            paddingLeft: "50px",
          },
          cellStyle: {
            textAlign: "right",
          },
          filterCellStyle: {
            paddingRight: "0",
          },
        },
        {
          title: "Preview file",
          field: "preview_file",
          editable: "always",
          editComponent: (props) => (
            <PreviewDropzone
              file={props.rowData.preview_file}
              onChange={(files) => {
                props.rowData.preview_file = files;
              }}
            />
          ),
          render: (data) =>
            data.preview_file.length > 0 ? (
              <PreviewDropzone previewFiles={data.preview_file} />
            ) : (
              <p style={{ fontStyle: "italic" }}>No file uploaded</p>
            ),
        },
      ],
      products: [
        { title: "ID", field: "id", hidden: true },
        { title: "Fabric", field: "fabric_id" },
        // {title: 'Compositions', field: 'composition_id'},
        { title: "Compositions", field: "composition", editComponent: (props) => editComponent(props, true) },
        { title: "GSM", field: "gsm", editComponent: (props) => editComponent(props, false) },
        { title: "Main color", field: "color_id" },
        {
          title: "Washing/Finishing",
          field: "washing_finishing",
          editComponent: (props) => editComponent(props, false),
        },
        { title: "Details color", field: "details_color" },
        { title: "Fabric name", field: "fabric_name", editComponent: (props) => editComponent(props, false) },
        {
          title: "Collection ref.",
          field: "collection_ref",
          editComponent: (props) => editComponent(props, false),
        },
        {
          title: "Contrast details",
          field: "contrast_details",
          editComponent: (props) => editComponent(props, false),
        },
        { title: "Trim details", field: "trim_details", editComponent: (props) => editComponent(props, false) },
        { title: "Labels", field: "labels", editComponent: (props) => editComponent(props, false) },
        { title: "Size", field: "size_id" },
        {
          title: "Quantity",
          field: "quantity",
          customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
          headerStyle: {
            textAlign: "right",
            paddingRight: "0",
            paddingLeft: "50px",
          },
          cellStyle: {
            textAlign: "right",
          },
          filterCellStyle: {
            paddingRight: "0",
          },
        },
        {
          title: "Price (USD)",
          field: "price",
          customSort: (a, b) => parseFloat(a.price.replace(" ", "")) - parseFloat(b.price.replace(" ", "")),
          headerStyle: {
            textAlign: "right",
            paddingRight: "0",
            paddingLeft: "50px",
          },
          cellStyle: {
            textAlign: "right",
          },
          filterCellStyle: {
            paddingRight: "0",
          },
        },
        {
          title: "Patch file",
          field: "patch_file",
          editable: "always",
          editComponent: (props) => (
            <PreviewDropzone
              file={props.rowData.patch_file}
              onChange={(files) => {
                props.rowData.patch_file = files;
              }}
            />
          ),
          render: (data) =>
            data.patch_file.length > 0 ? (
              <PreviewDropzone previewFiles={data.patch_file} />
            ) : (
              <p style={{ fontStyle: "italic" }}>No file uploaded</p>
            ),
        },
      ],
    },
  },

  bookingsColumns: [
    { title: "ID", field: "id", hidden: true },
    {
      title: "Accepted",
      field: "accepted",
      type: "multiSelect",
      filterComponent: ({ columnDef, onFilterChanged }) => {
        return (
          <MultiSelect
            value={columnDef.tableData.initialValue || ["all"]}
            onChangeValue={(values) => {
              onFilterChanged(columnDef.tableData.id, values);
            }}
            options={["all", "empty", "cancel", "hold", "accepted"]}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        term === "all"
          ? rowData
          : term === "empty"
          ? rowData.accepted === null
          : term === "cancel"
          ? rowData.accepted === "CANCEL"
          : term === "hold"
          ? rowData.accepted === "HOLD"
          : term === "accepted"
          ? rowData.accepted !== null &&
            rowData.accepted !== "" &&
            rowData.accepted !== "CANCEL" &&
            rowData.accepted !== "HOLD"
          : rowData.accepted !== null
          ? rowData.accepted.includes(term)
          : null,
      editComponent: (props) => editComponent(props, false),
    },
    {
      title: "Style name",
      field: "style_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <Tooltip
            arrow
            placement="bottom"
            title={
              rowData.image !== null ? (
                <img style={{ padding: 5, width: "200px", height: "auto" }} src={rowData.image} />
              ) : (
                <p>No preview photo</p>
              )
            }
          >
            <span style={{ textDecoration: "underline dotted", cursor: "pointer" }}>{rowData.style_name}</span>
          </Tooltip>
        );
      },
      headerStyle: {
        paddingRight: "100px",
      },
    },
    {
      title: "Index",
      field: "index",
      editable: "never",
      defaultFilter: "all",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return (
          <>
            {/* <Select
                            value={term.columnDef.tableData.filterValue}
                            onChange={(e) => {
                                term.columnDef.tableData.filterValue = e.target.value;
                                term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="unadded">Unadded</MenuItem>
                            <MenuItem value="added">Added</MenuItem>
                        </Select> */}

            <FilterInput term={term} onChange={onChange} />
          </>
        );
      },
      customFilterAndSearch: (term, rowData) =>
        term === "all"
          ? rowData
          : term === "unadded"
          ? rowData.index === null
          : term === "added"
          ? rowData.index !== null
          : rowData.index !== null
          ? rowData.index.includes(term)
          : null,
    },
    {
      title: "Order number",
      field: "order_number",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <div>
            <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
          </div>
        );
      },
    },
    {
      title: "Designer",
      field: "designer",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.designer);
      },
      headerStyle: {
        maxWidth: "60px",
        margin: "0",
        padding: "0",
      },
      cellStyle: {
        maxWidth: "60px",
        textAlign: "center",
      },
    },
    {
      title: "Shipment per order",
      field: "shipment_date",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.shipment_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
    },
    {
      title: "Shipper name",
      field: "contact_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Port",
      field: "port",
      editable: "never",
      type: "multiSelect",
    },
    {
      title: "Quantity per order",
      field: "quantity",
      editable: "never",
      type: "text",
      render: (rowData) => <div className={"amountPercentage"}>{rowData.quantity}</div>,
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Price (USD) per order",
      field: "price",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) => parseFloat(a.price.replace(" ", "")) - parseFloat(b.price.replace(" ", "")),
      render: (rowData) => <div className={"pricePerOrder"}>{rowData.price}</div>,
      headerStyle: {
        textAlign: "center",
        padding: "0",
      },
      cellStyle: {
        textAlign: "right",
        maxWidth: "70px",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Total (USD) per order",
      field: "total",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) => parseFloat(a.total.replace(" ", "")) - parseFloat(b.total.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Index check date",
      field: "index_check_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, true),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.index_check_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "index_check_date"),
    },
    {
      title: "Sample confirm date",
      field: "sample_confirm_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sample_confirm_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "sample_confirm_date"),
    },
    {
      title: "Forw.",
      field: "forwarder",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Booking date",
      field: "booking_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.booking_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "booking_date"),
    },
    {
      title: "Readiness date",
      field: "readiness_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.readiness_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "readiness_date"),
    },
    {
      title: "Forwarder confirm date",
      field: "forwarder_confirm_date",
      type: "dateType",
      editComponent: (props) => {
        let output;
        if (
          props.rowData.accepted !== null &&
          props.rowData.accepted.trim() !== "" &&
          props.rowData.accepted !== "CANCEL" &&
          props.rowData.accepted !== "HOLD"
        ) {
          output = (
            <Input
              style={{ fontSize: 14 }}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
              }}
            />
          );
        } else {
          output = (
            <p style={{ fontSize: 8, color: "darkred", textAlign: "center" }}>Product unaccepted, canceled or hold!</p>
          );
        }
        return output;
      },
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.forwarder_confirm_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "forwarder_confirm_date"),
    },
    {
      title: "Quantity as per commercial invoice",
      field: "quantity_invoice",
      type: "number",
      render: (rowData) => <div className={"amountPercentage"}>{rowData.quantity_invoice}</div>,
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) =>
        parseInt(a.quantity_invoice.replace(" ", "")) - parseInt(b.quantity_invoice.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Price as per commercial invoice",
      field: "price_invoice",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) => parseFloat(a.price_invoice.replace(" ", "")) - parseFloat(b.price_invoice.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Value (USD) per commercial invoice",
      field: "value",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) => parseFloat(a.value.replace(" ", "")) - parseFloat(b.value.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "LC number",
      field: "lc_number",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Container number",
      field: "container_number",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
    },
    {
      title: "Invoice number",
      field: "invoice_number",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
    },
    {
      title: "ETD",
      field: "etd",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.etd);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "etd"),
    },
    {
      title: "ETA",
      field: "eta",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.eta);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "eta"),
    },
    {
      title: "Warehouse",
      field: "warehouse",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.warehouse);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
    },
    {
      title: "Deposit date",
      field: "deposit",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.deposit);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "deposit"),
    },
    {
      title: "Quantity in % shipped",
      field: "amount_percentage",
      type: "number",
      render: (rowData) => <div className={"amountPercentage"}>{rowData.amount_percentage}</div>,
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editable: "never",
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    { title: "Validated", field: "validated", type: "boolean" },
    {
      title: "Discount",
      field: "discount",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Comments",
      field: "comments",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Cancelled",
      field: "cancelled",
      type: "boolean",
      editable: "never",
    },
    {
      title: "Sex",
      field: "sex",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Fabric",
      field: "fabric",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    { title: "Description", type: "multiSelect", field: "description", editable: "never" }, // Zmienione z description_id na potrzeby sortowania po nazwie
    { title: "Accessory", editable: "never", field: "accessory", type: "boolean" },
    {
      title: "Barcode Multi",
      field: "barcode_multi",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.index);
      },
    },
  ],

  contactsColumns: [
    { title: "ID", field: "id", hidden: true },
    { title: "Country", field: "country", editComponent: (props) => editComponent(props, true) },
    { title: "Company", field: "company", editComponent: (props) => editComponent(props, false) },
    { title: "Contact person", field: "name", editComponent: (props) => editComponent(props, false) },
    { title: "Code", field: "code", editComponent: (props) => editComponent(props, false) },
    { title: "Emails", field: "email", editComponent: (props) => editComponent(props, false) },
    {
      title: "Supervisor Contact",
      field: "supervisor_contact",
      editComponent: (props) => editComponent(props, false),
    },
    { title: "Carry terms sent", field: "carry_terms_sent", type: "boolean" },
    { title: "Carry terms received", field: "carry_terms_received", type: "boolean" },
    { title: "SSD sent", field: "ssd_sent", type: "boolean" },
    { title: "SSD received", field: "ssd_received", type: "boolean" },
  ],
  contactsColumnsForDesigner: [
    { title: "ID", field: "id", hidden: true },
    { title: "Country", field: "country" },
    { title: "Company", field: "company" },
    { title: "Contact person", field: "name" },
    { title: "Code", field: "code" },
    { title: "Emails", field: "email" },
    { title: "Supervisor Contact", field: "supervisor_contact" },
  ],
  invoicesColumns: [
    { title: "Invoice ID", field: "id", hidden: true },
    {
      title: "Container number",
      field: "container_number",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Shipper",
      editable: "never",
      field: "shipper",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Invoice number",
      field: "number",
      editComponent: (props) => editComponent(props, true),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "CTNS",
      field: "ctns",
      type: "text",
      editable: "never",
      cellStyle: {
        textAlign: "right",
      },
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Invoice date",
      field: "invoice_date",
      editComponent: (props) => editComponent(props, true),
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.invoice_date);
      },
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: "Contact person",
      field: "contact_person",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Invoice issuer",
      field: "invoice_shipper",
      editComponent: (props) => editComponent(props, false),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Invoice quantity",
      field: "invoice_quantity",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) =>
        parseInt(a.invoice_quantity.replace(" ", "")) - parseInt(b.invoice_quantity.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Invoice value",
      field: "invoice_value",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) => parseFloat(a.invoice_value.replace(" ", "")) - parseFloat(b.invoice_value.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Amount to pay DP",
      field: "amount_to_pay_dp",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) =>
        parseFloat(a.amount_to_pay_dp.replace(" ", "")) - parseFloat(b.amount_to_pay_dp.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Amount to pay LC",
      field: "amount_to_pay_lc",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) =>
        parseFloat(a.amount_to_pay_lc.replace(" ", "")) - parseFloat(b.amount_to_pay_lc.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Amount to pay TT",
      field: "amount_to_pay_tt",
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) =>
        parseFloat(a.amount_to_pay_tt.replace(" ", "")) - parseFloat(b.amount_to_pay_tt.replace(" ", "")),
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Payment terms",
      field: "payment_terms",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        let date;
        if (typeof rowData !== "undefined") {
          rowData.payment_terms === "LC" ? (date = rowData.payment_terms_lc_date) : (date = rowData.payment_date);
          return (
            <div>
              <p>{rowData.payment_terms}</p>
              {/*date !== null || '' ? <p style={{marginTop: -10, fontSize: 10}}>({date})</p> : null*/}
            </div>
          );
        }
      },
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Bank Name",
      field: "bank_name",
      type: "multiSelect",
    },
    {
      title: "Payment ref.",
      field: "payment_ref",
      editable: "onUpdate",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        let payment;
        if (typeof rowData !== "undefined") {
          rowData.payment_ref_lc_number ? (payment = rowData.payment_ref_lc_number) : (payment = rowData.payment_ref);
          return (
            <div>
              <p>{payment}</p>
            </div>
          );
        }
      },
      editComponent: (props) => {
        let result;
        props.rowData.payment_terms === "LC"
          ? (result = <p>{props.rowData.payment_ref_lc_number}</p>)
          : (result = editComponent(props, false));
        return <div>{result}</div>;
      },
      cellStyle: {
        width: "140px",
      },
      headerStyle: headerStyle,
    },
    {
      title: "Bank",
      field: "has_bank",
      type: "boolean",
      cellStyle: checkboxCellStyle,
      headerStyle: checkboxHeaderStyle,
    },
    {
      title: "ETA",
      field: "eta",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.eta);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "eta"),
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: "Warehouse",
      field: "warehouse",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.warehouse);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: "Payment date",
      field: "payment_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.payment_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "payment_date"),
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: "Paid",
      field: "validated",
      type: "boolean",
      cellStyle: checkboxCellStyle,
      headerStyle: {
        ...checkboxHeaderStyle,
        paddingLeft: 10,
      },
    },
    {
      title: "Dox received",
      field: "dox_received",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.dox_received);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "dox_received"),
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: "Currency date",
      field: "currency_date",
      editable: "never",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.currency_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "currency_date"),
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: "Season",
      field: "season",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: "Comments",
      field: "comments",
      editComponent: (props) => editComponent(props, false),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <CustomTooltip content={rowData.comments} />
          </div>
        );
      },
    },
  ],
  containersColumns: [
    { title: "Container ID", field: "id", hidden: true },
    {
      title: "Container number",
      field: "number",
      type: "text",
      editComponent: (props) => editComponent(props, true),
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Container type",
      field: "container_type",
      type: "text",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      cellStyle: {
        width: "120px",
      },
      headerStyle: {
        width: "120px",
      },
    },
    {
      title: "Delivered",
      field: "supplied",
      type: "boolean",
    },
    {
      title: "Port",
      field: "port_name",
      type: "multiSelect",
    },
    {
      title: "ETD",
      field: "etd",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.etd);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "etd"),
    },
    {
      title: "ETA",
      field: "eta",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.eta);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "eta"),
    },
    {
      title: "Warehouse",
      field: "warehouse",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.warehouse);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
    },
    {
      title: "DOX AC",
      field: "dox_ac",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.warehouse);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "dox_ac"),
    },
    {
      title: "Cleared",
      field: "cleared",
      type: "boolean",
    },
    {
      title: "Comments",
      field: "comments",
      editComponent: (props) => editComponent(props, false),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "FV",
      field: "fv",
      type: "boolean",
    },
    {
      title: "FV Back",
      field: "fv_gave_back",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.warehouse);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "dox_ac"),
    },

    {
      title: "Season",
      field: "season",
      type: "text",
      editComponent: (props) => multiEditSeasonComponent(props, false),
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      cellStyle: {
        width: "120px",
      },
      headerStyle: {
        width: "120px",
      },
    },
    {
      title: "Season as per Invoice",
      field: "season_invoice",
      type: "text",
      editable: "never",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Container file",
      field: "container_file",
      editable: "always",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => (
        <PreviewDropzone
          file={props.rowData.container_file}
          onChange={(files) => {
            props.rowData.container_file = files ? files[0] : null;
          }}
        />
      ),
      render: (data) =>
        data.container_file ? (
          <PreviewDropzone previewFiles={data.container_file} />
        ) : (
          <p style={{ fontStyle: "italic" }}>No file uploaded</p>
        ),
    },
  ],
  containerInvoicesColumns: [
    { title: "Container invoice id", field: "id", hidden: true },
    { title: "Shipper", field: "shipper", editComponent: (props) => editComponent(props, true) },
    { title: "Code", field: "code", editComponent: (props) => editComponent(props, true) },
    { title: "Contact person", field: "contact_person", editable: "never" },
    {
      title: "CTNS",
      field: "ctns",
      editComponent: (props) => editComponent(props, false),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "CBM",
      field: "cbm",
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) => parseFloat(a.cbm.replace(" ", "")) - parseFloat(b.cbm.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Quantity in pcs",
      field: "quantity_in_pcs",
      editable: "never",
      customSort: (a, b) => parseInt(a.quantity_in_pcs.replace(" ", "")) - parseInt(b.quantity_in_pcs.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Value",
      field: "value",
      editable: "never",
      customSort: (a, b) => parseFloat(a.value.replace(" ", "")) - parseFloat(b.value.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Invoice number",
      field: "invoice_number",
      editComponent: (props) => editComponent(props, false),
      render: (rowData) => {
        return (
          <div>
            <Link to={`/invoices?page=1&limit=20&search=${encodeURIComponent(rowData.invoice_number)}`}>
              {rowData.invoice_number}
            </Link>
          </div>
        );
      },
      headerStyle: {
        textAlign: "center",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Payment Ref",
      field: "payment_ref",
      editable: "never",
      render: (rowData) => {
        let payment;
        if (typeof rowData !== "undefined") {
          rowData.payment_ref_lc_number ? (payment = rowData.payment_ref_lc_number) : (payment = rowData.payment_ref);
          return (
            <div>
              <p>{payment}</p>
            </div>
          );
        }
      },
    },
    { title: "Paid", field: "paid", type: "boolean", editable: "never" },
    {
      title: "NET",
      field: "net",
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) => parseFloat(a.net.replace(" ", "")) - parseFloat(b.net.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "GROSS",
      field: "gross",
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) => parseFloat(a.gross.replace(" ", "")) - parseFloat(b.gross.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Average carton weight",
      field: "average_carton_weight",
      editable: "never",
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Average item weight",
      field: "average_item_weight",
      editable: "never",
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Documents",
      field: "documents_approved",
      type: "boolean",
      headerStyle: {
        textAlign: "center",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Originals approved",
      field: "originals_approved_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
    },
    {
      title: "Season as per invoice",
      field: "season",
      editable: "never",
    },
    {
      title: "File",
      field: "file",
      editable: "always",
      editComponent: (props) => (
        <PreviewDropzone
          file={props.rowData.file}
          onChange={(files) => {
            props.rowData.file = files ? files[0] : null;
          }}
        />
      ),
      render: (data) =>
        data.file ? (
          <PreviewDropzone previewFiles={data.file} />
        ) : (
          <p style={{ fontStyle: "italic" }}>No file uploaded</p>
        ),
    },
  ],
  designersColumns: [
    { title: "ID", field: "id", editable: "never", hidden: true },
    { title: "Name", field: "name", editComponent: (props) => editComponent(props, true) },
    { title: "Code", field: "code", editComponent: (props) => editComponent(props, false) },
    { title: "User", field: "user_id" },
  ],
  productsColumns: [
    { title: "Product id", field: "id", editable: "never", hidden: true },
    { title: "Order id", field: "order_id", hidden: true },
    {
      title: "Accepted",
      field: "accepted",
      type: "multiSelect",
      editable: "never",
      filterComponent: (term) => {
        return (
          <MultiSelect
            value={term.columnDef.tableData.initialValue || ["all"]}
            onChangeValue={(values) => {
              console.log(values);
              term.onFilterChanged(term.columnDef.tableData.id, values);
            }}
            options={["all", "empty", "cancel", "hold", "accepted"]}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        term === "all"
          ? rowData
          : term === "empty"
          ? rowData.accepted === null
          : term === "cancel"
          ? rowData.accepted === "CANCEL"
          : term === "hold"
          ? rowData.accepted === "HOLD"
          : term === "accepted"
          ? rowData.accepted !== null &&
            rowData.accepted !== "" &&
            rowData.accepted !== "CANCEL" &&
            rowData.accepted !== "HOLD"
          : rowData.accepted !== null
          ? rowData.accepted.includes(term)
          : null,
      editComponent: (props) => editComponent(props, false),
    },
    {
      title: "Contact person",
      field: "contact_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.contact_name);
      },
    },
    {
      title: "Company",
      field: "company",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.company);
      },
    },
    {
      title: "Designer",
      field: "designer",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.designer);
      },
    },
    {
      title: "Season",
      field: "season",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.season);
      },
    },
    {
      title: "Order number",
      field: "order_number",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.order_number);
      },
      render: (rowData) => {
        return (
          <div>
            <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
          </div>
        );
      },
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "70px",
      },
    },
    {
      title: "Style name",
      field: "style_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <Tooltip
            arrow
            placement="bottom"
            title={
              rowData.image !== null ? (
                <img style={{ padding: 5, width: "200px", height: "auto" }} src={rowData.image} />
              ) : (
                <p>No preview photo</p>
              )
            }
          >
            <span style={{ textDecoration: "underline dotted", cursor: "pointer" }}>{rowData.style_name}</span>
          </Tooltip>
        );
      },
      headerStyle: {
        paddingRight: "100px",
      },
    },
    {
      title: "Index",
      field: "index",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.index);
      },
    },
    {
      title: "Store Price",
      field: "store_price",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.index);
      },
      editComponent: (props) => editComponent(props, true),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Price accepted",
      field: "price_accepted",
      type: "boolean",
      editable: "never",
    },
    {
      title: "Barcode check",
      field: "barcode_check",
      type: "boolean",
    }, // EDITABLE
    {
      title: "Port",
      field: "port",
      editable: "never",
      type: "multiSelect",
    },
    {
      title: "Quantity per order",
      field: "quantity",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.quantity);
      },
      customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Price (USD) per order",
      field: "price",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.price);
      },
      customSort: (a, b) => parseFloat(a.price.replace(" ", "")) - parseFloat(b.price.replace(" ", "")),
      render: (rowData) => <div className={"pricePerOrder"}>{rowData.price}</div>,
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Total value (USD) per order",
      field: "total",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.number);
      },
      customSort: (a, b) => parseFloat(a.total.replace(" ", "")) - parseFloat(b.total.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Shipment date as per order",
      field: "shipment_date",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.shipment_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
      editComponent: (props) => editComponent(props, false),
      headerStyle: {
        textAlign: "center",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    }, // EDITABLE
    {
      title: "Payment",
      type: "text",
      field: "payment_term",
      editable: "never",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.payment_term);
      },
    },
    {
      title: "LC number",
      type: "text",
      field: "payment_ref",
      editable: "never",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.payment_ref);
      },
    },
    {
      title: "TT amount",
      field: "tt_amount",
      editable: "never",
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Discount",
      field: "discount",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.discount);
      },
      editComponent: (props) => editComponent(props, false),
      render: (rowData) => <span>{rowData.discount}</span>,
      headerStyle: {
        textAlign: "center",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    }, // EDITABLE
    {
      title: "M",
      field: "m",
      type: "boolean",
    }, // EDITABLE
    {
      title: "Barcode Multi",
      field: "barcode_multi",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.index);
      },
      editComponent: (props) => EditTags({ ...props, placeholder: "TYPE AND PRESS ENTER OR SEMICOLON" }),
    }, // EDITABLE
    {
      title: "Accessory",
      field: "accessory",
      type: "boolean",
      editable: "never",
    },
    {
      title: "Comments",
      type: "text",
      field: "comments",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.comments);
      },
      editComponent: (props) => editComponent(props, false),
    }, // EDITABLE
    // Ukryte
    {
      title: "Cancelled",
      field: "cancelled",
      type: "boolean",
    }, // EDITABLE
    {
      title: "Sex",
      field: "sex",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sex);
      },
    },
    { title: "Description", type: "multiSelect", field: "description", editable: "never" }, // Zmienione z description_id na potrzeby sortowania po nazwie
    {
      title: "Fabric",
      field: "fabric_title",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sex);
      },
    },
  ],
  productDetailsColumns: {
    features: [
      { title: "ID", field: "id", hidden: true },
      { title: "Title", field: "title", editComponent: (props) => editComponent(props, true) },
      {
        title: "Shop feature",
        field: "shop_feature",
        type: "text",
        editComponent: (props) => editColorsComponent(props),
        cellStyle: {
          width: "200px",
        },
      },
    ],
    feature_values: [
      { title: "ID", field: "id", hidden: true },
      { title: "Title", field: "title", editComponent: (props) => editComponent(props, true) },
      {
        title: "Shop feature",
        field: "shop_feature",
        type: "text",
        editComponent: (props) => editColorsComponent(props),
        cellStyle: {
          width: "200px",
        },
      },
    ],
    fabrics: [
      { title: "ID", field: "id", hidden: true },
      { title: "Title", field: "title", editComponent: (props) => editComponent(props, true) },
    ],
    compositions: [
      { title: "ID", field: "id", hidden: true },
      { title: "Title", field: "title", editComponent: (props) => editComponent(props, true) },
    ],
    descriptions: [
      { title: "ID", field: "id", hidden: true },
      { title: "Title", field: "title", editComponent: (props) => editComponent(props, true) },
      { title: "HZ title", field: "hz_title", editComponent: (props) => editComponent(props, false) },
      { title: "Short HZ title", field: "short_hz_title", editComponent: (props) => editComponent(props, false, 10) },
      { title: "Code", field: "code", editComponent: (props) => editComponent(props, false) },
      { title: "Accessory", field: "accessory", type: "boolean" },
      {
        title: "Men categories",
        field: "men_categories",
        filtering: false,
        render: (rowData) =>
          rowData.men_categories.map((category) => {
            return <span>{category.text}; </span>;
          }),
        editComponent: (props) => CategoriesTags({ ...props, placeholder: "TYPE AND PRESS ENTER OR SEMICOLON" }),
      },
      {
        title: "Ladies categories",
        field: "ladies_categories",
        filtering: false,
        render: (rowData) =>
          rowData.ladies_categories.map((category) => {
            return <span>{category.text}; </span>;
          }),
        editComponent: (props) => CategoriesTags({ ...props, placeholder: "TYPE AND PRESS ENTER OR SEMICOLON" }),
      },
      {
        title: "Boys categories",
        field: "boys_categories",
        filtering: false,
        render: (rowData) =>
          rowData.boys_categories.map((category) => {
            return <span>{category.text}; </span>;
          }),
        editComponent: (props) => CategoriesTags({ ...props, placeholder: "TYPE AND PRESS ENTER OR SEMICOLON" }),
      },
      {
        title: "Girls categories",
        field: "girls_categories",
        filtering: false,
        render: (rowData) =>
          rowData.girls_categories.map((category) => {
            return <span>{category.text}; </span>;
          }),
        editComponent: (props) => CategoriesTags({ ...props, placeholder: "TYPE AND PRESS ENTER OR SEMICOLON" }),
      },
      {
        title: "Kids categories",
        field: "kids_categories",
        filtering: false,
        render: (rowData) =>
          rowData.kids_categories.map((category) => {
            return <span>{category.text}; </span>;
          }),
        editComponent: (props) => CategoriesTags({ ...props, placeholder: "TYPE AND PRESS ENTER OR SEMICOLON" }),
      },
      {
        title: "Unisex categories",
        field: "unisex_categories",
        filtering: false,
        render: (rowData) =>
          rowData.unisex_categories.map((category) => {
            return <span>{category.text}; </span>;
          }),
        editComponent: (props) => CategoriesTags({ ...props, placeholder: "TYPE AND PRESS ENTER OR SEMICOLON" }),
      },
    ],
    seasons: [
      { title: "ID", field: "id", hidden: true },
      { title: "Code", field: "code", editComponent: (props) => editComponent(props, true) },
      { title: "HZ Code", field: "hz_code", editComponent: (props) => editComponent(props, true) },
    ],
    ports: [
      { title: "ID", field: "id", hidden: true },
      { title: "Code", field: "code", editComponent: (props) => editComponent(props, true) },
      { title: "Description", field: "description", editComponent: (props) => editComponent(props, false) },
    ],
    paymentTerms: [
      { title: "ID", field: "id", hidden: true },
      { title: "Code", field: "code", editComponent: (props) => editComponent(props, true) },
      { title: "Description", field: "description", editComponent: (props) => editComponent(props, false) },
    ],
    sexes: [
      { title: "ID", field: "id", hidden: true },
      { title: "Code", field: "code", editComponent: (props) => editComponent(props, true) },
      { title: "Description", field: "description", editComponent: (props) => editComponent(props, false) },
    ],
    colors: [
      { title: "ID", field: "id", hidden: true },
      { title: "Title", field: "title", editComponent: (props) => editComponent(props, true) },
      { title: "Title (PL)", field: "title_pl", editComponent: (props) => editComponent(props, false) },
      {
        title: "Shop color",
        field: "shop_color",
        type: "text",
        editComponent: (props) => editColorsComponent(props, false),
        cellStyle: {
          width: "200px",
        },
      },
    ],
    sub_colors: [
      { title: "ID", field: "id", hidden: true },
      { title: "Title", field: "title", editComponent: (props) => editComponent(props, true) },
      { title: "Title (PL)", field: "title_pl", editComponent: (props) => editComponent(props, false) },
      {
        title: "Shop color",
        field: "shop_color",
        type: "text",
        editComponent: (props) => editColorsComponent(props, false),
        cellStyle: {
          width: "200px",
        },
      },
    ],
    sizes: [
      { title: "ID", field: "id", hidden: true },
      { title: "Code", field: "code", editComponent: (props) => editComponent(props, true) },
      {
        title: "Size type",
        field: "size_type",
        lookup: {
          attribute_size: "Regular size",
          attribute_size_trousers: "Trousers size",
          attribute_size_kids: "Kids size",
          attribute_size_shoes: "Shoes size",
          attribute_size_hats: "Hats size",
          attribute_size_shorts: "Shorts size",
          attribute_size_socks: "Socks size",
        },
      },
      { title: "Shop size", field: "shop_size", lookup: {} },
    ],
    payment_terms: [
      { title: "ID", field: "id", hidden: true },
      { title: "Code", field: "code", editComponent: (props) => editComponent(props, true) },
    ],
    free_days: [
      { title: "ID", field: "id", hidden: true },
      { title: "Local Name", field: "local_name", editComponent: (props) => editComponent(props, true) },
      { title: "Name", field: "name", editComponent: (props) => editComponent(props, true) },
      { title: "Date", field: "date", editComponent: (props) => editComponent(props, true) },
      { title: "Country Code", field: "country_code", editComponent: (props) => editComponent(props, true) },
      { title: "Year", field: "year", editComponent: (props) => editComponent(props, true) },
    ],
    banks: [
      { title: "ID", field: "id", hidden: true },
      { title: "Name", field: "name", editComponent: (props) => editComponent(props, true) },
    ],
  },
  hidden: {
    productsColumns: [
      { title: "Product id", field: "id", editable: "never", hidden: true },
      { title: "Order id", field: "order_id", hidden: true },
      {
        title: "Accepted",
        field: "accepted",
        editable: "never",
        defaultFilter: "all",
        type: "select",
        filterComponent: (term) => {
          return (
            <Select
              value={term.columnDef.tableData.filterValue}
              onChange={(e) => {
                term.columnDef.tableData.filterValue = e.target.value;
                term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="empty">Empty</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="hold">Hold</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
            </Select>
          );
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "accepted"),
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "empty"
            ? rowData.accepted === null
            : term === "cancel"
            ? rowData.accepted === "CANCEL"
            : term === "hold"
            ? rowData.accepted === "HOLD"
            : term === "accepted"
            ? rowData.accepted !== null &&
              rowData.accepted !== "" &&
              rowData.accepted !== "CANCEL" &&
              rowData.accepted !== "HOLD"
            : rowData.accepted !== null
            ? rowData.accepted.includes(term)
            : null,
      },
      {
        title: "Contact person",
        field: "contact_name",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.contact_name);
        },
      },
      {
        title: "Company",
        field: "company",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.company);
        },
      },
      {
        title: "Designer",
        field: "designer",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.designer);
        },
      },
      {
        title: "Season",
        field: "season",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.season);
        },
      },
      {
        title: "Order number",
        field: "order_number",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.order_number);
        },
        render: (rowData) => {
          return (
            <div>
              <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
            </div>
          );
        },
      },
      {
        title: "Style name",
        field: "style_name",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.style_name);
        },
        headerStyle: {
          paddingRight: "100px",
        },
      },
      {
        title: "Index",
        field: "index",
        editComponent: (props) => editComponent(props, true),
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.index);
        },
      }, // EDITABLE
      {
        title: "Store Price",
        field: "store_price",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.index);
        },
        editComponent: (props) => editComponent(props, true),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      { title: "Barcode check", field: "barcode_check", type: "boolean" }, // EDITABLE
      {
        title: "Port",
        field: "port",
        editable: "never",
        type: "multiSelect",
      },
      {
        title: "Quantity per order",
        field: "quantity",
        editable: "never",
        type: "number",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.quantity);
        },
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Price (USD) per order",
        field: "price",
        editable: "never",
        type: "number",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.price);
        },
        customSort: (a, b) => parseFloat(a.price.replace(" ", "")) - parseFloat(b.price.replace(" ", "")),
        render: (rowData) => <div className={"pricePerOrder"}>{rowData.price}</div>,
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Total value (USD) per order",
        field: "total",
        editable: "never",
        type: "number",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.total);
        },

        customSort: (a, b) => parseFloat(a.total.replace(" ", "")) - parseFloat(b.total.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Shipment date as per order",
        field: "shipment_date",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
        editComponent: (props) => editComponent(props, false),
        headerStyle: {
          textAlign: "center",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "center",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      }, // EDITABLE
      {
        title: "Payment",
        field: "payment_term",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.payment_term);
        },
      },
      {
        title: "LC number",
        field: "payment_ref",
        editable: "never",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.payment_ref);
        },
      },
      {
        title: "TT amount",
        field: "tt_amount",
        editable: "never",
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Discount",
        field: "discount",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.discount);
        },
        editComponent: (props) => editComponent(props, false),
      }, // EDITABLE
      { title: "M", field: "m", type: "boolean" }, // EDITABLE
      { title: "Accessory", field: "accessory", type: "boolean", editable: "never" },
      {
        title: "Comments",
        field: "comments",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.comments);
        },
        editComponent: (props) => editComponent(props, false),
      }, // EDITABLE
    ],
    bookingsColumns: [
      { title: "ID", field: "id", hidden: true },
      {
        title: "Accepted",
        field: "accepted",
        editable: "never",
        defaultFilter: "all",
        type: "select",
        filterComponent: (term) => {
          return (
            <Select
              value={term.columnDef.tableData.filterValue}
              onChange={(e) => {
                term.columnDef.tableData.filterValue = e.target.value;
                term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="empty">Empty</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="hold">Hold</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
            </Select>
          );
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "accepted"),
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "empty"
            ? rowData.accepted === null
            : term === "cancel"
            ? rowData.accepted === "CANCEL"
            : term === "hold"
            ? rowData.accepted === "HOLD"
            : term === "accepted"
            ? rowData.accepted !== null &&
              rowData.accepted !== "" &&
              rowData.accepted !== "CANCEL" &&
              rowData.accepted !== "HOLD"
            : rowData.accepted !== null
            ? rowData.accepted.includes(term)
            : null,
      },
      {
        title: "Style name",
        field: "style_name",
        editable: "never",
        headerStyle: {
          paddingRight: "100px",
        },
      },
      {
        title: "Index",
        field: "index",
        editable: "never",
        defaultFilter: "all",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return (
            <>
              {/* <Select
                                value={term.columnDef.tableData.filterValue}
                                onChange={(e) => {
                                    term.columnDef.tableData.filterValue = e.target.value;
                                    term.onFilterChanged(2, e.target.value);
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="unadded">Unadded</MenuItem>
                                <MenuItem value="added">Added</MenuItem>
                            </Select> */}

              <FilterInput term={term} onChange={onChange} />
            </>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term === "all"
            ? rowData
            : term === "unadded"
            ? rowData.index === null
            : term === "added"
            ? rowData.index !== null
            : rowData.index !== null
            ? rowData.index.includes(term)
            : null,
      },
      {
        title: "Order number",
        field: "order_number",
        editable: "never",
        render: (rowData) => {
          return (
            <div>
              <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
            </div>
          );
        },
      },
      {
        title: "Designer",
        field: "designer",
        editable: "never",
        type: "text",
        render: (rowData) => rowData.designer,
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.designer);
        },
      },
      {
        title: "Shipment per order",
        field: "shipment_date",
        editable: "never",
        type: "dateType",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.shipment_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
      },
      { title: "Shipper name", field: "contact_name", editable: "never" },
      { title: "Port", field: "port", editable: "never" },
      {
        title: "Quantity per order",
        field: "quantity",
        editable: "never",
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        render: (rowData) => <div className={"quantityPerOrder"}>{rowData.quantity}</div>,
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Price (USD) per order",
        field: "price",
        editable: "never",
        customSort: (a, b) => parseFloat(a.price.replace(" ", "")) - parseFloat(b.price.replace(" ", "")),
        render: (rowData) => <div className={"pricePerOrder"}>{rowData.price}</div>,
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Total (USD) per order",
        field: "total",
        editable: "never",
        customSort: (a, b) => parseFloat(a.total.replace(" ", "")) - parseFloat(b.total.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      // Editable
      {
        title: "Index check date",
        field: "index_check_date",
        editComponent: (props) => editComponent(props, true),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.index_check_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "index_check_date"),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "center",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Sample confirm date",
        field: "sample_confirm_date",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.sample_confirm_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "sample_confirm_date"),
      },
      {
        title: "Forw.",
        field: "forwarder",
        type: "text",
        filterComponent: (term) => {
          const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
          return <FilterInput term={term} onChange={onChange} />;
        },
      },
      {
        title: "Booking date",
        field: "booking_date",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.booking_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "booking_date"),
      },
      {
        title: "Readiness date",
        field: "readiness_date",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.readiness_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "readiness_date"),
      },
      {
        title: "Forwarder confirm date",
        field: "forwarder_confirm_date",
        editComponent: (props) => {
          let output;
          if (
            props.rowData.accepted !== null &&
            props.rowData.accepted.trim() !== "" &&
            props.rowData.accepted !== "CANCEL" &&
            props.rowData.accepted !== "HOLD"
          ) {
            output = (
              <Input
                style={{ fontSize: 14 }}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.target.blur();
                  }
                }}
              />
            );
          } else {
            output = (
              <p style={{ fontSize: 8, color: "darkred", textAlign: "center" }}>
                Product unaccepted, canceled or hold!
              </p>
            );
          }
          return output;
        },
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.forwarder_confirm_date);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "forwarder_confirm_date"),
      },
      {
        title: "Quantity as per commercial invoice",
        field: "quantity_invoice",
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b) =>
          parseInt(a.quantity_invoice.replace(" ", "")) - parseInt(b.quantity_invoice.replace(" ", "")),
        render: (rowData) => <div className={"quantityInvoice"}>{rowData.quantity_invoice}</div>,
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Price as per commercial invoice",
        field: "price_invoice",
        editComponent: (props) => editComponent(props, false),
        customSort: (a, b) =>
          parseFloat(a.price_invoice.replace(" ", "")) - parseFloat(b.price_invoice.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Value (USD) per commercial invoice",
        field: "value",
        editable: "never",
        customSort: (a, b) => parseFloat(a.value.replace(" ", "")) - parseFloat(b.value.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "LC number",
        field: "lc_number",
        editable: "never",
        headerStyle: {
          textAlign: "center",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "center",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      { title: "Container number", field: "container_number", editable: "never" },
      { title: "Invoice number", field: "invoice_number", editComponent: (props) => editComponent(props, false) },
      {
        title: "ETD",
        field: "etd",
        editable: "never",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.etd);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "etd"),
      },
      {
        title: "ETA",
        field: "eta",
        editable: "never",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.eta);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "eta"),
      },
      {
        title: "Warehouse",
        field: "warehouse",
        editable: "never",
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.warehouse);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
      },
      {
        title: "Deposit date",
        field: "deposit",
        editComponent: (props) => editComponent(props, false),
        filterComponent: (term) => {
          const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
          return <FilterDateRange onChange={onChange} />;
        },
        customFilterAndSearch: (term, rowData) => {
          return checkDateRange(term, rowData.deposit);
        },
        customSort: (a, b, c, d) => sortByDate(a, b, d, "deposit"),
      },
      {
        title: "Quantity in % shipped",
        field: "amount_percentage",
        editable: "never",
        render: (rowData) => <div className={"amountPercentage"}>{rowData.amount_percentage}</div>,
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Validated",
        field: "validated",
        type: "boolean",
        headerStyle: {
          textAlign: "center",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "center",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      { title: "Discount", field: "discount", editable: "never" },
    ],
  },
  lcsColumns: [
    { title: "LC ID", field: "id", hidden: true },
    {
      title: "LC number",
      field: "number",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, true),
    },
    {
      title: "LC Bank",
      field: "lc_bank",
      type: "multiSelect",
    },
    {
      title: "Contact person",
      field: "contact_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Company",
      field: "company",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Quantity per order/LC total quantity",
      field: "total_quantity",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      headerStyle: {
        whiteSpace: "wrap",
        overflow: "hidden",
        maxWidth: 90,
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: "right",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        maxWidth: 120,
        paddingRight: "0",
      },
    },
    {
      title: "Total value/LC value (USD)",
      field: "total_value",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      headerStyle: {
        whiteSpace: "wrap",
        overflow: "hidden",
        maxWidth: 90,
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: "right",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        maxWidth: 120,
        paddingRight: "0",
      },
    },
    {
      title: "Opening date",
      field: "opening_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.opening_date);
      },
      headerStyle: {
        textAlign: "center",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Expiration date",
      field: "expiration_date",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.expiration_date);
      },
    },
    {
      title: "Expiration place",
      field: "expiration_place",
      editComponent: (props) => editComponent(props, false),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Expiry of security date",
      field: "expiry_of_security_date",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.expiry_of_security_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "expiry_of_security_date"),
      headerStyle: {
        whiteSpace: "wrap",
        overflow: "hidden",
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: "right",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "State",
      field: "state",
      type: "multiSelect",
      lookup: {
        "": "",
        D: "D",
        P: "P",
        B: "B",
        O: "O",
        Z: "Z",
      },
      headerStyle: {
        whiteSpace: "wrap",
        overflow: "hidden",
        maxWidth: 50,
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: "right",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        maxWidth: 50,
        paddingRight: "0",
      },
    },
    {
      title: "Balance USD",
      field: "balance_usd",
      editable: "never",
      filtering: false,
      sorting: false,
      type: "text",
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "File",
      field: "file",
      editable: "always",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => (
        <PreviewDropzone
          file={props.rowData.file}
          onChange={(files) => {
            props.rowData.file = files ? files[0] : null;
          }}
        />
      ),
      render: (data) =>
        data.file ? (
          <PreviewDropzone previewFiles={data.file} />
        ) : (
          <p style={{ fontStyle: "italic" }}>No file uploaded</p>
        ),
      headerStyle: {
        whiteSpace: "wrap",
        overflow: "hidden",
        maxWidth: 90,
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: "right",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        maxWidth: 120,
        paddingRight: "0",
      },
    },
    {
      title: "Amendment file",
      field: "amendment_file",
      editable: "always",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => (
        <PreviewDropzone
          file={props.rowData.amendment_file}
          onChange={(files) => {
            props.rowData.amendment_file = files ? files[0] : null;
          }}
        />
      ),
      render: (data) =>
        data.amendment_file ? (
          <PreviewDropzone previewFiles={data.amendment_file} />
        ) : (
          <p style={{ fontStyle: "italic" }}>No file uploaded</p>
        ),
    },
    {
      title: "LC comments",
      field: "lc_comments",
      editComponent: (props) => editComponent(props, false),
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <CustomTooltip content={rowData.lc_comments} />
          </div>
        );
      },
    },
  ],
  userLogsColumns: [
    { title: "ID", field: "id", hidden: true },
    { title: "Timestamp", field: "timestamp" },
    { title: "Method", field: "method_name" },
    { title: "Status", field: "status" },
    { title: "Full path", field: "full_path" },
    { title: "Controller", field: "controller" }, // Controller ze względu na funkcję jbuilder'a
    { title: "Action", field: "action_name" },
    { title: "User full name", field: "user_full" },
    { title: "IP Address", field: "ip_address" },
    { title: "Logged changes if update", field: "logged_changes" },
  ],
  adminColumns: [
    { title: "ID", field: "id", editable: "never" },
    { title: "Imię", field: "name" },
    { title: "Nazwisko", field: "surname" },
    { title: "Adres e-mail", field: "email" },
    { title: "Profil", field: "role_id" },
    { title: "Last logged", field: "last_logged", editable: "never" },
  ],
  productForm: {
    combinationsColumns: [
      { title: "ID", field: "id", hidden: true },
      { title: "Fabric", field: "fabric_id" },
      // {title: 'Composition', field: 'composition_id'},
      { title: "Composition", field: "composition" },

      { title: "GSM", field: "gsm", editComponent: (props) => editComponent(props, false) },

      { title: "Main color", field: "main_color" },

      {
        title: "Washing/finishing",
        field: "washing_finishing",
        editComponent: (props) => editComponent(props, false),
      },

      { title: "Details color", field: "details_color" },
      { title: "Fabric name", field: "fabric_name", editComponent: (props) => editComponent(props, false) },

      { title: "Collection ref.", field: "collection_ref", editComponent: (props) => editComponent(props, false) },
      { title: "Contrast details", field: "contrast_details", editComponent: (props) => editComponent(props, false) },
      { title: "Trim details", field: "trim_details", editComponent: (props) => editComponent(props, false) },
      { title: "Labels", field: "labels", editComponent: (props) => editComponent(props, false) },

      {
        title: "Total quantity",
        field: "total_quantity",
        editable: "never",
        customSort: (a, b) => parseInt(a.total_quantity.replace(" ", "")) - parseInt(b.total_quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      }, // Zliczone
      {
        title: "Price (USD)",
        field: "price",
        customSort: (a, b) => parseFloat(a.price.replace(" ", "")) - parseFloat(b.price.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      {
        title: "Total value",
        field: "total_value",
        editable: "never",
        customSort: (a, b) => parseFloat(a.total_value.replace(" ", "")) - parseFloat(b.total_value.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      }, // Zliczone
      {
        title: "Patch file",
        field: "patch_file",
        editable: "always",
        editComponent: (props) => (
          <PreviewDropzone
            file={props.rowData.patch_file}
            onChange={(files) => {
              let patch_files = [];
              files.forEach((patchFile) => {
                patch_files.push({
                  preview: patchFile.preview,
                  file: patchFile,
                });
              });
              props.rowData.patch_file = patch_files;
            }}
          />
        ),
        render: (data) =>
          data.patch_file ? (
            <PreviewDropzone previewFiles={data.patch_file} />
          ) : (
            <p style={{ fontStyle: "italic" }}>No file uploaded</p>
          ),
      },
    ],
    sizesPricesColumns: [
      { title: "ID", field: "id", hidden: true },
      { title: "Size", field: "size_id" },
      {
        title: "Quantity",
        field: "quantity",
        customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
        headerStyle: {
          textAlign: "right",
          paddingRight: "0",
          paddingLeft: "50px",
        },
        cellStyle: {
          textAlign: "right",
        },
        filterCellStyle: {
          paddingRight: "0",
        },
      },
      { title: "Product ID", field: "product_id", hidden: true },
    ],
  },
};
