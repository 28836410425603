import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { json2excel } from "js2excel";
import { Helmet } from "react-helmet";

import axios from "../../../axios-sw";

import MaterialTable, { MTableBody, MTableToolbar } from "material-table";

import { tableIcons, titleTemplate } from "../../../shared/tableVariables";
import Columns from "../../../shared/tables";

import { Chip, TablePagination, Checkbox } from "@material-ui/core";

import { HotKeys } from "../../../components/System/HotKeys/HotKeys";
import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  formatDate,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  roleLimitedView,
} from "../../../shared/utility";

import classes from "./Collections.module.scss";
import ProgressCircular from "../../../components/UI/ProgressCircular/ProgressCircular";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../../shared/defines";
import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff } from "@material-ui/icons";

import { sortingPhraseHelper } from "./CollectionDefinition";

class Collections extends Component {
  state = {
    columns: Columns.collectionsColumns,
    currentStateColumns: null,
    data: [],
    loaded: false,
    tableRef: React.createRef(),
    filteredData: [],
    filterPhrase: "",
    sortingColumnsList: [],
    singleColumnFilterList: [],
    editable: {},
    actions: [],
    exportChosen: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexCollections(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
  }

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexCollections(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);

    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "all");
        if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "empty");
        if (filter.column.key === "proforma_document" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (filter.column.key === "index" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (
          filter.column.key === "accepted" &&
          filter.value ===
            `<> '' AND ${filter.column.path} NOT LIKE "%CANCEL%" AND ${filter.column.path} NOT LIKE "%HOLD%" AND ${filter.column.path} IS NOT NULL`
        )
          table.onFilterChange(index, "accepted");
        if (
          filter.column.key === "proforma_document" &&
          filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
        )
          table.onFilterChange(index, "added");
        if (filter.column.key === "index" && filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`)
          table.onFilterChange(index, "added");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) table.onFilterChange(index, "hold");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`)
          table.onFilterChange(index, "cancel");
      } else if (filter.type === "multiSelect") {
        if (filter.column.key === "accepted") {
          let value = [];
          if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`))
            value = [...value, "all"];
          if (filter.column.key === "accepted" && filter.value.includes(`="" OR ${filter.column.path} IS NULL`))
            value = [...value, "empty"];
          if (
            filter.column.key === "accepted" &&
            filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
          )
            value = [...value, "accepted"];
          if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value = [...value, "hold"];
          if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
            value = [...value, "cancel"];
          this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
        } else {
          let value = filter.value
            .split("LIKE")
            .join("")
            .split("'%")
            .join("")
            .split("%'")
            .join("")
            .split(`OR ${filter.column.path}`)
            .join(",")
            .trim();
          let valueArray = value.split(",").map((item) => item.trim());
          table.onFilterChange(index, valueArray);
        }
      }
    });

    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/vm/collections?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexCollections(
      page,
      limit,
      search,
      sortingColumnsList,
      singleColumnFilterList,
      this.state.currentStateColumns
    );
  };

  indexCollections = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    axios
      .get(`/collections/${page}/${limit}.json`, {
        params: {
          phrase: phrase,
          sortPath: sortPath,
          filterPath: filterPath,
          currentSeasons: currentSeasons,
        },
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        this.setState({
          data: res.data.combinations,
          totalRow: res.data.total_row,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          loaded: true,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.combinations,
        });

        this.setDataRefTable();
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  setDataRefTable = () => {
    const stateTable = { ...this.state.tableRef };
    stateTable.current.state.data = this.state.tableRef.current.state.originalData;
    stateTable.current.state.renderData = this.state.tableRef.current.state.originalData;
    stateTable.current.dataManager.pagedData = this.state.tableRef.current.state.originalData;
    stateTable.current.dataManager.searchedData = this.state.tableRef.current.state.originalData;
    stateTable.current.dataManager.sortedData = this.state.tableRef.current.state.originalData;
    this.setState({ tableRef: stateTable });
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    const table = (
      <MaterialTable
        tableRef={this.state.tableRef}
        title={titleTemplate("Collections")}
        localization={{
          body: {
            emptyDataSourceMessage: emptyDataMessage,
            editRow: {
              cancelTooltip: "Back",
            },
          },
        }}
        icons={tableIcons}
        style={{ width: "100%" }}
        columns={this.state.columns}
        data={this.state.data}
        options={{
          pageSize: defaultPageSize,
          filtering: true,
          search: false,
        }}
        components={{
          Body: (props) => {
            return (
              <MTableBody
                {...props}
                onFilterChanged={(columnId, value) => {
                  const columnName = this.state.columns[columnId].field;
                  let singleColumnFilterList = this.state.singleColumnFilterList;
                  let indexIsExist = this.state.singleColumnFilterList.findIndex(
                    (filter) => filter.column.key === columnName
                  );
                  const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                  const table = this.state.tableRef.current;

                  if (this.state.columns[columnId].type === "dateType") {
                    if (indexIsExist !== -1) {
                      if (value.isEmpty) {
                        singleColumnFilterList[indexIsExist].value = "IS NULL";
                      } else if (value.isFull) {
                        singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                      } else {
                        const start = moment(value.start).format("YYYY-MM-DD");
                        const end = moment(value.end).format("YYYY-MM-DD");
                        singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                      }
                    } else {
                      if (value.isEmpty) {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "IS NULL",
                            type: "dateType",
                          },
                        ];
                      } else if (value.isFull) {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "IS NOT NULL",
                            type: "dateType",
                          },
                        ];
                      } else {
                        const start = moment(value.start).format("YYYY-MM-DD");
                        const end = moment(value.end).format("YYYY-MM-DD");
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `BETWEEN "${start}" AND "${end}"`,
                            type: "dateType",
                          },
                        ];
                      }
                    }
                  } else if (this.state.columns[columnId].type === "text") {
                    if (indexIsExist !== -1) {
                      singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                      table.state.columns[columnId].tableData.initialValue = value;
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: `LIKE '%${value}%'`,
                          type: "text",
                        },
                      ];
                      table.state.columns[columnId].tableData.initialValue = value;
                    }
                  } else if (this.state.columns[columnId].type === "boolean") {
                    if (indexIsExist !== -1) {
                      if (value === "checked") {
                        singleColumnFilterList.splice(indexIsExist, 1);
                        table.onFilterChange(columnId, null);
                      } else if (value === "unchecked") {
                        singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                        table.onFilterChange(columnId, undefined);
                      } else if (!value) {
                        singleColumnFilterList[indexIsExist].value = "=true";
                        table.onFilterChange(columnId, "checked");
                      }
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: "=true",
                          type: "boolean",
                        },
                      ];
                      table.onFilterChange(columnId, "checked");
                    }
                  } else if (this.state.columns[columnId].type === "number") {
                    const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                    if (indexIsExist !== -1) {
                      singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                      table.state.columns[columnId].tableData.initialValue = convertValue;
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: `= ${convertValue}`,
                          type: "number",
                        },
                      ];
                      table.state.columns[columnId].tableData.initialValue = convertValue;
                    }
                  } else if (this.state.columns[columnId].type === "select") {
                    if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                    else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                    else if (value === "accepted" || value === "added")
                      value = `<> '' AND ${sqlColumn[0].path} NOT LIKE "%CANCEL%" AND ${sqlColumn[0].path} NOT LIKE "%HOLD%" AND ${sqlColumn[0].path} IS NOT NULL`;
                    else value = `LIKE "%${value}%"`;

                    if (indexIsExist !== -1) {
                      singleColumnFilterList[indexIsExist].value = value;
                      table.state.columns[columnId].tableData.initialValue = value;
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: value,
                          type: "select",
                        },
                      ];
                      table.state.columns[columnId].tableData.initialValue = value;
                    }
                  } else if (this.state.columns[columnId].type === "multiSelect") {
                    if (this.state.columns[columnId].field === "accepted") {
                      let convertValue = "";
                      value.forEach((item, index) => {
                        if (item === "all")
                          convertValue +=
                            index < value.length - 1
                              ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                              : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                        else if (item === "empty" || item === "unadded")
                          convertValue +=
                            index < value.length - 1
                              ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                              : `="" OR ${sqlColumn[0].path} IS NULL`;
                        else if (item === "accepted" || item === "added")
                          convertValue +=
                            index < value.length - 1
                              ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                              : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                        else
                          convertValue +=
                            index < value.length - 1 ? `LIKE "%${item}%" OR ${sqlColumn[0].path} ` : `LIKE "%${item}%"`;
                      });

                      if (value.length > 0) {
                        if (indexIsExist !== -1) {
                          singleColumnFilterList[indexIsExist].value = convertValue;
                          table.state.columns[columnId].tableData.initialValue = value;
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: convertValue,
                              type: "multiSelect",
                            },
                          ];
                          table.state.columns[columnId].tableData.initialValue = value;
                        }
                      } else {
                        singleColumnFilterList = singleColumnFilterList.filter(
                          (filter) => filter.column.key !== "accepted"
                        );
                        table.state.columns[columnId].tableData.initialValue = ["all"];
                      }
                    } else {
                      if (indexIsExist !== -1) {
                        let multiFiltersValues = "";
                        value.forEach((filter, index) => {
                          multiFiltersValues +=
                            index === 0
                              ? `LIKE '%${value[index]}%'`
                              : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                        });
                        singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                        table.onFilterChange(columnId, value);
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "multiSelect",
                          },
                        ];
                        table.onFilterChange(columnId, value);
                      }
                    }
                  }
                  this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                  this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                }}
              />
            );
          },
          Toolbar: (props) => {
            const table = this.state.tableRef.current;

            return (
              <div className="mainToolbar">
                <MTableToolbar {...props} />

                <Chip
                  variant="outlined"
                  style={{ marginLeft: 24, marginTop: 5 }}
                  avatar={<HighlightOff />}
                  label="Clear filters"
                  onClick={() => {
                    this.goPage();
                    this.props.history.push(`/vm/collections?page=${1}&limit=${20}`);

                    table.state.columns.forEach((column, index) => {
                      if (column.field === "accepted") {
                        table.state.columns[index].tableData.filterValue = "all";
                        column.tableData.initialValue = "all";
                      } else {
                        table.onFilterChange(index, null);
                        table.state.columns[index].tableData.filterValue = null;
                        column.tableData.initialValue = "";
                      }
                    });
                  }}
                />

                {/* Eksportowanie danych z tabeli */}
                <div className={classes.exportToExcelWrapper}>
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 10 }}
                    avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                    label="Export data to Excel"
                    onClick={() => {
                      const startTime = Date.now();
                      this.setState({ loaded: false, filteredData: [] });
                      const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                      const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                        this.props.location.search,
                        sortingPhraseHelper
                      );
                      const sortPath = getSortPath(sortingColumnsList);
                      const filterPath = getFilterPath(singleColumnFilterList);

                      axios
                        .get(`/collections-export-to-excel.json`, {
                          params: {
                            phrase: search,
                            sortPath: sortPath,
                            filterPath: filterPath,
                            currentSeasons: currentSeasons,
                          },
                          headers: { Authorization: `Bearer ${this.props.token}` },
                        })
                        .then((res) => {
                          console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                          const data = res.data.combinations;

                          if (this.state.exportChosen) {
                            const exportColumns = this.state.columnsHidden.map((column) => {
                              return column.field;
                            });
                            data = data.map((row) => {
                              const toDelete = exportColumns;
                              const filtered = Object.keys(row)
                                .filter((key) => toDelete.includes(key))
                                .reduce((obj, key) => {
                                  obj[key] = row[key];
                                  return obj;
                                }, {});

                              return filtered;
                            });
                          }

                          json2excel({
                            data: data,
                            name: "collections_" + formatDate(Date.now()),
                            formateDate: "dd/mm/yyyy",
                          });

                          this.setState({ loaded: true, filteredData: this.state.data });
                        })
                        .catch((err) => {
                          catchResponse(err);
                        });
                    }}
                  />
                  <div className={classes.checkboxWrapper}>
                    <Checkbox
                      checked={this.state.exportChosen}
                      size={"small"}
                      onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                      color="primary"
                    />
                    <span>Export only chosen columns</span>
                  </div>
                </div>

                {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                  <Chip
                    style={{ marginLeft: 24, marginTop: 10 }}
                    onDelete={() => {
                      this.goPage(
                        0,
                        this.state.limit,
                        "",
                        this.state.sortingColumnsList,
                        this.state.singleColumnFilterList
                      );
                      this.setState({ filterPhrase: "" });
                    }}
                    label={`search: ${this.state.filterPhrase}`}
                    color="primary"
                  />
                )}

                {this.state.singleColumnFilterList.map((filter) => {
                  let value;
                  let key;
                  let icon = "";
                  if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                  else if (filter.type === "text")
                    value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                  else if (filter.type === "select")
                    value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                  else if (filter.type === "multiSelect")
                    value = filter.value
                      .split("LIKE")
                      .join("")
                      .split("'%")
                      .join("")
                      .split("%'")
                      .join("")
                      .split(`OR ${filter.column.path}`)
                      .join(",");
                  else if (filter.type === "number") value = filter.value.replace("=", "");
                  else if (filter.type === "boolean") {
                    if (filter.value.includes("true")) {
                      value = "checked";
                      icon = <Check />;
                    } else {
                      value = "unchecked";
                      icon = <Close />;
                    }
                  }

                  /*WYJĄTKI*/
                  if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
                    value = "ALL";
                  if (filter.column.key === "index" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
                    value = "ALL";
                  if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                    value = "EMPTY";
                  if (
                    filter.column.key === "accepted" &&
                    filter.value ===
                      `<> '' AND ${filter.column.path} NOT LIKE "%CANCEL%" AND ${filter.column.path} NOT LIKE "%HOLD%" AND ${filter.column.path} IS NOT NULL`
                  )
                    value = "ACCEPTED";
                  if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) value = "HOLD";
                  if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`) value = "CANCEL";
                  if (
                    filter.column.key === "proforma_document" &&
                    filter.value === `="" OR ${filter.column.path} IS NULL`
                  )
                    value = "UNADDED";
                  if (
                    filter.column.key === "proforma_document" &&
                    filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`
                  )
                    value = "ADDED";
                  if (filter.column.key === "index" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                    value = "UNADDED";
                  if (filter.column.key === "index" && filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`)
                    value = "ADDED";
                  filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                  if (filter.column.key === "payment_term_id") {
                    let currentValue = "";
                    const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                    const paymnetValue = value.replace(/\s/g, "").split(",");
                    paymnetValue.forEach((item, index) =>
                      index + 1 === paymnetValue.length
                        ? (currentValue += `${column[parseInt(item)]}`)
                        : (currentValue += `${column[parseInt(item)]}, `)
                    );
                    value = currentValue;
                    key = "PAYMENT";
                  }

                  /*MULTISELECT ACCEPTED WYJĄTKI*/
                  if (filter.column.key === "accepted") {
                    value = "";
                    if (
                      filter.column.key === "accepted" &&
                      filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)
                    )
                      value += "ALL ";
                    if (
                      filter.column.key === "accepted" &&
                      filter.value.includes(`="" OR ${filter.column.path} IS NULL`)
                    )
                      value += "EMPTY ";
                    if (
                      filter.column.key === "accepted" &&
                      filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
                    )
                      value += "ACCEPTED ";
                    if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value += "HOLD ";
                    if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
                      value += "CANCEL ";
                  }

                  return (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                          (item) => item.column.key !== filter.column.key
                        );
                        this.goPage(
                          0,
                          this.state.limit,
                          this.state.filterPhrase,
                          this.state.sortingColumnsList,
                          singleColumnFilterList
                        );
                        this.setState({ singleColumnFilterList: singleColumnFilterList });
                        const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                        if (index !== -1) {
                          if (table.state.columns[index].field === "accepted") {
                            table.state.columns[index].tableData.initialValue = ["all"];
                          } else {
                            table.state.columns[index].tableData.initialValue = "";
                            table.onFilterChange(index, null);
                          }
                        }
                      }}
                      label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                      icon={icon}
                      variant="outlined"
                    />
                  );
                })}

                {this.state.sortingColumnsList.map((column) => {
                  let key;
                  let icon = "";
                  if (column.order === "ASC") icon = <ArrowDownward />;
                  if (column.order === "DESC") icon = <ArrowUpward />;

                  /*WYJĄTKI*/
                  column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                  return (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        const sortingColumnsList = this.state.sortingColumnsList.filter(
                          (item) => item.sortBy.key !== column.sortBy.key
                        );
                        this.goPage(
                          0,
                          this.state.limit,
                          this.state.filterPhrase,
                          sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ sortingColumnsList: sortingColumnsList });
                      }}
                      label={`${key.toUpperCase().replace("_", " ")}`}
                      icon={icon}
                    />
                  );
                })}
              </div>
            );
          },
          Pagination: (props) => {
            return (
              <TablePagination
                {...props}
                rowsPerPage={this.state.limit}
                count={this.state.totalRow}
                page={this.state.page}
                onChangePage={(e, page) => {
                  this.state.tableRef.current.onChangePage(e, page);
                  this.setState({ page: page });
                  this.goPage(
                    page,
                    this.state.limit,
                    this.state.filterPhrase,
                    this.state.sortingColumnsList,
                    this.state.singleColumnFilterList
                  );
                }}
                onChangeRowsPerPage={(event) => {
                  props.onChangeRowsPerPage(event);
                  this.setState({ limit: event.target.value });
                  this.goPage(
                    this.state.page,
                    event.target.value,
                    this.state.filterPhrase,
                    this.state.sortingColumnsList,
                    this.state.singleColumnFilterList
                  );
                }}
              />
            );
          },
        }}
      />
    );

    return (
      <div>
        <Helmet>
          <title>VM Collections | Carry System</title>
        </Helmet>

        {authRedirect(this.props.token)}
        {roleLimitedView("vm", this.props.role, this.props.user_id)}
        <HotKeys />

        {table}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Collections);
